import "./index.css";
import "react-toastify/dist/ReactToastify.css";

import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

import { RouterProvider } from "react-router-dom";

import Routes from "./Routes";
import CoreProvider from "./Context/Core";
import { ApolloProvider } from "@apollo/client";
import useClientGraphql from "./Hooks/useClientGraphql";
import { ThemeProvider } from "@mui/material";
import theme from "./Assets/Theme";
import ModalProvider from "./Context/Modal";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const App = () => {
  const { client, ErrorSubject } = useClientGraphql();
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <CoreProvider ErrorSubject={ErrorSubject}>
          <ModalProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <RouterProvider router={Routes} />
            </LocalizationProvider>
          </ModalProvider>
        </CoreProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
};

root.render(App());

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
