import { FC, useCallback, useEffect } from "react";
import { Add, Close } from "@mui/icons-material";

import {
	Autocomplete,
	Box,
	Button,
	ButtonGroup,
	Chip,
	Grid,
	Modal,
	TextField,
	Typography,
	useTheme
} from "@mui/material";

import { Controller, useForm } from "react-hook-form";
import uuid from "react-uuid";

import type { PLTFieldErrors } from "../../../../Types";
import { CommonAreasSchema } from "../../CommonAreas/Querys";

type CommonAreaTypeCreationModalProps = {
	isVisible: boolean;
	listCommonAreas: CommonAreasSchema[];
	errorsField: PLTFieldErrors;
	commonAreas: CommonAreasSchema[];
	onSuccess: (label: string, commonAreaID: number) => void;
	onClose: () => void;
};

export const CommonAreaTypeCreationModal: FC<CommonAreaTypeCreationModalProps> = ({
	isVisible,
	errorsField,
	listCommonAreas,
	onSuccess,
	onClose
}) => {
	const theme = useTheme();

	const { control, getValues, reset } = useForm<{
		label: string;
		commonAreaID: number;
	}>({
		defaultValues: {
			label: "",
			commonAreaID: 0
		}
	});

	const isError = useCallback(
		(key: string) => {
			return errorsField.some(error => error.key === key);
		},
		[errorsField]
	);

	const getTextError = useCallback(
		(key: string) => {
			return errorsField?.find(e => e.key === key)?.message ?? "";
		},
		[errorsField]
	);

	useEffect(() => {
		if (!isVisible) {
			reset();
		}
	}, [isVisible, reset]);

	return (
		<Modal open={isVisible} onClose={onClose}>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: 400,
					bgcolor: "background.paper",
					boxShadow: 24,
					p: 4
				}}
			>
				<Grid container flexDirection={"row"}>
					<Typography variant="h5" color={theme.palette.primary.main} textAlign={"center"}>
						Agregar Tipo de Espacio Común
					</Typography>

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={value}
									onChange={onChange}
									label="Etiqueta"
									variant="outlined"
									error={isError("label")}
									helperText={getTextError("label")}
								/>
							)}
							name="label"
						/>
					</Grid>

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							render={({ field: { onChange } }) => (
								<Autocomplete
									multiple={false}
									id={uuid()}
									size="small"
									options={listCommonAreas}
									getOptionLabel={(option: any) => option.label}
									onChange={(_, value) => {
										if (value) {
											onChange(value.id);
										}
									}}
									renderTags={(value, getTagProps) =>
										value.map((option: any, index) => (
											<Chip
												variant="outlined"
												label={option.label}
												size="small"
												{...getTagProps({ index })}
											/>
										))
									}
									renderInput={params => (
										<TextField
											{...params}
											variant="filled"
											label="Selecciona un Espacio Común"
											placeholder="Espacios Comunes"
											error={isError("commonAreaID")}
											helperText={getTextError("commonAreaID")}
										/>
									)}
								/>
							)}
							name="commonAreaID"
						/>
					</Grid>
				</Grid>

				<ButtonGroup
					style={{ paddingTop: 20, paddingBottom: 20, alignSelf: "center" }}
					variant="text"
					aria-label="text button group"
				>
					<Button onClick={onClose}>
						<Close /> Cancelar
					</Button>

					<Button onClick={() => onSuccess(getValues().label, getValues().commonAreaID)}>
						<Add /> Agregar
					</Button>
				</ButtonGroup>
			</Box>
		</Modal>
	);
};

export default CommonAreaTypeCreationModal;
