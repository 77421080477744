import React, { FC, useCallback, useEffect } from "react";
import { Add, Close } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Grid, Modal, TextField, Typography, useTheme } from "@mui/material";

import { Controller, useForm } from "react-hook-form";
import uuid from "react-uuid";
import { PLTFieldErrors } from "../../../../Types";
import { PropertySchema } from "../GraphQL";
type props = {
	visible: boolean;
	errorsField: PLTFieldErrors;
	property: PropertySchema | undefined;
	handleClose: () => void;
	onCancel: () => void;
	onUpdate: (form: PropertySchema) => void;
};

type PropertyForm = {
	residence_identification: string;
};
const ModalUpdateProperty: FC<props> = ({ visible, errorsField, property, handleClose, onUpdate, onCancel }) => {
	const theme = useTheme();

	const { control, getValues, reset, setValue } = useForm<PropertyForm>({
		defaultValues: {
			residence_identification: ""
		}
	});

	const isError = useCallback(
		(key: string) => {
			if (errorsField) {
				return errorsField.find(e => e.key === key) ? true : false;
			}
			return false;
		},
		[errorsField]
	);

	const getTextError = useCallback(
		(key: string) => {
			console.log("ERRORs-key", key);
			if (errorsField) {
				const found = errorsField.find(e => e.key === key);
				if (found) return found.message;
			}
			return "";
		},
		[errorsField]
	);

	useEffect(() => {
		if (!visible) reset();
	}, [visible]);

	useEffect(() => {
		if (property) setValue("residence_identification", property.residence_identification);
	}, [property]);

	return (
		<Modal open={visible} onClose={handleClose}>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: 400,
					bgcolor: "background.paper",
					boxShadow: 24,
					p: 4
				}}
			>
				<Grid container flexDirection={"row"}>
					<Typography variant="h5" color={theme.palette.primary.main} textAlign={"center"}>
						Actualizar Inmueble
					</Typography>
					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={value}
									onChange={onChange}
									label="Texto"
									variant="outlined"
									error={isError("residence_identification")}
									helperText={getTextError("residence_identification")}
								/>
							)}
							name="residence_identification"
						/>
					</Grid>
				</Grid>
				<ButtonGroup
					style={{ paddingTop: 20, paddingBottom: 20, alignSelf: "center" }}
					variant="text"
					aria-label="text button group"
				>
					<Button onClick={onCancel}>
						<Close /> Cancelar
					</Button>
					<Button
						onClick={() =>
							onUpdate({
								...property,
								residence_identification: getValues().residence_identification
							} as PropertySchema)
						}
					>
						<Add /> Actualizar
					</Button>
				</ButtonGroup>
			</Box>
		</Modal>
	);
};
export default ModalUpdateProperty;
