import { gql } from "@apollo/client";

export type EnablePLATSupport = {
  EnablePLATSupport?: boolean;
};

export type DisablePLATSupport = {
  DisablePLATSupport?: boolean;
};

export const mutation_enable_plat_support = gql`
  mutation EnableCondoPLATSupport {
    EnableCondoPLATSupport
  }
`;

export const mutation_disable_plat_support = gql`
  mutation DisableCondoPLATSupport {
    DisableCondoPLATSupport
  }
`;
