import {
	Box,
	Button,
	ButtonGroup,
	Checkbox,
	FormControlLabel,
	FormHelperText,
	Grid,
	Modal,
	TextField,
	Typography,
	useTheme
} from "@mui/material";

import { useCallback, useEffect, type FC } from "react";
import { Add, Close } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import uuid from "react-uuid";
import { PLTFieldErrors, UserRole } from "../../../../Types";

interface Props {
	visible: boolean;
	user: UpdateUserForm | undefined;
	errorsField: PLTFieldErrors;
	handleClose: (state: boolean) => void;
	onUpdate: (form: UpdateUserForm) => void;
	onCancel: () => void;
}

interface UserForm {
	name: string;
	last_name: string;
	email?: string;
	phone_number?: string;
	password: string;
	enroll: boolean;
}

export type UpdateUserForm = UserForm & { id: number; role: UserRole };
const ModalUpdateWorker: FC<Props> = ({ visible, user, errorsField, handleClose, onUpdate, onCancel }) => {
	const { control, getValues, reset, setValue } = useForm<UserForm>({
		defaultValues: {
			name: "",
			last_name: "",
			email: "",
			phone_number: "",
			password: "",
			enroll: false
		}
	});

	const theme = useTheme();

	const hasError = useCallback(
		(key: string) => {
			if (errorsField) {
				return errorsField.find(e => e.key === key) ? true : false;
			}

			return false;
		},
		[errorsField]
	);

	const getErrorMessage = useCallback(
		(key: string) => {
			console.log("ERRORs-key", key);

			if (errorsField) {
				const found = errorsField.find(e => e.key === key);

				if (found) {
					return found.message;
				}
			}

			return "";
		},
		[errorsField]
	);

	useEffect(() => {
		if (!visible) {
			reset();
		}
	}, [visible]);

	useEffect(() => {
		if (visible && user) {
			setValue("name", user.name);
			setValue("last_name", user.last_name);
			setValue("email", user.email);
			setValue("phone_number", user.phone_number);
			setValue("enroll", user.enroll);
		}
	}, [visible, user]);

	return (
		<Modal open={visible} onClose={handleClose}>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: 400,
					bgcolor: "background.paper",
					boxShadow: 24,
					p: 4
				}}
			>
				<Grid container flexDirection={"row"}>
					<Typography variant="h5" color={theme.palette.primary.main} textAlign={"center"}>
						Actualizar Colaborador
					</Typography>

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={value}
									onChange={onChange}
									label="Nombre"
									variant="outlined"
									error={hasError("name")}
									helperText={getErrorMessage("name")}
								/>
							)}
							name="name"
						/>
					</Grid>

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={value}
									onChange={onChange}
									label="Apellido"
									variant="outlined"
									error={hasError("last_name")}
									helperText={getErrorMessage("last_name")}
								/>
							)}
							name="last_name"
						/>
					</Grid>

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={value}
									onChange={onChange}
									label="Email"
									variant="outlined"
									error={hasError("email")}
									helperText={getErrorMessage("email")}
								/>
							)}
							name="email"
						/>
					</Grid>

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							name="phone_number"
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={value}
									onChange={onChange}
									label="Número telefónico"
									variant="outlined"
									error={hasError("phone_number")}
									helperText={getErrorMessage("phone_number")}
								/>
							)}
						/>
					</Grid>

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={value}
									onChange={onChange}
									label="Contraseña"
									variant="outlined"
									error={hasError("password")}
									helperText={getErrorMessage("password")}
								/>
							)}
							name="password"
						/>
					</Grid>

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							render={({ field: { onChange, value } }) => (
								<>
									<FormControlLabel
										id={uuid()}
										control={
											<Checkbox
												onChange={e => onChange(e.target.checked)}
												value={value}
												checked={value}
											/>
										}
										label="Permitdo registrar"
									/>
									<FormHelperText>
										{hasError("enroll") ? getErrorMessage("enroll") : ""}
									</FormHelperText>
								</>
							)}
							name="enroll"
						/>
					</Grid>
				</Grid>

				<ButtonGroup
					style={{ paddingTop: 20, paddingBottom: 20, alignSelf: "center" }}
					variant="text"
					aria-label="text button group"
				>
					<Button onClick={onCancel}>
						<Close /> Cancelar
					</Button>

					<Button
						onClick={() => {
							if (user)
								onUpdate({
									name: getValues().name,
									last_name: getValues().last_name,
									email: getValues().email,
									phone_number: getValues().phone_number,
									password: getValues().password,
									enroll: getValues().enroll,
									role: UserRole.worker,
									id: Number(user.id)
								});
						}}
					>
						<Add /> Actualizar
					</Button>
				</ButtonGroup>
			</Box>
		</Modal>
	);
};

export default ModalUpdateWorker;
