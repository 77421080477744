import {
	GET_IN_PROGRESS_DELIVERY_LOGS,
	FINISH_DELIVERIES_WITHOUT_RESIDENT,
	type DeliveryActionLogSchema,
	type GetInProgressDeliveryLogsResponseData,
	type FinishDeliveriesWithoutResidentResponseData
} from "./GraphQL";

import useGraphQL from "../../../Hooks/useGraphQL";

import { useEffect, useState, useCallback } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Box, Button } from "@mui/material";

import {
	DataGrid,
	esES,
	type GridColDef,
	type GridRowSelectionModel,
	type GridRowClassNameParams
} from "@mui/x-data-grid";

import { styled } from "@mui/material/styles";

const dataGridColumns: GridColDef[] = [
	{ field: "local_datetime", headerName: "Fecha", flex: 1 },
	{ field: "property_label", headerName: "Inmueble", flex: 1 },
	{ field: "resident_full_name", headerName: "Residente", flex: 1 },
	{ field: "user_full_name", headerName: "Trabajador", flex: 1 },
	{ field: "packages_count", headerName: "Número de Paquetes", flex: 1 }
];

const StyledDataGrid = styled(DataGrid<DeliveryActionLogSchema>)(() => ({
	"& .expired-delivery": {
		backgroundColor: "#E57373",
		"&:hover": {
			backgroundColor: "#FF8A80"
		},
		"&.Mui-selected": {
			backgroundColor: "#EF5350",
			"&:hover": {
				backgroundColor: "#D32F2F"
			}
		}
	}
}));

export default function CondosPendingDeliveries(): JSX.Element {
	const [deliveryLogs, setDeliveryLogs] = useState<DeliveryActionLogSchema[]>([]);
	const [selectedDeliveryLogsIDs, setSelectedDeliveryLogsIDs] = useState<number[]>([]);

	const { useLazyGraphQuery, useGraphMutation } = useGraphQL();

	const [getInProgressDeliveryLogs] =
		useLazyGraphQuery<GetInProgressDeliveryLogsResponseData>(GET_IN_PROGRESS_DELIVERY_LOGS);

	const [finishDeliveries, { loading: isFinishingDeliveries }] =
		useGraphMutation<FinishDeliveriesWithoutResidentResponseData>(FINISH_DELIVERIES_WITHOUT_RESIDENT);

	const fetchDeliveryLogs = async () => {
		const { data } = await getInProgressDeliveryLogs();

		if (!data) {
			return;
		}

		setDeliveryLogs(data.GetInProgressDeliveryLogs.filter(deliveryLog => deliveryLog.delivery_id !== null));
	};

	const onRowSelection = useCallback((selectedRowsIDs: GridRowSelectionModel) => {
		setSelectedDeliveryLogsIDs(selectedRowsIDs as number[]);
	}, []);

	const onFinishDeliveriesButtonClick = useCallback(async () => {
		const { data } = await finishDeliveries({
			variables: {
				deliveriesIDs: selectedDeliveryLogsIDs
			}
		});

		if (!data) {
			const pluralS = selectedDeliveryLogsIDs.length > 1 ? "s" : "";
			toast.error(`Ocurrió un error al finalizar la${pluralS} encomienda${pluralS}.`);

			return;
		}

		setDeliveryLogs(
			deliveryLogs.filter(deliveryLog => !selectedDeliveryLogsIDs.includes(deliveryLog.delivery_id!))
		);

		const finishedDeliveriesCount = data.finishDeliveriesWithoutResident;
		const pluralS = finishedDeliveriesCount > 1 ? "s" : "";

		toast.success(`${finishedDeliveriesCount} encomiendas${pluralS} finalizada${pluralS} correctamente.`);
	}, [deliveryLogs, selectedDeliveryLogsIDs]);

	const getRowID = useCallback((row: DeliveryActionLogSchema) => row.delivery_id!, []);

	const getRowClassName = useCallback((params: GridRowClassNameParams<DeliveryActionLogSchema>) => {
		return params.row.is_delivery_expired ? "expired-delivery" : "";
	}, []);

	useEffect(() => {
		fetchDeliveryLogs();
	}, []);

	return (
		<Box>
			<Box display="flex" flexDirection="column" rowGap={4}>
				<Button
					style={{ alignSelf: "flex-start" }}
					variant="contained"
					disabled={selectedDeliveryLogsIDs.length === 0 || isFinishingDeliveries}
					onClick={onFinishDeliveriesButtonClick}
				>
					{`Finalizar ${selectedDeliveryLogsIDs.length} de ${deliveryLogs.length} encomiendas`}
				</Button>

				<StyledDataGrid
					rows={deliveryLogs}
					columns={dataGridColumns}
					onRowSelectionModelChange={onRowSelection}
					getRowId={getRowID}
					getRowClassName={getRowClassName}
					localeText={esES.components.MuiDataGrid.defaultProps.localeText}
					sx={theme => ({
						"& .MuiDataGrid-columnHeadersInner": {
							background: theme.palette.primary.main,
							color: "#fff",
							"& .MuiCheckbox-root": {
								color: "#fff"
							}
						}
					})}
					checkboxSelection
				/>
			</Box>

			<ToastContainer position="bottom-left" autoClose={3000} />
		</Box>
	);
}
