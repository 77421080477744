import { useEffect, memo, type FC } from "react";
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from "recharts";
import { Card, CircularProgress } from "@mui/material";
import useGraphQL from "../../../../../Hooks/useGraphQL";

import {
	GET_IN_PROGRESS_DELIVERIES_COUNT,
	GET_EXPIRED_DELIVERIES_COUNT,
	type InProgressDeliveriesCountData,
	type ExpiredDeliveriesCountData
} from "../../GraphQL";

const colors = ["#81C784", "#E57373"];

const DeliveriesPieChart: FC<{ condoID: number }> = ({ condoID }) => {
	const { useLazyGraphQuery } = useGraphQL();

	const [
		getInProgressDeliveriesCount,
		{ data: inProgressDeliveriesCountData, loading: isLoadingInProgressDeliveriesCounts }
	] = useLazyGraphQuery<InProgressDeliveriesCountData>(GET_IN_PROGRESS_DELIVERIES_COUNT);

	const [getExpiredDeliveriesCount, { data: expiredDeliveriesCountData, loading: isLoadingExpiredDeliveriesCounts }] =
		useLazyGraphQuery<ExpiredDeliveriesCountData>(GET_EXPIRED_DELIVERIES_COUNT);

	const inProgressDeliveriesCount = inProgressDeliveriesCountData?.inProgressDeliveriesCount ?? 0;
	const expiredDeliveriesCount = expiredDeliveriesCountData?.expiredDeliveriesCount ?? 0;

	const deliveriesCountsData = [
		{
			name: "Pendientes de entrega",
			value: inProgressDeliveriesCount ? inProgressDeliveriesCount - expiredDeliveriesCount : 0
		},
		{
			name: "Expiradas",
			value: expiredDeliveriesCount
		}
	];

	const Loader = () => (
		<div style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
			<CircularProgress />
		</div>
	);

	useEffect(() => {
		if (condoID) {
			getInProgressDeliveriesCount({ variables: { condoID } });
			getExpiredDeliveriesCount({ variables: { condoID } });
		}
	}, [condoID]);

	if (!condoID) {
		return <Loader />;
	}

	if (isLoadingInProgressDeliveriesCounts || isLoadingExpiredDeliveriesCounts) {
		return <Loader />;
	}

	return (
		<Card
			style={{
				width: 370,
				height: 370
			}}
		>
			<ResponsiveContainer width="100%" height="100%">
				<PieChart>
					<Pie
						data={deliveriesCountsData}
						cx="50%"
						cy="50%"
						labelLine={false}
						label={({ name, value }) => `${value}`}
						fill="rgba(255, 255, 255, 0.4)"
						dataKey="value"
					>
						{colors.map(color => (
							<Cell fill={color} />
						))}
					</Pie>

					<Legend layout="horizontal" verticalAlign="bottom" align="center" />
				</PieChart>
			</ResponsiveContainer>
		</Card>
	);
};

export default memo(DeliveriesPieChart);
