import { gql } from "@apollo/client";

type commonSelector = {
  id: string;
  title: string;
};

type CompanyMaintenanceTypes = {
  CompanyMaintenanceTypes: Array<{
    MaintenanceType: commonSelector;
  }>;
};

export type MaintenanceCompanySchema = commonSelector & CompanyMaintenanceTypes;

export type TypesMaintenanceSchema = commonSelector;

export type RequestGetMaintenanceCompany = {
  GetAllMaintenanceCompaniesByCondoID?: Array<MaintenanceCompanySchema>;
};
export type RequestCreateMaintenanceCompany = {
  CreateMaintenanceCompany?: Array<MaintenanceCompanySchema>;
};
export type ToggleMaintenanceCompanyRequest = {
  ToggleMaintenanceCompanyActiveStatus: Array<MaintenanceCompanySchema>;
};
export type RequestUpdateMaintenanceCompany = {
  UpdateMaintenanceCompany?: Array<MaintenanceCompanySchema>;
};
export type RequestDeleteMaintenanceCompany = {
  DeleteMaintenanceCompany?: Array<MaintenanceCompanySchema>;
};

export type RequestGetTypeMaintenance = {
  GetAllMaintenanceTypesByCondoID?: Array<TypesMaintenanceSchema>;
};

const schema = `
id
title
active
`;

const company_with_maintenance = `
CompanyMaintenanceTypes {
  MaintenanceType {
    id
    title
  }
}`;

export const query_get_maintenance_company = gql`
  query GetAllMaintenanceCompaniesByCondoID {
    GetAllMaintenanceCompaniesByCondoID {
      ${schema}
      ${company_with_maintenance}
    }
  }
`;

export const mutation_create_maintenance_company = gql`
  mutation CreateMaintenanceCompany($creationForm: MaintenanceCompanyCreationForm!) {
    CreateMaintenanceCompany(creationForm: $creationForm) {
      ${schema}
      ${company_with_maintenance}
    }
  }
`;

export const mutation_toggle_maintenance_company = gql`
  mutation ToggleMaintenanceCompanyActiveStatus($company_id: Int!) {
    ToggleMaintenanceCompanyActiveStatus(company_id: $company_id) {
      ${schema}
      ${company_with_maintenance}
    }
  }
`;

export const mutation_delete_maintenance_company = gql`
  mutation DeleteMaintenanceCompany($company_id: Int!) {
    DeleteMaintenanceCompany(company_id: $company_id) {
      ${schema}
      ${company_with_maintenance}
    }
  }
`;

export const mutation_update_maintenance_company = gql`
  mutation UpdateMaintenanceCompany($updateForm: MaintenanceCompanyUpdateForm!) {
    UpdateMaintenanceCompany(updateForm: $updateForm) {
      ${schema}
      ${company_with_maintenance}
    }
  }
`;

export const query_get_type_maintenance = gql`
  query GetAllMaintenanceTypesByCondoID {
    GetAllMaintenanceTypesByCondoID {
      ${schema}
    }
  }
`;
