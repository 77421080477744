import { gql } from "@apollo/client";

export type LoginSchema = {
  id: number;
  name: string;
  last_name: string;
  email: string;
  role: string;
  active: boolean;
  enroll: boolean;
  token: string;
  user: string;
};

export type LoginRequest = {
  Login: LoginSchema;
};

export const query_login = gql`
  mutation Login($FormLogin: FormLogin!) {
    Login(FormLogin: $FormLogin) {
        id
        name
        last_name
        email
        role
        active
        enroll
        token
        user
    }
  }
`;
