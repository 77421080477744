import {
	GET_GLOBAL_SETTINGS,
	UPDATE_GLOBAL_SETTINGS,
	type GetGlobalSettingsResponseData,
	type UpdateGlobalSettingsResponseData,
	type GlobalSettingsUpdateInput
} from "./GraphQL";

import useGraphQL from "../../../Hooks/useGraphQL";
import useErrorGraphQL from "../../../Hooks/useErrorGraphql";

import { useCallback, useEffect } from "react";
import { Box, Grid, TextField, Button } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";

export default function GlobalSettingsScreen(): JSX.Element {
	const { control, getValues, setValue } = useForm<GlobalSettingsUpdateInput>({
		defaultValues: {
			invitationsGracePeriodMinutes: 0,
			invitationsValidityWindowMinutes: 0,
			platContactNumber: ""
		}
	});

	const { useLazyGraphQuery, useGraphMutation } = useGraphQL();
	const { errorsField: fieldErrors, setErrorFields: setFieldErrors } = useErrorGraphQL();

	const [getGlobalSettings] = useLazyGraphQuery<GetGlobalSettingsResponseData>(GET_GLOBAL_SETTINGS);
	const [updateGlobalSettings] = useGraphMutation<UpdateGlobalSettingsResponseData>(UPDATE_GLOBAL_SETTINGS);

	const hasFieldError = useCallback((key: string) => fieldErrors?.some(error => error.key === key), [fieldErrors]);

	const getFieldErrorMessage = useCallback(
		(key: string) => fieldErrors?.find(error => error.key === key)?.message ?? "",
		[fieldErrors]
	);

	const phoneNumberRegex = /^\+?(56)?\s?(9\s?)?\d{4}\s?\d{4}$/;

	const fetchGlobalSettings = useCallback(async () => {
		const response = await getGlobalSettings();

		if (response.data) {
			setValue("invitationsGracePeriodMinutes", response.data.globalSettings.invitations_grace_period_minutes);

			setValue(
				"invitationsValidityWindowMinutes",
				response.data.globalSettings.invitations_validity_window_minutes
			);

			setValue("platContactNumber", response.data.globalSettings.plat_contact_number);
		}
	}, []);

	const handleGlobalSettingsUpdate = useCallback(async () => {
		setFieldErrors(undefined);

		const updateInput = getValues();
		const response = await updateGlobalSettings({ variables: { updateInput } });

		if (!response.data) {
			return;
		}

		toast.success("Ajustes actualizados correctamente.");
	}, [setFieldErrors, getValues, updateGlobalSettings]);

	useEffect(() => {
		fetchGlobalSettings();
	}, [fetchGlobalSettings]);

	return (
		<Box>
			<Box display="flex" flexDirection="column" rowGap={1}>
				<Box>
					<h2>Invitaciones:</h2>

					<Box
						display="flex"
						flexDirection="column"
						justifyContent="space-between"
						width="37.5%"
						height={150}
					>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<Controller
								control={control}
								name="invitationsGracePeriodMinutes"
								render={({ field: { onChange, value } }) => (
									<TextField
										type="number"
										inputProps={{ min: 0 }}
										color="primary"
										id="invitationsGracePeriodMinutesInput"
										fullWidth
										value={value}
										onChange={e => onChange(Number(e.target.value))}
										label="Minutos para que expire la invitación"
										variant="outlined"
										error={hasFieldError("invitationsGracePeriodMinutes")}
										helperText={getFieldErrorMessage("invitationsGracePeriodMinutes")}
									/>
								)}
							/>
						</Grid>

						<Grid item xs={12} sm={12} md={12} lg={12}>
							<Controller
								control={control}
								name="invitationsValidityWindowMinutes"
								render={({ field: { onChange, value } }) => (
									<TextField
										type="number"
										inputProps={{ min: 15 }}
										color="primary"
										id="invitationsValidityWindowMinutesInput"
										fullWidth
										value={value}
										onChange={e => onChange(Number(e.target.value))}
										label="Minutos máximos faltantes para recibir al invitado"
										variant="outlined"
										error={hasFieldError("invitationsValidityWindowMinutes")}
										helperText={getFieldErrorMessage("invitationsValidityWindowMinutes")}
									/>
								)}
							/>
						</Grid>
					</Box>
				</Box>

				<Box>
					<h2>PLAT:</h2>

					<Box display="flex" flexDirection="column" justifyContent="space-between" width="37.5%" height={75}>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<Controller
								control={control}
								name="platContactNumber"
								render={({ field: { onChange, value } }) => (
									<TextField
										inputProps={{ minLength: 9, maxLength: 15, pattern: phoneNumberRegex }}
										color="primary"
										id="platContactNumberInput"
										fullWidth
										value={value}
										onChange={e => onChange(e.target.value)}
										label="Número de contacto"
										variant="outlined"
										error={hasFieldError("platContactNumber")}
										helperText={getFieldErrorMessage("platContactNumber")}
									/>
								)}
							/>
						</Grid>
					</Box>
				</Box>

				<Button
					style={{ width: "30%", marginTop: "15px" }}
					variant="contained"
					onClick={handleGlobalSettingsUpdate}
				>
					Guardar
				</Button>
			</Box>

			<ToastContainer position="bottom-left" autoClose={3000} />
		</Box>
	);
}
