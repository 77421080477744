import { gql } from "@apollo/client";
import { CondoSchema } from "../../Condos/GraphQL";
import { UserRole } from "../../../../Types";

export type UserForm = {
	name: string;
	last_name: string;
	email?: string;
	phone_number?: string;
	rut?: string;
	password?: string;
	enroll: boolean;
	role: string;
	user?: string;
};

export type Condos = Array<{ Condo: CondoSchema }>;

export interface UserSchema {
	id: number;
	name: string;
	rut: string;
	last_name: string;
	user?: string;
	role: UserRole;
	active: boolean;
	enroll: boolean;
	UsersInCondos?: Condos;
	email?: string;
	phone_number?: string;
	full_name: string;
	formatted_rut?: string;
}

export interface UserDescription extends UserSchema {
	[key: string]: any;
	role_description: string;
}

export type RegisterUserResponse = {
	RegisterUser?: Array<UserSchema>;
};

export type UpdateUserResponse = {
	UpdateUser?: Array<UserSchema>;
};

export type GetAllUserResponse = {
	GetAllPLTAndADMINUsers?: Array<UserSchema>;
};

export type ToggleUsersResponse = {
	ChangeActiveUser?: Array<UserSchema>;
};

export type DeleteUsersResponse = {
	DeleteUser?: Array<UserSchema>;
};

export type AddAdminToCondosRequest = {
	AddAdminToCondo?: Array<UserSchema>;
};

export type DeleteAdminToCondosRequest = {
	DeleteAdminInCondo?: Array<UserSchema>;
};

const graphQLSchema = `
  id
  name
  last_name
  rut
  email
  phone_number
  role
  active
  enroll
  user
  formatted_rut

  UsersInCondos {
    Condo {
      id
      name
      pairing_code
      maximum_parking_time
      inactivity_time
    }
  }
`;

export const query_get_users = gql`
  query GetAllPLTAndADMINUsers {
    GetAllPLTAndADMINUsers {
      ${graphQLSchema}
    }
  }
`;

export const mutation_create_user = gql`
  mutation RegisterUser($FormCreateUser: FormCreateUser!) {
    RegisterUser(FormCreateUser: $FormCreateUser) {
        ${graphQLSchema}
    }
  }
`;

export const mutation_update_user = gql`
  mutation UpdateUser($FormUpdateUser: FormUpdateUser!) {
    UpdateUser(FormUpdateUser: $FormUpdateUser) {
        ${graphQLSchema}
    }
  }
`;

export const mutation_toggle_user = gql`
  mutation ChangeActiveUser($user_id: Int!) {
    ChangeActiveUser(user_id: $user_id) {
        ${graphQLSchema}
    }
  }
`;

export const mutation_delete_user = gql`
  mutation DeleteUser($user_id: Int!) {
    DeleteUser(user_id: $user_id) {
        ${graphQLSchema}
    }
  }
`;

export const mutation_add_admins_in_condos = gql`
  mutation AddAdminToCondo($admin_id: Int!, $condos: CondosInput!) {
    AddAdminToCondo(admin_id: $admin_id,condos:$condos) {
        ${graphQLSchema}
    }
  }
`;

export const mutation_delete_admins_in_condos = gql`
  mutation DeleteAdminInCondo($admin_id: Int!, $condos: CondosInput!) {
    DeleteAdminInCondo(admin_id: $admin_id,condos:$condos) {
        ${graphQLSchema}
    }
  }
`;

const userRoleSpanishDescriptions = {
	[UserRole.plt]: "Administrador PLAT",
	[UserRole.admin]: "Cliente Web",
	[UserRole.worker]: "Colaborador"
};

export function getUserRoleSpanishDescription(userRole: UserRole): string {
	return userRoleSpanishDescriptions[userRole];
}
