import { useCallback, useEffect, useState, useMemo } from "react";
import { Box, Button, ButtonGroup, Divider } from "@mui/material";
import { Add } from "@mui/icons-material";
import CustomTable, { type ControlsTable, type RowTable } from "../../Components/CustomTable";
import { useCore } from "../../../Context/Core";

import EmergencyContactCreationModal, {
	type EmergencyContactCreationModalInput
} from "./EmergencyContactCreationModal";

import EmergencyContactUpdateModal, { type EmergencyContactUpdateModalInput } from "./EmergencyContactUpdateModal";
import useTable from "../../Components/CustomTable/useTable";
import useGraphQL from "../../../Hooks/useGraphQL";
import AlertDialog from "../../Components/CustomAlertDialog";
import useErrorGraphql from "../../../Hooks/useErrorGraphql";
import uploadLogoFile from "../utils/uploadLogoFile";

import {
	CREATE_EMERGENCY_CONTACT,
	GET_ALL_EMERGENCY_CONTACTS,
	UPDATE_EMERGENCY_CONTACT,
	DELETE_EMERGENCY_CONTACT,
	TOGGLE_EMERGENCY_CONTACT_ACTIVE_STATUS,
	type EmergencyContactSchema,
	type CreateEmergencyContactData,
	type GetAllEmergencyContactsData,
	type UpdateEmergencyContactData,
	type DeleteEmergencyContactData,
	type ToggleEmergencyContactActiveStatusData
} from "./GraphQL";

import { ToastContainer, toast } from "react-toastify";

const headerRow = [
	{ id: "name", label: "Nombre" },
	{ id: "phone_number", label: "Número Telefónico" },
	{ id: "description", label: "Descripción" },
	{ id: "is_active", label: "Activo/Inactivo" },
	{ id: "actions", label: "Acciones" }
];

export default function EmergencyContactsScreen(): JSX.Element {
	const [emergencyContacts, setEmergencyContacts] = useState<EmergencyContactSchema[]>([]);
	const [isCreatingemergencyContact, setIsCreatingemergencyContact] = useState(false);

	const [updateState, setUpdateState] = useState<{
		isUpdating: boolean;
		emergencyContact?: EmergencyContactSchema;
	}>({ isUpdating: false, emergencyContact: undefined });

	const [shouldConfirmDeletion, setShouldConfirmDeletion] = useState(false);
	const [idToDelete, setIDToDelete] = useState<number | undefined>();

	const { useLazyGraphQuery, useGraphMutation } = useGraphQL();
	const { errorsField, setErrorFields } = useErrorGraphql();

	const [createEmergencyContact] = useGraphMutation<CreateEmergencyContactData>(CREATE_EMERGENCY_CONTACT);
	const [getAllEmergencyContacts] = useLazyGraphQuery<GetAllEmergencyContactsData>(GET_ALL_EMERGENCY_CONTACTS);
	const [updateEmergencyContact] = useGraphMutation<UpdateEmergencyContactData>(UPDATE_EMERGENCY_CONTACT);
	const [deleteEmergencyContact] = useGraphMutation<DeleteEmergencyContactData>(DELETE_EMERGENCY_CONTACT);

	const [toggleEmergencyContactActiveStatus] = useGraphMutation<ToggleEmergencyContactActiveStatusData>(
		TOGGLE_EMERGENCY_CONTACT_ACTIVE_STATUS
	);

	const { token: bearerToken } = useCore();

	useEffect(() => {
		async function fetchEmergencyContacts(): Promise<void> {
			const response = await getAllEmergencyContacts();

			if (response.data) {
				setEmergencyContacts(response.data.GetAllEmergencyContacts);
			}
		}

		fetchEmergencyContacts();
	}, [getAllEmergencyContacts, setEmergencyContacts]);

	const emergencyContactCreationCallback = useCallback(
		async (creationInput: EmergencyContactCreationModalInput) => {
			let logoURL = "";

			if (creationInput.logoFile) {
				const logoUploadResponse = await uploadLogoFile(creationInput.logoFile, bearerToken!);

				if (logoUploadResponse) {
					logoURL = logoUploadResponse.imageURL ?? "";
				}
			}

			const defaultLogoURL =
				"https://static.innovaweb.cl/nodejs/plt_api_nodejs/emergency_contact_default_logo.jpeg";

			const response = await createEmergencyContact({
				variables: {
					creationInput: {
						name: creationInput.name,
						phoneNumber: creationInput.phoneNumber,
						logoURL: logoURL && logoURL.length > 0 ? logoURL : defaultLogoURL,
						description: creationInput.description
					}
				}
			});

			if (response.data) {
				toast.success("Contacto de emergencia agregado correctamente");

				setIsCreatingemergencyContact(false);
				setEmergencyContacts(response.data.CreateEmergencyContact);
			}
		},
		[uploadLogoFile, createEmergencyContact, toast, setIsCreatingemergencyContact, setEmergencyContacts]
	);

	const emergencyContactUpdateCallback = useCallback(
		async (emergencyContactID: number, updateInput: EmergencyContactUpdateModalInput) => {
			let logoURL: string | undefined;

			if (updateInput.logoFile) {
				const logoUploadResponse = await uploadLogoFile(updateInput.logoFile, bearerToken!);

				if (logoUploadResponse) {
					logoURL = logoUploadResponse.imageURL;
				}
			}

			const defaultLogoURL =
				"https://static.innovaweb.cl/nodejs/plt_api_nodejs/emergency_contact_default_logo.jpeg";

			const response = await updateEmergencyContact({
				variables: {
					id: emergencyContactID,
					updateInput: {
						name: updateInput.name,
						phoneNumber: updateInput.phoneNumber,
						logoURL: logoURL && logoURL.length > 0 ? logoURL : defaultLogoURL,
						description: updateInput.description
					}
				}
			});

			if (response.data) {
				toast.success("Contacto de emergencia actualizado correctamente");

				setUpdateState({ isUpdating: false, emergencyContact: undefined });
				setEmergencyContacts(response.data.UpdateEmergencyContact);
			}
		},
		[uploadLogoFile, updateEmergencyContact, toast, setUpdateState, setEmergencyContacts]
	);

	const emergencyContactDeletionCallback = useCallback(async () => {
		if (idToDelete === undefined) {
			return;
		}

		const response = await deleteEmergencyContact({
			variables: { id: idToDelete }
		});

		if (response.data) {
			toast.success("Contacto de emergencia eliminado correctamente");

			setEmergencyContacts(response.data.DeleteEmergencyContact);
			setShouldConfirmDeletion(false);
			setIDToDelete(undefined);
		}
	}, [idToDelete, deleteEmergencyContact, toast, setEmergencyContacts, setShouldConfirmDeletion, setIDToDelete]);

	const deletionCancelingCallback = useCallback(() => {
		setIDToDelete(undefined);
	}, [setIDToDelete]);

	const onUpdateCallback = useCallback(
		(row: RowTable) => {
			setErrorFields(undefined);
			setUpdateState({
				isUpdating: true,
				emergencyContact: row as EmergencyContactSchema
			});
		},
		[setErrorFields, setUpdateState]
	);

	const onDeleteCallback = useCallback(
		(row: RowTable) => {
			setShouldConfirmDeletion(true);
			setIDToDelete(row.id as number);
		},
		[setShouldConfirmDeletion, setIDToDelete]
	);

	const onActiveStatusToggleCallback = useCallback(
		async (row: RowTable) => {
			const response = await toggleEmergencyContactActiveStatus({
				variables: {
					id: row.id
				}
			});

			if (response.data) {
				setEmergencyContacts(response.data.ToggleEmergencyContactActiveStatus);
			}
		},
		[toggleEmergencyContactActiveStatus, setEmergencyContacts]
	);

	const tableProps = useTable();

	const customTableControls: ControlsTable = useMemo(
		() => ({
			...tableProps,
			onUpdate: onUpdateCallback,
			onDelete: onDeleteCallback,
			onActiveStatusToggle: onActiveStatusToggleCallback
		}),
		[tableProps, onUpdateCallback, onDeleteCallback, onActiveStatusToggleCallback]
	);

	const EmergencyContactsTable = () => (
		<CustomTable controls={customTableControls} columns={headerRow} rows={emergencyContacts} />
	);

	const onEmergencyContactCreationModalCloseCallback = useCallback(() => {
		setIsCreatingemergencyContact(false);
	}, [setIsCreatingemergencyContact]);

	const onEmergencyContactUpdateModalCloseCallback = useCallback(() => {
		setUpdateState({ isUpdating: false, emergencyContact: undefined });
	}, [setUpdateState]);

	return (
		<Box>
			<ButtonGroup style={{ paddingTop: 20, paddingBottom: 20 }} variant="text" aria-label="text button group">
				<Button
					onClick={() => {
						setErrorFields(undefined);
						setIsCreatingemergencyContact(true);
					}}
				>
					<Add /> Agregar Contacto de Emergencia
				</Button>
			</ButtonGroup>

			<Divider style={{ marginTop: 15, marginBottom: 15 }} />

			<EmergencyContactsTable />

			<EmergencyContactCreationModal
				errorsField={errorsField ?? []}
				isVisible={isCreatingemergencyContact}
				onSuccess={emergencyContactCreationCallback}
				onClose={onEmergencyContactCreationModalCloseCallback}
			/>

			<EmergencyContactUpdateModal
				errorsField={errorsField ?? []}
				isVisible={updateState.isUpdating}
				emergencyContact={updateState.emergencyContact!}
				onSuccess={emergencyContactUpdateCallback}
				onClose={onEmergencyContactUpdateModalCloseCallback}
			/>

			<AlertDialog
				title="Advertencia"
				open={shouldConfirmDeletion}
				setOpen={setShouldConfirmDeletion}
				warningMessage="Estás a punto de eliminar un contacto de emergencia. ¿Deseas continuar?"
				onCancel={deletionCancelingCallback}
				onConfirm={emergencyContactDeletionCallback}
			/>

			<ToastContainer position="bottom-left" autoClose={3000} />
		</Box>
	);
}
