import { useCallback, useEffect, useState, useMemo } from "react";
import { Box, Button, ButtonGroup, Divider } from "@mui/material";
import { Add } from "@mui/icons-material";
import CustomTable, { type ControlsTable, type RowTable } from "../../Components/CustomTable";
import BannedVisitorCreationModal, { type BannedVisitorCreationModalInput } from "./BannedVisitorCreationModal";
import BannedVisitorUpdateModal, { type BannedVisitorUpdateModalInput } from "./BannedVisitorUpdateModal";
import useTable from "../../Components/CustomTable/useTable";
import useGraphQL from "../../../Hooks/useGraphQL";
import AlertDialog from "../../Components/CustomAlertDialog";
import useErrorGraphql from "../../../Hooks/useErrorGraphql";

import {
	CREATE_BANNED_VISITOR,
	GET_ALL_BANNED_VISITORS,
	UPDATE_BANNED_VISITOR,
	DELETE_BANNED_VISITOR,
	type FormattedBannedVisitor,
	type CreateBannedVisitorData,
	type GetAllBannedVisitorsData,
	type UpdateBannedVisitorData,
	type DeleteBannedVisitorData
} from "./Query";

import { PropertySchema, query_get_properties, type RequestGetProperty } from "../CondosProperty/GraphQL";

import { ToastContainer, toast } from "react-toastify";
import { getNationalitySpanishDescription, Nationality } from "../CondosResidents/GraphQL";
import GuardCondos from "../../Shared/GuardCondos";
import { useCore } from "../../../Context/Core";

const headerRow = [
	{ id: "formattedIdentification", label: "Identificación" },
	{ id: "nationalityDescription", label: "Nacionalidad" },
	{ id: "actions", label: "Acciones" }
];

export default function BannedVisitorsScreen(): JSX.Element {
	const [bannedVisitors, setBannedVisitors] = useState<FormattedBannedVisitor[]>([]);
	const [isCreatingBannedVisitor, setIsCreatingBannedVisitor] = useState(false);
	const [properties, setProperties] = useState<PropertySchema[]>([]);

	const [updateState, setUpdateState] = useState<{
		isUpdating: boolean,
		bannedVisitor?: FormattedBannedVisitor
	}>({ isUpdating: false, bannedVisitor: undefined });

	const [shouldConfirmDeletion, setShouldConfirmDeletion] = useState(false);
	const [idToDelete, setIDToDelete] = useState<number | undefined>();

	const { useLazyGraphQuery, useGraphMutation } = useGraphQL();
	const { errorsField, setErrorFields } = useErrorGraphql();

	const [createBannedVisitor] = useGraphMutation<CreateBannedVisitorData>(CREATE_BANNED_VISITOR);
	const [getAllBannedVisitors] = useLazyGraphQuery<GetAllBannedVisitorsData>(GET_ALL_BANNED_VISITORS);
	const [updateBannedVisitor] = useGraphMutation<UpdateBannedVisitorData>(UPDATE_BANNED_VISITOR);
	const [deleteBannedVisitor] = useGraphMutation<DeleteBannedVisitorData>(DELETE_BANNED_VISITOR);
	const [getAllProperties] = useLazyGraphQuery<RequestGetProperty>(query_get_properties);

	const { condo } = useCore();

	useEffect(() => {
		async function fetchBannedVisitors(): Promise<void> {
			const response = await getAllBannedVisitors();

			if (response.data) {
				setBannedVisitors(response.data.GetAllBannedVisitors.map(bannedVisitor => ({
					...bannedVisitor,
					formattedIdentification: bannedVisitor.nationality == Nationality.CHILEAN
						? bannedVisitor.formatted_rut!
						: bannedVisitor.identification,
					nationalityDescription: getNationalitySpanishDescription(bannedVisitor.nationality)
				})));
			}
		}

		fetchBannedVisitors();
	}, [condo, getAllBannedVisitors, setBannedVisitors]);

	const fetchProperties = useCallback(async () => {
		const response = await getAllProperties();

		if (response.data) {
			setProperties(response.data.GetAllPropertiesByCondoId);
		}
	}, [getAllProperties, setProperties]);

	const bannedVisitorCreationCallback = useCallback(
		async (creationInput: BannedVisitorCreationModalInput) => {  
			const response = await createBannedVisitor({
				variables: {
					creationInput: {
						identification: creationInput.identification,
						nationality: creationInput.nationality,
						propertiesIDs: getPropertiesIDsFromPropertiesWithLabels(
							properties,
							creationInput.propertiesLabels
						)
					}
				}
			});

			if (response.data) {
				toast.success("Visita bloqueada agregada correctamente");

				setIsCreatingBannedVisitor(false);

				setBannedVisitors(response.data.CreateBannedVisitor.map(bannedVisitor => ({
					...bannedVisitor,
					formattedIdentification: bannedVisitor.nationality == Nationality.CHILEAN
						? bannedVisitor.formatted_rut!
						: bannedVisitor.identification,
					nationalityDescription: getNationalitySpanishDescription(bannedVisitor.nationality)
				})));
			}
		},
		[createBannedVisitor, properties, toast, setIsCreatingBannedVisitor, setBannedVisitors]
	);

	const bannedVisitorUpdateCallback = useCallback(
		async (bannedVisitorID: number, updateInput: BannedVisitorUpdateModalInput) => {
			const response = await updateBannedVisitor({
				variables: {
					id: bannedVisitorID,
					updateInput: {
						identification: updateInput.identification,
						nationality: updateInput.nationality,
						propertiesIDs: getPropertiesIDsFromPropertiesWithLabels(
							properties,
							updateInput.propertiesLabels
						)
					}
				}
			});

			if (response.data) {
				toast.success("Visita bloqueada actualizada correctamente");

				setUpdateState({ isUpdating: false, bannedVisitor: undefined });

				setBannedVisitors(response.data.UpdateBannedVisitor.map(bannedVisitor => ({
					...bannedVisitor,
					formattedIdentification: bannedVisitor.nationality == Nationality.CHILEAN
						? bannedVisitor.formatted_rut!
						: bannedVisitor.identification,
					nationalityDescription: getNationalitySpanishDescription(bannedVisitor.nationality)
				})));
			}
		},
		[updateBannedVisitor, properties, toast, setUpdateState, setBannedVisitors]
	);

	const bannedVisitorDeletionCallback = useCallback(async () => {
    	if (idToDelete === undefined) {
      		return;
    	}

		const response = await deleteBannedVisitor({
			variables: { id: idToDelete },
		});

    	if (response.data) {
			toast.success("Visita bloqueada eliminada correctamente");

			setBannedVisitors(response.data.DeleteBannedVisitor.map(bannedVisitor => ({
				...bannedVisitor,
					formattedIdentification: bannedVisitor.nationality == Nationality.CHILEAN
						? bannedVisitor.formatted_rut!
						: bannedVisitor.identification,
					nationalityDescription: getNationalitySpanishDescription(bannedVisitor.nationality)
			})));

			setShouldConfirmDeletion(false);
			setIDToDelete(undefined);
   		}
	}, [
		idToDelete,
		deleteBannedVisitor,
		toast,
		setBannedVisitors,
		setShouldConfirmDeletion,
		setIDToDelete,
	]);

	const deletionCancelingCallback = useCallback(() => {
		setIDToDelete(undefined);
	}, [setIDToDelete]);

	const onUpdateCallback = useCallback(
		async (row: RowTable) => {
			await fetchProperties();

			setErrorFields(undefined);

			setUpdateState({
				isUpdating: true,
				bannedVisitor: row as FormattedBannedVisitor,
			});
		},
		[fetchProperties, setErrorFields, setUpdateState]
	);

	const onDeleteCallback = useCallback(
		(row: RowTable) => {
			setShouldConfirmDeletion(true);
			setIDToDelete(row.id as number);
		},
		[setShouldConfirmDeletion, setIDToDelete]
	);

  	const tableProps = useTable();

	const customTableControls: ControlsTable = useMemo(
		() => ({
			...tableProps,
			onUpdate: onUpdateCallback,
			onDelete: onDeleteCallback
		}),
		[tableProps, onUpdateCallback, onDeleteCallback]
	);

	const BannedVisitorsTable = () => (
		<CustomTable
			controls={customTableControls}
			columns={headerRow}
			rows={bannedVisitors}
		/>
	);

	const onBannedVisitorCreationModalCloseCallback = useCallback(() => {
		setIsCreatingBannedVisitor(false);
	}, [setIsCreatingBannedVisitor]);

	const onBannedVisitorUpdateModalCloseCallback = useCallback(() => {
    	setUpdateState({ isUpdating: false, bannedVisitor: undefined });
  	}, [setUpdateState]);

	// TODO: Make it run in O(n + m) time
	function getPropertiesIDsFromPropertiesWithLabels(properties: PropertySchema[], labels?: string[]): number[] {
		if (!labels) {
			return [];
		}

		return properties.filter(property =>
			labels.includes(property.residence_identification)
		).map(property => property.id)
	}

	return (
		<Box>
			<GuardCondos />

			<ButtonGroup
				style={{ paddingTop: 20, paddingBottom: 20 }}
				variant="text"
				aria-label="text button group"
			>
				<Button
					onClick={() => {
						fetchProperties();
						setErrorFields(undefined);
						setIsCreatingBannedVisitor(true);
					}}
				>
					<Add /> Agregar Visita Bloqueada
				</Button>
			</ButtonGroup>

			<Divider style={{ marginTop: 15, marginBottom: 15 }} />

			<BannedVisitorsTable />

			<BannedVisitorCreationModal
				isVisible={isCreatingBannedVisitor}
				errorsField={errorsField ?? []}
				properties={properties}
				onSuccess={bannedVisitorCreationCallback}
				onClose={onBannedVisitorCreationModalCloseCallback}
			/>

			<BannedVisitorUpdateModal
				isVisible={updateState.isUpdating}
				errorsField={errorsField ?? []}
				bannedVisitor={updateState.bannedVisitor!}
				properties={properties}
				onSuccess={bannedVisitorUpdateCallback}
				onClose={onBannedVisitorUpdateModalCloseCallback}
			/>
		
			<AlertDialog
				title="Advertencia"
				open={shouldConfirmDeletion}
				setOpen={setShouldConfirmDeletion}
				warningMessage="Estás a punto de eliminar una visita bloqueada. ¿Deseas continuar?"
				onCancel={deletionCancelingCallback}
				onConfirm={bannedVisitorDeletionCallback}
			/>

      		<ToastContainer position="bottom-left" autoClose={3000} />
		</Box>
	);
}
