import { gql } from "@apollo/client";

export type PossessionTypeSchema = {
  id: number;
  label: string;
  is_active: boolean;
};

export type CreatePossessionTypeData = {
  CreatePossessionType: PossessionTypeSchema[];
};

export type GetAllPossessionTypesData = {
  GetAllPossessionTypes: PossessionTypeSchema[];
};

export type UpdatePossessionTypeData = {
  UpdatePossessionType: PossessionTypeSchema[];
};

export type DeletePossessionTypeData = {
  DeletePossessionType: PossessionTypeSchema[];
};

export type TogglePossessionTypeActiveStatusData = {
  TogglePossessionTypeActiveStatus: PossessionTypeSchema[];
};

const graphQLSchema = `
  id
  label
  is_active
`;

export const CREATE_POSSESSION_TYPE = gql`
  mutation CreatePossessionType($label: String!) {
    CreatePossessionType(label: $label) {
      ${graphQLSchema}
    }
  }
`;

export const GET_ALL_POSSESSION_TYPES = gql`
  query GetAllPossessionTypes {
    GetAllPossessionTypes {
      ${graphQLSchema}
    }
  }
`;

export const UPDATE_POSSESSION_TYPE = gql`
  mutation UpdatePossessionType($id: Int!, $label: String) {
    UpdatePossessionType(id: $id, label: $label) {
      ${graphQLSchema}
    }
  }
`;

export const DELETE_POSSESSION_TYPE = gql`
  mutation DeletePossessionType($id: Int!) {
    DeletePossessionType(id: $id) {
      ${graphQLSchema}
    }
  }
`;

export const TOGGLE_POSSESSION_TYPE_ACTIVE_STATUS = gql`
  mutation TogglePossessionTypeActiveStatus($id: Int!) {
    TogglePossessionTypeActiveStatus(id: $id) {
      ${graphQLSchema}
    }
  }
`;
