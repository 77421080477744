import { gql } from "@apollo/client";
import { ActionLogType } from "../../Reports/types";

interface ActiveParkings {
	id: number;
	is_busy: boolean;
	is_expired: boolean;
}

export interface ActionLog {
	id: number;
	register_type: ActionLogType;
	user_id?: number | null;
	user_full_name?: string;
	is_group_invitation?: boolean;
	local_date: string;
}

export interface DeliveriesCount {
	date: Date;
	count: number;
}

export type GetActionLogsResponseData = {
	actionLogs: ActionLog[];
};

export type GetActiveParkingLotsReservedForVisitorsByCondoIDResponseData = {
	activeParkingLotsForVisitorsByCondoID: ActiveParkings[];
};

export type InProgressDeliveriesCountData = {
	inProgressDeliveriesCount: number;
};

export type FinishedDeliveriesCountData = {
	finishedDeliveriesCount: number;
};

export type ExpiredDeliveriesCountData = {
	expiredDeliveriesCount: number;
};

export type InProgressDeliveriesCountsData = {
	inProgressDeliveriesCounts: DeliveriesCount[];
};

export type FinishedDeliveriesCountsData = {
	finishedDeliveriesCounts: DeliveriesCount[];
};

export type ReservationsBarCharts = {
	ReservesBarChart: [{ date: string; count: number }];
};

const deliveriesCountsSchema = `
  date
  count
`;

export const GET_ACTION_LOGS = gql`
	query GetActionLogsWithFilters($queryInput: ActionLogsQueryInput!) {
		actionLogs(queryInput: $queryInput) {
			id
			register_type
			user_id
			user_full_name
			is_group_invitation
			local_date
		}
	}
`;

export const GET_ACTIVE_PARKING_LOTS_FOR_VISITORS = gql`
	query GetActiveParkingLotsReservedForVisitorsByCondoID($condoID: Int!) {
		activeParkingLotsForVisitorsByCondoID(condoID: $condoID) {
			id
			is_busy
			is_expired
		}
	}
`;

export const GET_IN_PROGRESS_DELIVERIES_COUNT = gql`
	query GetInProgressDeliveriesCount($condoID: Int!, $date: DateTime) {
		inProgressDeliveriesCount(condoID: $condoID, date: $date)
	}
`;

export const GET_FINISHED_DELIVERIES_COUNT = gql`
	query GetFinishedDeliveriesCount($condoID: Int!, $date: DateTime) {
		finishedDeliveriesCount(condoID: $condoID, date: $date)
	}
`;

export const GET_EXPIRED_DELIVERIES_COUNT = gql`
	query GetExpiredDeliveriesCount($condoID: Int!, $date: DateTime) {
		expiredDeliveriesCount(condoID: $condoID, date: $date)
	}
`;

export const GET_IN_PROGRESS_DELIVERIES_COUNTS = gql`
	query GetInProgressDeliveriesCounts($countsInput: DeliveriesCountsInput!) {
		inProgressDeliveriesCounts(countsInput: $countsInput) {
			${deliveriesCountsSchema}
		}
	}
`;

export const GET_FINISHED_DELIVERIES_COUNTS = gql`
	query GetFinishedDeliveriesCounts($countsInput: DeliveriesCountsInput!) {
		finishedDeliveriesCounts(countsInput: $countsInput) {
			${deliveriesCountsSchema}
		}
	}
`;

export const GET_RESERVATIONS_BAR_CHARTS = gql`
	query ReservesBarChart($condo_id: Int!, $start_date: DateTime!, $end_date: DateTime!) {
		ReservesBarChart(condo_id: $condo_id, start_date: $start_date, end_date: $end_date) {
			date
			count
		}
	}
`;
