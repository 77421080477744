import { useCallback, useEffect, useState } from "react";
import { useCore } from "../../../Context/Core";
import { Box, Divider } from "@mui/material";
import CustomTable from "../../Components/CustomTable";
import useTable from "../../Components/CustomTable/useTable";
import GuardCondos from "../../Shared/GuardCondos";
import {
  RequestGetResidentLogs,
  query_get_visit,
  residentLogsSchema,
} from "./Querys";
import useGraphQL from "../../../Hooks/useGraphQL";
import { Subscription } from "rxjs";
import useErrorGraphql from "../../../Hooks/useErrorGraphql";

const head = [
  { id: "description", label: "Descripción" },
  { id: "date", label: "Fecha" },
  { id: "actions", label: "Acciones" },
];

const CondosResidentsReports = () => {
  const [currentList, setCurrentList] = useState<Array<residentLogsSchema>>([]);
  const { condo, ErrorSubject } = useCore();
  const { useLazyGraphQuery } = useGraphQL();
  const { setErrorFields } = useErrorGraphql();
  const [loadVisit] =
    useLazyGraphQuery<RequestGetResidentLogs>(query_get_visit);
  const tableProps = useTable();

  const handleloadVisit = useCallback(() => {
    if (condo)
      loadVisit({ variables: { condo_id: Number(condo.id) } }).then(
        (response) => {
          if (response.data) {
            const list = response.data.GetActionLogsResidentByCondoID;
            if (list) setCurrentList(list);
          }
        }
      );
  }, []);

  const ResidentReportTable = () => (
    <CustomTable
      controls={{
        ...tableProps,
      }}
      columns={head}
      rows={currentList}
    />
  );

  useEffect(() => {
    handleloadVisit();
  }, []);

  useEffect(() => {
    let Sub: null | Subscription = null;
    if (ErrorSubject) {
      Sub = ErrorSubject.subscribe((FieldsError) => {
        if (Array.isArray(FieldsError)) setErrorFields(FieldsError);
      });
    }
    return () => {
      console.log("clean state");
      setErrorFields(undefined);
      if (Sub) Sub.unsubscribe();
    };
  }, [ErrorSubject]);

  return (
    <Box>
      <GuardCondos />
      <Divider style={{ marginTop: 15, marginBottom: 15 }} />
      <ResidentReportTable />
    </Box>
  );
};
export default CondosResidentsReports;
