import {
	ActionLogType,
	ActionLogTypesCategory,
	CommonAreaReservationTimeRange,
	EntryMethod,
	type ActionLogSchema
} from "../types";

import { LogEntryTypeCategory } from "../../CondoLogEntryTypes/Query";

import { gql } from "@apollo/client";

export type GetAllActionLogsResponseData = {
	actionLogs: ActionLogSchema[];
};

type ActionLogTypeSpanishDescriptionKey = keyof typeof actionLogTypeSpanishDescriptions;
type ActionLogTypeSpanishDescription = (typeof actionLogTypeSpanishDescriptions)[ActionLogTypeSpanishDescriptionKey];

type ActionLogTypesCategorySpanishDescriptionKey = keyof typeof actionLogTypesCategorySpanishDescriptions;
type ActionLogTypesCategorySpanishDescription =
	(typeof actionLogTypesCategorySpanishDescriptions)[ActionLogTypesCategorySpanishDescriptionKey];

const actionLogTypeSpanishDescriptions: Record<ActionLogType, string> = {
	[ActionLogType.newVisit]: "Nueva visita",
	[ActionLogType.finishedVisit]: "Visita finalizada",
	[ActionLogType.visitEmail]: "Email por visita",
	[ActionLogType.visitPhoneCall]: "Llamada por visita",
	[ActionLogType.newResident]: "Nuevo residente",
	[ActionLogType.assignedParkingLot]: "Estacionamiento asignado",
	[ActionLogType.releasedParkingLot]: "Estacionamiento liberado",
	[ActionLogType.expiredParkingLot]: "Estacionamiento expirado",
	[ActionLogType.parkingLotAlert]: "Alerta de estacionamiento expirado",
	[ActionLogType.parkingLotPhoneCall]: "Llamada por estacionamiento expirado",
	[ActionLogType.parkingLotFine]: "Multa por estacionamiento expirado",
	[ActionLogType.newMaintenance]: "Nueva mantención",
	[ActionLogType.finishedMaintenance]: "Mantención finalizada",
	[ActionLogType.newDelivery]: "Nueva encomienda",
	[ActionLogType.finishedDelivery]: "Encomienda finalizada",
	[ActionLogType.newCommonAreaReservation]: "Nueva reserva de espacio común",
	[ActionLogType.canceledCommonAreaReservation]: "Reserva de espacio común cancelada",
	[ActionLogType.newLogEntry]: "Nuevo registro en bitácora",
	[ActionLogType.newInvitation]: "Nueva invitación",
	[ActionLogType.receivedVisitor]: "Visitante recibido",
	[ActionLogType.editedUser]: "Usuario modificado",
	[ActionLogType.newPossession]: "Nueva posesión",
	[ActionLogType.editedPossession]: "Posesión modificada"
};

const actionLogTypesCategorySpanishDescriptions: Record<ActionLogTypesCategory, string> = {
	[ActionLogTypesCategory.visit]: "Visitas",
	[ActionLogTypesCategory.resident]: "Residentes",
	[ActionLogTypesCategory.parking]: "Estacionamientos",
	[ActionLogTypesCategory.maintenance]: "Mantenciones",
	[ActionLogTypesCategory.delivery]: "Encomiendas",
	[ActionLogTypesCategory.commonAreaReservation]: "Reservas",
	[ActionLogTypesCategory.logEntry]: "Registros en bitácora",
	[ActionLogTypesCategory.invitation]: "Invitaciones",
	[ActionLogTypesCategory.user]: "Usuarios",
	[ActionLogTypesCategory.possession]: "Inventario"
};

const entryMethodSpanishDescriptions: Record<EntryMethod, string> = {
	[EntryMethod.scan]: "Escáner",
	[EntryMethod.manual]: "Manual"
};

const timeRangeSpanishDescriptions: Record<CommonAreaReservationTimeRange, string> = {
	[CommonAreaReservationTimeRange.morning]: "Mañana",
	[CommonAreaReservationTimeRange.afternoon]: "Tarde",
	[CommonAreaReservationTimeRange.night]: "Noche",
	[CommonAreaReservationTimeRange.allDay]: "Todo el día"
};

const logEntryTypeCategorySpanishDescriptions: Record<LogEntryTypeCategory, string> = {
	[LogEntryTypeCategory.notice]: "Novedad",
	[LogEntryTypeCategory.request]: "Requerimiento",
	[LogEntryTypeCategory.wall]: "Muro"
};

const graphQLSchema = `
	id
	description
	register_type
	utc_timestamp
	user_full_name
	property_label
	resident_full_name
	company_name
	car_plate
	parking
	entry_method
	condo_id
	user_id
	resident_id
	parked_minutes
	was_parking_lot_fined
	visit_full_name
	visit_type
	visit_delivery_type_label
	visit_id
	maintenance_id
	packages_count
	picking_up_resident_full_name
	delivery_id
	reservation_date
	reservation_time_range
	canceling_resident_full_name
	reserved_common_area_type_label
	reserved_common_area_label
	common_area_reservation_id
	log_entry_description
	log_entry_type_category
	log_entry_type_label
	log_entry_id  
	host_full_name
	visitor_alias
	event_name
	visitor_full_name
	invitation_utc_timestamp
	is_group_invitation
	invitation_id
	edited_user_password
	edited_user_enrollment_privilege
	possession_label
	possession_type_label
	local_datetime
	local_date
	local_time
	parking_lot_assignment_local_datetime
	invitation_local_datetime
	invitation_confirmation_local_datetime

	Resident {
		id
		personal_identification
		email
		phone_number
		resident_type
		nationality
		patent
  		secondary_patent

		ParkingsResidents {
			id
			
			Parking {
				label
			}
		}
	}

	Visit {
		id
		personal_identification
	}

	Maintenance {
		id
		technical_rut
		technical_name
		technical_last_name
		company
		maintenance_type
	}

	Delivery {
		id

		Resident {
			id
			personal_identification
		}
	}

	Invitation {
		id
		visitor_rut
	}
`;

export const GET_ACTION_LOGS_WITH_FILTERS = gql`
	query GetActionLogsWithFilters($queryInput: ActionLogsQueryInput!) {
		actionLogs(queryInput: $queryInput) {
     	 ${graphQLSchema}
    	}
  	}
`;

export function getActionLogTypeSpanishDescription(actionLogType: ActionLogType): string {
	return actionLogTypeSpanishDescriptions[actionLogType];
}

export function getActionLogTypeFromSpanishDescription(
	spanishDescription: ActionLogTypeSpanishDescription
): ActionLogType {
	return (Object.keys(actionLogTypeSpanishDescriptions) as ActionLogType[]).find(
		actionLogType => actionLogTypeSpanishDescriptions[actionLogType] === spanishDescription
	)!;
}

export function getActionLogTypesCategorySpanishDescription(actionLogTypesCategory: ActionLogTypesCategory): string {
	return actionLogTypesCategorySpanishDescriptions[actionLogTypesCategory];
}

export function getActionLogTypesCategoryFromSpanishDescription(
	spanishDescription: ActionLogTypesCategorySpanishDescription
): ActionLogTypesCategory {
	return (Object.keys(actionLogTypesCategorySpanishDescriptions) as ActionLogTypesCategory[]).find(
		actionLogTypesCategory =>
			actionLogTypesCategorySpanishDescriptions[actionLogTypesCategory] === spanishDescription
	)!;
}

export function getEntryMethodSpanishDescription(entryMethod: EntryMethod): string {
	return entryMethodSpanishDescriptions[entryMethod];
}

export function getTimeRangeSpanishDescription(timeRange: CommonAreaReservationTimeRange): string {
	return timeRangeSpanishDescriptions[timeRange];
}

export function getLogEntryTypeCategorySpanishDescription(category: LogEntryTypeCategory): string {
	return logEntryTypeCategorySpanishDescriptions[category];
}
