import { useEffect, memo, type FC } from "react";
import { GET_RESERVATIONS_BAR_CHARTS, type ReservationsBarCharts } from "../../GraphQL";
import DateRangePicker from "../../../../Components/DateRangePicker/DateRangePicker";
import useGraphQL from "../../../../../Hooks/useGraphQL";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { CircularProgress } from "@mui/material";
import CustomBarChart, { type BarData } from "../../../../Components/CustomBarChart";

enum ReservationsDataKey {
	entry = "Reservas en curso"
}

interface ReservationsBarChartProps {
	condoID: number;
}

const reservationsBarsData: BarData[] = [
	{
		barFillColor: "#827717",
		dataKey: ReservationsDataKey.entry,
		rectangleFillColor: "rgba(255, 255, 255, 0.7)",
		rectangleStrokeColor: "#827717"
	}
];

const ReservationsBarChart: FC<ReservationsBarChartProps> = ({ condoID }) => {
	const { useLazyGraphQuery } = useGraphQL();

	const [loadReservations, { data: reservationsData, loading: isLoadingReservationsData }] =
		useLazyGraphQuery<ReservationsBarCharts>(GET_RESERVATIONS_BAR_CHARTS);

	const { setValue, getValues } = useForm<{
		startDate: Date;
		endDate: Date;
	}>({
		defaultValues: {
			startDate: dayjs().subtract(6, "day").toDate(),
			endDate: dayjs().toDate()
		}
	});

	const FormatDate = (date: Date) => dayjs(date).format("YYYY-MM-DD");

	const list =
		reservationsData?.ReservesBarChart.map(reservations => ({
			[ReservationsDataKey.entry]: reservations.count,
			name: dayjs(reservations.date).format("DD/MM/YYYY")
		})) ?? [];

	const Loader = () => (
		<div style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
			<CircularProgress />
		</div>
	);

	useEffect(() => {
		if (condoID) {
			loadReservations({
				variables: {
					condo_id: condoID,
					start_date: FormatDate(getValues("startDate")) + "T00:00:00Z",
					end_date: FormatDate(getValues("endDate")) + "T23:59:00Z"
				}
			});
		}
	}, [condoID]);

	if (!condoID) {
		return <Loader />;
	}

	if (isLoadingReservationsData) {
		return <Loader />;
	}

	return (
		<section style={{ width: "95%", height: 350, marginTop: "2em" }}>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
					paddingBottom: "2%"
				}}
			>
				<DateRangePicker
					defaultStartDate={getValues("startDate")}
					defaultEndDate={getValues("endDate")}
					onChange={dates => {
						setValue("endDate", dates.endDate);
						setValue("startDate", dates.startDate);

						loadReservations({
							variables: {
								condo_id: condoID,
								start_date: FormatDate(getValues("startDate")) + "T00:00:00Z",
								end_date: FormatDate(getValues("endDate")) + "T23:59:00Z"
							}
						});
					}}
				/>
			</div>

			<CustomBarChart data={list} barsData={reservationsBarsData} />
		</section>
	);
};

export default memo(ReservationsBarChart);
