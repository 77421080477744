import React, { FC } from "react";
import { AppBar, Box, CircularProgress, Tab, Tabs, useTheme, Button, Divider, ButtonGroup } from "@mui/material";
import CustomTable, { ColumnTable, RowTable } from "../../Components/CustomTable";
import useTable from "../../Components/CustomTable/useTable";
import { UserForm, UserSchema, getUserRoleSpanishDescription } from "./GraphQL";
import { Add } from "@mui/icons-material";
import AlertDialog from "../../Components/CustomAlertDialog";
import ModalCreateUsers from "./ModalCreateUsers";
import ModalUpdateUsers, { UpdateUserForm } from "./ModalUpdateUser";
import useAdmins from "./useUsers";
import useErrorGraphql from "../../../Hooks/useErrorGraphql";
import { CondoSchema } from "../Condos/GraphQL";
import { ToastContainer, toast } from "react-toastify";
import { UserRole } from "../../../Types";

interface TabPanelProps {
	table?: FC<any>;
	dir?: string;
	index: number;
	value: number;
}

const headTable: Array<ColumnTable> = [
	{ id: "name", label: "Nombre" },
	{ id: "last_name", label: "Apellido" },
	{ id: "user", label: "Usuario" },
	{ id: "formatted_rut", label: "RUT" },
	{ id: "email", label: "Email" },
	{ id: "role_description", label: "Rol" },
	{ id: "active", label: "Activo/Inactivo" }
];

const AdminsScreen = () => {
	const tableProps = useTable();
	const theme = useTheme();

	const props = useAdmins();

	const { errorsField, setErrorFields } = useErrorGraphql();

	const { functions, state } = props;

	const { addUser, confirmDeleteUser, currentList, idDelete, updateUser, value, CondosList } = state;

	const {
		RegisterUser,
		UpdateUser,
		deleteUser,
		setAddUser,
		setConfirmDeleteUser,
		setIdDelete,
		setUpdateUser,
		setValue,
		toogleUsers,
		setCurrentList,
		loadUsers,
		addAdminToCondo,
		deleteAdminToCondo
	} = functions;

	/** Core components */

	const handleSelect = async (data: Array<unknown>, row: RowTable) => {
		const list = data as Array<CondoSchema>;
		const user = row as unknown as UserSchema;

		if (user.UsersInCondos && user.UsersInCondos.length > list.length) {
			const A1 = user.UsersInCondos.map(e => Number(e.Condo.id));
			const A2 = list.map(e => e.id);

			const diffArray = A1.filter(item => !A2.includes(item));

			const response = await deleteAdminToCondo({
				variables: {
					condos: { list: diffArray },
					admin_id: user.id
				}
			});
			if (response.data) {
				const admins = response.data.DeleteAdminInCondo;

				if (admins) {
					setCurrentList(
						admins.map(admin => ({ ...admin, role_description: getUserRoleSpanishDescription(admin.role) }))
					);
				}
			}
		} else {
			const response = await addAdminToCondo({
				variables: {
					condos: { list: list.map(e => e.id) },
					admin_id: user.id
				}
			});
			if (response.data) {
				const admins = response.data.AddAdminToCondo;

				if (admins) {
					setCurrentList(
						admins.map(admin => ({ ...admin, role_description: getUserRoleSpanishDescription(admin.role) }))
					);
				}
			}
		}
	};

	const onActive = async (row: RowTable) => {
		const response = await toogleUsers({
			variables: { user_id: Number(row.id) }
		});

		const admins = response.data?.ChangeActiveUser;

		if (admins) {
			setCurrentList(
				admins.map(admin => ({ ...admin, role_description: getUserRoleSpanishDescription(admin.role) }))
			);
		}
	};

	const onUpdate = async (form: UpdateUserForm) => {
		const response = await UpdateUser({ variables: { FormUpdateUser: form } });
		const admins = response.data?.UpdateUser;

		if (admins) {
			toast.success("Usuario actualizado correctamente");
			setUpdateUser({ active: false, id: undefined, user: undefined });

			setCurrentList(
				admins.map(admin => ({ ...admin, role_description: getUserRoleSpanishDescription(admin.role) }))
			);
		}
	};

	const onCreate = async (form: UserForm) => {
		const response = await RegisterUser({
			variables: {
				FormCreateUser: form
			}
		});

		const admins = response.data?.RegisterUser;

		if (admins) {
			setCurrentList(
				admins.map(admin => ({ ...admin, role_description: getUserRoleSpanishDescription(admin.role) }))
			);

			toast.success("Usuario agregado correctamente");
			setAddUser({ active: false, type: UserRole.plt });
		}
	};

	const onDelete = async () => {
		if (idDelete) {
			const response = await deleteUser({
				variables: {
					user_id: Number(idDelete.id)
				}
			});

			const admins = response.data?.DeleteUser;

			if (admins) {
				toast.success("Administrador eliminado correctamente");

				setCurrentList(
					admins.map(admin => ({ ...admin, role_description: getUserRoleSpanishDescription(admin.role) }))
				);

				setIdDelete(undefined);
			}
		}
	};

	const TableUsers = () => (
		<CustomTable
			controls={{
				...tableProps,
				onUpdate: row => {
					setErrorFields(undefined);
					setUpdateUser({
						active: true,
						id: Number(row.id as string),
						user: row
					});
				},
				onDelete: async ({ id, row }: { id: number; __typename: string; row: RowTable }) => {
					const user = row as unknown as UserSchema;
					setConfirmDeleteUser(true);
					setIdDelete({ id: Number(id), type: user.role });
				},
				onActiveStatusToggle: onActive,
				collapsible: undefined
			}}
			columns={[
				...headTable,
				{
					id: "UserInCondos",
					label: "Condominios",
					isMultipleSelect: true,
					isUserTable: true,
					list_options: CondosList && Array.isArray(CondosList.GetAllCondos) ? CondosList.GetAllCondos : [],
					onChangeMultiSelect: handleSelect
				},
				{ id: "actions", label: "Acciones" }
			]}
			rows={
				currentList
					? currentList.filter(user => {
							if (value === 1) {
								return user.role === UserRole.plt;
							}

							if (value === 2) {
								return user.role === UserRole.admin;
							}

							return true;
					  })
					: []
			}
		/>
	);

	const handleDefaultProps = (index: number) => ({
		id: `full-width-tab-${index}`,
		"aria-controls": `full-width-tabpanel-${index}`
	});

	const TabPanel = ({ table: Table, value, index }: TabPanelProps) => (
		<Box
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
		>
			{Table ? <Table /> : null}
		</Box>
	);

	const CustomLoader = ({ text }: { text: string }) => (
		<Box flexDirection={"column"} justifyContent={"center"} alignItems={"center"} flex={1}>
			<CircularProgress color="secondary" />
			<h3 style={{ textAlign: "center" }}>{text}</h3>
		</Box>
	);

	const HadleRenderTable = () => (false ? <CustomLoader text="Cargando..." /> : <TableUsers />);

	return (
		<Box>
			<ButtonGroup style={{ paddingTop: 20, paddingBottom: 20 }} variant="text" aria-label="text button group">
				<Button
					onClick={() => {
						setErrorFields(undefined);
						setAddUser({ active: true, type: UserRole.plt });
					}}
				>
					<Add /> Administrador PLAT
				</Button>

				<Button
					onClick={() => {
						setErrorFields(undefined);
						setAddUser({ active: true, type: UserRole.admin });
					}}
				>
					<Add /> Cliente Web
				</Button>
			</ButtonGroup>

			<Divider style={{ marginTop: 15, marginBottom: 15 }} />

			<AppBar position="static">
				<Tabs
					value={value}
					onChange={async (event: React.SyntheticEvent, newValue: number) => {
						setValue(newValue);

						tableProps.setPage(0);

						const response = await loadUsers();
						const admins = response.data?.GetAllPLTAndADMINUsers;

						if (admins) {
							setCurrentList(
								admins.map(admin => ({
									...admin,
									role_description: getUserRoleSpanishDescription(admin.role)
								}))
							);
						}
					}}
					style={{ backgroundColor: theme.palette.primary.main }}
					indicatorColor={"secondary"}
					textColor="inherit"
					variant="fullWidth"
					aria-label="Tabla de administradores"
				>
					<Tab label="Todos" {...handleDefaultProps(0)} />
					<Tab label="Administradores PLAT" {...handleDefaultProps(1)} />
					<Tab label="Clientes Web" {...handleDefaultProps(2)} />
				</Tabs>
			</AppBar>

			<TabPanel value={value} index={value} table={HadleRenderTable} />

			<ModalCreateUsers
				errorsField={errorsField ? errorsField : []}
				handleClose={() => setAddUser({ active: false, type: UserRole.plt })}
				onAdd={onCreate}
				onCancel={() => {
					setErrorFields(undefined);
					setAddUser({ active: false, type: UserRole.plt });
				}}
				type={addUser.type}
				visible={addUser.active}
			/>

			<ModalUpdateUsers
				errorsField={errorsField ? errorsField : []}
				handleClose={() => setUpdateUser({ active: false, user: undefined, id: undefined })}
				onCancel={() => setUpdateUser({ active: false, user: undefined, id: undefined })}
				onUpdate={onUpdate}
				user={updateUser.user}
				visible={updateUser.active}
			/>

			<AlertDialog
				title={"Advertencia"}
				open={confirmDeleteUser}
				setOpen={setConfirmDeleteUser}
				warningMessage={"Estás apunto de eliminar un usuario. ¿Deseas continuar?"}
				onCancel={() => setIdDelete(undefined)}
				onConfirm={onDelete}
			/>

			<ToastContainer position="bottom-left" autoClose={3000} />
		</Box>
	);
};

export default AdminsScreen;
