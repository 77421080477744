import { gql } from "@apollo/client";
import { ParkingSchema } from "../../CondosParking/Querys";

export enum ResidentType {
	owner = "owner",
	tenant = "tenant",
	authorizedVisitor = "authorizedVisitor",
	agent = "agent",
	estTenant = "estTenant",
	apartmentHotel = "apartmentHotel",
	services = "services",
	squatter = "squatter"
}

export enum Nationality {
	CHILEAN = "CHILEAN",
	FOREIGN = "FOREIGN"
}

const residentTypeSpanishDescriptions = {
	[ResidentType.owner]: "Dueño",
	[ResidentType.tenant]: "Arrendatario",
	[ResidentType.authorizedVisitor]: "Visitante autorizado",
	[ResidentType.agent]: "Corredor",
	[ResidentType.estTenant]: "Arrendatario (EST)",
	[ResidentType.apartmentHotel]: "Apart hotel",
	[ResidentType.services]: "Servicios",
	[ResidentType.squatter]: "Ocupante"
};

const nationalitySpanishDescriptions = {
	[Nationality.CHILEAN]: "Chileno",
	[Nationality.FOREIGN]: "Extranjero"
};

export function getResidentTypeSpanishDescription(residentType: ResidentType): string {
	return residentTypeSpanishDescriptions[residentType];
}

export function getNationalitySpanishDescription(nationality: Nationality): string {
	return nationalitySpanishDescriptions[nationality];
}

export function getNationalityFromSpanishDescription(spanishDescription: string): Nationality | undefined {
	const spanishDescriptionEntries = Object.entries(nationalitySpanishDescriptions) as [
		key: Nationality,
		value: string
	][];

	const matchingEntry = spanishDescriptionEntries.find(
		([_, value]: [key: Nationality, value: string]) => value === spanishDescription
	);

	return matchingEntry?.[0];
}

export type PropertySchema = {
	id: number;
	residence_identification: string;
};

export type ParkingsResidents = {
	id: number;
	Parking?: ParkingSchema;
};

export interface ResidentSchema {
	id: number;
	personal_identification: string;
	name: string;
	last_name: string;
	full_name: string;
	phone_number?: string;
	email?: string;
	nationality: Nationality;
	resident_type: ResidentType;
	patent?: string;
	secondary_patent?: string;
	property_id: number;
	active: boolean;
	formatted_rut?: string;
	ParkingsResidents?: Array<ParkingsResidents>;
	Property?: PropertySchema;
}

export interface FormattedResidentSchema extends ResidentSchema {
	[key: string]: any;
	resident_type_description: string;
	nationality_description: string;
	formatted_identification: string;
}

export type RequestGetResidents = {
	GetAllResidentsByCondoId: ResidentSchema[];
};

export type GetResidentsByPropertyIDData = {
	residentsWithPropertyID: ResidentSchema[];
};

export type RequestCreateResident = {
	RegisterResident: ResidentSchema[];
};

export type RequestUpdateResident = {
	UpdateResident: ResidentSchema[];
};

export type RequestDeleteResident = {
	DeleteResident: ResidentSchema[];
};

const graphQLSchema = `
  id
  personal_identification
  name
  last_name
  full_name
  phone_number
  email
  nationality
  resident_type
  patent
  secondary_patent
  property_id
  active
  formatted_rut

  ParkingsResidents {
    id
    Parking {
      id
      label
    }
  }

  Property{
    id
    residence_identification
  }
`;

export const query_get_residents = gql`
	query GetAllResidentsByCondoId {
		GetAllResidentsByCondoId {
			${graphQLSchema}
		}
	}
`;

export const GET_RESIDENTS_WITH_PROPERTY_ID = gql`
	query GetResidentsByPropertyID($propertyID: Int!) {
    	residentsWithPropertyID(propertyID: $propertyID) {
      		${graphQLSchema}
   		}
	}
`;

export const mutation_register_resident = gql`
	mutation RegisterResident($FormCreateResident: FormCreateResident!) {
		RegisterResident(FormCreateResident: $FormCreateResident) {
			${graphQLSchema}
		}
	}
`;
export const mutation_update_resident = gql`
	mutation UpdateResident($FormUpdateResident: FormUpdateResident!) {
		UpdateResident(FormUpdateResident: $FormUpdateResident) {
			${graphQLSchema}
		}
	}
`;

export const mutation_delete_resident = gql`
	mutation DeleteResident($resident_id: Int!) {
		DeleteResident(resident_id:$resident_id) {
			${graphQLSchema}
		}
	}
`;

export function sortResidents(residents: ResidentSchema[]): ResidentSchema[] {
	const residentsCopy = [...residents];

	residentsCopy.sort((resident1, resident2) => {
		if (resident1.resident_type === ResidentType.owner && resident2.resident_type !== ResidentType.owner) {
			return -1;
		} else if (resident2.resident_type === ResidentType.owner && resident1.resident_type !== ResidentType.owner) {
			return 1;
		} else if (resident1.resident_type === ResidentType.tenant && resident2.resident_type !== ResidentType.tenant) {
			return -1;
		} else if (resident2.resident_type === ResidentType.tenant && resident1.resident_type !== ResidentType.tenant) {
			return 1;
		}

		return resident1.full_name.localeCompare(resident2.full_name);
	});

	return residentsCopy;
}
