import { useCallback, useEffect, useState } from "react";
import useGraphQL from "../../../../Hooks/useGraphQL";

import {
	AddAdminToCondosRequest,
	DeleteAdminToCondosRequest,
	DeleteUsersResponse,
	GetAllUserResponse,
	RegisterUserResponse,
	ToggleUsersResponse,
	UpdateUserResponse,
	mutation_add_admins_in_condos,
	mutation_create_user,
	mutation_delete_admins_in_condos,
	mutation_delete_user,
	mutation_toggle_user,
	mutation_update_user,
	query_get_users,
	getUserRoleSpanishDescription,
	type UserDescription
} from "../GraphQL";

import { useLocation } from "react-router-dom";
import { GetAllCondosData, query_get_all_condos } from "../../Condos/GraphQL";
import { UserRole } from "../../../../Types";

const useAdmins = () => {
	const location = useLocation();
	const [currentList, setCurrentList] = useState<UserDescription[]>([]);

	const [value, setValue] = useState(0);

	const { useGraphMutation, useLazyGraphQuery } = useGraphQL();

	const [confirmDeleteAdmin, setConfirmDeleteAdmin] = useState<boolean>(false);

	const [idDelete, setIdDelete] = useState<{ id: number; type: UserRole } | undefined>();

	const [addAdmin, setAddAdmin] = useState<{ active: boolean; type: UserRole }>({
		active: false,
		type: UserRole.plt
	});

	const [updateUser, setUpdateUser] = useState<{
		active: boolean;
		user: any;
		id: undefined | number;
	}>({
		active: false,
		user: undefined,
		id: undefined
	});

	const [loadUsers, { loading }] = useLazyGraphQuery<GetAllUserResponse>(query_get_users);

	const [RegisterUser] = useGraphMutation<RegisterUserResponse>(mutation_create_user);
	const [UpdateUser] = useGraphMutation<UpdateUserResponse>(mutation_update_user);
	const [toogleUsers] = useGraphMutation<ToggleUsersResponse>(mutation_toggle_user);
	const [deleteUser] = useGraphMutation<DeleteUsersResponse>(mutation_delete_user);
	const [addAdminToCondo] = useGraphMutation<AddAdminToCondosRequest>(mutation_add_admins_in_condos);
	const [deleteAdminToCondo] = useGraphMutation<DeleteAdminToCondosRequest>(mutation_delete_admins_in_condos);

	const [loadCondos, { data: CondosList }] = useLazyGraphQuery<GetAllCondosData>(query_get_all_condos);

	const handleLoadUsers = useCallback(() => {
		loadUsers().then(response => {
			if (response.data) {
				const admins = response.data.GetAllPLTAndADMINUsers;

				if (admins) {
					setCurrentList(
						admins.map(admin => ({ ...admin, role_description: getUserRoleSpanishDescription(admin.role) }))
					);
				}
			}
		});
		loadCondos();
	}, []);

	useEffect(() => {
		handleLoadUsers();
	}, [location.pathname]);

	return {
		state: {
			currentList,
			value,
			confirmDeleteUser: confirmDeleteAdmin,
			idDelete,
			addUser: addAdmin,
			updateUser,
			loading,
			CondosList
		},
		functions: {
			setValue,
			setConfirmDeleteUser: setConfirmDeleteAdmin,
			setIdDelete,
			setAddUser: setAddAdmin,
			setUpdateUser,
			RegisterUser,
			UpdateUser,
			toogleUsers,
			deleteUser,
			setCurrentList,
			loadUsers,
			loadCondos,
			addAdminToCondo,
			deleteAdminToCondo
		}
	};
};
export default useAdmins;
