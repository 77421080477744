import {
  DocumentNode,
  MutationHookOptions,
  OperationVariables,
  QueryHookOptions,
  QueryOptions,
  TypedDocumentNode,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import { useCore } from "../../Context/Core";

import { graphQlEndpoint } from "../useClientGraphql";

export type ContextLET = {
  appname: string;
  appversion: string;
  plataform: string;
  authorization: string;
  "Content-Type": string;
};

type UnionOptions = QueryHookOptions | MutationHookOptions;

export const getHeaders = () => ({
  "Content-Type": "application/json",
  platform: "WEB",
  authorization: "Bearer ",
});

const useGraphQL = () => {
  const defaultOps: UnionOptions = {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  };
  const { token, condo } = useCore();
  const header = {
    ...getHeaders(),
    authorization: "Bearer " + token,
    condo: condo?.id,
  }; // + JWT

  const handleGetHeader = (): UnionOptions => ({
    context: { headers: header },
  });

  const useGraphQuery = <T,>(
    query: DocumentNode | TypedDocumentNode<T, OperationVariables>,
    options?: QueryOptions<OperationVariables>
  ) =>
    useQuery<T>(query, {
      variables: options?.variables,
      ...(defaultOps as QueryHookOptions),
      ...(handleGetHeader() as QueryHookOptions),
    });

  const useLazyGraphQuery = <T,>(
    query: DocumentNode | TypedDocumentNode<T, OperationVariables>,
    options?: QueryOptions<OperationVariables>
  ) =>
    useLazyQuery<T>(query, {
      variables: options?.variables,
      ...(defaultOps as QueryHookOptions),
      ...(handleGetHeader() as QueryHookOptions),
    });

  const useGraphMutation = <T,>(
    query: DocumentNode | TypedDocumentNode<T, OperationVariables>,
    options?: QueryOptions<OperationVariables>
  ) =>
    useMutation<T>(query, {
      variables: options?.variables,
      ...(defaultOps as MutationHookOptions),
      ...(handleGetHeader() as MutationHookOptions),
    });

  const MultiparRequest = async (
    form: {
      [key: string]: any;
    },
    query: any
  ): Promise<Response | undefined> => {
    try {
      const headers = new Headers();
      const body = new FormData();

      headers.append("authorization", "Bearer " + token); // JWT
      headers.append("platform", getHeaders().platform);
      console.log(form);

      body.append("query", query);
      Object.keys(form).map((key) => {
        body.append(key, form[key]);
      });
      console.log(graphQlEndpoint, { headers, body, method: "POST" });
      const response = await fetch(graphQlEndpoint, { headers, body, method: "POST" });

      return response;
    } catch (error) {
      console.log("Error subida archivo", error);
      return;
    }
  };

  return {
    useGraphQuery,
    useLazyGraphQuery,
    useGraphMutation,
    MultiparRequest,
  };
};

export default useGraphQL;
