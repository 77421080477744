import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { useCore } from "../../../../Context/Core";
import { apiURL } from "../../../../Hooks/useClientGraphql";
import { saveAs } from "file-saver";
import { useState } from "react";

const useIntegrationBulkCommonExpensives = () => {
	const { token } = useCore();
	const [loading, setLoading] = useState<boolean>(false);

	const uploadExcelFile = async (file: File): Promise<AxiosResponse<any> | boolean> => {
		try {
			setLoading(true);
			const formData = new FormData();
			formData.append("overdue_common_charges", file);

			const config = {
				headers: {
					"Content-Type": "multipart/form-data",
					Authorization: `Bearer ${token}`,
					platform: "WEB"
				}
			};

			const response = await axios.post(`${apiURL}/api/bulk-upload/overdue-common-charges`, formData, config);
			setLoading(false);
			return response;
		} catch (error) {
			setLoading(false);
			return false;
		}
	};

	const downloadFile = async () => {
		try {
			setLoading(true);
			const config: AxiosRequestConfig = {
				headers: {
					"Content-Type": "multipart/form-data",
					Authorization: `Bearer ${token}`,
					platform: "WEB"
				},
				responseType: "blob"
			};

			const response = await axios.get(`${apiURL}/api/bulk-upload/overdue-common-charges`, config);

			setLoading(false);
			saveAs(response.data, "Gastos Comunes.xlsx");
		} catch (error) {
			setLoading(false);
		}
	};

	return {
		downloadFile,
		uploadExcelFile,
		loading
	};
};
export default useIntegrationBulkCommonExpensives;
