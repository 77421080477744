import { useCallback, useEffect } from "react";
import { Box, Button, Divider, ButtonGroup } from "@mui/material";

import CustomTable, { ColumnTable, RowTable } from "../../Components/CustomTable";
import useTable from "../../Components/CustomTable/useTable";

import { Add } from "@mui/icons-material";
import AlertDialog from "../../Components/CustomAlertDialog";

import ModalCreateWorker, { UserForm } from "./ModalCreateWorker";
import ModalUpdateWorker, { UpdateUserForm } from "./ModalUpdateWorker";
import useUsers from "./useUsers";
import useErrorGraphql from "../../../Hooks/useErrorGraphql";
import { UserSchema } from "../Users/GraphQL";
import { useCore } from "../../../Context/Core";

import { ToastContainer, toast } from "react-toastify";
import { UserRole } from "../../../Types";
import GuardCondos from "../../Shared/GuardCondos";

const Roles = {
	[UserRole.admin]: "Cliente Web",
	[UserRole.plt]: "Administrador PLAT",
	[UserRole.worker]: "Colaborador"
};

const headTable: Array<ColumnTable> = [
	{ id: "formatted_rut", label: "RUT" },
	{ id: "name", label: "Nombre" },
	{ id: "last_name", label: "Apellido" },
	{ id: "email", label: "Email" },
	{ id: "phone_number", label: "Número Telefónico" },
	{ id: "can_create", label: "Permitido Registrar" },
	{ id: "active", label: "Activo/Inactivo" },
	{ id: "actions", label: "Acciones" }
];

const UsersScreen = () => {
	const tableProps = useTable();
	const { condo } = useCore();
	const props = useUsers();
	const { errorsField, setErrorFields } = useErrorGraphql();

	const { functions, state } = props;
	const { addUser, confirmDeleteUser, currentList, idDelete, updateUser } = state;

	const {
		RegisterPLT,
		UpdateUserPLT,
		deleteUser,
		setAddUser,
		setConfirmDeleteUser,
		setIdDelete,
		setUpdateUser,
		toogleUsers,
		setCurrentList,
		loadUsers
	} = functions;

	/** Core components */

	const handleSetListWorkers = (list: Array<UserSchema>) => {
		setCurrentList(
			list.map(item => ({
				...item,
				parse_role: Roles[item.role],
				can_create: item.enroll ? "Sí" : "No"
			}))
		);
	};

	const handleLoadWorkers = useCallback(async () => {
		if (condo !== undefined) {
			const response = await loadUsers({ variables: { condo_id: condo.id } });
			const list = response.data?.GetAllWorkersByCondo;

			if (list) {
				handleSetListWorkers(list);
			}
		}
	}, [condo]);

	const onActive = async (row: RowTable) => {
		if (condo) {
			const response = await toogleUsers({
				variables: { user_id: Number(row.id) }
			});
			const list = response.data?.ChangeActiveUser;
			if (list) handleSetListWorkers(list);
		}
	};

	const onUpdate = async (form: UpdateUserForm) => {
		if (condo) {
			const response = await UpdateUserPLT({
				variables: { FormUpdateUser: form }
			});
			const list = response.data?.UpdateUser;
			if (list) {
				handleSetListWorkers(list);
				toast.success("Colaborador Actualizado correctamente");
				setUpdateUser({ active: false, id: undefined, user: undefined });
			}
		}
	};

	const onCreate = async (form: UserForm) => {
		if (condo) {
			const response = await RegisterPLT({
				variables: { FormCreateUser: form }
			});

			const list = response.data?.RegisterUser;

			if (list) {
				handleSetListWorkers(list);
				setAddUser({ active: false, type: UserRole.plt });
				toast.success("Colaborador agregado correctamente");
			}
		}
	};

	const onDelete = async () => {
		if (idDelete && condo) {
			const response = await deleteUser({
				variables: {
					user_id: idDelete.id
				}
			});

			const list = response.data?.DeleteUser;

			if (list) {
				handleSetListWorkers(list);
				toast.success("Colaborador eliminado correctamente");
				setIdDelete(undefined);
			}
		}
	};

	const TableUsers = () => (
		<CustomTable
			controls={{
				...tableProps,
				onUpdate: row => {
					setErrorFields(undefined);
					setUpdateUser({
						active: true,
						id: Number(row.id as string),
						user: row
					});
				},
				onDelete: async ({ id, row }: { id: number; __typename: string; row: RowTable }) => {
					const user = row as unknown as UserSchema;
					setConfirmDeleteUser(true);
					setIdDelete({ id: Number(id), type: user.role });
				},
				onActiveStatusToggle: onActive,
				collapsible: undefined
			}}
			columns={headTable}
			rows={currentList ? currentList : []}
		/>
	);

	useEffect(() => {
		handleLoadWorkers();
	}, [handleLoadWorkers]);

	return (
		<Box>
			<GuardCondos />

			<ButtonGroup style={{ paddingTop: 20, paddingBottom: 20 }} variant="text" aria-label="text button group">
				<Button
					onClick={() => {
						setErrorFields(undefined);
						setAddUser({ active: true, type: UserRole.worker });
					}}
				>
					<Add /> Colaborador
				</Button>
			</ButtonGroup>
			<Divider style={{ marginTop: 15, marginBottom: 15 }} />
			<TableUsers />
			<ModalCreateWorker
				errorsField={errorsField ? errorsField : []}
				handleClose={() => setAddUser({ active: false, type: UserRole.plt })}
				onAdd={onCreate}
				onCancel={() => {
					setErrorFields(undefined);
					setAddUser({ active: false, type: UserRole.plt });
				}}
				type={addUser.type}
				visible={addUser.active}
			/>

			<ModalUpdateWorker
				errorsField={errorsField ? errorsField : []}
				handleClose={() => setUpdateUser({ active: false, user: undefined, id: undefined })}
				onCancel={() => setUpdateUser({ active: false, user: undefined, id: undefined })}
				onUpdate={onUpdate}
				user={updateUser.user}
				visible={updateUser.active}
			/>

			<AlertDialog
				title={"Advertencia"}
				open={confirmDeleteUser}
				setOpen={setConfirmDeleteUser}
				warningMessage={"Estás apunto de eliminar un colaborador. ¿Deseas continuar?"}
				onCancel={() => setIdDelete(undefined)}
				onConfirm={onDelete}
			/>
			<ToastContainer position="bottom-left" autoClose={3000} />
		</Box>
	);
};
export default UsersScreen;
