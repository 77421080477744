import { Add } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Divider } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import CustomTable, { ControlsTable, RowTable } from "../../Components/CustomTable";
import useTable from "../../Components/CustomTable/useTable";
import CommonAreasCreationModal from "./CommonAreasCreationModal";
import CommonAreaUpdateModal from "./CommonAreasUpdateModal";
import useGraphQL from "../../../Hooks/useGraphQL";

import {
	CommonAreasSchema,
	CreateCommonArea,
	DeleteCommonArea,
	GetAllCommonAreasData,
	ToggleCommonAreaActiveStatus,
	UpdateCommonArea,
	mutation_create_common_area,
	mutation_delete_common_area,
	mutation_toggle_active_common_area,
	mutation_update_common_area,
	query_get_all_common_areas
} from "./Querys";

import AlertDialog from "../../Components/CustomAlertDialog";
import useErrorGraphql from "../../../Hooks/useErrorGraphql";

const head = [
	{ id: "label", label: "Nombre" },
	{ id: "is_active", label: "Activo/Inactivo" },
	{ id: "actions", label: "Acciones" }
];

function CommonAreas() {
	const [showCreationModal, setShowCreationModal] = useState(false);
	const [shouldConfirmDeletion, setShouldConfirmDeletion] = useState(false);
	const [idToDelete, setIDToDelete] = useState<number | undefined>();
	const [commonAreasList, setCommonAreasList] = useState<CommonAreasSchema[]>([]);
	const [updateState, setUpdateState] = useState<{
		isUpdating: boolean;
		commonArea?: CommonAreasSchema;
	}>({ isUpdating: false, commonArea: undefined });

	const { useLazyGraphQuery, useGraphMutation } = useGraphQL();
	const { errorsField, setErrorFields } = useErrorGraphql();

	const [loadCommonAreas] = useLazyGraphQuery<GetAllCommonAreasData>(query_get_all_common_areas);
	const [createCommonArea] = useGraphMutation<CreateCommonArea>(mutation_create_common_area);
	const [updateCommonArea] = useGraphMutation<UpdateCommonArea>(mutation_update_common_area);
	const [toggleCommonArea] = useGraphMutation<ToggleCommonAreaActiveStatus>(mutation_toggle_active_common_area);
	const [deleteCommonArea] = useGraphMutation<DeleteCommonArea>(mutation_delete_common_area);

	const handleLoadCommonAreas = async () => {
		const response = await loadCommonAreas();
		if (response.data?.GetAllCommonAreas) {
			setCommonAreasList(response.data?.GetAllCommonAreas);
		}
	};

	const onUpdateCallback = useCallback(
		(row: RowTable) => {
			setErrorFields(undefined);
			const { id, label, active } = row;
			if (label) {
				setUpdateState({
					isUpdating: true,
					commonArea: row as CommonAreasSchema
				});
			}
		},
		[errorsField]
	);

	const onDeleteCallback = useCallback(
		(row: RowTable) => {
			const { id } = row;
			if (id) {
				setIDToDelete(Number(id));
				setShouldConfirmDeletion(true);
			}
		},
		[idToDelete]
	);

	const onActiveCallback = useCallback(async (row: RowTable) => {
		const { id } = row;
		if (id) {
			await toggleCommonArea({ variables: { id } });
			await handleLoadCommonAreas();
			toast.success("Espacio común actualizado correctamente");
		}
	}, []);

	const tableProps = useTable();
	const customTableControls: ControlsTable = useMemo(
		() => ({
			...tableProps,
			onUpdate: onUpdateCallback,
			onDelete: onDeleteCallback,
			onActiveStatusToggle: onActiveCallback
		}),
		[tableProps, onUpdateCallback, onDeleteCallback, onActiveCallback]
	);

	const handleCreateCommonArea = useCallback(async (label: string) => {
		if (label) {
			const response = await createCommonArea({ variables: { label } });
			if (response.data?.CreateCommonArea) {
				setShowCreationModal(false);
				await handleLoadCommonAreas();
				toast.success("Espacio común creado correctamente");
			}
		}
	}, []);

	const handleUpdateCommonArea = async (id: number, label: string) => {
		const response = await updateCommonArea({
			variables: {
				id,
				label
			}
		});

		if (response.data?.UpdateCommonArea) {
			setUpdateState({
				isUpdating: false,
				commonArea: undefined
			});

			await handleLoadCommonAreas();
			toast.success("Espacio común actualizado correctamente");
		}
	};

	const handleDeleteCommonArea = useCallback(async () => {
		if (idToDelete) {
			const response = await deleteCommonArea({
				variables: { id: idToDelete }
			});

			if (response.data) {
				toast.success("Espacio común eliminado correctamente");
				setShouldConfirmDeletion(false);
				setIDToDelete(undefined);
				handleLoadCommonAreas();
			}
		}
	}, [idToDelete, toast, setShouldConfirmDeletion, setIDToDelete]);

	const deletionCancelingCallback = useCallback(() => {
		setIDToDelete(undefined);
	}, [setIDToDelete]);

	const CommonAreasTable = () => <CustomTable controls={customTableControls} columns={head} rows={commonAreasList} />;

	useEffect(() => {
		handleLoadCommonAreas();
	}, []);

	return (
		<Box>
			<ButtonGroup style={{ paddingTop: 20, paddingBottom: 20 }} variant="text" aria-label="text button group">
				<Button
					onClick={() => {
						setErrorFields(undefined);
						setShowCreationModal(true);
					}}
				>
					<Add /> Agregar Espacio Común
				</Button>
			</ButtonGroup>

			<Divider style={{ marginTop: 15, marginBottom: 15 }} />
			<CommonAreasTable />

			<CommonAreasCreationModal
				isVisible={showCreationModal}
				errorsField={errorsField ?? []}
				onSuccess={handleCreateCommonArea}
				onClose={() => setShowCreationModal(!showCreationModal)}
			/>

			<CommonAreaUpdateModal
				isVisible={updateState.isUpdating}
				errorsField={errorsField ?? []}
				commonArea={updateState.commonArea!}
				onSuccess={(label, id) => handleUpdateCommonArea(id, label)}
				onClose={() =>
					setUpdateState({
						isUpdating: false,
						commonArea: undefined
					})
				}
			/>

			<AlertDialog
				title="Advertencia"
				open={shouldConfirmDeletion}
				setOpen={setShouldConfirmDeletion}
				warningMessage="Estás apunto de eliminar un espacio común. ¿Deseas continuar?"
				onCancel={deletionCancelingCallback}
				onConfirm={() => handleDeleteCommonArea()}
			/>

			<ToastContainer position="bottom-left" autoClose={3000} />
		</Box>
	);
}

export default CommonAreas;
