import React, { FC, useCallback, useEffect } from "react";
import { Add, Close } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Grid, Modal, TextField, Typography, useTheme } from "@mui/material";

import { Controller, useForm } from "react-hook-form";
import uuid from "react-uuid";
import { PLTFieldErrors } from "../../../../Types";
import { MaintenanceTypeSchema } from "../Query";
type props = {
	visible: boolean;
	errorsField: PLTFieldErrors;
	maintenance: MaintenanceTypeSchema | undefined;
	handleClose: () => void;
	onCancel: () => void;
	onUpdate: (form: MaintenanceTypeSchema) => void;
};

type MaintenanceForm = {
	title: string;
};
const ModalUpdateTypeMaintenance: FC<props> = ({
	visible,
	errorsField,
	maintenance,
	handleClose,
	onUpdate,
	onCancel
}) => {
	const theme = useTheme();

	const { control, getValues, reset, setValue } = useForm<MaintenanceForm>({
		defaultValues: {
			title: ""
		}
	});

	const isError = useCallback(
		(key: string) => {
			if (errorsField) {
				return errorsField.find(e => e.key === key) ? true : false;
			}
			return false;
		},
		[errorsField]
	);

	const getTextError = useCallback(
		(key: string) => {
			console.log("ERRORs-key", key);
			if (errorsField) {
				const found = errorsField.find(e => e.key === key);
				if (found) return found.message;
			}
			return "";
		},
		[errorsField]
	);

	useEffect(() => {
		if (!visible) reset();
	}, [visible]);

	useEffect(() => {
		if (visible && maintenance) {
			setValue("title", maintenance.title);
		}
	}, [maintenance, visible]);

	return (
		<Modal open={visible} onClose={handleClose}>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: 400,
					bgcolor: "background.paper",
					boxShadow: 24,
					p: 4
				}}
			>
				<Grid container flexDirection={"row"}>
					<Typography variant="h5" color={theme.palette.primary.main} textAlign={"center"}>
						Actualizar Tipo de Mantención
					</Typography>
					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={value}
									onChange={onChange}
									label="Nombre"
									variant="outlined"
									error={isError("title")}
									helperText={getTextError("title")}
								/>
							)}
							name="title"
						/>
					</Grid>
				</Grid>
				<ButtonGroup
					style={{ paddingTop: 20, paddingBottom: 20, alignSelf: "center" }}
					variant="text"
					aria-label="text button group"
				>
					<Button onClick={onCancel}>
						<Close /> Cancelar
					</Button>
					<Button
						onClick={() =>
							onUpdate({
								...maintenance,
								title: getValues().title
							} as MaintenanceTypeSchema)
						}
					>
						<Add /> Actualizar
					</Button>
				</ButtonGroup>
			</Box>
		</Modal>
	);
};
export default ModalUpdateTypeMaintenance;
