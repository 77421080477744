import {
	LogEntryTypeCategory,
	getLogEntryTypeCategorySpanishDescription,
	getLogEntryTypeCategoryFromSpanishDescription,
	type LogEntryTypeSchema
} from "../Query";

import { useCallback, useEffect, type FC } from "react";
import { Add, Close } from "@mui/icons-material";

import {
	useTheme,
	Box,
	Button,
	ButtonGroup,
	Grid,
	Modal,
	TextField,
	Typography,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	FormHelperText
} from "@mui/material";

import { Controller, useForm } from "react-hook-form";
import uuid from "react-uuid";

import type { PLTFieldErrors } from "../../../../Types";

export interface LogEntryTypeUpdateInput {
	category?: LogEntryTypeCategory;
	label?: string;
}

type LogEntryTypeUpdateModalProps = {
	isVisible: boolean;
	errorsField: PLTFieldErrors;
	logEntryType: LogEntryTypeSchema;
	onSuccess: (logEntryTypeID: number, updateInput: LogEntryTypeUpdateInput) => void;
	onClose: () => void;
};

export const LogEntryTypeCreationModal: FC<LogEntryTypeUpdateModalProps> = ({
	isVisible,
	errorsField,
	logEntryType,
	onSuccess,
	onClose
}) => {
	const theme = useTheme();

	const { control, getValues, setValue, reset } = useForm<LogEntryTypeUpdateInput>({
		defaultValues: {
			label: "",
			category: undefined
		}
	});

	const isError = useCallback((key: string) => errorsField.some(error => error.key === key), [errorsField]);

	const getErrorText = useCallback(
		(key: string) => errorsField?.find(error => error.key === key)?.message ?? "",
		[errorsField]
	);

	useEffect(() => {
		if (isVisible) {
			setValue("label", logEntryType.label);
			setValue("category", logEntryType.category);
		} else {
			reset();
		}
	}, [isVisible, setValue, logEntryType]);

	return (
		<Modal open={isVisible} onClose={onClose}>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: 400,
					bgcolor: "background.paper",
					boxShadow: 24,
					p: 4
				}}
			>
				<Grid container flexDirection={"row"}>
					<Typography variant="h5" color={theme.palette.primary.main} textAlign={"center"}>
						Actualizar Tipo de Registro en Bitácora
					</Typography>

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							name="label"
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={value}
									onChange={onChange}
									label="Etiqueta"
									variant="outlined"
									error={isError("label")}
									helperText={getErrorText("label")}
								/>
							)}
						/>
					</Grid>
				</Grid>

				<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
					<Controller
						control={control}
						name="category"
						render={({ field: { onChange, value } }) => (
							<FormControl sx={{ width: "100%" }}>
								<InputLabel
									id="category"
									style={{
										color: isError("category")
											? theme.palette.error.main
											: theme.palette.primary.main
									}}
								>
									Categoría
								</InputLabel>

								<Select
									labelId="category"
									id="category"
									value={value}
									label="Categoría"
									onChange={e => onChange(e.target.value)}
									error={isError("category")}
								>
									{Object.values(LogEntryTypeCategory).map(logEntryType => (
										<MenuItem key={`${logEntryType}-selres`} value={logEntryType}>
											{getLogEntryTypeCategorySpanishDescription(logEntryType)}
										</MenuItem>
									))}
								</Select>

								{isError("category") ? (
									<FormHelperText style={{ color: theme.palette.error.main }}>
										{getErrorText("category")}
									</FormHelperText>
								) : null}
							</FormControl>
						)}
					/>
				</Grid>

				<ButtonGroup
					style={{ paddingTop: 20, paddingBottom: 20, alignSelf: "center" }}
					variant="text"
					aria-label="text button group"
				>
					<Button onClick={onClose}>
						<Close /> Cancelar
					</Button>

					<Button onClick={() => onSuccess(logEntryType.id, getValues())}>
						<Add /> Actualizar
					</Button>
				</ButtonGroup>
			</Box>
		</Modal>
	);
};

export default LogEntryTypeCreationModal;
