import React, { FC, useCallback, useEffect } from "react";
import { Add, Close } from "@mui/icons-material";
import {
	Autocomplete,
	Box,
	Button,
	ButtonGroup,
	Chip,
	Grid,
	Modal,
	TextField,
	Typography,
	useTheme
} from "@mui/material";

import { Controller, useForm } from "react-hook-form";
import uuid from "react-uuid";
import type { PLTFieldErrors } from "../../../../Types";
import type { TypesMaintenanceSchema, MaintenanceCompanySchema } from "../Query";

type props = {
	visible: boolean;
	errorsField: PLTFieldErrors;
	maintenanceCompany?: MaintenanceCompanySchema;
	handleClose: () => void;
	onCancel: () => void;
	onUpdate: (form: { id: number; title: string; maintenance_type_ids: Array<number> }) => void;
	listTypeMaintenance: Array<TypesMaintenanceSchema>;
};

type MaintenanceCompanyForm = {
	title: string;
	maintenance: Array<TypesMaintenanceSchema>;
};

const MaintenanceCompanyUpdateModal: FC<props> = ({
	visible,
	errorsField,
	maintenanceCompany,
	listTypeMaintenance,
	handleClose,
	onUpdate,
	onCancel
}) => {
	const theme = useTheme();

	const { control, getValues, reset, setValue } = useForm<MaintenanceCompanyForm>({
		defaultValues: {
			title: "",
			maintenance: []
		}
	});

	const isError = useCallback(
		(key: string) => {
			if (errorsField) {
				return errorsField.find(e => e.key === key) ? true : false;
			}
			return false;
		},
		[errorsField]
	);

	const getTextError = useCallback(
		(key: string) => {
			console.log("ERRORs-key", key);
			if (errorsField) {
				const found = errorsField.find(e => e.key === key);
				if (found) return found.message;
			}
			return "";
		},
		[errorsField]
	);

	useEffect(() => {
		if (!visible) reset();
	}, [visible, reset]);

	useEffect(() => {
		if (visible && maintenanceCompany) {
			setValue("title", maintenanceCompany.title);
			setValue(
				"maintenance",
				maintenanceCompany.CompanyMaintenanceTypes.map(item => item.MaintenanceType)
			);
		}
	}, [maintenanceCompany, visible, setValue]);

	return (
		<Modal open={visible} onClose={handleClose}>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: 400,
					bgcolor: "background.paper",
					boxShadow: 24,
					p: 4
				}}
			>
				<Grid container flexDirection={"row"}>
					<Typography variant="h5" color={theme.palette.primary.main} textAlign={"center"}>
						Actualizar Empresa
					</Typography>
					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={value}
									onChange={onChange}
									label="Nombre"
									variant="outlined"
									error={isError("title")}
									helperText={getTextError("title")}
								/>
							)}
							name="title"
						/>
					</Grid>
					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							render={({ field: { onChange, value } }) => (
								<Autocomplete
									multiple
									id={uuid()}
									size="small"
									options={listTypeMaintenance}
									getOptionLabel={(option: any) => option.title}
									onChange={(_, value) => {
										onChange(value);
									}}
									defaultValue={value}
									renderTags={(value, getTagProps) =>
										value.map((option: any, index) => (
											<Chip
												variant="outlined"
												label={option.title}
												size="small"
												{...getTagProps({ index })}
											/>
										))
									}
									renderInput={params => (
										<TextField
											{...params}
											variant="filled"
											label="Seleccionar tipos de matención"
											placeholder="Tipos de mantención"
											error={isError("maintenance_type_ids")}
											helperText={getTextError("maintenance_type_ids")}
										/>
									)}
								/>
							)}
							name="maintenance"
						/>
					</Grid>
				</Grid>
				<ButtonGroup
					style={{ paddingTop: 20, paddingBottom: 20, alignSelf: "center" }}
					variant="text"
					aria-label="text button group"
				>
					<Button onClick={onCancel}>
						<Close /> Cancelar
					</Button>
					<Button
						onClick={() =>
							onUpdate({
								id: Number(maintenanceCompany!.id),
								title: getValues().title,
								maintenance_type_ids: getValues().maintenance.map(item => Number(item.id))
							})
						}
					>
						<Add /> Actualizar
					</Button>
				</ButtonGroup>
			</Box>
		</Modal>
	);
};

export default MaintenanceCompanyUpdateModal;
