import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import React, { FC, useCallback } from "react";
import theme from "../../../Assets/Theme";
import { useCore } from "../../../Context/Core";
import useGraphQL from "../../../Hooks/useGraphQL";
import {
  DisablePLATSupport,
  EnablePLATSupport,
  mutation_disable_plat_support,
  mutation_enable_plat_support,
} from "./Query";
import { useNavigate } from "react-router-dom";

type Props = {
  visible: boolean;
  handleClose: () => void;
};

const PlatStatus: FC<Props> = ({ visible, handleClose }) => {
  const { condo, cleanCondosID } = useCore();
  const { useGraphMutation } = useGraphQL();
  const navigate = useNavigate();

  const [enablePlat, { loading: LoadingEnablePlat }] =
    useGraphMutation<EnablePLATSupport>(mutation_enable_plat_support);

  const [disablePlat, { loading: LoadingDisablePlat }] =
    useGraphMutation<DisablePLATSupport>(mutation_disable_plat_support);

  const handleEnablePlatSupport = useCallback(async () => {
    await enablePlat();
    handleClose();
    cleanCondosID();
    navigate("/intranet/condos");
  }, [enablePlat, LoadingEnablePlat]);

  const handleDisablePlatSupport = useCallback(async () => {
    await disablePlat();
    handleClose();
    cleanCondosID();
    navigate("/intranet/condos");
  }, [enablePlat, LoadingEnablePlat]);

  const loading = LoadingDisablePlat || LoadingEnablePlat;

  return (
    <Modal open={visible}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Grid container flexDirection={"column"}>
          <Typography
            variant="h4"
            color={theme.palette.primary.main}
            textAlign={"center"}
          >
            {condo && condo.supports_plat ? "DESHABILITAR" : "HABILITAR"} PLAT
          </Typography>
          <Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
            <Typography variant="h6" alignSelf={"center"}>
              ¿Desea
              {condo && condo.supports_plat ? " DESHABILITAR " : " HABILITAR "}
              PLAT para todos los RESIDENTES?
            </Typography>
          </Grid>
        </Grid>

        {loading ? (
          <Grid container flexDirection={"column"}>
            <CircularProgress style={{ margin: 20, alignSelf: "center" }} />
          </Grid>
        ) : (
          <ButtonGroup
            style={{ paddingTop: 20, paddingBottom: 20, alignSelf: "center" }}
            variant="text"
            aria-label="text button group"
          >
            <Button onClick={handleClose} size={"large"}>
              Cancelar
            </Button>
            <Button
              onClick={() =>
                condo && condo.supports_plat
                  ? handleDisablePlatSupport()
                  : handleEnablePlatSupport()
              }
              size={"large"}
            >
              {condo && condo.supports_plat ? "DESHABILITAR" : "HABILITAR"}
            </Button>
          </ButtonGroup>
        )}
      </Box>
    </Modal>
  );
};

export default PlatStatus;
