import { useState } from "react";

const useStatesDrawer = () => {
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const [isUpload, setUpload] = useState<boolean>(false);
  const [showPlatModal, setShowPlatModal] = useState<boolean>(false);
  const [isShowCommonExpense, setShowCommonExpense] = useState<boolean>(false);
  const [isOptionsActive, setIsOptionsActive] = useState<boolean>(false);
  const [location, setLocation] = useState<string>("");

  return {
    mobileOpen,
    isUpload,
    showPlatModal,
    isShowCommonExpense,
    isOptionsActive,
    location,
    setMobileOpen,
    setUpload,
    setShowPlatModal,
    setShowCommonExpense,
    setIsOptionsActive,
    setLocation,
  };
};
export default useStatesDrawer;
