import { DatePicker } from "@mui/x-date-pickers";
import { memo, useState, type FC } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { type Dayjs } from "dayjs";

export interface DateRange {
	startDate: Date;
	endDate: Date;
}

interface DateRangePickerProps {
	defaultStartDate?: Date;
	defaultEndDate?: Date;
	shouldHideFutureDates?: boolean;
	onChange: (dateRange: DateRange) => void;
}

const DateRangePicker: FC<DateRangePickerProps> = memo(
	({ onChange, defaultStartDate, defaultEndDate, shouldHideFutureDates = false }) => {
		const [startDate, setStartDate] = useState<Dayjs | null>(defaultStartDate ? dayjs(defaultStartDate) : null);
		const [endDate, setEndDate] = useState<Dayjs | null>(defaultEndDate ? dayjs(defaultEndDate) : null);

		const onDateChangeCallback = (newStartDate: Dayjs | null, newEndDate: Dayjs | null) => {
			setStartDate(newStartDate);
			setEndDate(newEndDate);

			if (newStartDate && newEndDate) {
				onChange({
					startDate: newStartDate.toDate(),
					endDate: newEndDate.toDate()
				});
			}
		};

		return (
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<div style={{ marginInlineEnd: "5%" }}>
					<DatePicker
						label="Desde"
						format="DD/MM/YYYY"
						disableFuture={shouldHideFutureDates}
						value={startDate}
						onChange={newDate => onDateChangeCallback(newDate, endDate)}
						slotProps={{
							textField: {
								inputProps: { readOnly: true }
							}
						}}
					/>
				</div>

				<div>
					<DatePicker
						label="Hasta"
						format="DD/MM/YYYY"
						disableFuture={shouldHideFutureDates}
						value={endDate}
						onChange={newDate => onDateChangeCallback(startDate, newDate)}
						slotProps={{
							textField: {
								inputProps: { readOnly: true }
							}
						}}
					/>
				</div>
			</LocalizationProvider>
		);
	}
);

export default DateRangePicker;
