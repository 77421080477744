import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { useCore } from "../../../../Context/Core";
import { apiURL } from "../../../../Hooks/useClientGraphql";
import { saveAs } from "file-saver";
import { useState } from "react";

const useIntegrationBulkloads = () => {
	const { token } = useCore();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const uploadExcelFile = async (file: File): Promise<AxiosResponse> => {
		try {
			setIsLoading(true);

			const formData = new FormData();
			formData.append("condos-data", file);

			const config = {
				headers: {
					"Content-Type": "multipart/form-data",
					Authorization: `Bearer ${token}`,
					platform: "WEB"
				}
			};

			const response = await axios.post(`${apiURL}/api/bulk-upload/condos-data`, formData, config);

			setIsLoading(false);

			return response;
		} catch (error) {
			setIsLoading(false);
			throw error;
		}
	};

	const downloadFile = async () => {
		const config: AxiosRequestConfig = {
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: `Bearer ${token}`,
				platform: "WEB"
			},
			responseType: "blob"
		};

		const response = await axios.get(`${apiURL}/api/bulk-upload/condos-data`, config);
		saveAs(response.data, "Datos de Condominios.xlsx");
	};

	return {
		downloadFile,
		uploadExcelFile,
		isLoading
	};
};
export default useIntegrationBulkloads;
