import { Dialog, IconButton, Slide, useTheme } from "@mui/material";
import React, {
  createContext,
  forwardRef,
  ReactNode,
  useContext,
  useState,
} from "react";
import { RowTable } from "../../UI/Components/CustomTable";
import { TransitionProps } from "@mui/material/transitions";
import { Close } from "@mui/icons-material";

type StateModalTable = {
  active: boolean;
  children?: ReactNode;
  row?: RowTable;
};

interface ModalContext {
  modal_table: StateModalTable;
  setModalTable: (state: StateModalTable) => void;
  modal_qualities: StateModalTable;
  setModalQualities: (state: StateModalTable) => void;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const modalContext = createContext<ModalContext>({
  modal_table: { active: false },
  setModalTable: () => {},
  modal_qualities: { active: false },
  setModalQualities: () => {},
});

export const useModal = () => useContext(modalContext);

const ModalProvider = ({ children }: { children: ReactNode }) => {
  const theme = useTheme();
  const [modal_table, setModalTable] = useState<StateModalTable>({
    active: false,
    children: undefined,
    row: undefined,
  });
  const [modal_qualities, setModalQualities] = useState<StateModalTable>({
    active: false,
    row: undefined,
  });

  const handleCleanStateModalTable = () =>
    setModalTable({
      active: false,
      children: undefined,
      row: undefined,
    });
  const handleCleanStateModalQualities = () =>
    setModalQualities({
      active: false,
      row: undefined,
    });

  const getLabelByRowID = () =>
    modal_table.row && "label" in modal_table.row
      ? (modal_table.row.label as string)
      : "";

  const getLabelMaterial = () =>
    modal_qualities.row && "label" in modal_qualities.row
      ? (modal_qualities.row.label as string)
      : "";

  const ModalForTable = () => (
    <Dialog
      fullScreen
      style={{
        backdropFilter: "blur(4px)", // Agrega el efecto de desenfoque al fondo
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      }}
      open={modal_table.active}
      onClose={handleCleanStateModalTable}
      TransitionComponent={Transition}
    >
      <div
        style={{
          flex: 1,
          backgroundColor: "#f5f5f5",
          paddingTop: 20,
          paddingLeft: 50,
          paddingRight: 50,
        }}
      >
        <h1
          style={{
            color: theme.palette.secondary.main,
          }}
        >
          {getLabelByRowID()}
        </h1>
        <IconButton
          onClick={handleCleanStateModalTable}
          style={{
            border: `1px solid ${theme.palette.secondary.main}`,
            right: 20,
            position: "absolute",
            top: 10,
          }}
        >
          <Close style={{ color: theme.palette.secondary.main }} />
        </IconButton>
      </div>
    </Dialog>
  );

  const ModalForQualities = () => (
    <Dialog
      fullScreen
      style={{
        backdropFilter: "blur(4px)", // Agrega el efecto de desenfoque al fondo
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      }}
      open={modal_qualities.active}
      onClose={handleCleanStateModalQualities}
      TransitionComponent={Transition}
    >
      <div
        style={{
          flex: 1,
          backgroundColor: "#f5f5f5",
          paddingTop: 20,
          paddingLeft: 50,
          paddingRight: 50,
        }}
      >
        <h1
          style={{
            color: theme.palette.secondary.main,
          }}
        >
          {getLabelMaterial()}
        </h1>
        <IconButton
          onClick={handleCleanStateModalQualities}
          style={{
            border: `1px solid ${theme.palette.secondary.main}`,
            right: 20,
            position: "absolute",
            top: 10,
          }}
        >
          <Close style={{ color: theme.palette.secondary.main }} />
        </IconButton>
      </div>
    </Dialog>
  );

  return (
    <modalContext.Provider
      value={{ modal_table, setModalTable, modal_qualities, setModalQualities }}
    >
      {children}
      <ModalForTable />
      <ModalForQualities />
    </modalContext.Provider>
  );
};

export default ModalProvider;
