import { useCallback, useEffect, useState } from "react";
import useGraphQL from "../../../../Hooks/useGraphQL";

import {
	mutation_create_user,
	mutation_delete_user,
	mutation_toggle_user,
	mutation_update_user,
	GET_WORKERS_BY_CONDO,
	type DeleteUsersResponse,
	type GetAllWorkersByCondoResponseData,
	type RegisterUserResponse,
	type ToggleUsersResponse,
	type UpdateUserResponse
} from "../GraphQL";

import { useLocation } from "react-router-dom";
import { UserRole } from "../../../../Types";

const useUsers = () => {
	const location = useLocation();

	const [currentList, setCurrentList] = useState<any[]>([]);
	const [value, setValue] = useState(0);
	const [confirmDeleteUser, setConfirmDeleteUser] = useState<boolean>(false);
	const [idDelete, setIdDelete] = useState<{ id: number; type: UserRole } | undefined>();

	const [addUser, setAddUser] = useState<{ active: boolean; type: UserRole }>({
		active: false,
		type: UserRole.plt
	});

	const { useGraphMutation, useLazyGraphQuery } = useGraphQL();

	const [updateUser, setUpdateUser] = useState<{
		active: boolean;
		user: any;
		id: undefined | number;
	}>({
		active: false,
		user: undefined,
		id: undefined
	});

	const [RegisterPLT] = useGraphMutation<RegisterUserResponse>(mutation_create_user);
	const [UpdateUserPLT] = useGraphMutation<UpdateUserResponse>(mutation_update_user);
	const [loadUsers, { loading }] = useLazyGraphQuery<GetAllWorkersByCondoResponseData>(GET_WORKERS_BY_CONDO);
	const [toogleUsers] = useGraphMutation<ToggleUsersResponse>(mutation_toggle_user);
	const [deleteUser] = useGraphMutation<DeleteUsersResponse>(mutation_delete_user);

	return {
		state: {
			currentList,
			value,
			confirmDeleteUser,
			idDelete,
			addUser,
			updateUser,
			loading
		},
		functions: {
			setValue,
			setConfirmDeleteUser,
			setIdDelete,
			setAddUser,
			setUpdateUser,
			RegisterPLT,
			UpdateUserPLT,
			toogleUsers,
			deleteUser,
			setCurrentList,
			loadUsers
		}
	};
};

export default useUsers;
