import { Box, Button, ButtonGroup, Grid, Modal, TextField, Typography, useTheme } from "@mui/material";
import { FC, useCallback, useEffect } from "react";

import { Add, Close } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import uuid from "react-uuid";
import { PLTFieldErrors, UserRole } from "../../../../Types";
import { UserForm } from "../GraphQL";

interface Props {
	visible: boolean;
	user: UpdateUserForm | undefined;
	errorsField: PLTFieldErrors;
	handleClose: (state: boolean) => void;
	onUpdate: (form: UpdateUserForm) => void;
	onCancel: () => void;
}

export type UpdateUserForm = UserForm & { id: number; role: UserRole };

const ModalUpdateUsers: FC<Props> = ({ visible, user, errorsField, handleClose, onUpdate, onCancel }) => {
	const { control, getValues, reset, setValue } = useForm<UserForm>({
		defaultValues: {
			name: "",
			last_name: "",
			email: "",
			password: "",
			enroll: false,
			user: ""
		}
	});

	const theme = useTheme();

	const isError = useCallback(
		(key: string) => {
			if (errorsField) {
				return errorsField.find(e => e.key === key) ? true : false;
			}
			return false;
		},
		[errorsField]
	);

	const getTextError = useCallback(
		(key: string) => {
			console.log("ERRORs-key", key);
			if (errorsField) {
				const found = errorsField.find(e => e.key === key);
				if (found) return found.message;
			}
			return "";
		},
		[errorsField]
	);

	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === "Enter" && user) {
			onUpdate({
				name: getValues().name,
				last_name: getValues().last_name,
				email: getValues().email,
				password: getValues().password,
				enroll: false,
				role: user.role,
				user: getValues().user,
				id: Number(user.id)
			});
		}
	};

	useEffect(() => {
		if (!visible) reset();
	}, [visible]);

	useEffect(() => {
		if (visible && user) {
			setValue("name", user.name);
			setValue("last_name", user.last_name);
			setValue("email", user.email);
			setValue("user", user.user);
		}
	}, [visible, user]);

	return (
		<Modal open={visible} onClose={handleClose}>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: 400,
					bgcolor: "background.paper",
					boxShadow: 24,
					p: 4
				}}
			>
				<Grid container flexDirection={"row"}>
					<Typography variant="h5" color={theme.palette.primary.main} textAlign={"center"}>
						Actualizar Usuario
					</Typography>
					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={value}
									onChange={onChange}
									label="Nombre"
									variant="outlined"
									error={isError("name")}
									helperText={getTextError("name")}
									onKeyDown={e => handleKeyDown(e)}
								/>
							)}
							name="name"
						/>
					</Grid>
					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={value}
									onChange={onChange}
									label="Apellido"
									variant="outlined"
									error={isError("last_name")}
									helperText={getTextError("last_name")}
									onKeyDown={e => handleKeyDown(e)}
								/>
							)}
							name="last_name"
						/>
					</Grid>

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={value}
									onChange={onChange}
									label="Usuario"
									variant="outlined"
									error={isError("user")}
									helperText={getTextError("user")}
									onKeyDown={e => handleKeyDown(e)}
								/>
							)}
							name="user"
						/>
					</Grid>

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={value}
									onChange={onChange}
									label="Email"
									variant="outlined"
									error={isError("email")}
									helperText={getTextError("email")}
									onKeyDown={e => handleKeyDown(e)}
								/>
							)}
							name="email"
						/>
					</Grid>

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={value}
									onChange={onChange}
									label="Contraseña"
									variant="outlined"
									error={isError("password")}
									helperText={getTextError("password")}
									onKeyDown={e => handleKeyDown(e)}
								/>
							)}
							name="password"
						/>
					</Grid>
				</Grid>

				<ButtonGroup
					style={{ paddingTop: 20, paddingBottom: 20, alignSelf: "center" }}
					variant="text"
					aria-label="text button group"
				>
					<Button onClick={onCancel}>
						<Close /> Cancelar
					</Button>
					<Button
						onClick={() => {
							if (user)
								onUpdate({
									name: getValues().name,
									last_name: getValues().last_name,
									email: getValues().email,
									password: getValues().password,
									enroll: false,
									role: user.role,
									user: getValues().user,
									id: Number(user.id)
								});
						}}
					>
						<Add /> Actualizar
					</Button>
				</ButtonGroup>
			</Box>
		</Modal>
	);
};

export default ModalUpdateUsers;
