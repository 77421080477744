import CustomTable, { type ControlsTable } from "../../Components/CustomTable";
import useTable from "../../Components/CustomTable/useTable";
import DateRangePicker, { type DateRange } from "../../Components/DateRangePicker/DateRangePicker";
import useGraphQL from "../../../Hooks/useGraphQL";
import { Nationality, getNationalitySpanishDescription } from "../CondosResidents/GraphQL";

import {
	GET_ACTION_LOGS_WITH_FILTERS,
	getActionLogTypeSpanishDescription,
	getActionLogTypesCategorySpanishDescription,
	getEntryMethodSpanishDescription,
	getTimeRangeSpanishDescription,
	getLogEntryTypeCategorySpanishDescription,
	type GetAllActionLogsResponseData
} from "./GraphQL";

import { query_get_all_condos, type CondoSchema, type GetAllCondosData } from "../Condos/GraphQL";
import { useEffect, useState, useMemo, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import * as XLSX from "xlsx";
import { format as formatRUT } from "rut.js";
import { useTheme, Box, Container, FormControl, InputLabel, Select, MenuItem, Button } from "@mui/material";
import { Download as DownloadIcon } from "@mui/icons-material";

import {
	ActionLogType,
	ActionLogTypesCategory,
	type ActionLogSchema,
	type ActionLogDescription,
	type HeaderRow
} from "./types";

import useReportsTable from "./Hooks/useReportsTable";

export default function ReportsScreen(): JSX.Element {
	const [actionLogs, setActionLogs] = useState<ActionLogDescription[]>([]);
	const [condos, setCondos] = useState<CondoSchema[]>([]);
	const [selectedCondoID, setSelectedCondoID] = useState<number | undefined>();
	const [selectedDateRange, setSelectedDateRange] = useState<DateRange | undefined>();
	const [headerRow, setHeaderRow] = useState<HeaderRow | undefined>();

	const [selectedActionLogTypesCategory, setSelectedActionLogTypesCategory] = useState<
		ActionLogTypesCategory | undefined
	>();

	const { useLazyGraphQuery } = useGraphQL();

	const [getAllActionLogs] = useLazyGraphQuery<GetAllActionLogsResponseData>(GET_ACTION_LOGS_WITH_FILTERS);
	const [getAllCondos] = useLazyGraphQuery<GetAllCondosData>(query_get_all_condos);

	const { getHeaderRowFromActionLogTypesCategory, getReportEntriesFromActionLogsWithCategory } = useReportsTable();

	const fetchCondos = useCallback(async () => {
		const response = await getAllCondos();

		if (response.data) {
			setCondos(response.data.GetAllCondos);
		}
	}, []);

	const fetchActionLogs = useCallback(async () => {
		if (selectedCondoID === undefined) {
			return;
		}

		setActionLogs([]);

		const response = await getAllActionLogs({
			variables: {
				queryInput: {
					condoID: selectedCondoID,
					startDate: selectedDateRange?.startDate,
					endDate: selectedDateRange?.endDate,
					actionLogTypesCategory: selectedActionLogTypesCategory
				}
			}
		});

		if (response.data) {
			const actionLogDescriptions = await Promise.all(
				response.data.actionLogs.map(actionLog => convertActionLogToActionLogDescription(actionLog))
			);

			setActionLogs(sortActionLogDescriptions(actionLogDescriptions));
		}
	}, [selectedCondoID, selectedDateRange, selectedActionLogTypesCategory]);

	const sortActionLogDescriptions = (actionLogDescriptions: ActionLogDescription[]) => {
		if (selectedActionLogTypesCategory === ActionLogTypesCategory.parking) {
			const sortedTypes = [
				ActionLogType.releasedParkingLot,
				ActionLogType.parkingLotFine,
				ActionLogType.parkingLotAlert,
				ActionLogType.parkingLotPhoneCall,
				ActionLogType.assignedParkingLot
			];

			actionLogDescriptions.sort((lhs, rhs) => {
				const lhsIndex = sortedTypes.indexOf(lhs.register_type);
				const rhsIndex = sortedTypes.indexOf(rhs.register_type);

				if (lhsIndex !== rhsIndex) {
					return lhsIndex - rhsIndex;
				} else if (lhs.parked_minutes && rhs.parked_minutes) {
					return rhs.parked_minutes - lhs.parked_minutes;
				} else if (lhs.parked_minutes) {
					return -1;
				} else if (rhs.parked_minutes) {
					return 1;
				}

				return rhs.utc_timestamp - lhs.utc_timestamp;
			});
		}

		return actionLogDescriptions;
	};

	useEffect(() => {
		fetchCondos();
		fetchActionLogs();
	}, [fetchCondos, fetchActionLogs]);

	const reportDownloadCallback = useCallback(async () => {
		if (actionLogs.length === 0) {
			return;
		}

		const categoryLabel = getActionLogTypesCategorySpanishDescription(selectedActionLogTypesCategory!);
		const condoName = condos.find(condo => condo.id === selectedCondoID)!.name;
		const sheetTitle = buildSheetTitleFromAttributes(categoryLabel, condoName, selectedDateRange);
		const reportSheet = XLSX.utils.json_to_sheet([{ title: sheetTitle }], { skipHeader: true });
		const reportEntries = getReportEntriesFromActionLogsWithCategory(actionLogs, selectedActionLogTypesCategory!);

		XLSX.utils.sheet_add_json(reportSheet, reportEntries, { origin: 1 });
		reportSheet["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 9 } }];

		const reportWorkbook = XLSX.utils.book_new();
		const destinationFilename = buildDestinationFilenameFromAttributes(categoryLabel, condoName, selectedDateRange);

		XLSX.utils.book_append_sheet(reportWorkbook, reportSheet, categoryLabel);
		XLSX.writeFileXLSX(reportWorkbook, `${destinationFilename}.xlsx`);
	}, [actionLogs, selectedActionLogTypesCategory, selectedDateRange, condos, selectedCondoID]);

	const convertActionLogToActionLogDescription = useCallback(
		async (actionLog: ActionLogSchema): Promise<ActionLogDescription> => {
			const technicianFullName =
				actionLog.Maintenance?.technical_name + ` ${actionLog.Maintenance?.technical_last_name}`;

			const residentIdentification =
				actionLog.Resident?.personal_identification ?? actionLog.Delivery?.Resident?.personal_identification;

			const formattedResidentIdentification =
				residentIdentification && actionLog.Resident?.nationality === Nationality.CHILEAN
					? formatRUT(residentIdentification)
					: residentIdentification;

			const nationalityDescription = actionLog.Resident
				? getNationalitySpanishDescription(actionLog.Resident?.nationality)
				: undefined;

			const timeRange = actionLog.reservation_time_range;
			const timeRangeDescription = timeRange ? getTimeRangeSpanishDescription(timeRange) : undefined;

			const logEntryType = actionLog.log_entry_type_category;
			const logEntryTypeDescription = logEntryType
				? getLogEntryTypeCategorySpanishDescription(logEntryType)
				: undefined;

			const visitorRUT = actionLog.Invitation?.visitor_rut
				? formatRUT(actionLog.Invitation?.visitor_rut)
				: undefined;

			const entryMethod = actionLog.entry_method;
			const entryMethodDescription = entryMethod ? getEntryMethodSpanishDescription(entryMethod) : undefined;

			return {
				...actionLog,
				visit_identification: actionLog.Visit?.personal_identification,
				resident_identification: formattedResidentIdentification,
				resident_type: actionLog.Resident?.resident_type,
				resident_email: actionLog.Resident?.email,
				resident_phone_number: actionLog.Resident?.phone_number,
				resident_nationality: nationalityDescription,
				resident_second_car_plate: actionLog.Resident?.secondary_patent,
				resident_parking_lot_label: actionLog.Resident?.ParkingsResidents?.[0]?.Parking?.label,
				resident_second_parking_lot_label: actionLog.Resident?.ParkingsResidents?.[1]?.Parking?.label,
				technician_full_name: technicianFullName,
				technician_rut: actionLog.Maintenance?.technical_rut,
				maintenance_company_name: actionLog.Maintenance?.company,
				maintenance_type_label: actionLog.Maintenance?.maintenance_type,
				parked_minutes_description: actionLog.parked_minutes?.toLocaleString("es-CL"),
				parking_lot_assignment_local_datetime: actionLog.parking_lot_assignment_local_datetime,
				was_parking_lot_fined_description: actionLog.was_parking_lot_fined ? "Sí" : "No",
				reservation_time_range_description: timeRangeDescription,
				log_entry_type_category_description: logEntryTypeDescription,
				visitor_rut: visitorRUT,
				visitor_name: actionLog.visitor_full_name ?? actionLog.visitor_alias,
				is_group_invitation_description: actionLog.is_group_invitation ? "Sí" : "No",
				edited_user_password_description: actionLog.edited_user_password ? "Sí" : "No",
				edited_user_enrollment_privilege_description: actionLog.edited_user_enrollment_privilege ? "Sí" : "No",
				entry_method_description: entryMethodDescription,
				type_description: getActionLogTypeSpanishDescription(actionLog.register_type)
			};
		},
		[selectedActionLogTypesCategory]
	);

	const tableProps = useTable();
	const customTableControls: ControlsTable = useMemo(() => tableProps, [tableProps]);

	const { control } = useForm<{
		condoID: number | undefined;
		actionLogTypesCategory: ActionLogTypesCategory | undefined;
	}>({
		defaultValues: {
			condoID: undefined,
			actionLogTypesCategory: undefined
		}
	});

	const theme = useTheme();

	return (
		<Box>
			<Container sx={{ display: "flex", alignItems: "center", gap: "15px" }}>
				<Box width="100%">
					<Controller
						control={control}
						name="condoID"
						render={({ field: { value, onChange } }) => (
							<FormControl sx={{ width: "100%" }}>
								<InputLabel id="condoLabel" style={{ color: theme.palette.primary.main }}>
									Condominio
								</InputLabel>

								<Select
									labelId="condo"
									id="condo"
									value={value}
									onChange={e => {
										setSelectedCondoID(e.target.value as number);
										onChange(e.target.value);
									}}
								>
									{condos.map(condo => (
										<MenuItem key={`${condo.id}-selres`} value={condo.id}>
											{condo.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						)}
					/>
				</Box>

				<Box width="100%" display="flex">
					<DateRangePicker
						shouldHideFutureDates={true}
						onChange={dateRange => setSelectedDateRange(dateRange)}
					/>
				</Box>

				<Box width="100%">
					<Controller
						control={control}
						name="actionLogTypesCategory"
						render={({ field: { value, onChange } }) => (
							<FormControl sx={{ width: "100%" }}>
								<InputLabel
									id="actionLogTypesCategoryLabel"
									style={{ color: theme.palette.primary.main }}
								>
									Categoría de tipos de registro
								</InputLabel>

								<Select
									labelId="actionLogTypesCategory"
									id="actionLogTypesCategory"
									value={value}
									onChange={e => {
										const category = e.target.value as ActionLogTypesCategory;

										setSelectedActionLogTypesCategory(category);
										setHeaderRow(getHeaderRowFromActionLogTypesCategory(category));

										onChange(e.target.value);
									}}
								>
									{Object.values(ActionLogTypesCategory).map(actionLogTypesCategory => (
										<MenuItem
											key={`${actionLogTypesCategory}-selres`}
											value={actionLogTypesCategory}
										>
											{getActionLogTypesCategorySpanishDescription(actionLogTypesCategory)}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						)}
					/>
				</Box>
			</Container>

			{actionLogs.length > 0 && (
				<Button sx={{ float: "right" }} onClick={reportDownloadCallback}>
					<DownloadIcon /> Descargar
				</Button>
			)}

			{selectedCondoID && headerRow && (
				<CustomTable
					controls={{ ...customTableControls, rowsPerPage: 100 }}
					columns={headerRow}
					rows={actionLogs}
				/>
			)}
		</Box>
	);
}

const locale = "es-CL";

function buildSheetTitleFromAttributes(categoryLabel: string, condoName: string, dateRange?: DateRange): string {
	let dateString: string;

	if (dateRange) {
		const startDateString = dateRange.startDate.toLocaleDateString(locale);
		const endDateString = dateRange.endDate.toLocaleDateString(locale);

		dateString = `${startDateString}–${endDateString}`;
	} else {
		dateString = new Date().toLocaleDateString(locale);
	}

	return `${categoryLabel} en ${condoName} (${dateString.replaceAll("-", "/")})`;
}

function buildDestinationFilenameFromAttributes(categoryLabel: string, condoName: string, dateRange?: DateRange) {
	let filename = categoryLabel;
	let dateString: string;

	if (dateRange) {
		const startDateString = dateRange.startDate.toLocaleDateString(locale);
		const endDateString = dateRange.endDate.toLocaleDateString(locale);

		dateString = `_${startDateString}–${endDateString}`;
	} else {
		dateString = `_${new Date().toLocaleDateString(locale)}`;
	}

	return filename + dateString.replaceAll("-", "_") + `_${condoName}`;
}
