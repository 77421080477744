import { gql } from "@apollo/client";

export type CommonAreasSchema = {
  id: number;
  label: string;
  is_active: boolean;

  CommonAreaTypes: {
    id: number;
    label: string;
    is_active: boolean;
  };
};

export type GetAllCommonAreasData = {
  GetAllCommonAreas: CommonAreasSchema[];
};

export type GetAllCommonAreasByCondoData = {
  GetAllCommonAreasByCondo: CommonAreasSchema[];
};

export type CreateCommonArea = {
  CreateCommonArea: CommonAreasSchema;
};

export type UpdateCommonArea = {
  UpdateCommonArea: CommonAreasSchema;
};

export type DeleteCommonArea = {
  DeleteCommonArea: CommonAreasSchema;
};

export type ToggleCommonAreaActiveStatus = {
  ToggleCommonArea: CommonAreasSchema;
};

const getCommonAreasSchema = `
    id
    label
    is_active
`;

export const query_get_all_common_areas = gql`
  query GetAllCommonAreas {
    GetAllCommonAreas {
      ${getCommonAreasSchema}
    }
  }
`;

export const GET_ALL_COMMON_AREAS_BY_CONDO = gql`
  query GetAllCommonAreasByCondo {
    GetAllCommonAreasByCondo {
      ${getCommonAreasSchema}
    }
  }
`;

export const mutation_create_common_area = gql`
  mutation CreateCommonArea($label: String!) {
    CreateCommonArea(label: $label) {
      id
    }
  }
`;

export const mutation_update_common_area = gql`
  mutation UpdateCommonArea($id: Int!, $label: String!) {
    UpdateCommonArea(id: $id, label: $label) {
      id
      label
      is_active
    }
  }
`;

export const mutation_toggle_active_common_area = gql`
  mutation ToggleCommonAreaActiveStatus($id: Int!) {
    ToggleCommonAreaActiveStatus(id: $id) {
      is_active
    }
  }
`;

export const mutation_delete_common_area = gql`
  mutation DeleteCommonArea($id: Int!) {
    DeleteCommonArea(id: $id) {
      is_active
    }
  }
`;
