import { FC, useCallback, useEffect } from "react";
import {
	Autocomplete,
	Box,
	Button,
	ButtonGroup,
	Chip,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Modal,
	Select,
	TextField,
	Typography,
	useTheme
} from "@mui/material";
import { Close, Add } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import uuid from "react-uuid";
import type { PLTFieldErrors } from "../../../../Types";
import { CommonAreaTypeSchema } from "../Querys";
import { CommonAreasSchema } from "../../CommonAreas/Querys";

export interface CommonAreaUpdateInput {
	label?: string;
	commonArea: CommonAreasSchema | undefined;
}

type CommonAreaTypeUpdateModalProps = {
	errorsField: PLTFieldErrors;
	isVisible: boolean;
	CommonAreaType: CommonAreaTypeSchema | undefined;
	listCommonAreas: CommonAreasSchema[];
	onSuccess: (name: string, id: number) => void;
	onClose: () => void;
};

export const CommonAreaTypeUpdateModal: FC<CommonAreaTypeUpdateModalProps> = ({
	isVisible,
	errorsField,
	CommonAreaType,
	listCommonAreas,
	onSuccess,
	onClose
}) => {
	const theme = useTheme();

	const { control, getValues, setValue } = useForm<CommonAreaUpdateInput>({
		defaultValues: {
			label: "",
			commonArea: undefined
		}
	});

	const isError = useCallback(
		(key: string) => {
			return errorsField.some(error => error.key === key);
		},
		[errorsField]
	);

	const getTextError = useCallback(
		(key: string) => {
			return errorsField?.find(e => e.key === key)?.message ?? "";
		},
		[errorsField]
	);

	useEffect(() => {
		if (isVisible && CommonAreaType) {
			setValue("label", CommonAreaType.label);
			setValue("commonArea", CommonAreaType.CommonArea);
		}
	}, [CommonAreaType, isVisible, setValue]);

	return (
		<Modal open={isVisible} onClose={onClose}>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: 400,
					bgcolor: "background.paper",
					boxShadow: 24,
					p: 4
				}}
			>
				<Grid container flexDirection={"row"}>
					<Typography variant="h5" color={theme.palette.primary.main} textAlign={"center"}>
						Actualizar Tipo de Área Común
					</Typography>

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={value}
									onChange={onChange}
									label="Nombre del Tipo de Área Común"
									variant="outlined"
									error={isError("label")}
									helperText={getTextError("label")}
								/>
							)}
							name="label"
						/>
					</Grid>

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							render={({ field: { onChange, value } }) => (
								<FormControl sx={{ width: "100%" }}>
									<InputLabel id="category">Espacio Común</InputLabel>
									<Select
										labelId="commonArea"
										id="commonArea"
										value={value?.id}
										label="Area Común"
										onChange={e =>
											onChange(listCommonAreas.filter(item => item.id === e.target.value)[0])
										}
										error={isError("commonArea")}
									>
										{listCommonAreas.map(commonArea => (
											<MenuItem key={`${commonArea.id}`} value={commonArea.id}>
												{commonArea.label}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							)}
							name="commonArea"
						/>
					</Grid>
				</Grid>

				<ButtonGroup
					style={{ paddingTop: 20, paddingBottom: 20, alignSelf: "center" }}
					variant="text"
					aria-label="text button group"
				>
					<Button onClick={onClose}>
						<Close /> Cancelar
					</Button>

					<Button
						onClick={() => {
							const name = getValues("label");
							const commonAreaID = getValues("commonArea")?.id;
							if (name) {
								onSuccess(name, Number(commonAreaID));
							}
						}}
					>
						<Add /> Actualizar
					</Button>
				</ButtonGroup>
			</Box>
		</Modal>
	);
};

export default CommonAreaTypeUpdateModal;
