import React, { CSSProperties, useState } from "react";
import { Box, Button, CircularProgress, Grid, Hidden, TextField, Typography } from "@mui/material";

import uuid from "react-uuid";
import { useCore } from "../../../Context/Core";
import GuardLogin from "../../Shared/GuardLogin";
import LogoPNG from "../../../Assets/Image/plat-logo.png";
import { Controller, useForm } from "react-hook-form";
import useGraphQL from "../../../Hooks/useGraphQL";

import { LoginRequest, LoginSchema, query_login } from "./Query";
import theme from "../../../Assets/Theme";
import useErrorGraphql from "../../../Hooks/useErrorGraphql";

const styleGrid: CSSProperties = {
	height: "100vh",
	alignItems: "center",
	justifyContent: "center",
	flexDirection: "column",
	display: "flex"
};

type FormLogin = {
	username: string;
	password: string;
};

const SignIn = () => {
	const [loading, setLoading] = useState(false);
	const { useGraphMutation } = useGraphQL();
	const { errorsField } = useErrorGraphql();

	const [onLogin] = useGraphMutation<LoginRequest>(query_login);

	const { setAuth } = useCore();

	const { control, getValues } = useForm<FormLogin>({
		defaultValues: {
			username: "",
			password: ""
		}
	});

	const handleLogin = async () => {
		setLoading(true);
		const { username, password } = getValues();
		const response = await onLogin({
			variables: {
				FormLogin: {
					username,
					password
				}
			}
		});
		if (response.data && response.data.Login.hasOwnProperty("id")) {
			const r = response.data.Login as unknown as LoginSchema;
			setAuth(r);
		}
		setLoading(false);
	};

	const isError = (key: string) => {
		if (errorsField) {
			return errorsField.find(e => e.key === key) ? true : false;
		}
		return false;
	};

	const getTextError = (key: string) => {
		if (errorsField) {
			const found = errorsField.find(e => e.key === key);
			if (found) return found.message;
		}
		return "";
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === "Enter") {
			handleLogin();
		}
	};

	const Form = () => (
		<Box
			component={"form"}
			noValidate
			autoComplete="off"
			sx={{
				"& .MuiTextField-root": { m: 1, width: "25ch" }
			}}
			width={"80%"}
			style={{
				paddingTop: "20%",
				alignItems: "center",
				display: "flex",
				flexDirection: "column"
			}}
		>
			<Hidden lgUp mdUp>
				<img
					src={LogoPNG}
					width={100}
					height={60}
					style={{
						backgroundColor: "#0E89D9",
						padding: 20,
						borderRadius: 10,
						alignSelf: "center",
						marginBottom: 20
					}}
				/>
			</Hidden>
			<Typography variant="h3" component="h2" color={theme.palette.primary.main} textAlign={"center"}>
				Iniciar Sesión
			</Typography>
			<Controller
				control={control}
				render={({ field: { onChange, value } }) => (
					<TextField
						disabled={false} // loading de login
						style={{ marginTop: "3em", width: "100%" }}
						id={uuid()}
						fullWidth
						value={value}
						onChange={onChange}
						label="Usuario"
						variant="filled"
						error={isError("user")}
						helperText={getTextError("user")}
						onKeyDown={e => handleKeyDown(e)}
					/>
				)}
				name="username"
			/>

			<Controller
				control={control}
				render={({ field: { onChange, value } }) => (
					<TextField
						disabled={false} // loading de login
						style={{ marginTop: "3em", width: "100%" }}
						id={uuid()}
						fullWidth
						value={value}
						onChange={onChange}
						label="Contraseña"
						variant="filled"
						security="*"
						error={isError("password")}
						helperText={getTextError("password")}
						type={"password"}
						onKeyDown={e => handleKeyDown(e)}
					/>
				)}
				name="password"
			/>
		</Box>
	);

	const ImageLogin = () => (
		<Hidden xsDown smDown mdDown>
			<Grid
				key={uuid()}
				sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block" } }}
				item
				md={7}
				lg={7}
				style={{
					...styleGrid,
					background:
						"radial-gradient(circle, rgba(229,233,243,1) 0%, rgba(133,149,199,1) 26%, rgba(78,102,174,1) 57%, rgba(28,58,151,1) 83%, rgba(3,37,140,1) 100%)"
				}}
			>
				<img src={LogoPNG} width={200} height={120} />
			</Grid>
		</Hidden>
	);

	const ContainerForm = () => (
		<Grid
			key={uuid()}
			item
			sx={{ display: { xs: "block", sm: "block", md: "block", lg: "block" } }}
			xs={12}
			sm={12}
			md={5}
			lg={5}
			style={{
				...styleGrid,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "flex-start"
			}}
		>
			<Form />

			<Button
				onClick={async () => handleLogin()}
				disabled={loading}
				style={{ marginTop: "20%" }}
				size="large"
				variant="contained"
			>
				{loading ? <CircularProgress color="primary" style={{ paddingInline: 10 }} size={20} /> : "Ingresar"}
			</Button>
		</Grid>
	);

	return (
		<Grid container>
			<GuardLogin />
			<ImageLogin />
			<ContainerForm />
		</Grid>
	);
};
export default SignIn;
