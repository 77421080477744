import { useState, useEffect } from "react";
import { Box, Button, ButtonGroup, Divider, Autocomplete, Chip, TextField } from "@mui/material";
import CustomTable from "../../Components/CustomTable";
import useTable from "../../Components/CustomTable/useTable";
import { Controller, useForm } from "react-hook-form";
import { Add } from "@mui/icons-material";
import GuardCondos from "../../Shared/GuardCondos";

import {
	type RequestCreateResident,
	type RequestDeleteResident,
	type RequestGetResidents,
	type RequestUpdateResident,
	type ResidentSchema,
	type FormattedResidentSchema,
	mutation_delete_resident,
	mutation_register_resident,
	mutation_update_resident,
	query_get_residents,
	getResidentTypeSpanishDescription,
	getNationalitySpanishDescription
} from "./GraphQL";

import ModalResident from "./ModalResident";
import useErrorGraphql from "../../../Hooks/useErrorGraphql";
import ModalUpdateResident from "./ModalUpdateResident";
import useGraphQL from "../../../Hooks/useGraphQL";
import { useCore } from "../../../Context/Core";
import AlertDialog from "../../Components/CustomAlertDialog";

import {
	query_get_properties,
	GET_PROPERTIES_WITH_RESIDENTS_BY_CONDO,
	type PropertySchema,
	type RequestGetProperty,
	type GetPropertiesWithResidentsByCondoData
} from "../CondosProperty/GraphQL";

import { query_get_all_parking, type ParkingSchema, type GetAllParkingLotsResponseData } from "../CondosParking/Querys";
import { ResidentForm } from "./FormResident";
import { Nationality, sortResidents } from "./GraphQL";
import { ToastContainer, toast } from "react-toastify";

const headerRow = [
	{ id: "formatted_identification", label: "Identificación" },
	{ id: "full_name", label: "Nombre" },
	{ id: "phone_number", label: "Número Telefónico" },
	{ id: "email", label: "Email" },
	{ id: "nationality_description", label: "Nacionalidad" },
	{ id: "resident_type_description", label: "Tipo" },
	{ id: "property_label", label: "Inmueble" },
	{ id: "actions", label: "Acciones" }
];

const CondosResidents = () => {
	const tableProps = useTable();
	const { condo } = useCore();
	const [residents, setResidents] = useState<FormattedResidentSchema[]>([]);
	const [properties, setProperties] = useState<PropertySchema[]>([]);
	const [filteringProperties, setFilteringProperties] = useState<PropertySchema[]>([]);
	const [filteringProperty, setFilteringProperty] = useState<PropertySchema | undefined>();
	const [parkingLots, setParkingLots] = useState<ParkingSchema[]>([]);
	const [isAdd, setAdd] = useState<boolean>(false);
	const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
	const [idDelete, setIdDelete] = useState<{ id: number } | undefined>();
	const [isUpdate, setUpdate] = useState<{ id: number; resident: FormattedResidentSchema } | undefined>();

	const handleSetResidents = (residents: ResidentSchema[]) => {
		const residentsList = sortResidents(residents).map(resident => ({
			...resident,
			resident_type_description: getResidentTypeSpanishDescription(resident.resident_type),
			property_label: resident.Property?.residence_identification,
			nationality_description: getNationalitySpanishDescription(resident.nationality),
			formatted_identification:
				resident.nationality == Nationality.CHILEAN ? resident.formatted_rut! : resident.personal_identification
		}));

		setResidents(residentsList);
	};

	const { errorsField, setErrorFields } = useErrorGraphql();
	const { useLazyGraphQuery, useGraphMutation } = useGraphQL();

	const [getProperties] = useLazyGraphQuery<RequestGetProperty>(query_get_properties);

	const [getFilteringProperties] = useLazyGraphQuery<GetPropertiesWithResidentsByCondoData>(
		GET_PROPERTIES_WITH_RESIDENTS_BY_CONDO
	);

	const [getParkingLots] = useLazyGraphQuery<GetAllParkingLotsResponseData>(query_get_all_parking);
	const [getResidents] = useLazyGraphQuery<RequestGetResidents>(query_get_residents);
	const [deleteResident] = useGraphMutation<RequestDeleteResident>(mutation_delete_resident);
	const [updateResidents] = useGraphMutation<RequestUpdateResident>(mutation_update_resident);
	const [createResident] = useGraphMutation<RequestCreateResident>(mutation_register_resident);

	const fetchFilteringProperties = async () => {
		const response = await getFilteringProperties();

		if (response.data && response.data.propertiesWithResidentsByCondo) {
			const sortedFilteringProperties = response.data.propertiesWithResidentsByCondo.toSorted(
				(property1: PropertySchema, property2: PropertySchema) => {
					const propertyLabel1 = property1.residence_identification;
					const propertyLabel2 = property2.residence_identification;

					if (!isNaN(Number(propertyLabel1)) && !isNaN(Number(propertyLabel2))) {
						return Number(propertyLabel1) - Number(propertyLabel2);
					} else if (!isNaN(Number(propertyLabel1))) {
						return -1;
					} else if (!isNaN(Number(propertyLabel2))) {
						return 1;
					} else {
						return propertyLabel1.localeCompare(propertyLabel2);
					}
				}
			);

			setFilteringProperties(sortedFilteringProperties);
		}
	};

	const fetchResidents = async () => {
		const response = await getResidents();

		if (!response.data) {
			return;
		}

		const residentsResponse = response.data.GetAllResidentsByCondoId;

		handleSetResidents(residentsResponse);
	};

	const fetchProperties = async () => {
		const response = await getProperties();

		if (response.data) {
			setProperties(response.data.GetAllPropertiesByCondoId);
		}
	};

	const fetchParkingLots = async () => {
		const response = await getParkingLots();

		if (response.data) {
			setParkingLots(response.data.GetAllParkings);
		}
	};

	useEffect(() => {
		fetchFilteringProperties();
	}, [condo]);

	useEffect(() => {
		fetchResidents();
	}, [condo]);

	useEffect(() => {
		fetchProperties();
	}, [condo]);

	useEffect(() => {
		fetchParkingLots();
	}, [condo]);

	const handleCreateResident = async (form: ResidentForm) => {
		const response = await createResident({
			variables: {
				FormCreateResident: {
					...form,
					parkings: form.parkings.map(p => Number(p.id)) as unknown as Array<number>,
					property_id: Number(form.property_id)
				}
			}
		});

		const list = response.data?.RegisterResident;

		if (list) {
			toast.success("Residente agregado correctamente");
			handleSetResidents(list);
			setAdd(false);
		}
	};

	const handleUpdateResident = async (form: ResidentForm) => {
		const response = await updateResidents({
			variables: {
				FormUpdateResident: {
					...form,
					parkings: form.parkings.map(p => Number(p.id)) as unknown as Array<number>,
					id: Number(form.id),
					property_id: Number(form.property_id),
					patent: form.patent ?? "",
					secondary_patent: form.secondary_patent ?? ""
				}
			}
		});

		const list = response.data?.UpdateResident;

		if (list) {
			toast.success("Residente actualizado correctamente");
			handleSetResidents(list);
			setUpdate(undefined);
		}
	};

	const handleDeleteResident = async () => {
		if (condo)
			if (idDelete) {
				const response = await deleteResident({
					variables: {
						resident_id: Number(idDelete.id)
					}
				});

				if (response.data) {
					const list = response.data as RequestDeleteResident;

					if (Array.isArray(list.DeleteResident)) {
						toast.success("Residente eliminado correctamente");

						handleSetResidents(list.DeleteResident);

						setConfirmDelete(false);
						setIdDelete(undefined);
					}
				}
			}
	};

	const { control } = useForm<{ property?: PropertySchema }>({
		defaultValues: {
			property: undefined
		}
	});

	const CondosList = () => (
		<Box>
			<GuardCondos />

			<Box width="25%">
				<Controller
					control={control}
					name="property"
					render={({ field: { onChange, value } }) => (
						<Autocomplete
							multiple={false}
							size="small"
							options={filteringProperties}
							getOptionLabel={(property: PropertySchema) => property.residence_identification}
							value={value}
							onChange={(_, property) => {
								setFilteringProperty(property ?? undefined);
								onChange(property);
							}}
							renderTags={(properties, getTagProps) =>
								properties.map((property, index) => (
									<Chip
										variant="outlined"
										label={property.residence_identification}
										size="small"
										{...getTagProps({ index })}
									/>
								))
							}
							renderInput={params => (
								<TextField
									{...params}
									variant="filled"
									label="Selecciona un inmueble"
									placeholder="Inmueble"
								/>
							)}
						/>
					)}
				/>
			</Box>

			<ButtonGroup style={{ paddingTop: 20, paddingBottom: 20 }} variant="text" aria-label="text button group">
				<Button
					onClick={() => {
						setErrorFields(undefined);
						setAdd(true);
					}}
				>
					<Add /> Agregar Residente
				</Button>
			</ButtonGroup>

			<Divider style={{ marginTop: 15, marginBottom: 15 }} />

			<CustomTable
				controls={{
					...tableProps,
					onUpdate: row => {
						setErrorFields(undefined);

						const resident = row as FormattedResidentSchema;
						setUpdate({ id: resident.id, resident });
					},
					onActiveStatusToggle: () => {},
					onDelete: ({ id, row }) => {
						const resident = row as FormattedResidentSchema;

						setIdDelete({ id: Number(resident.id) });
						setConfirmDelete(true);
					}
				}}
				columns={headerRow}
				rows={
					filteringProperty
						? residents.filter(resident => resident.Property!.id === filteringProperty.id)
						: residents
				}
			/>
		</Box>
	);

	return (
		<Box>
			<CondosList />

			<ModalResident
				errorsField={errorsField ? errorsField : []}
				handleClose={() => setAdd(false)}
				onAdd={handleCreateResident}
				onCancel={() => setAdd(false)}
				visible={isAdd}
				propertiesList={properties}
				listParking={parkingLots}
			/>

			<ModalUpdateResident
				visible={isUpdate ? true : false}
				errorsField={errorsField ? errorsField : []}
				handleClose={() => setUpdate(undefined)}
				onCancel={() => setUpdate(undefined)}
				onUpdate={handleUpdateResident}
				resident={isUpdate?.resident}
				propertiesList={properties}
				listParking={parkingLots}
			/>

			<AlertDialog
				title={"Advertencia"}
				open={confirmDelete}
				setOpen={setConfirmDelete}
				warningMessage={"Estás apunto de eliminar un residente. ¿Deseas continuar?"}
				onCancel={() => setIdDelete(undefined)}
				onConfirm={handleDeleteResident}
			/>

			<ToastContainer position="bottom-left" autoClose={3000} />
		</Box>
	);
};

export default CondosResidents;
