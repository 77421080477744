import { gql } from "@apollo/client";

const schema = `
    id
    label
    condo_id
    active
`;

export type VisitDeliveryTypeSchema = {
  id: number;
  label: string;
  condo_id: number;
  active: boolean;
};

export type RequestGetVisitDeliveryTypes = {
  GetAllVisitDeliveryTypes?: Array<VisitDeliveryTypeSchema>;
};
export type RequestCreateVisitDeliveryType = {
  RegisterVisitDeliveryType?: Array<VisitDeliveryTypeSchema>;
};
export type RequestUpdateVisitDeliveryType = {
  UpdateVisitDeliveryType?: Array<VisitDeliveryTypeSchema>;
};
export type RequestDeleteVisitDeliveryType = {
  DeleteVisitDeliveryType?: Array<VisitDeliveryTypeSchema>;
};
export type RequestActiveVisitDeliveryType = {
  ChangeActiveVisitDeliveryType?: Array<VisitDeliveryTypeSchema>;
};

export const query_get_visit_delivery_types = gql`
query GetAllVisitDeliveryTypes{
  GetAllVisitDeliveryTypes{
        ${schema}
    }
}
`;

export const mutation_register_visit_delivery_type = gql`
mutation RegisterVisitDeliveryType($label:String!){
  RegisterVisitDeliveryType(label:$label){
        ${schema}
    }
}
`;
export const mutation_update_visit_delivery_type = gql`
mutation UpdateVisitDeliveryType($id: Int!, $label: String!) {
	UpdateVisitDeliveryType(id: $id, label: $label) {
        ${schema}
    }
}
`;
export const mutation_delete_visit_delivery_type = gql`
mutation DeleteVisitDeliveryType($delivery_type_id: Int!){
  DeleteVisitDeliveryType(delivery_type_id:$delivery_type_id){
        ${schema}
    }
}
`;
export const mutation_active_visit_delivery_type = gql`
mutation ChangeActiveVisitDeliveryType($delivery_type_id: Int!) {
	ChangeActiveVisitDeliveryType(delivery_type_id: $delivery_type_id) {
    ${schema}
	}
}
`;
