import { TextField } from "@mui/material";
import { createRef, useEffect, useState, memo } from "react";
interface ColumnTable {
	id: string;
	label: string;
	minWidth?: number;
	align?: "right";
	isImage?: boolean;
	format?: (value: number) => string;
}
interface RowTable {
	[key: string]: string | number | boolean | null | object;
}

interface SearchState {
	searchText: string;
	setSearchText: (value: string) => void;
}

const useFilterTable = (header: Array<ColumnTable>, { searchText, setSearchText }: SearchState) => {
	const [keyFilter, setKeyFilter] = useState<Array<string>>([]);
	const [shouldAutoFocusInput, setShouldAutoFocusInput] = useState<boolean>(false);

	useEffect(() => {
		setKeyFilter(header.map(head => head.id) as unknown as Array<string>);
		setShouldAutoFocusInput(true);
	}, []);

	const handleFilterList = (list: RowTable[], searchText: string) => {
		if (searchText.length === 0) {
			return list;
		}

		const filteredList = new Set<RowTable>();

		keyFilter.forEach(key => {
			list.forEach(body => {
				const data = body[key];

				if (!data || !data.toString().toLowerCase().includes(searchText.toLowerCase())) {
					return;
				}

				filteredList.add(body);
			});
		});

		return [...filteredList];
	};

	const SearchInput = () => (
		<div style={{ padding: 20 }}>
			<TextField
				id="outlined-basic"
				label="Buscar"
				variant="outlined"
				type="search"
				value={searchText}
				autoFocus={shouldAutoFocusInput}
				onChange={text => {
					text.preventDefault();
					setSearchText(text.target.value);
				}}
			/>
		</div>
	);

	return { handleFilterList, SearchInput: memo(SearchInput) };
};

export default useFilterTable;
