import { useEffect, memo, type FC } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from "recharts";
import { Card, CircularProgress } from "@mui/material";
import useGraphQL from "../../../../../Hooks/useGraphQL";

import {
	GET_ACTIVE_PARKING_LOTS_FOR_VISITORS,
	type GetActiveParkingLotsReservedForVisitorsByCondoIDResponseData
} from "../../GraphQL";

const ParkingLotsPieChart: FC<{ condoID: number }> = ({ condoID }) => {
	const { useLazyGraphQuery } = useGraphQL();

	const [loadParkingLots, { data: parkingLotsData, loading: isLoadingParkingLots }] =
		useLazyGraphQuery<GetActiveParkingLotsReservedForVisitorsByCondoIDResponseData>(
			GET_ACTIVE_PARKING_LOTS_FOR_VISITORS
		);

	const list = parkingLotsData?.activeParkingLotsForVisitorsByCondoID ?? [];

	const data = [
		{
			name: "Libres",
			value: list.filter(parking => !parking.is_busy).length
		},
		{
			name: "Ocupados",
			value: list.filter(parking => parking.is_busy && !parking.is_expired).length
		},
		{
			name: "Expirados",
			value: list.filter(parking => parking.is_expired).length
		}
	];

	const colors = ["#4FC3F7 ", "#FFB74D", "#E57373"];

	const Loader = () => (
		<div style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
			<CircularProgress />
		</div>
	);

	useEffect(() => {
		if (condoID) {
			loadParkingLots({ variables: { condoID: condoID } });
		}
	}, [condoID]);

	if (!condoID) {
		return <Loader />;
	}

	if (isLoadingParkingLots) {
		return <Loader />;
	}

	return (
		<Card
			style={{
				width: 370,
				height: 370
			}}
		>
			<ResponsiveContainer width="100%" height="100%">
				<PieChart>
					<Pie
						data={data}
						cx="50%"
						cy="50%"
						labelLine={false}
						label={({ name, value }) => `${value}`}
						fill="rgba(255, 255, 255, 0.4)"
						dataKey="value"
					>
						{data.map((entry, index) => (
							<Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
						))}
					</Pie>

					<Legend layout="horizontal" verticalAlign="bottom" align="center" />
				</PieChart>
			</ResponsiveContainer>
		</Card>
	);
};

export default memo(ParkingLotsPieChart);
