import React, { useCallback, useEffect, useState } from "react";
import { useCore } from "../../../Context/Core";
import { Box, Button, ButtonGroup, Divider } from "@mui/material";
import { Add } from "@mui/icons-material";
import CustomTable, { RowTable } from "../../Components/CustomTable";
import useTable from "../../Components/CustomTable/useTable";
import useGraphQL from "../../../Hooks/useGraphQL";
import ModalCreateTypeMaintenance from "./ModalCreateTypeMaintenance";
import GuardCondos from "../../Shared/GuardCondos";
import ModalUpdateTypeMaintenance from "./ModalUpdateTypeMaintenance";
import AlertDialog from "../../Components/CustomAlertDialog";
import useErrorGraphql from "../../../Hooks/useErrorGraphql";
import {
	type MaintenanceTypeSchema,
	type RequestCreateMaintenance,
	type RequestDeleteMaintenance,
	type RequestGetMaintenance,
	type RequestUpdateMaintenance,
	type ToggleMaintenanceRequest,
	mutation_delete_maintenance,
	mutation_create_maintenance,
	mutation_toggle_maintenance,
	mutation_update_maintenance,
	query_get_maintenances
} from "./Query";

import { ToastContainer, toast } from "react-toastify";
<ToastContainer position="bottom-left" autoClose={3000} />;

const head = [
	{ id: "title", label: "Nombre" },
	{ id: "active", label: "Activo/Inactivo" },
	{ id: "actions", label: "Acciones" }
];

const CondosMaintenance = () => {
	const [isAdd, setAdd] = useState<boolean>(false);

	const [isUpdate, setUpdate] = useState<{
		active: boolean;
		maintenance: MaintenanceTypeSchema | undefined;
	}>({ active: false, maintenance: undefined });

	const [currentList, setCurrentList] = useState<Array<MaintenanceTypeSchema>>([]);

	const [confirmDeleteMaintenance, setConfirmDeleteMaintenance] = useState<boolean>(false);
	const [idDelete, setIdDelete] = useState<{ id: number } | undefined>();

	const { condo } = useCore();

	const { useLazyGraphQuery, useGraphMutation } = useGraphQL();

	const { errorsField, setErrorFields } = useErrorGraphql();

	const tableProps = useTable();

	const [loadMaintenance] = useLazyGraphQuery<RequestGetMaintenance>(query_get_maintenances);
	const [createMaintenance] = useGraphMutation<RequestCreateMaintenance>(mutation_create_maintenance);
	const [deleteMaintenance] = useGraphMutation<RequestDeleteMaintenance>(mutation_delete_maintenance);
	const [toogleMaintenance] = useGraphMutation<ToggleMaintenanceRequest>(mutation_toggle_maintenance);
	const [updateMaintenance] = useGraphMutation<RequestUpdateMaintenance>(mutation_update_maintenance);

	const handleCreate = useCallback(async ({ title }: { title: string }) => {
		if (condo) {
			const reponse = await createMaintenance({
				variables: {
					title
				}
			});
			if (reponse.data) {
				const list = reponse.data.CreateMaintenanceType;

				if (list) {
					toast.success("Tipo de Mantención agregada correctamente");

					setAdd(false);
					setCurrentList(list);
				}
			}
		}
	}, []);

	const handleUpdate = useCallback(async ({ id, title }: MaintenanceTypeSchema) => {
		if (condo) {
			const reponse = await updateMaintenance({
				variables: {
					title,
					id: Number(id)
				}
			});

			if (reponse.data) {
				const list = reponse.data.UpdateMaintenanceType;

				if (list) {
					toast.success("Tipo de Mantención actualizado correctamente");
					setUpdate({ active: false, maintenance: undefined });
					setCurrentList(list);
				}
			}
		}
	}, []);

	const handleResponseToggle = (data?: ToggleMaintenanceRequest | null | undefined) => {
		if (data) {
			const { ChangeActiveMaintenanceType } = data as ToggleMaintenanceRequest;
			return ChangeActiveMaintenanceType;
		}
		return [];
	};

	const handleToggle = async (variables: { [key: string]: any }) =>
		await toogleMaintenance({
			variables
		});

	const onActive = async (row: RowTable) => {
		if (condo) {
			setCurrentList(
				handleResponseToggle(
					(
						await handleToggle({
							maintenance_type_id: Number(row.id)
						})
					).data
				)
			);
		}
	};

	const handleDelete = useCallback(async (id: number) => {
		const response = await deleteMaintenance({
			variables: {
				maintenance_type_id: id
			}
		});
		if (response.data) {
			const list = response.data.DeleteMaintenanceType;
			if (list) {
				toast.success("Tipo de Mantención Eliminada correctamente");
				setCurrentList(list);
				setConfirmDeleteMaintenance(false);
				setIdDelete(undefined);
			}
		}
	}, []);

	const loadMaintenanceTypes = useCallback(() => {
		if (condo)
			loadMaintenance().then(response => {
				if (response.data) {
					const list = response.data.GetAllMaintenanceTypesByCondoID;

					if (list) {
						setCurrentList(list);
					}
				}
			});
	}, []);

	const MaintenanceTable = () => (
		<CustomTable
			controls={{
				...tableProps,
				onUpdate: row => {
					setErrorFields(undefined);
					setUpdate({
						active: true,
						maintenance: row as unknown as MaintenanceTypeSchema
					});
				},
				onActiveStatusToggle: onActive,
				onDelete: ({ id }) => {
					setConfirmDeleteMaintenance(true);
					setIdDelete({ id: Number(id) });
				}
			}}
			columns={head}
			rows={currentList}
		/>
	);

	useEffect(() => {
		loadMaintenanceTypes();
	}, [condo]);

	return (
		<Box>
			<GuardCondos />

			<ButtonGroup style={{ paddingTop: 20, paddingBottom: 20 }} variant="text" aria-label="text button group">
				<Button
					onClick={() => {
						setErrorFields(undefined);
						setAdd(true);
					}}
				>
					<Add /> Agregar Tipo de Mantención
				</Button>
			</ButtonGroup>

			<Divider style={{ marginTop: 15, marginBottom: 15 }} />

			<MaintenanceTable />

			<ModalCreateTypeMaintenance
				errorsField={errorsField ? errorsField : []}
				handleClose={() => setAdd(false)}
				onAdd={handleCreate}
				onCancel={() => setAdd(false)}
				visible={isAdd}
			/>

			<ModalUpdateTypeMaintenance
				errorsField={errorsField ? errorsField : []}
				handleClose={() => setUpdate({ active: false, maintenance: undefined })}
				onUpdate={handleUpdate}
				onCancel={() => setUpdate({ active: false, maintenance: undefined })}
				visible={isUpdate.active}
				maintenance={isUpdate.maintenance}
			/>

			<AlertDialog
				title={"Advertencia"}
				open={confirmDeleteMaintenance}
				setOpen={setConfirmDeleteMaintenance}
				warningMessage={"Estás apunto de eliminar un Tipo de Mantención. ¿Deseas continuar?"}
				onCancel={() => setIdDelete(undefined)}
				onConfirm={() => {
					if (idDelete) handleDelete(idDelete.id);
				}}
			/>

			<ToastContainer position="bottom-left" autoClose={3000} />
		</Box>
	);
};
export default CondosMaintenance;
