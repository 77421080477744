import {
	Autocomplete,
	Box,
	Button,
	ButtonGroup,
	Chip,
	Grid,
	Modal,
	TextField,
	Typography,
	useTheme
} from "@mui/material";

import { Add, Close } from "@mui/icons-material";
import { useCallback, useEffect, type FC } from "react";
import { Controller, useForm } from "react-hook-form";
import uuid from "react-uuid";

import type { PLTFieldErrors } from "../../../../Types";
import type { CommonAreasSchema } from "../../CommonAreas/Querys";

type Props = {
	visible: boolean;
	errorsField: PLTFieldErrors;
	onClose: () => void;
	onAdd: (form: CondoCreationInput) => void;
	CommonAreas: CommonAreasSchema[];
};

export interface CondoCreationInput {
	name: string;
	pairing_code: number;
	maximum_parking_time: number;
	inactivity_time: number;
	address: string;
	primary_phone_number: string;
	secondary_phone_number?: string;
	devices_number: number;
	commonAreas: CommonAreasSchema[];
}

const ModalCondos: FC<Props> = ({ visible, errorsField, onClose, onAdd, CommonAreas }) => {
	const theme = useTheme();

	const { control, getValues, reset } = useForm<CondoCreationInput>({
		defaultValues: {
			name: "",
			pairing_code: 0,
			maximum_parking_time: 0,
			inactivity_time: 0,
			address: "",
			primary_phone_number: "",
			secondary_phone_number: "",
			devices_number: 1,
			commonAreas: []
		}
	});

	const hasError = useCallback(
		(key: string) => {
			if (errorsField) {
				return errorsField.find(e => e.key === key) ? true : false;
			}

			return false;
		},
		[errorsField]
	);

	const getErrorMessage = useCallback(
		(key: string) => {
			console.log("ERRORs-key", key);

			if (errorsField) {
				const found = errorsField.find(e => e.key === key);
				if (found) return found.message;
			}

			return "";
		},
		[errorsField]
	);

	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === "Enter") {
			console.log(getValues());
			onAdd(getValues());
		}
	};

	useEffect(() => {
		if (!visible) reset();
	}, [visible]);

	return (
		<Modal open={visible} onClose={onClose}>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: 400,
					bgcolor: "background.paper",
					boxShadow: 24,
					p: 4,
					maxHeight: "95vh",
					overflowY: "auto"
				}}
			>
				<Grid container flexDirection={"row"}>
					<Typography variant="h5" color={theme.palette.primary.main} textAlign={"center"}>
						Agregar Condominio
					</Typography>

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							name="name"
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={value}
									onChange={onChange}
									label="Nombre"
									variant="outlined"
									error={hasError("name")}
									helperText={getErrorMessage("name")}
									onKeyDown={e => handleKeyDown(e)}
									required
								/>
							)}
						/>
					</Grid>

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							name="maximum_parking_time"
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={`${value}`}
									onChange={e => onChange(Number(e.target.value))}
									label="Minutos de estacionamiento"
									variant="outlined"
									type="number"
									error={hasError("maximum_parking_time")}
									helperText={getErrorMessage("maximum_parking_time")}
									onKeyDown={e => handleKeyDown(e)}
									required
								/>
							)}
						/>
					</Grid>

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							name="inactivity_time"
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={`${value}`}
									onChange={e => onChange(Number(e.target.value))}
									label="Minutos de inactividad en la aplicación"
									type="number"
									variant="outlined"
									error={hasError("inactivity_time")}
									helperText={getErrorMessage("inactivity_time")}
									onKeyDown={e => handleKeyDown(e)}
									required
								/>
							)}
						/>
					</Grid>

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							name="address"
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={value}
									onChange={onChange}
									label="Dirección"
									variant="outlined"
									error={hasError("address")}
									helperText={getErrorMessage("address")}
									onKeyDown={e => handleKeyDown(e)}
								/>
							)}
						/>
					</Grid>

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							name="primary_phone_number"
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={value}
									onChange={onChange}
									label="Número telefónico principal"
									variant="outlined"
									error={hasError("primary_phone_number")}
									helperText={getErrorMessage("primary_phone_number")}
									onKeyDown={e => handleKeyDown(e)}
								/>
							)}
						/>
					</Grid>

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							name="secondary_phone_number"
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={value}
									onChange={onChange}
									label="Número telefónico secundario"
									variant="outlined"
									error={hasError("secondary_phone_number")}
									helperText={getErrorMessage("secondary_phone_number")}
									onKeyDown={e => handleKeyDown(e)}
								/>
							)}
						/>
					</Grid>

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							name="devices_number"
							render={({ field: { onChange, value } }) => (
								<TextField
									type="number"
									inputProps={{ min: 1 }}
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={value}
									onChange={e => onChange(Number(e.target.value))}
									label="Número de dispositivos"
									variant="outlined"
									error={hasError("devices_number")}
									helperText={getErrorMessage("devices_number")}
									onKeyDown={e => handleKeyDown(e)}
									required
								/>
							)}
						/>
					</Grid>

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							name="pairing_code"
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={`${value}`}
									onChange={e => onChange(Number(e.target.value))}
									type="number"
									label="Código de pareo"
									variant="outlined"
									error={hasError("pairing_code")}
									helperText={getErrorMessage("pairing_code")}
									onKeyDown={e => handleKeyDown(e)}
									required
								/>
							)}
						/>
					</Grid>

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							name="commonAreas"
							render={({ field: { onChange, value } }) => (
								<Autocomplete
									multiple
									id={uuid()}
									size="small"
									options={CommonAreas}
									onChange={(_, value) => {
										onChange(value);
									}}
									defaultValue={value}
									renderTags={(commonAreaLabels, getTagProps) =>
										commonAreaLabels.map((commonAreaLabel, index) => (
											<Chip
												variant="outlined"
												label={commonAreaLabel.label}
												size="small"
												{...getTagProps({ index })}
											/>
										))
									}
									renderInput={params => (
										<TextField
											{...params}
											variant="filled"
											label="Seleccionar espacios comunes"
											placeholder="Espacios comunes"
										/>
									)}
								/>
							)}
						/>
					</Grid>
				</Grid>

				<ButtonGroup
					style={{ paddingTop: 20, paddingBottom: 20, alignSelf: "center" }}
					variant="text"
					aria-label="text button group"
				>
					<Button onClick={onClose}>
						<Close /> Cancelar
					</Button>

					<Button onClick={() => onAdd(getValues())}>
						<Add /> Agregar
					</Button>
				</ButtonGroup>
			</Box>
		</Modal>
	);
};

export default ModalCondos;
