import { Add } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Divider } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import CustomTable, {
  ControlsTable,
  RowTable,
} from "../../Components/CustomTable";
import { useCore } from "../../../Context/Core";
import useTable from "../../Components/CustomTable/useTable";
import CommonAreaTypeCreationModal from "./CommonAreaTypesCreationModal";
import CommonAreaTypeUpdateModal from "./CommonAreaTypesUpdateModal";
import useGraphQL from "../../../Hooks/useGraphQL";
import {
  CommonAreaTypeSchema,
  CreateCommonArea,
  DeleteCommonAreaType,
  GetCommonAreasTypes,
  ToggleCommonAreaTypeActiveStatus,
  UpdateCommonArea,
  mutation_create_common_area_type,
  mutation_delete_common_area_type,
  mutation_toggle_active_common_area_type,
  mutation_update_common_area_type,
  query_get_common_areas_types,
} from "./Querys";
import AlertDialog from "../../Components/CustomAlertDialog";
import useErrorGraphql from "../../../Hooks/useErrorGraphql";
import {
  CommonAreasSchema,
  GetAllCommonAreasByCondoData,
  GET_ALL_COMMON_AREAS_BY_CONDO,
} from "../CommonAreas/Querys";
import GuardCondos from "../../Shared/GuardCondos";

const head = [
  { id: "label", label: "Nombre" },
  { id: "is_active", label: "Activo/Inactivo" },
  { id: "actions", label: "Acciones" },
];

function CommonAreasTypes() {
  const [showCreationModal, setShowCreationModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [shouldConfirmDeletion, setShouldConfirmDeletion] = useState(false);
  const [idToDelete, setIDToDelete] = useState<number | undefined>();

  const [commonAreasTypesList, setCommonAreasTypesList] = useState<
    CommonAreaTypeSchema[]
  >([]);

  const [commonAreas, setCommonAreas] = useState<CommonAreasSchema[]>([]);

  const [updateState, setUpdateState] = useState<{
    isUpdating: boolean;
    commonAreaType: CommonAreaTypeSchema | undefined;
  }>({
    isUpdating: false,
    commonAreaType: undefined,
  });

  const { useLazyGraphQuery, useGraphMutation } = useGraphQL();
  const { errorsField, setErrorFields } = useErrorGraphql();

  const [loadCommonAreasTypes] = useLazyGraphQuery<GetCommonAreasTypes>(
    query_get_common_areas_types
  );

  const [loadCommonAreas] = useLazyGraphQuery<GetAllCommonAreasByCondoData>(
    GET_ALL_COMMON_AREAS_BY_CONDO
  );

  const [createCommonArea] = useGraphMutation<CreateCommonArea>(
    mutation_create_common_area_type
  );
  const [updateCommonArea] = useGraphMutation<UpdateCommonArea>(
    mutation_update_common_area_type
  );
  const [toggleCommonArea] = useGraphMutation<ToggleCommonAreaTypeActiveStatus>(
    mutation_toggle_active_common_area_type
  );
  const [deleteCommonArea] = useGraphMutation<DeleteCommonAreaType>(
    mutation_delete_common_area_type
  );

  const handleLoadCommonAreas = async () => {
    const response = await loadCommonAreasTypes();
    if (response.data?.GetAllCommonAreaTypes) {
      setCommonAreasTypesList(response.data?.GetAllCommonAreaTypes);
    }

    const commonAreas = await loadCommonAreas();
    if (commonAreas.data?.GetAllCommonAreasByCondo) {
      setCommonAreas(commonAreas.data?.GetAllCommonAreasByCondo);
    }
  };

  const onUpdateCallback = useCallback(
    (row: RowTable) => {
      setErrorFields(undefined);
      const { label } = row;

      if (label) {
        setUpdateState({
          isUpdating: true,
          commonAreaType: row as CommonAreaTypeSchema,
        });
        setShowUpdateModal(true);
      }
    },
    [errorsField]
  );

  const onDeleteCallback = useCallback(
    (row: RowTable) => {
      const { id } = row;
      if (id) {
        setIDToDelete(Number(id));
        setShouldConfirmDeletion(true);
      }
    },
    [idToDelete]
  );

  const onActiveCallback = useCallback(async (row: RowTable) => {
    const { id } = row;
    if (id) {
      await toggleCommonArea({ variables: { id } });
      await handleLoadCommonAreas();
      toast.success("Tipo de espacio común activado correctamente");
    }
  }, []);

  const tableProps = useTable();

  const customTableControls: ControlsTable = useMemo(
    () => ({
      ...tableProps,
      onUpdate: onUpdateCallback,
      onDelete: onDeleteCallback,
      onActiveStatusToggle: onActiveCallback,
    }),
    [tableProps, onUpdateCallback, onDeleteCallback, onActiveCallback]
  );

  const handleCreateCommonArea = useCallback(
    async (label: string, commonAreaID: number) => {
      if (label) {
        setShowCreationModal(false);

        const response = await createCommonArea({
          variables: { label, commonAreaID },
        });

        if (response.data?.CreateCommonAreaType) {
          setCommonAreasTypesList(response.data.CreateCommonAreaType);
          toast.success("Tipo de espacio común creado correctamente");
        }
      }
    },
    []
  );

  const handleUpdateCommonArea = async (label: string, id: number) => {
    const response = await updateCommonArea({
      variables: {
        id: Number(updateState.commonAreaType?.id),
        updateInput: { label, commonAreaID: Number(id) },
      },
    });

    if (response.data?.UpdateCommonAreaType) {
      setUpdateState({
        isUpdating: false,
        commonAreaType: undefined,
      });
      setCommonAreasTypesList(response.data.UpdateCommonAreaType);
      toast.success("Tipo de espacio común actualizado correctamente");
    }
  };

  const handleDeleteCommonArea = useCallback(async () => {
    if (idToDelete) {
      const response = await deleteCommonArea({
        variables: { id: idToDelete },
      });

      if (response.data) {
        toast.success("Tipo de espacio común eliminado correctamente");
        setShouldConfirmDeletion(false);
        setIDToDelete(undefined);
        handleLoadCommonAreas();
      }
    }
  }, [idToDelete, toast, setShouldConfirmDeletion, setIDToDelete]);

  const deletionCancelingCallback = useCallback(() => {
    setIDToDelete(undefined);
  }, [setIDToDelete]);

  const CommonAreasTable = () => (
    <CustomTable
      controls={customTableControls}
      columns={head}
      rows={commonAreasTypesList}
    />
  );

  const { condo } = useCore();

  useEffect(() => {
    handleLoadCommonAreas();
  }, [condo]);

  return (
    <Box>
      <GuardCondos />

      <ButtonGroup
        style={{ paddingTop: 20, paddingBottom: 20 }}
        variant="text"
        aria-label="text button group"
      >
        <Button
          onClick={() => {
            setErrorFields(undefined);
            setShowCreationModal(true);
          }}
        >
          <Add /> Agregar Tipo de Espacio Común
        </Button>
      </ButtonGroup>

      <Divider style={{ marginTop: 15, marginBottom: 15 }} />
      <CommonAreasTable />

      <CommonAreaTypeCreationModal
        isVisible={showCreationModal}
        listCommonAreas={commonAreas}
        errorsField={errorsField ?? []}
        commonAreas={commonAreas}
        onSuccess={handleCreateCommonArea}
        onClose={() => setShowCreationModal(!showCreationModal)}
      />

      <CommonAreaTypeUpdateModal
        isVisible={updateState.isUpdating}
        errorsField={errorsField ?? []}
        listCommonAreas={commonAreas}
        CommonAreaType={
          updateState.commonAreaType ? updateState.commonAreaType : undefined
        }
        onSuccess={handleUpdateCommonArea}
        onClose={() =>
          setUpdateState({
            isUpdating: false,
            commonAreaType: updateState.commonAreaType,
          })
        }
      />

      <AlertDialog
        title="Advertencia"
        open={shouldConfirmDeletion}
        setOpen={setShouldConfirmDeletion}
        warningMessage="Estás apunto de eliminar un tipo de espacio común. ¿Deseas continuar?"
        onCancel={deletionCancelingCallback}
        onConfirm={() => handleDeleteCommonArea()}
      />

      <ToastContainer position="bottom-left" autoClose={3000} />
    </Box>
  );
}

export default CommonAreasTypes;
