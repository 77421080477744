import React, { useCallback, useEffect, useState } from "react";
import { useCore } from "../../../Context/Core";
import { Box, Button, ButtonGroup, Divider } from "@mui/material";
import { Add } from "@mui/icons-material";
import CustomTable from "../../Components/CustomTable";
import useTable from "../../Components/CustomTable/useTable";
import useGraphQL from "../../../Hooks/useGraphQL";
import {
	type RequestCreateVisitDeliveryType,
	type RequestDeleteVisitDeliveryType,
	type RequestGetVisitDeliveryTypes,
	type RequestUpdateVisitDeliveryType,
	type VisitDeliveryTypeSchema,
	type RequestActiveVisitDeliveryType,
	mutation_delete_visit_delivery_type,
	mutation_register_visit_delivery_type,
	mutation_update_visit_delivery_type,
	mutation_active_visit_delivery_type,
	query_get_visit_delivery_types
} from "./Querys";
import ModalDelivery from "./ModalVisitDeliveryType";
import GuardCondos from "../../Shared/GuardCondos";
import ModalUpdateDelivery from "./ModalUpdateVisitDeliveryType";
import AlertDialog from "../../Components/CustomAlertDialog";
import useErrorGraphql from "../../../Hooks/useErrorGraphql";

import { ToastContainer, toast } from "react-toastify";

const head = [
	{ id: "label", label: "Tipo de Entrega" },
	{ id: "active", label: "Activo/Inactivo" },
	{ id: "actions", label: "Acciones" }
];
const VisitDeliveryTypes = () => {
	const [isAdd, setAdd] = useState<boolean>(false);

	const [isUpdate, setUpdate] = useState<{
		active: boolean;
		delivery: VisitDeliveryTypeSchema | undefined;
	}>({ active: false, delivery: undefined });

	const [visitDeliveryTypesList, setVisitDeliveryTypes] = useState<Array<VisitDeliveryTypeSchema>>([]);

	const [confirmDeleteDelivery, setConfirmDeleteDelivery] = useState<boolean>(false);
	const [idDelete, setIdDelete] = useState<{ id: number } | undefined>();

	const { condo } = useCore();

	const { useLazyGraphQuery, useGraphMutation } = useGraphQL();

	const { errorsField, setErrorFields } = useErrorGraphql();

	const tableProps = useTable();

	const [loadVisitDeliveryTypes] = useLazyGraphQuery<RequestGetVisitDeliveryTypes>(query_get_visit_delivery_types);

	const [createVisitDeliveryTypes] = useGraphMutation<RequestCreateVisitDeliveryType>(
		mutation_register_visit_delivery_type
	);
	const [updateVisitDeliveryTypes] = useGraphMutation<RequestUpdateVisitDeliveryType>(
		mutation_update_visit_delivery_type
	);
	const [deleteVisitDeliveryTypes] = useGraphMutation<RequestDeleteVisitDeliveryType>(
		mutation_delete_visit_delivery_type
	);
	const [activeVisitDeliveryTypes] = useGraphMutation<RequestActiveVisitDeliveryType>(
		mutation_active_visit_delivery_type
	);

	const handleCreate = async ({ label }: { label: string }) => {
		if (condo) {
			const reponse = await createVisitDeliveryTypes({
				variables: {
					label
				}
			});
			if (reponse.data) {
				const list = reponse.data.RegisterVisitDeliveryType;
				if (list) {
					toast.success("Tipo de entrega agregado correctamente");
					setAdd(false);
					setVisitDeliveryTypes(list);
				}
			}
		}
	};
	const handleUpdate = async ({ label, id }: { label: string; id: number }) => {
		if (condo) {
			const reponse = await updateVisitDeliveryTypes({
				variables: {
					label,
					id: Number(id)
				}
			});
			if (reponse.data) {
				const list = reponse.data.UpdateVisitDeliveryType;
				if (list) {
					toast.success("Tipo de entrega actualizado correctamente");
					setUpdate({ active: false, delivery: undefined });
					setVisitDeliveryTypes(list);
				}
			}
		}
	};

	const handleDelete = async (id: number) => {
		const response = await deleteVisitDeliveryTypes({
			variables: { delivery_type_id: Number(id) }
		});
		if (response.data) {
			const list = response.data.DeleteVisitDeliveryType;
			if (list) {
				toast.success("Tipo de entrega Eliminado correctamente");
				setVisitDeliveryTypes(list);
				setConfirmDeleteDelivery(false);
				setIdDelete(undefined);
			}
		}
	};

	const handleLoadDeliveries = useCallback(
		() =>
			loadVisitDeliveryTypes().then(response => {
				if (response.data) {
					const list = response.data.GetAllVisitDeliveryTypes;
					if (list) setVisitDeliveryTypes(list);
				}
			}),
		[loadVisitDeliveryTypes]
	);

	const DeliverysTable = () => (
		<CustomTable
			controls={{
				...tableProps,
				onUpdate: row => {
					setErrorFields(undefined);
					setUpdate({
						active: true,
						delivery: row as unknown as VisitDeliveryTypeSchema
					});
				},
				onActiveStatusToggle: async row => {
					const response = await activeVisitDeliveryTypes({
						variables: {
							delivery_type_id: Number(row.id)
						}
					});
					const list = response.data?.ChangeActiveVisitDeliveryType;
					if (list) setVisitDeliveryTypes(list);
				},
				onDelete: ({ id }) => {
					setConfirmDeleteDelivery(true);
					setIdDelete({ id: Number(id) });
				}
			}}
			columns={head}
			rows={visitDeliveryTypesList}
		/>
	);

	useEffect(() => {
		if (condo) handleLoadDeliveries();
	}, [condo, handleLoadDeliveries]);

	return (
		<Box>
			<GuardCondos />

			<ButtonGroup style={{ paddingTop: 20, paddingBottom: 20 }} variant="text" aria-label="text button group">
				<Button
					onClick={() => {
						setErrorFields(undefined);
						setAdd(true);
					}}
				>
					<Add /> Agregar Tipo de Entrega
				</Button>
			</ButtonGroup>
			<Divider style={{ marginTop: 15, marginBottom: 15 }} />
			<DeliverysTable />
			<ModalDelivery
				errorsField={errorsField ? errorsField : []}
				handleClose={() => setAdd(false)}
				onAdd={handleCreate}
				onCancel={() => setAdd(false)}
				visible={isAdd}
			/>
			<ModalUpdateDelivery
				errorsField={errorsField ? errorsField : []}
				handleClose={() => setUpdate({ active: false, delivery: undefined })}
				onUpdate={handleUpdate}
				onCancel={() => setUpdate({ active: false, delivery: undefined })}
				visible={isUpdate.active}
				delivery={isUpdate.delivery}
			/>
			<AlertDialog
				title={"Advertencia"}
				open={confirmDeleteDelivery}
				setOpen={setConfirmDeleteDelivery}
				warningMessage={"Estás apunto de eliminar un Tipo de Entrega. ¿Deseas continuar?"}
				onCancel={() => setIdDelete(undefined)}
				onConfirm={() => {
					if (idDelete) handleDelete(idDelete.id);
				}}
			/>
			<ToastContainer position="bottom-left" autoClose={3000} />
		</Box>
	);
};
export default VisitDeliveryTypes;
