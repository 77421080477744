import { useState, useCallback, useEffect, type FC, type ChangeEvent } from "react";
import { Add, Close } from "@mui/icons-material";

import { useTheme, Box, Button, ButtonGroup, Grid, Modal, TextField, Typography, Input } from "@mui/material";

import { Controller, useForm } from "react-hook-form";
import uuid from "react-uuid";

import type { EmergencyContactSchema } from "../GraphQL";
import type { PLTFieldErrors } from "../../../../Types";

export interface EmergencyContactUpdateModalInput {
	name?: string;
	phoneNumber?: string;
	description?: string;
	logoFile?: File;
}

type EmergencyContactUpdateModalProps = {
	isVisible: boolean;
	errorsField: PLTFieldErrors;
	emergencyContact: EmergencyContactSchema;
	onSuccess: (emergencyContactID: number, creationInput: EmergencyContactUpdateModalInput) => void;
	onClose: () => void;
};

export const EmergencyContactCreationModal: FC<EmergencyContactUpdateModalProps> = ({
	isVisible,
	errorsField,
	emergencyContact,
	onSuccess,
	onClose
}) => {
	const [logoURL, setLogoURL] = useState("");
	const theme = useTheme();

	const { control, getValues, setValue, reset } = useForm<EmergencyContactUpdateModalInput>({
		defaultValues: {
			name: "",
			phoneNumber: "",
			description: "",
			logoFile: undefined
		}
	});

	const isError = useCallback((key: string) => errorsField.some(error => error.key === key), [errorsField]);

	const getErrorText = useCallback(
		(key: string) => errorsField?.find(error => error.key === key)?.message ?? "",
		[errorsField]
	);

	const onLogoFileChangeCallback = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const logoFile = event.target.files?.[0];

			if (!logoFile || logoFile.size > 200 * 1024) {
				return;
			}

			setValue("logoFile", logoFile);

			const fileReader = new FileReader();

			fileReader.onload = () => {
				setLogoURL(fileReader.result as string);
			};

			fileReader.readAsDataURL(logoFile);
		},
		[setValue, setLogoURL]
	);

	useEffect(() => {
		if (isVisible) {
			setValue("name", emergencyContact.name);
			setValue("phoneNumber", emergencyContact.phone_number);
			setValue("description", emergencyContact.description);

			setLogoURL(emergencyContact.logo_url);
		} else {
			reset();
			setLogoURL("");
		}
	}, [isVisible, setValue, emergencyContact, setLogoURL]);

	return (
		<Modal open={isVisible} onClose={onClose}>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: 400,
					bgcolor: "background.paper",
					boxShadow: 24,
					p: 4
				}}
			>
				<Grid container flexDirection={"row"}>
					<Typography variant="h5" color={theme.palette.primary.main} textAlign={"center"}>
						Actualizar Contacto de Emergencia
					</Typography>

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							name="name"
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={value}
									onChange={onChange}
									label="Nombre"
									variant="outlined"
									error={isError("name")}
									helperText={getErrorText("name")}
								/>
							)}
						/>
					</Grid>

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							name="phoneNumber"
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={value}
									onChange={onChange}
									label="Número telefónico"
									variant="outlined"
									error={isError("phoneNumber")}
									helperText={getErrorText("phoneNumber")}
								/>
							)}
						/>
					</Grid>

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							name="description"
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={value}
									onChange={onChange}
									label="Descripción"
									variant="outlined"
									error={isError("description")}
									helperText={getErrorText("description")}
								/>
							)}
						/>
					</Grid>

					{logoURL && (
						<Grid style={{ padding: 15, width: "100%" }} item xs={12} sm={12} md={12} lg={12}>
							<img
								src={logoURL}
								alt="Logo"
								style={{
									width: "100%",
									height: 100,
									objectFit: "contain",
									borderRadius: 10
								}}
							/>
						</Grid>
					)}

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							name="logoFile"
							render={() => (
								<Button variant="contained" component="label" style={{ width: "100%" }}>
									Subir Logo
									<input type="file" hidden onChange={onLogoFileChangeCallback} />
								</Button>
							)}
						/>
					</Grid>
				</Grid>

				<ButtonGroup
					style={{ paddingTop: 20, paddingBottom: 20, alignSelf: "center" }}
					variant="text"
					aria-label="text button group"
				>
					<Button onClick={onClose}>
						<Close /> Cancelar
					</Button>

					<Button onClick={() => onSuccess(emergencyContact.id, getValues())}>
						<Add /> Actualizar
					</Button>
				</ButtonGroup>
			</Box>
		</Modal>
	);
};

export default EmergencyContactCreationModal;
