import { Box, Button, ButtonGroup, Chip, Grid, Modal, TextField, Typography, useTheme } from "@mui/material";
import React, { FC, useCallback, useEffect } from "react";
import { UserForm } from "../GraphQL";
import { Add, Close } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import uuid from "react-uuid";
import { PLTFieldErrors, UserRole } from "../../../../Types";
import { format as formatRUT } from "rut.js";

interface Props {
	visible: boolean;
	type: UserRole;
	errorsField: PLTFieldErrors;
	handleClose: (state: boolean) => void;
	onAdd: (form: UserForm) => void;
	onCancel: () => void;
}

const ModalCreateUsers: FC<Props> = ({ visible, type, errorsField, handleClose, onAdd, onCancel }) => {
	const { control, getValues, reset } = useForm<UserForm>({
		defaultValues: {
			name: "",
			last_name: "",
			email: "",
			rut: undefined,
			password: undefined,
			enroll: false,
			user: undefined
		}
	});

	const theme = useTheme();

	const isError = useCallback(
		(key: string) => {
			if (errorsField) {
				return errorsField.find(e => e.key === key) ? true : false;
			}
			return false;
		},
		[errorsField]
	);

	const getTextError = useCallback(
		(key: string) => {
			console.log("ERRORs-key", key);
			if (errorsField) {
				const found = errorsField.find(e => e.key === key);
				if (found) return found.message;
			}
			return "";
		},
		[errorsField]
	);

	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === "Enter") {
			onAdd({
				name: getValues().name,
				last_name: getValues().last_name,
				email: getValues().email,
				rut: getValues().rut,
				password: getValues().password,
				enroll: false,
				role: type,
				user: getValues().user
			});
		}
	};

	useEffect(() => {
		if (!visible) reset();
	}, [visible]);

	return (
		<Modal open={visible} onClose={handleClose}>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: 400,
					bgcolor: "background.paper",
					boxShadow: 24,
					p: 4
				}}
			>
				<Grid container flexDirection={"row"}>
					<Typography variant="h5" color={theme.palette.primary.main} textAlign={"center"}>
						Agregar Usuario <Chip label={type} variant="outlined" />
					</Typography>
					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={value}
									onChange={onChange}
									label="Nombre"
									variant="outlined"
									error={isError("name")}
									helperText={getTextError("name")}
									onKeyDown={e => handleKeyDown(e)}
									required
								/>
							)}
							name="name"
						/>
					</Grid>
					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={value}
									onChange={onChange}
									label="Apellido"
									variant="outlined"
									error={isError("last_name")}
									helperText={getTextError("last_name")}
									onKeyDown={e => handleKeyDown(e)}
									required
								/>
							)}
							name="last_name"
						/>
					</Grid>
					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={value}
									onChange={onChange}
									label="Email"
									variant="outlined"
									error={isError("email")}
									helperText={getTextError("email")}
									onKeyDown={e => handleKeyDown(e)}
									required
								/>
							)}
							name="email"
						/>
					</Grid>

					{type == UserRole.admin && (
						<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
							<Controller
								control={control}
								name="rut"
								render={({ field: { onChange, value } }) => (
									<TextField
										color={"primary"}
										style={{ width: "100%" }}
										id={uuid()}
										fullWidth
										value={value}
										onChange={e => {
											const rut = e.target.value;
											onChange(rut.length > 2 ? formatRUT(rut) : rut);
										}}
										label="RUT"
										variant="outlined"
										error={isError("rut")}
										helperText={getTextError("rut")}
										onKeyDown={e => handleKeyDown(e)}
										required
									/>
								)}
							/>
						</Grid>
					)}

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={value}
									onChange={onChange}
									label="Usuario"
									variant="outlined"
									error={isError("user")}
									helperText={getTextError("user")}
									onKeyDown={e => handleKeyDown(e)}
									required
								/>
							)}
							name="user"
						/>
					</Grid>

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={value}
									onChange={onChange}
									label="Contraseña"
									variant="outlined"
									error={isError("password")}
									helperText={getTextError("password")}
									onKeyDown={e => handleKeyDown(e)}
									required
								/>
							)}
							name="password"
						/>
					</Grid>
				</Grid>

				<ButtonGroup
					style={{ paddingTop: 20, paddingBottom: 20, alignSelf: "center" }}
					variant="text"
					aria-label="text button group"
				>
					<Button onClick={onCancel}>
						<Close /> Cancelar
					</Button>
					<Button
						onClick={() =>
							onAdd({
								name: getValues().name,
								last_name: getValues().last_name,
								email: getValues().email,
								rut: getValues().rut,
								password: getValues().password,
								enroll: false,
								role: type,
								user: getValues().user
							})
						}
					>
						<Add /> Agregar
					</Button>
				</ButtonGroup>
			</Box>
		</Modal>
	);
};

export default ModalCreateUsers;
