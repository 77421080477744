import { useState } from "react";

const useTable = () => {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [tabValue, setTabValue] = useState<number>(0);

	const [collapsible, setCollapsible] = useState<{
		active: boolean;
		id: number;
	}>({
		active: false,
		id: 0
	});

	const [isEditable, setIsEditable] = useState<{
		isEditable: boolean;
		__typename: string;
	}>({ __typename: "", isEditable: false });

	return {
		page,
		setPage,
		rowsPerPage,
		setRowsPerPage,
		collapsible,
		setCollapsible,
		isEditable,
		setIsEditable,
		tabValue,
		setTabValue
	};
};

export default useTable;
