import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { Subject, Subscription } from "rxjs";
import { CodeError, PLTFieldErrors } from "../../Types";
import { CondoSchema } from "../../UI/Screens/Condos/GraphQL";

type infoAuth = {
	id: number | null;
	name: string | null;
	last_name: string | null;
	email: string | null;
	user: string | null;
	role: string | null;
	active: boolean | null;
	enroll: boolean | null;
	token: string | null;
};

interface CoreContext {
	id: number | null;
	name: string | null;
	last_name: string | null;
	email: string | null;
	role: string | null;
	active: boolean | null;
	enroll: boolean | null;
	token: string | null;
	condo: CondoSchema | undefined;
	setAuth: (props: infoAuth) => void;
	setCondos: (condo: CondoSchema) => void;
	cleanAuth: () => void;
	cleanCondosID: () => void;
	isActiveSession: () => boolean;
	getLettersName: () => string;
	ErrorSubject: Subject<PLTFieldErrors>;
}

type Props = {
	children: ReactNode;
	ErrorSubject: Subject<PLTFieldErrors>;
};

const coreContext = createContext<CoreContext>({
	id: null,
	name: null,
	last_name: null,
	email: null,
	role: null,
	active: null,
	enroll: null,
	token: null,
	condo: undefined,
	setAuth: (props: infoAuth) => {},
	cleanAuth: () => {},
	cleanCondosID: () => {},
	setCondos: (state: CondoSchema) => {},
	isActiveSession: () => false,
	getLettersName: () => "",
	ErrorSubject: new Subject<PLTFieldErrors>()
});

export enum StorageKeys {
	auth = "@auth",
	condos_id = "@condos_id"
}

export const useCore = () => useContext(coreContext);

const CoreProvider = ({ children, ErrorSubject }: Props) => {
	const [condo, setCondo] = useState<CondoSchema | undefined>();
	const [Error, setError] = useState<string | null>(null);
	const CleanSession: Subject<boolean> = new Subject<boolean>();

	const [infoAuth, setInfoAuth] = useState<infoAuth>({
		id: null,
		name: null,
		last_name: null,
		email: null,
		role: null,
		active: null,
		enroll: null,
		token: null,
		user: null
	});

	const setAuth = (props: infoAuth) => {
		setInfoAuth(props);
		sessionStorage.setItem(StorageKeys.auth, JSON.stringify(props));
	};

	const setCondos = (condo: CondoSchema) => {
		setCondo(condo);
		sessionStorage.setItem(StorageKeys.condos_id, JSON.stringify(condo));
	};

	const cleanCondosID = () => {
		setCondo(undefined);
		sessionStorage.removeItem(StorageKeys.condos_id);
	};

	const cleanAuth = () => {
		setInfoAuth({
			id: null,
			name: null,
			last_name: null,
			email: null,
			role: null,
			active: null,
			enroll: null,
			token: null,
			user: null
		});
		sessionStorage.removeItem(StorageKeys.auth);
		cleanCondosID();
	};

	const isActiveSession = (): boolean => typeof infoAuth.token === "string";

	const getLettersName = () => {
		if (infoAuth.name && infoAuth.last_name) {
			return `${infoAuth.name.charAt(0)}${infoAuth.last_name.charAt(0)}`.toUpperCase();
		}
		return "";
	};

	useEffect(() => {
		const auth = sessionStorage.getItem(StorageKeys.auth);
		if (auth) setInfoAuth(JSON.parse(auth));
	}, []);

	useEffect(() => {
		const condo = sessionStorage.getItem(StorageKeys.condos_id);
		if (condo) setCondo(JSON.parse(condo));
	}, []);

	useEffect(() => {
		let Sub: null | Subscription = null;
		if (ErrorSubject) {
			Sub = ErrorSubject.subscribe(FieldsError => {
				setError(null);
				console.log(FieldsError);

				const isAuthReject = FieldsError.find(
					field =>
						field.code_error === CodeError.Unauthorized ||
						field.code_error === CodeError.Forbidden ||
						field.code_error === CodeError.InternalError
				);
				console.log(isAuthReject);

				if (isAuthReject) {
					setError(isAuthReject.message);
					CleanSession.next(true);
				}
			});
		}
		return () => {
			console.log("clean state");
			setError(null);
			if (Sub) Sub.unsubscribe();
		};
	}, [ErrorSubject]);

	return (
		<coreContext.Provider
			value={{
				...infoAuth,
				condo,
				setAuth,
				cleanAuth,
				isActiveSession,
				getLettersName,
				setCondos,
				cleanCondosID,
				ErrorSubject
			}}
		>
			{children}
		</coreContext.Provider>
	);
};

export default CoreProvider;
