import { gql } from "@apollo/client";

export type PropertySchema = {
  id: number;
  residence_identification: string;
  condo_id: number;
  supports_plat: boolean;
};

export type RequestGetProperty = {
  GetAllPropertiesByCondoId: PropertySchema[];
};

export type GetPropertiesWithResidentsByCondoData = {
  propertiesWithResidentsByCondo: PropertySchema[];
};

export type RequestCreateProperty = {
  RegisterProperty?: undefined | null | Array<PropertySchema>;
};

export type RequestUpdateProperty = {
  UpdateProperty?: undefined | null | Array<PropertySchema>;
};

export type RequestDeleteProperty = {
  DeleteProperty?: undefined | null | Array<PropertySchema>;
};

export type EnablePLATSupport = {
  EnablePLATSupport?: boolean;
};

export type DisablePLATSupport = {
  DisablePLATSupport?: boolean;
};

const graphQLSchema = `
  id
  residence_identification
  condo_id
  supports_plat
`;

export const mutation_enable_plat_support = gql`
  mutation EnablePropertyPLATSupport($id: Int!) {
    EnablePropertyPLATSupport(id: $id)
  }
`;

export const mutation_disable_plat_support = gql`
  mutation DisablePropertyPLATSupport($id: Int!) {
    DisablePropertyPLATSupport(id: $id)
  }
`;

export const query_get_properties = gql`
query GetAllPropertiesByCondoId{
  GetAllPropertiesByCondoId{
        ${graphQLSchema}
    }
}
`;

export const GET_PROPERTIES_WITH_RESIDENTS_BY_CONDO = gql`
  query GetPropertiesWithResidentsByCondo {
    propertiesWithResidentsByCondo {
      ${graphQLSchema}
    }
  }
`;

export const mutation_register_property = gql`
mutation RegisterProperty($FormCreateProperty:FormCreateProperty!){
  RegisterProperty(FormCreateProperty:$FormCreateProperty){
        ${graphQLSchema}
    }
}
`;
export const mutation_update_property = gql`
mutation UpdateProperty($FormUpdateProperty:FormUpdateProperty!){
  UpdateProperty(FormUpdateProperty:$FormUpdateProperty){
        ${graphQLSchema}
    }
}
`;
export const mutation_delete_property = gql`
mutation DeleteProperty($property_id: Int!){
  DeleteProperty(property_id:$property_id){
        ${graphQLSchema}
    }
}
`;
