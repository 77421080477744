import { gql } from "@apollo/client";
import { CommonAreasSchema } from "../../CommonAreas/Querys";

export type CommonAreaTypeSchema = {
  id: number;
  label: string;
  active: boolean;
  CommonArea: CommonAreasSchema;
};

export type AllCommonAreasSchema = {
  id: number;
  label: string;
  is_active: boolean;

  CommonAreaTypes: {
    id: number;
    label: string;
    is_active: boolean;
  };
};

export type GetCommonAreasTypes = {
  GetAllCommonAreaTypes?: CommonAreaTypeSchema[];
};

export type CreateCommonArea = {
  CreateCommonAreaType: CommonAreaTypeSchema[];
};

export type UpdateCommonArea = {
  UpdateCommonAreaType: CommonAreaTypeSchema[];
};

export type DeleteCommonAreaType = {
  DeleteCommonAreaType: CommonAreaTypeSchema[];
};

export type ToggleCommonAreaTypeActiveStatus = {
  ToggleCommonAreaTypeActiveStatus: CommonAreaTypeSchema[];
};

export type GetAllCommonAreas = {
  GetAllCommonAreas: AllCommonAreasSchema[];
};

const getCommonAreasTypesSchema = `
  id
  label
  is_active

  CommonArea {
    id
    label
    is_active
  }
`;

export const query_get_common_areas_types = gql`
  query GetAllCommonAreaTypes {
    GetAllCommonAreaTypes {
      ${getCommonAreasTypesSchema}
    }
  }
`;

export const mutation_create_common_area_type = gql`
  mutation CreateCommonAreaType($label: String!, $commonAreaID: Int!) {
    CreateCommonAreaType(label: $label, commonAreaID: $commonAreaID) {
      id
      label
      is_active

      CommonArea {
        id
        label
        is_active
      }
    }
  }
`;

export const mutation_update_common_area_type = gql`
  mutation UpdateCommonAreaType(
    $id: Int!
    $updateInput: CommonAreaTypeUpdateInput!
  ) {
    UpdateCommonAreaType(id: $id, updateInput: $updateInput) {
      id
      label
      is_active

      CommonArea {
        id
        label
        is_active
      }
    }
  }
`;

export const mutation_toggle_active_common_area_type = gql`
  mutation ToggleCommonAreaTypeActiveStatus($id: Int!) {
    ToggleCommonAreaTypeActiveStatus(id: $id) {
      id
      label
      is_active

      CommonArea {
        id
        label
        is_active
      }
    }
  }
`;

export const mutation_delete_common_area_type = gql`
  mutation DeleteCommonAreaType($id: Int!) {
    DeleteCommonAreaType(id: $id) {
      id
      label
      is_active

      CommonArea {
        id
        label
        is_active
      }
    }
  }
`;

export const query_get_all_common_areas = gql`
  query GetAllCommonAreas {
    GetAllCommonAreas {
      id
      label
      is_active

      CommonAreaTypes {
        id
        label
        is_active
      }
    }
  }
`;
