import React, { FC, useCallback, useEffect } from "react";
import { Box, Button, ButtonGroup, Grid, Modal, TextField, Typography, useTheme } from "@mui/material";
import { Close, Add } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import uuid from "react-uuid";
import type { PLTFieldErrors } from "../../../../Types";
import { CommonAreasSchema } from "../Querys";

export interface CommonAreaUpdateInput {
	label?: string;
}

type CommonAreaUpdateModalProps = {
	errorsField: PLTFieldErrors;
	isVisible: boolean;
	commonArea: CommonAreasSchema;
	onSuccess: (name: string, id: number) => void;
	onClose: () => void;
};

export const CommonAreaUpdateModal: FC<CommonAreaUpdateModalProps> = ({
	isVisible,
	errorsField,
	commonArea,
	onSuccess,
	onClose
}) => {
	const theme = useTheme();

	const { control, getValues, setValue, reset } = useForm<CommonAreaUpdateInput>({
		defaultValues: {
			label: ""
		}
	});

	const isError = useCallback(
		(key: string) => {
			return errorsField.some(error => error.key === key);
		},
		[errorsField]
	);

	const getTextError = useCallback(
		(key: string) => {
			return errorsField?.find(e => e.key === key)?.message ?? "";
		},
		[errorsField]
	);

	useEffect(() => {
		if (isVisible) {
			setValue("label", commonArea.label);
		} else {
			reset();
		}
	}, [isVisible]);

	return (
		<Modal open={isVisible} onClose={onClose}>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: 400,
					bgcolor: "background.paper",
					boxShadow: 24,
					p: 4
				}}
			>
				<Grid container flexDirection={"row"}>
					<Typography variant="h5" color={theme.palette.primary.main} textAlign={"center"}>
						Actualizar Área Común
					</Typography>

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={value}
									onChange={onChange}
									label="Nombre del Área Común"
									variant="outlined"
									error={isError("label")}
									helperText={getTextError("label")}
								/>
							)}
							name="label"
						/>
					</Grid>
				</Grid>

				<ButtonGroup
					style={{ paddingTop: 20, paddingBottom: 20, alignSelf: "center" }}
					variant="text"
					aria-label="text button group"
				>
					<Button onClick={onClose}>
						<Close /> Cancelar
					</Button>

					<Button
						onClick={() => {
							const name = getValues().label;
							if (name) {
								onSuccess(name, commonArea.id);
							}
						}}
					>
						<Add /> Actualizar
					</Button>
				</ButtonGroup>
			</Box>
		</Modal>
	);
};

export default CommonAreaUpdateModal;
