import {
	Dashboard as DashboardIcon,
	Summarize as SummarizeIcon,
	Group as UsersIcons,
	SupportAgent as SupportAgentIcon,
	Block as BlockIcon,
	House as HouseIcon,
	AccessibilityNew as AccessibilityNewIcon,
	Engineering as EngineeringIcon,
	Medication as MedicationIcon,
	DeliveryDining as DeliveryDiningIcon,
	ContentPasteGo as ContentPasteGoIcon,
	Garage as GarageIcon,
	Build as BuildIcon,
	Business as BusinessIcon,
	Pool as PoolIcon,
	Backup as BackupIcon,
	Settings as SettingsIcon
} from "@mui/icons-material";

interface NavigationOptions {
	label: string;
	nav: string;
}

const useDrawer = () => {
	const RenderIcon = (color: string): { [key: string]: JSX.Element } => ({
		Gráficos: <DashboardIcon color={color as any} />,
		Reportes: <SummarizeIcon color={color as any} />,
		Administradores: <UsersIcons color={color as any} />,
		"Sistema Completo": <BackupIcon color={color as any} />,
		"Gastos Comunes": <BackupIcon color={color as any} />,
		Residentes: <AccessibilityNewIcon color={color as any} />,
		Inmuebles: <HouseIcon color={color as any} />,
		Estacionamientos: <GarageIcon color={color as any} />,
		"Tipos de Mantención": <BuildIcon color={color as any} />,
		"Emp. de Mantención": <BusinessIcon color={color as any} />,
		Colaboradores: <EngineeringIcon color={color as any} />,
		"Nº de Emergencia": <MedicationIcon color={color as any} />,
		"Entregas (Visitas)": <DeliveryDiningIcon color={color as any} />,
		"Emp. de Encomienda": <BusinessIcon color={color as any} />,
		"Espacios Comunes": <PoolIcon color={color as any} />,
		"Tipos de Espacios Comunes": <PoolIcon color={color as any} />,
		"Nº de Servicios": <SupportAgentIcon color={color as any} />,
		"Nº de Soporte": <SupportAgentIcon color={color as any} />,
		"Tipos de Posesiones": <GarageIcon color={color as any} />,
		"Tipos de Registro en Bitácora": <ContentPasteGoIcon color={color as any} />,
		"Visitas Bloqueadas": <BlockIcon color={color as any} />,
		"Ajustes Globales": <SettingsIcon color={color as any} />,
		"Encomiendas Pendientes": <DeliveryDiningIcon color={color as any} />
	});

	const NavListArray: Array<NavigationOptions> = [
		{ label: "Carga Masiva", nav: "bulk-loads" },
		{ label: "Dashboard", nav: "home" },
		{ label: "Reportes", nav: "reports" },
		{ label: "Administradores", nav: "admins" },
		{ label: "Empresas de Encomienda", nav: "delivery-companies" },
		{ label: "Espacios Comunes", nav: "commonareas" },
		{ label: "Contactos de Emergencia", nav: "emergency-contacts" },
		{ label: "Contactos de Soporte", nav: "support-contacts" },
		{ label: "Tipos de Posesiones", nav: "possession-types" },
		{ label: "Condominios", nav: "condos" },
		{ label: "Ajustes Globales", nav: "global-settings" }
	];

	const NavListCondominios: Array<NavigationOptions> = [
		{ label: "Inmuebles", nav: "propertys" },
		{ label: "Residentes", nav: "residents" },
		{ label: "Estacionamientos", nav: "parkings" },
		{ label: "Tipos de Mantención", nav: "maintenances" },
		{ label: "Empresas de Mantención", nav: "maintenance-companies" },
		{ label: "Colaboradores", nav: "collaborators" },
		{ label: "Tipos de Espacios Comunes", nav: "commonareastypes" },
		{ label: "Tipos de Entrega (Visitas)", nav: "visitdeliverytypes" },
		{ label: "Contactos de Servicios", nav: "services-contacts" },
		{ label: "Tipos de Registro en Bitácora", nav: "log-entry-types" },
		{ label: "Visitas Bloqueadas", nav: "banned-visitors" },
		{ label: "Encomiendas Pendientes", nav: "pending-deliveries" }
	];

	const ExcludeAdminNav: Array<string> = [
		"Gestión de Administradores",
		"Configuraciones Comunes",
		"Sistema Completo"
	];

	const ThreeViewList = [
		{
			id: 1,
			label: "Dashboard",
			ScreenList: [
				{
					id: 2,
					label: "Gráficos",
					nav: "home"
				},
				{
					id: 3,
					label: "Reportes",
					nav: "reports"
				}
			]
		},
		{
			id: 5,
			label: "Cargas Masivas",
			ScreenList: [
				{
					id: 6,
					label: "Sistema Completo",
					nav: "bulk-loads"
				},
				{
					id: 7,
					label: "Gastos Comunes",
					nav: "bulk-loads-common-expensives"
				}
			]
		},
		{
			id: 8,
			label: "Gestión de Administradores",
			ScreenList: [
				{
					id: 9,
					label: "Administradores",
					nav: "admins"
				}
			]
		},
		{
			id: 10,
			label: "Configuraciones Comunes",
			ScreenList: [
				{
					id: 11,
					label: "Emp. de Encomienda",
					nav: "delivery-companies"
				},
				{
					id: 12,
					label: "Espacios Comunes",
					nav: "commonareas"
				},
				{
					id: 13,
					label: "Nº de Emergencia",
					nav: "emergency-contacts"
				},
				{
					id: 14,
					label: "Nº de Soporte",
					nav: "support-contacts"
				},
				{
					id: 15,
					label: "Tipos de Posesiones",
					nav: "possession-types"
				},
				{
					id: 32,
					label: "Ajustes Globales",
					nav: "global-settings",
					ScreenList: []
				}
			]
		},
		{
			id: 20,
			label: "Condominios",
			nav: "condos",
			ScreenList: [
				{ id: 21, label: "Inmuebles", nav: "propertys" },
				{ id: 22, label: "Residentes", nav: "residents" },
				{ id: 23, label: "Estacionamientos", nav: "parkings" },
				{ id: 24, label: "Tipos de Mantención", nav: "maintenances" },
				{
					id: 25,
					label: "Emp. de Mantención",
					nav: "maintenance-companies"
				},
				{ id: 26, label: "Colaboradores", nav: "collaborators" },
				{ id: 27, label: "Tipos de Espacios Comunes", nav: "commonareastypes" },
				{
					id: 28,
					label: "Entregas (Visitas)",
					nav: "visitdeliverytypes"
				},
				{ id: 29, label: "Nº de Servicios", nav: "services-contacts" },
				{
					id: 30,
					label: "Tipos de Registro en Bitácora",
					nav: "log-entry-types"
				},
				{ id: 31, label: "Visitas Bloqueadas", nav: "banned-visitors" },
				{
					id: 33,
					label: "Encomiendas Pendientes",
					nav: "pending-deliveries"
				}
			]
		}
	];

	return {
		RenderIcon,
		NavListArray,
		NavListCondominios,
		ExcludeAdminNav,
		ThreeViewList
	};
};

export default useDrawer;
