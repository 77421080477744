import { useCallback, useEffect, useState, useMemo } from "react";
import { Box, Button, ButtonGroup, Divider } from "@mui/material";
import { Add } from "@mui/icons-material";
import CustomTable, { type ControlsTable, type RowTable } from "../../Components/CustomTable";
import DeliveryCompanyCreationModal from "./DeliveryCompanyCreationModal";
import DeliveryCompanyUpdateModal from "./DeliveryCompanyUpdateModal";
import useTable from "../../Components/CustomTable/useTable";
import useGraphQL from "../../../Hooks/useGraphQL";
import AlertDialog from "../../Components/CustomAlertDialog";
import useErrorGraphql from "../../../Hooks/useErrorGraphql";

import {
  CREATE_DELIVERY_COMPANY,
  GET_ALL_DELIVERY_COMPANIES,
  UPDATE_DELIVERY_COMPANY,
  DELETE_DELIVERY_COMPANY,
  TOGGLE_DELIVERY_COMPANY_ACTIVE_STATUS,
  type DeliveryCompanySchema,
  type CreateDeliveryCompanyData,
  type GetAllDeliveryCompaniesData,
  type UpdateDeliveryCompanyData,
  type DeleteDeliveryCompanyData,
  type ToggleDeliveryCompanyActiveStatusData,
} from "./Query";

import { ToastContainer, toast } from "react-toastify";

const headerRow = [
  { id: "name", label: "Nombre" },
  { id: "is_active", label: "Activo/Inactivo" },
  { id: "actions", label: "Acciones" }
];

export default function DeliveryCompaniesScreen(): JSX.Element {
  const [deliveryCompanies, setDeliveryCompanies] = useState<DeliveryCompanySchema[]>([]);
  const [isCreatingCompany, setIsCreatingCompany] = useState(false);
  
  const [updateState, setUpdateState] = useState<{
    isUpdating: boolean;
    deliveryCompany?: DeliveryCompanySchema;
  }>({ isUpdating: false, deliveryCompany: undefined });

  const [shouldConfirmDeletion, setShouldConfirmDeletion] = useState(false);
  const [idToDelete, setIDToDelete] = useState<number | undefined>();

  const { useLazyGraphQuery, useGraphMutation } = useGraphQL();
  const { errorsField, setErrorFields } = useErrorGraphql();

  const [getAllDeliveryCompanies] = useLazyGraphQuery<GetAllDeliveryCompaniesData>(GET_ALL_DELIVERY_COMPANIES);
  const [createDeliveryCompany] = useGraphMutation<CreateDeliveryCompanyData>(CREATE_DELIVERY_COMPANY);
  const [updateDeliveryCompany] = useGraphMutation<UpdateDeliveryCompanyData>(UPDATE_DELIVERY_COMPANY);
  const [deleteDeliveryCompany] = useGraphMutation<DeleteDeliveryCompanyData>(DELETE_DELIVERY_COMPANY);

  const [toggleDeliveryCompanyActiveStatus] = useGraphMutation<ToggleDeliveryCompanyActiveStatusData>(
    TOGGLE_DELIVERY_COMPANY_ACTIVE_STATUS
  );

  useEffect(() => {
    async function fetchDeliveryCompanies(): Promise<void> {
      const response = await getAllDeliveryCompanies();

      if (response.data) {
        setDeliveryCompanies(response.data.GetAllDeliveryCompanies);
      }
    }

    fetchDeliveryCompanies();
  }, []);

  const deliveryCompanyCreationCallback = useCallback(
    async (name: string) => {
      const response = await createDeliveryCompany({
        variables: { name }
      });

      if (response.data) {
        toast.success("Empresa de encomiendas agregada correctamente");

        setIsCreatingCompany(false);
        setDeliveryCompanies(response.data.CreateDeliveryCompany);
      }
    },
    []
  );

  const deliveryCompanyUpdateCallback = useCallback(
    async (id: number, name: string | undefined) => {      
      const response = await updateDeliveryCompany({
        variables: { id, name }
      });

      if (response.data) {
        toast.success("Empresa de encomiendas actualizada correctamente");

        setUpdateState({ isUpdating: false, deliveryCompany: undefined });
        setDeliveryCompanies(response.data.UpdateDeliveryCompany);
      }
    },
    []
  );

  const deliveryCompanyDeletionCallback = useCallback(async () => {
    if (idToDelete === undefined) {
      return;
    }

    const response = await deleteDeliveryCompany({
      variables: { companyID: idToDelete },
    });

    if (response.data) {
      toast.success("Empresa de encomiendas eliminada correctamente");

      setDeliveryCompanies(response.data.DeleteDeliveryCompany);
      setShouldConfirmDeletion(false);
      setIDToDelete(undefined);
    }
  }, [idToDelete]);

  const deletionCancelingCallback = useCallback(() => {
    setIDToDelete(undefined);
  }, []);

  const onUpdateCallback = useCallback(
    (row: RowTable) => {
      setErrorFields(undefined);
      setUpdateState({
        isUpdating: true,
        deliveryCompany: row as DeliveryCompanySchema,
      });
    },
    []
  );

  const onDeleteCallback = useCallback(
    (row: RowTable) => {
      setShouldConfirmDeletion(true);
      setIDToDelete(row.id as number);
    },
    []
  );

  const onActiveStatusToggleCallback = useCallback(
    async (row: RowTable) => {
      const response = await toggleDeliveryCompanyActiveStatus({
        variables: {
          companyID: row.id,
        },
      });

      if (response.data) {
        setDeliveryCompanies(response.data.ToggleDeliveryCompanyActiveStatus);
      }
    },
    []
  );

  const tableProps = useTable();

  const customTableControls: ControlsTable = useMemo(
    () => ({
      ...tableProps,
      onUpdate: onUpdateCallback,
      onDelete: onDeleteCallback,
      onActiveStatusToggle: onActiveStatusToggleCallback,
    }),
    []
  );

  const DeliveryCompaniesTable = () => (
    <CustomTable
      controls={customTableControls}
      columns={headerRow}
      rows={deliveryCompanies}
    />
  );

  const onDeliveryCompanyCreationModalCloseCallback = useCallback(() => {
    setIsCreatingCompany(false);
  }, []);

  const onDeliveryCompanyUpdateModalCloseCallback = useCallback(() => {
    setUpdateState({ isUpdating: false, deliveryCompany: undefined });
  }, []);

  return (
    <Box>
      <ButtonGroup
        style={{ paddingTop: 20, paddingBottom: 20 }}
        variant="text"
        aria-label="text button group"
      >
        <Button
          onClick={() => {
            setErrorFields(undefined);
            setIsCreatingCompany(true);
          }}
        >
          <Add /> Agregar Empresa de Encomienda
        </Button>
      </ButtonGroup>

      <Divider style={{ marginTop: 15, marginBottom: 15 }} />

      <DeliveryCompaniesTable />

      <DeliveryCompanyCreationModal
        errorsField={errorsField ?? []}
        isVisible={isCreatingCompany}
        onSuccess={deliveryCompanyCreationCallback}
        onClose={onDeliveryCompanyCreationModalCloseCallback}
      />

      <DeliveryCompanyUpdateModal
        errorsField={errorsField ?? []}
        isVisible={updateState.isUpdating}
        deliveryCompany={updateState.deliveryCompany!}
        onSuccess={deliveryCompanyUpdateCallback}
        onClose={onDeliveryCompanyUpdateModalCloseCallback}
      />

      <AlertDialog
        title="Advertencia"
        open={shouldConfirmDeletion}
        setOpen={setShouldConfirmDeletion}
        warningMessage="Estás a punto de eliminar una empresa de encomiendas. ¿Deseas continuar?"
        onCancel={deletionCancelingCallback}
        onConfirm={deliveryCompanyDeletionCallback}
      />

      <ToastContainer position="bottom-left" autoClose={3000} />
    </Box>
  );
}
