import { Add, Close } from "@mui/icons-material";
import {
	Autocomplete,
	Box,
	Button,
	ButtonGroup,
	Chip,
	Grid,
	Modal,
	TextField,
	Typography,
	useTheme
} from "@mui/material";
import { useCallback, useEffect, type FC } from "react";
import { Controller, useForm } from "react-hook-form";
import uuid from "react-uuid";
import { PLTFieldErrors } from "../../../../Types";
import { CondoSchema } from "../GraphQL";
import { CommonAreasSchema } from "../../CommonAreas/Querys";

type Props = {
	isVisible: boolean;
	errorsField: PLTFieldErrors;
	commonAreas: CommonAreasSchema[];
	condo: CondoSchema;
	onClose: () => void;
	onUpdate: (form: CondoUpdateInput) => void;
};

export interface CondoUpdateInput {
	id: number;
	name?: string;
	pairing_code?: number;
	maximum_parking_time?: number;
	inactivity_time?: number;
	primary_phone_number?: string;
	secondary_phone_number?: string;
	devices_number?: number;
	commonAreasLabels: string[];
	address: string;
}

const ModalUpdateCondos: FC<Props> = ({ isVisible, errorsField, condo, onClose, onUpdate, commonAreas }) => {
	const theme = useTheme();

	const { control, getValues, reset, setValue } = useForm<CondoUpdateInput>({
		defaultValues: {
			name: undefined,
			pairing_code: undefined,
			maximum_parking_time: undefined,
			inactivity_time: undefined,
			primary_phone_number: undefined,
			secondary_phone_number: undefined,
			devices_number: undefined,
			commonAreasLabels: [],
			address: ""
		}
	});

	const hasError = useCallback(
		(key: string) => {
			if (errorsField) {
				return errorsField.find(e => e.key === key) ? true : false;
			}
			return false;
		},
		[errorsField]
	);

	const getErrorMessage = useCallback(
		(key: string) => {
			if (errorsField) {
				const found = errorsField.find(e => e.key === key);
				if (found) return found.message;
			}
			return "";
		},
		[errorsField]
	);

	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === "Enter") {
			onUpdate(getValues());
		}
	};

	useEffect(() => {
		if (isVisible) {
			setValue("id", condo.id);
			setValue("name", condo.name);
			setValue("inactivity_time", condo.inactivity_time);
			setValue("pairing_code", condo.pairing_code);
			setValue("maximum_parking_time", condo.maximum_parking_time);
			setValue("primary_phone_number", condo.primary_phone_number);
			setValue("secondary_phone_number", condo.secondary_phone_number);
			setValue("devices_number", condo.devices_number);
			setValue("address", condo.address);
			setValue(
				"commonAreasLabels",
				condo.CommonAreas.map(commonArea => commonArea.label)
			);
		} else {
			reset();
		}
	}, [isVisible, condo]);

	return (
		<Modal open={isVisible} onClose={onClose}>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: 400,
					bgcolor: "background.paper",
					boxShadow: 24,
					p: 4,
					maxHeight: "95vh",
					overflowY: "auto"
				}}
			>
				<Grid container flexDirection={"row"}>
					<Typography variant="h5" color={theme.palette.primary.main} textAlign={"center"}>
						Actualizar Condominio
					</Typography>

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							name="name"
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={value}
									onChange={onChange}
									label="Nombre"
									variant="outlined"
									error={hasError("name")}
									helperText={getErrorMessage("name")}
									onKeyDown={e => handleKeyDown(e)}
								/>
							)}
						/>
					</Grid>

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							name="maximum_parking_time"
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={`${value}`}
									onChange={e => onChange(Number(e.target.value))}
									label="Minutos de estacionamiento"
									variant="outlined"
									type="number"
									error={hasError("maximum_parking_time")}
									helperText={getErrorMessage("maximum_parking_time")}
									onKeyDown={e => handleKeyDown(e)}
								/>
							)}
						/>
					</Grid>

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							name="inactivity_time"
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={`${value}`}
									onChange={e => onChange(Number(e.target.value))}
									label="Minutos de inactividad en la aplicación"
									type="number"
									variant="outlined"
									error={hasError("inactivity_time")}
									helperText={getErrorMessage("inactivity_time")}
									onKeyDown={e => handleKeyDown(e)}
								/>
							)}
						/>
					</Grid>

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							name="address"
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={value}
									onChange={onChange}
									label="Dirección"
									variant="outlined"
									error={hasError("address")}
									helperText={getErrorMessage("address")}
									onKeyDown={e => handleKeyDown(e)}
									required
								/>
							)}
						/>
					</Grid>
					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							name="primary_phone_number"
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={value}
									onChange={onChange}
									label="Número telefónico principal"
									variant="outlined"
									error={hasError("primary_phone_number")}
									helperText={getErrorMessage("primary_phone_number")}
									onKeyDown={e => handleKeyDown(e)}
								/>
							)}
						/>
					</Grid>

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							name="secondary_phone_number"
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={value}
									onChange={onChange}
									label="Número telefónico secundario"
									variant="outlined"
									error={hasError("secondary_phone_number")}
									helperText={getErrorMessage("secondary_phone_number")}
									onKeyDown={e => handleKeyDown(e)}
								/>
							)}
						/>
					</Grid>

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							name="devices_number"
							render={({ field: { onChange, value } }) => (
								<TextField
									type="number"
									inputProps={{ min: 1 }}
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={value}
									onChange={e => onChange(Number(e.target.value))}
									label="Número de dispositivos"
									variant="outlined"
									error={hasError("devices_number")}
									helperText={getErrorMessage("devices_number")}
									onKeyDown={e => handleKeyDown(e)}
								/>
							)}
						/>
					</Grid>

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={`${value}`}
									onChange={e => onChange(e.target.value ? Number(e.target.value) : e.target)}
									label="Código de pareo"
									type="number"
									variant="outlined"
									error={hasError("pairing_code")}
									helperText={getErrorMessage("pairing_code")}
									onKeyDown={e => handleKeyDown(e)}
								/>
							)}
							name="pairing_code"
						/>
					</Grid>

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							name="commonAreasLabels"
							render={({ field: { onChange, value } }) => (
								<Autocomplete
									multiple
									id={uuid()}
									size="small"
									options={commonAreas.map(commonArea => commonArea.label)}
									onChange={(_, value) => {
										onChange(value);
									}}
									defaultValue={value}
									renderTags={(commonAreaLabels, getTagProps) =>
										commonAreaLabels.map((commonAreaLabel, index) => (
											<Chip
												variant="outlined"
												label={commonAreaLabel}
												size="small"
												{...getTagProps({ index })}
											/>
										))
									}
									renderInput={params => (
										<TextField
											{...params}
											variant="filled"
											label="Seleccionar espacios comunes"
											placeholder="Espacios comunes"
										/>
									)}
								/>
							)}
						/>
					</Grid>
				</Grid>
				<ButtonGroup
					style={{ paddingTop: 20, paddingBottom: 20, alignSelf: "center" }}
					variant="text"
					aria-label="text button group"
				>
					<Button onClick={onClose}>
						<Close /> Cancelar
					</Button>
					<Button onClick={() => onUpdate(getValues())}>
						<Add /> Actualizar
					</Button>
				</ButtonGroup>
			</Box>
		</Modal>
	);
};
export default ModalUpdateCondos;
