import { gql } from "@apollo/client";
import { UserSchema } from "../../Users/GraphQL";

export type RegisterUserResponse = {
	RegisterUser?: Array<UserSchema>;
};

export type UpdateUserResponse = {
	UpdateUser?: Array<UserSchema>;
};

export type GetAllWorkersByCondoResponseData = {
	GetAllWorkersByCondo: UserSchema[];
};

export type GetActiveWorkersByCondoResponseData = {
	activeWorkers: UserSchema[];
};

export type ToggleUsersResponse = {
	ChangeActiveUser?: Array<UserSchema>;
};

export type DeleteUsersResponse = {
	DeleteUser?: Array<UserSchema>;
};

const graphQLSchema = `
  id
  name
  last_name
  full_name
  email
  phone_number
  rut
  role
  active
  enroll
  formatted_rut
`;

export const GET_WORKERS_BY_CONDO = gql`
  query GetAllWorkersByCondo {
    GetAllWorkersByCondo {
      ${graphQLSchema}
    }
  }
`;

export const GET_ACTIVE_WORKERS_BY_CONDO = gql`
  query GetActiveWorkersByCondo($condoID: Int) {
    activeWorkers(condoID: $condoID) {
      ${graphQLSchema}
    }
  }
`;

export const mutation_create_user = gql`
  mutation RegisterUser($FormCreateUser: FormCreateUser!) {
    RegisterUser(FormCreateUser: $FormCreateUser) {
        ${graphQLSchema}
    }
  }
`;

export const mutation_update_user = gql`
  mutation UpdateUser($FormUpdateUser: FormUpdateUser!) {
    UpdateUser(FormUpdateUser: $FormUpdateUser) {
        ${graphQLSchema}
    }
  }
`;

export const mutation_toggle_user = gql`
  mutation ChangeActiveUser($user_id: Int!) {
    ChangeActiveUser(user_id: $user_id) {
        ${graphQLSchema}
    }
  }
`;

export const mutation_delete_user = gql`
  mutation DeleteUser($user_id: Int!) {
    DeleteUser(user_id: $user_id) {
        ${graphQLSchema}
    }
  }
`;
