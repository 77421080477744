import { useCallback, useEffect, useState, useMemo } from "react";
import { Box, Button, ButtonGroup, Divider } from "@mui/material";
import { Add } from "@mui/icons-material";
import CustomTable, { type ControlsTable, type RowTable } from "../../Components/CustomTable";
import LogEntryTypeCreationModal from "./LogEntryTypeCreationModal";
import LogEntryTypeUpdateModal, { type LogEntryTypeUpdateInput } from "./LogEntryTypeUpdateModal";
import useTable from "../../Components/CustomTable/useTable";
import useGraphQL from "../../../Hooks/useGraphQL";
import AlertDialog from "../../Components/CustomAlertDialog";
import useErrorGraphql from "../../../Hooks/useErrorGraphql";

import {
  LogEntryTypeCategory,
  getLogEntryTypeCategorySpanishDescription,
  CREATE_LOG_ENTRY_TYPE,
  GET_ALL_LOG_ENTRY_TYPES,
  UPDATE_LOG_ENTRY_TYPE,
  DELETE_LOG_ENTRY_TYPE,
  TOGGLE_LOG_ENTRY_TYPE_ACTIVE_STATUS,
  type FormattedLogEntryType,
  type CreateLogEntryTypeData,
  type GetAllLogEntryTypesData,
  type UpdateLogEntryTypeData,
  type DeleteLogEntryTypeData,
  type ToggleLogEntryTypeActiveStatusData
} from "./Query";

import { ToastContainer, toast } from "react-toastify";

const headerRow = [
  { id: "label", label: "Etiqueta" },
  { id: "categoryDescription", label: "Categoría" },
  { id: "is_active", label: "Activo/Inactivo" },
  { id: "actions", label: "Acciones" }
];

export default function LogEntryTypesScreen(): JSX.Element {
  const [logEntryTypes, setLogEntryTypes] = useState<FormattedLogEntryType[]>([]);
  const [isCreatingLogEntryType, setIsCreatingLogEntryType] = useState(false);

  const [updateState, setUpdateState] = useState<{
    isUpdating: boolean,
    logEntryType?: FormattedLogEntryType
  }>({ isUpdating: false, logEntryType: undefined });

  const [shouldConfirmDeletion, setShouldConfirmDeletion] = useState(false);
  const [idToDelete, setIDToDelete] = useState<number | undefined>();

  const { useLazyGraphQuery, useGraphMutation } = useGraphQL();
  const { errorsField, setErrorFields } = useErrorGraphql();

  const [createLogEntryType] = useGraphMutation<CreateLogEntryTypeData>(CREATE_LOG_ENTRY_TYPE);
  const [getAllLogEntryTypes] = useLazyGraphQuery<GetAllLogEntryTypesData>(GET_ALL_LOG_ENTRY_TYPES);
  const [updateLogEntryType] = useGraphMutation<UpdateLogEntryTypeData>(UPDATE_LOG_ENTRY_TYPE);
  const [deleteLogEntryType] = useGraphMutation<DeleteLogEntryTypeData>(DELETE_LOG_ENTRY_TYPE);

  const [toggleLogEntryTypeActiveStatus] = useGraphMutation<ToggleLogEntryTypeActiveStatusData>(
    TOGGLE_LOG_ENTRY_TYPE_ACTIVE_STATUS
  );

  useEffect(() => {
    async function fetchLogEntryTypes(): Promise<void> {
      const response = await getAllLogEntryTypes();

      if (response.data) {
        setLogEntryTypes(response.data.GetAllLogEntryTypes.map(logEntryType => ({
          ...logEntryType,
          categoryDescription: getLogEntryTypeCategorySpanishDescription(logEntryType.category)
        })));
      }
    }

    fetchLogEntryTypes();
  }, [getAllLogEntryTypes, setLogEntryTypes]);

  const logEntryTypeCreationCallback = useCallback(
    async (label: string, category: LogEntryTypeCategory | undefined) => {      
      const response = await createLogEntryType({
        variables: { label, category }
      });

      if (response.data) {
        toast.success("Tipo de registro en bitácora agregado correctamente");

        setIsCreatingLogEntryType(false);

        setLogEntryTypes(response.data.CreateLogEntryType.map(logEntryType => ({
          ...logEntryType,
          categoryDescription: getLogEntryTypeCategorySpanishDescription(logEntryType.category)
        })));
      }
    },
    [createLogEntryType, toast, setIsCreatingLogEntryType, setLogEntryTypes]
  );

  const logEntryTypeUpdateCallback = useCallback(
    async (logEntryTypeID: number, updateInput: LogEntryTypeUpdateInput) => {
      const response = await updateLogEntryType({
        variables: {
          id: logEntryTypeID,
          updateInput
        }
      });

      if (response.data) {
        toast.success("Tipo de registro en bitácora actualizado correctamente");

        setUpdateState({ isUpdating: false, logEntryType: undefined });

        setLogEntryTypes(response.data.UpdateLogEntryType.map(logEntryType => ({
          ...logEntryType,
          categoryDescription: getLogEntryTypeCategorySpanishDescription(logEntryType.category)
        })));
      }
    },
    [updateLogEntryType, toast, setUpdateState, setLogEntryTypes]
  );

  const logEntryTypeDeletionCallback = useCallback(async () => {
    if (idToDelete === undefined) {
      return;
    }

    const response = await deleteLogEntryType({
      variables: { id: idToDelete },
    });

    if (response.data) {
      toast.success("Tipo de registro en bitácora eliminado correctamente");

      setLogEntryTypes(response.data.DeleteLogEntryType.map(logEntryType => ({
        ...logEntryType,
        categoryDescription: getLogEntryTypeCategorySpanishDescription(logEntryType.category)
      })));

      setShouldConfirmDeletion(false);
      setIDToDelete(undefined);
    }
  }, [
    idToDelete,
    deleteLogEntryType,
    toast,
    setLogEntryTypes,
    setShouldConfirmDeletion,
    setIDToDelete,
  ]);

  const deletionCancelingCallback = useCallback(() => {
    setIDToDelete(undefined);
  }, [setIDToDelete]);

  const onUpdateCallback = useCallback(
    (row: RowTable) => {
      setErrorFields(undefined);
      setUpdateState({
        isUpdating: true,
        logEntryType: row as FormattedLogEntryType,
      });
    },
    [setErrorFields, setUpdateState]
  );

  const onDeleteCallback = useCallback(
    (row: RowTable) => {
      setShouldConfirmDeletion(true);
      setIDToDelete(row.id as number);
    },
    [setShouldConfirmDeletion, setIDToDelete]
  );

  const onActiveStatusToggleCallback = useCallback(
    async (row: RowTable) => {
      const response = await toggleLogEntryTypeActiveStatus({
        variables: {
          id: row.id,
        }
      });

      if (response.data) {
        setLogEntryTypes(response.data.ToggleLogEntryTypeActiveStatus.map(logEntryType => ({
          ...logEntryType,
          categoryDescription: getLogEntryTypeCategorySpanishDescription(logEntryType.category)
        })));
      }
    },
    [toggleLogEntryTypeActiveStatus, setLogEntryTypes]
  );

  const tableProps = useTable();

  const customTableControls: ControlsTable = useMemo(
    () => ({
      ...tableProps,
      onUpdate: onUpdateCallback,
      onDelete: onDeleteCallback,
      onActiveStatusToggle: onActiveStatusToggleCallback,
    }),
    [tableProps, onUpdateCallback, onDeleteCallback, onActiveStatusToggleCallback]
  );

  const LogEntryTypesTable = () => (
    <CustomTable
      controls={customTableControls}
      columns={headerRow}
      rows={logEntryTypes}
    />
  );

  const onLogEntryTypeCreationModalCloseCallback = useCallback(() => {
    setIsCreatingLogEntryType(false);
  }, [setIsCreatingLogEntryType]);

  const onLogEntryTypeUpdateModalCloseCallback = useCallback(() => {
    setUpdateState({ isUpdating: false, logEntryType: undefined });
  }, [setUpdateState]);

  return (
    <Box>
      <ButtonGroup
        style={{ paddingTop: 20, paddingBottom: 20 }}
        variant="text"
        aria-label="text button group"
      >
        <Button
          onClick={() => {
            setErrorFields(undefined);
            setIsCreatingLogEntryType(true);
          }}
        >
          <Add /> Agregar Tipo de Registro en Bitácora
        </Button>
      </ButtonGroup>

      <Divider style={{ marginTop: 15, marginBottom: 15 }} />

      <LogEntryTypesTable />

      <LogEntryTypeCreationModal
        errorsField={errorsField ?? []}
        isVisible={isCreatingLogEntryType}
        onSuccess={logEntryTypeCreationCallback}
        onClose={onLogEntryTypeCreationModalCloseCallback}
      />

      <LogEntryTypeUpdateModal
        errorsField={errorsField ?? []}
        isVisible={updateState.isUpdating}
        logEntryType={updateState.logEntryType!}
        onSuccess={logEntryTypeUpdateCallback}
        onClose={onLogEntryTypeUpdateModalCloseCallback}
      />

      <AlertDialog
        title="Advertencia"
        open={shouldConfirmDeletion}
        setOpen={setShouldConfirmDeletion}
        warningMessage="Estás a punto de eliminar un tipo de registro en bitácora. ¿Deseas continuar?"
        onCancel={deletionCancelingCallback}
        onConfirm={logEntryTypeDeletionCallback}
      />

      <ToastContainer position="bottom-left" autoClose={3000} />
    </Box>
  );
}
