import { RouteObject, createBrowserRouter } from "react-router-dom";

import PrevEmptyPathScreen from "../UI/Shared/PrevEmptyPath";
import RootDrawer from "../UI/Shared/Drawer";
import ReportsScreen from "../UI/Screens/Reports";
import AdminsScreen from "../UI/Screens/Users";
import SignInScreen from "../UI/Screens/SignIn";
import HomeScreen from "../UI/Screens/Home";
import VisitDeliveryTypesScreen from "../UI/Screens/CondosVisitDeliveryTypes";
import ParkingsScreen from "../UI/Screens/CondosParking";
import MaintenanceScreen from "../UI/Screens/CondosTypeMaintenance";
import MaintenanceCompanyScreen from "../UI/Screens/CondosMaintenanceCompany";
import VisitReportScreen from "../UI/Screens/CondosVisitReport";
import DeliveryCompaniesScreen from "../UI/Screens/DeliveryCompanies";
import CondosScreen from "../UI/Screens/Condos";
import GlobalSettingsScreen from "../UI/Screens/GlobalSettings";
import CondosPropertysScreen from "../UI/Screens/CondosProperty";
import CondosResidentsScreen from "../UI/Screens/CondosResidents";
import CondosWorkersScreen from "../UI/Screens/CondosWorkers";
import CondosResidentsReports from "../UI/Screens/CondosResidentsReports";
import CommonAreas from "../UI/Screens/CommonAreas";
import CommonAreasTypes from "../UI/Screens/CondoCommonAreaTypes";
import EmergencyNumbersScreen from "../UI/Screens/EmergencyContacts";
import SupportContactsScreen from "../UI/Screens/SupportContacts";
import ServicesContactsScreen from "../UI/Screens/CondoServicesContacts";
import PossessionTypesScreen from "../UI/Screens/PossessionTypes";
import LogEntryTypesScreen from "../UI/Screens/CondoLogEntryTypes";
import BannedVisitorsScreen from "../UI/Screens/CondoBannedVisitors";
import BulkLoads from "../UI/Screens/BulkLoads";
import BulkCommonExpensives from "../UI/Screens/BulkCommonExpensives";
import CondosPendingDeliveriesScreen from "../UI/Screens/CondosPendingDeliveries";

export enum PLTRouteLocation {
	condos = "/intranet/condos",
	commonAreas = "/intranet/commonareas",
	home = "/intranet/home",
	reports = "/intranet/reports",
	users = "/intranet/users",
	workers = "/intranet/workers",
	intranetProperties = "/intranet/propertys",
	intranetResidents = "/intranet/residents",
	intranetEntryControl = "/intranet/entrycontrol",
	intranetVisitDeliveryTypes = "/intranet/visitdeliverytypes",
	intranetParkings = "/intranet/parkings",
	intranetMaintenances = "/intranet/maintenances",
	intranetMaintenanceCompanies = "/intranet/maintenance-companies",
	signin = "/signin",
	intranetDeliveryCompanies = "/intranet/delivery-companies",
	intranetEmergencyContacts = "/intranet/emergency-contacts",
	intranetSupportContacts = "/intranet/support-contacts",
	intranetServicesContacts = "/intranet/services-contacts",
	intranetPossessionTypes = "/intranet/possession-types",
	intranetLogEntryTypes = "/intranet/log-entry-types",
	intranetBannedVisitors = "/intranet/banned-visitors",
	intranetBulkLoads = "/intranet/BulkLoads",
	globalSettings = "/intranet/global-settings",
	pendingDeliveries = "/intranet/pending-deliveries"
}

const PrimaryStack: Array<RouteObject> = [
	{
		path: "home",
		element: <HomeScreen />
	},
	{
		path: "reports",
		element: <ReportsScreen />
	},
	{
		path: "admins",
		element: <AdminsScreen />
	},
	{
		path: "condos",
		element: <CondosScreen />
	},
	{
		path: "commonareas",
		element: <CommonAreas />
	},
	{
		path: "collaborators",
		element: <CondosWorkersScreen />
	},
	{
		path: "propertys",
		element: <CondosPropertysScreen />
	},
	{
		path: "residents",
		element: <CondosResidentsScreen />
	},
	{
		path: "entrycontrol",
		element: <VisitReportScreen />
	},
	{
		path: "residentreport",
		element: <CondosResidentsReports />
	},
	{
		path: "visitdeliverytypes",
		element: <VisitDeliveryTypesScreen />
	},
	{
		path: "parkings",
		element: <ParkingsScreen />
	},
	{
		path: "maintenances",
		element: <MaintenanceScreen />
	},
	{
		path: "maintenance-companies",
		element: <MaintenanceCompanyScreen />
	},
	{
		path: "delivery-companies",
		element: <DeliveryCompaniesScreen />
	},
	{
		path: "commonareastypes",
		element: <CommonAreasTypes />
	},
	{
		path: "emergency-contacts",
		element: <EmergencyNumbersScreen />
	},
	{
		path: "support-contacts",
		element: <SupportContactsScreen />
	},
	{
		path: "services-contacts",
		element: <ServicesContactsScreen />
	},
	{
		path: "possession-types",
		element: <PossessionTypesScreen />
	},
	{
		path: "log-entry-types",
		element: <LogEntryTypesScreen />
	},
	{
		path: "banned-visitors",
		element: <BannedVisitorsScreen />
	},
	{
		path: "bulk-loads",
		element: <BulkLoads />
	},
	{
		path: "bulk-loads-common-expensives",
		element: <BulkCommonExpensives />
	},
	{
		path: "global-settings",
		element: <GlobalSettingsScreen />
	},
	{
		path: "pending-deliveries",
		element: <CondosPendingDeliveriesScreen />
	}
];

const Routes = createBrowserRouter([
	{
		path: "/",
		element: <PrevEmptyPathScreen />
	},
	{
		path: "/signin",
		element: <SignInScreen />
	},
	{
		path: "/intranet",
		element: <RootDrawer />,
		children: PrimaryStack
	}
]);

export default Routes;
