import { useEffect, memo, type FC } from "react";
import useGraphQL from "../../../../../Hooks/useGraphQL";
import { GetActionLogsResponseData, GET_ACTION_LOGS } from "../../GraphQL";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import DateRangePicker from "../../../../Components/DateRangePicker/DateRangePicker";
import { CircularProgress } from "@mui/material";
import CustomBarChart, { type BarData } from "../../../../Components/CustomBarChart";
import { ActionLogType } from "../../../Reports/types";

enum MaintenancesDataKey {
	entry = "Entrada de mantenciones",
	out = "Salida de mantenciones"
}

interface MaintenancesChartData {
	name: string;
	[MaintenancesDataKey.entry]: number;
	[MaintenancesDataKey.out]: number;
}

const maintenancesBarsData: BarData[] = [
	{
		barFillColor: "#2E7D32",
		dataKey: MaintenancesDataKey.entry,
		rectangleFillColor: "rgba(255, 255, 255, 0.7)",
		rectangleStrokeColor: "#2E7E32"
	},
	{
		barFillColor: "#9E9D24",
		dataKey: MaintenancesDataKey.out,
		rectangleFillColor: "rgba(255, 255, 255, 0.4)",
		rectangleStrokeColor: "#9E9D24"
	}
];

const actionLogTypes = [ActionLogType.newMaintenance, ActionLogType.finishedMaintenance];

const BarMaintenance: FC<{ condoID: number }> = ({ condoID }) => {
	const { useLazyGraphQuery } = useGraphQL();

	const [loadMaintenance, { data: maintenancesData, loading: isLoadingMaintenancesData }] =
		useLazyGraphQuery<GetActionLogsResponseData>(GET_ACTION_LOGS);

	const { setValue, getValues } = useForm<{
		startDate: Date;
		endDate: Date;
	}>({
		defaultValues: {
			startDate: dayjs().subtract(6, "day").toDate(),
			endDate: dayjs().toDate()
		}
	});

	const FormatDate = (date: Date) => dayjs(date).format("YYYY-MM-DD");

	const list = maintenancesData?.actionLogs
		? maintenancesData.actionLogs.reduce((acc: Array<MaintenancesChartData>, item) => {
				const existingDate = acc.find(entry => entry.name === item.local_date);

				if (existingDate) {
					if (item.register_type === ActionLogType.newMaintenance) {
						existingDate[MaintenancesDataKey.entry] += 1;
					} else if (item.register_type === ActionLogType.finishedMaintenance) {
						existingDate[MaintenancesDataKey.out] += 1;
					}
				} else {
					const newEntry = {
						name: item.local_date,
						[MaintenancesDataKey.entry]: item.register_type === ActionLogType.newMaintenance ? 1 : 0,
						[MaintenancesDataKey.out]: item.register_type === ActionLogType.finishedMaintenance ? 1 : 0
					};
					acc.push(newEntry);
				}
				return acc;
		  }, [])
		: [];

	const Loader = () => (
		<div style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
			<CircularProgress />
		</div>
	);

	useEffect(() => {
		if (condoID) {
			loadMaintenance({
				variables: {
					queryInput: {
						condoID: condoID,
						startDate: getValues("startDate"),
						endDate: getValues("endDate"),
						actionLogTypes: actionLogTypes
					}
				}
			});
		}
	}, [condoID]);

	if (!condoID) {
		return <Loader />;
	}

	if (isLoadingMaintenancesData) {
		return <Loader />;
	}

	return (
		<section style={{ width: "95%", height: 350 }}>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
					paddingBottom: "2%",
					marginTop: "2em"
				}}
			>
				<DateRangePicker
					defaultStartDate={getValues("startDate")}
					defaultEndDate={getValues("endDate")}
					shouldHideFutureDates={true}
					onChange={dates => {
						setValue("endDate", dates.endDate);
						setValue("startDate", dates.startDate);
						loadMaintenance({
							variables: {
								queryInput: {
									condoID: condoID,
									startDate: FormatDate(getValues("startDate")),
									endDate: FormatDate(getValues("endDate")),
									actionLogTypes: actionLogTypes
								}
							}
						});
					}}
				/>
			</div>

			<CustomBarChart data={list.reverse()} barsData={maintenancesBarsData} />
		</section>
	);
};

export default memo(BarMaintenance);
