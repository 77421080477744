import { startTransition, useCallback, useEffect, useState } from "react";
import { useCore } from "../../../Context/Core";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import CustomTable from "../../Components/CustomTable";
import useTable from "../../Components/CustomTable/useTable";
import { Box, Button, ButtonGroup, Divider } from "@mui/material";
import { Add } from "@mui/icons-material";
import ModalCondos, { type CondoCreationInput } from "./ModalCondos";
import useGraphQL from "../../../Hooks/useGraphQL";

import {
	CondoSchema,
	RequestCreateCondos,
	RequestDeleteCondos,
	GetAllCondosData,
	RequestUpdateCondos,
	mutation_delete_condos,
	mutation_register_condos,
	mutation_update_condos,
	query_get_all_condos,
	ToggleCommonAreaReservationTimeRangeSupport,
	mutation_toggle_common_area_reservation_time_range_support,
	mutation_toggle_alphanumeri_keyboard_usage,
	toggleAlphanumeriKeyboardUsageResponse,
	TOGGLE_COMMON_EXPENSES_VISIBILITY,
	type ToggleCommonExpensesVisibilityResponseData
} from "./GraphQL";

import { CommonAreasSchema, query_get_all_common_areas, type GetAllCommonAreasData } from "../CommonAreas/Querys";
import AlertDialog from "../../Components/CustomAlertDialog";
import ModalUpdateCondos, { type CondoUpdateInput } from "./ModalUpdateCondos";
import useErrorGraphql from "../../../Hooks/useErrorGraphql";
import { PLTRouteLocation } from "../../../Routes";

import { ToastContainer, toast } from "react-toastify";
import { UserRole } from "../../../Types";

const headtable = [
	{ id: "name", label: "Nombre" },
	{ id: "pairing_code", label: "Código de Pareo" },
	{ id: "maximum_parking_time", label: "Minutos de Estacionamiento" },
	{ id: "inactivity_time", label: "Minutos de Inactividad en la Aplicación" },
	{ id: "address", label: "Dirección" },
	{ id: "primary_phone_number", label: "Número Telefónico Principal" },
	{ id: "secondary_phone_number", label: "Número Telefónico Secundario" },
	{ id: "devices_number", label: "Número de Dispositivos" },
	{ id: "supports_common_area_reservation_time_ranges", label: "Reservas en Franjas Horarias" },
	{ id: "should_use_alphanumeric_keyboard_for_property_search", label: "Teclado Alfanumérico al Buscar Inmuebles" },
	{ id: "should_show_common_expenses_to_residents", label: "Visibilidad de Gastos Comunes" }
];

const Condos = () => {
	const [condosList, setCondosList] = useState<Array<CondoSchema>>([]);
	const [commonAreas, setCommonAreas] = useState<Array<CommonAreasSchema>>([]);
	const [isAdd, setAdd] = useState<boolean>(false);
	const [confirmDeleteCondos, setConfirmDeleteCondos] = useState<boolean>(false);
	const [idToDelete, setIDToDelete] = useState<{ id: number } | undefined>();

	const [updateState, setUpdateState] = useState<{
		isUpdating: boolean;
		condo?: CondoSchema;
	}>({
		isUpdating: false,
		condo: undefined
	});

	const tableProps = useTable();
	const { setCondos, condo, role } = useCore();

	const headForRole =
		role && role === UserRole.plt ? [...headtable, { id: "actions", label: "Acciones" }] : headtable;

	const navigate = useNavigate();
	const { useLazyGraphQuery, useGraphMutation } = useGraphQL();
	const { errorsField, setErrorFields } = useErrorGraphql();
	const location = useLocation();

	const [loadCondos] = useLazyGraphQuery<GetAllCondosData>(query_get_all_condos);
	const [loadCommonAreas] = useLazyGraphQuery<GetAllCommonAreasData>(query_get_all_common_areas);

	const handleLoadCondos = useCallback(async () => {
		const condos = await loadCondos();
		if (condos.data?.GetAllCondos) {
			setCondosList(condos.data.GetAllCondos);
		}

		const areas = await loadCommonAreas();

		if (areas.data) {
			setCommonAreas(areas.data?.GetAllCommonAreas);
		}
	}, [commonAreas, condosList]);

	const [createCondos] = useGraphMutation<RequestCreateCondos>(mutation_register_condos);
	const [updateCondos] = useGraphMutation<RequestUpdateCondos>(mutation_update_condos);
	const [DeleteCondo] = useGraphMutation<RequestDeleteCondos>(mutation_delete_condos);

	const [toggleCommonAreaReservationTimeRangeSupport] = useGraphMutation<ToggleCommonAreaReservationTimeRangeSupport>(
		mutation_toggle_common_area_reservation_time_range_support
	);

	const [toggleAlphanumericKeyboardUsage] = useGraphMutation<toggleAlphanumeriKeyboardUsageResponse>(
		mutation_toggle_alphanumeri_keyboard_usage
	);

	const [toggleCommonExpensesVisibility] = useGraphMutation<ToggleCommonExpensesVisibilityResponseData>(
		TOGGLE_COMMON_EXPENSES_VISIBILITY
	);

	const condoCreationCallback = useCallback(async (creationInput: CondoCreationInput) => {
		const response = await createCondos({
			variables: {
				FormCreateCondo: {
					name: creationInput.name,
					pairing_code: creationInput.pairing_code,
					maximum_parking_time: creationInput.maximum_parking_time,
					inactivity_time: creationInput.inactivity_time,
					address: creationInput.address,
					primary_phone_number: creationInput.primary_phone_number,
					secondary_phone_number: creationInput.secondary_phone_number,
					devices_number: creationInput.devices_number,
					common_areas_ids: creationInput.commonAreas.map(commonArea => commonArea.id)
				}
			}
		});

		if (response.data) {
			const list = response.data as unknown as RequestCreateCondos;
			if (Array.isArray(list.RegisterCondo)) {
				toast.success("Condominio agregado correctamente");
				setCondosList(list.RegisterCondo);
				setAdd(false);
			}
		}
	}, []);

	const condoUpdateCallback = useCallback(
		async (updateInput: CondoUpdateInput) => {
			const updatedPairingCode =
				updateInput.pairing_code && !isNaN(updateInput.pairing_code) ? updateInput.pairing_code : undefined;

			const response = await updateCondos({
				variables: {
					FormUpdateCondo: {
						id: updateInput.id,
						name: updateInput.name,
						pairing_code: updatedPairingCode,
						maximum_parking_time: updateInput.maximum_parking_time,
						inactivity_time: updateInput.inactivity_time,
						primary_phone_number: updateInput.primary_phone_number,
						secondary_phone_number: updateInput.secondary_phone_number,
						devices_number: updateInput.devices_number,
						address: updateInput.address,
						common_areas_ids: commonAreas
							.filter(commonArea => updateInput.commonAreasLabels.includes(commonArea.label))
							.map(commonArea => commonArea.id)
					}
				}
			});

			const list = response.data?.UpdateCondo;

			if (list) {
				toast.success("Condominio actualizado correctamente");
				setUpdateState({ isUpdating: false, condo: undefined });
				setCondosList(list);
			}
		},
		[commonAreas]
	);

	const condoDeletionCallback = useCallback(async () => {
		if (idToDelete) {
			const response = await DeleteCondo({
				variables: { condo_id: idToDelete.id }
			});

			if (response.data) {
				const list = response.data as unknown as RequestDeleteCondos;

				if (Array.isArray(list.DeleteCondo)) {
					toast.success("Condominio eliminado correctamente");
					setCondosList(list.DeleteCondo);
					setConfirmDeleteCondos(false);
					setIDToDelete(undefined);
				}
			}
		}
	}, [idToDelete]);

	const handleToggleSupportCommonAreaTimeRange = async (row: CondoSchema) => {
		const response = await toggleCommonAreaReservationTimeRangeSupport({
			variables: { id: row.id }
		});

		if (response.data && response.data.ToggleCommonAreaReservationTimeRangeSupport) {
			setCondosList(response.data?.ToggleCommonAreaReservationTimeRangeSupport);
		}
	};

	const handleShouldKeyboardAlphanumeric = async (row: CondoSchema) => {
		const response = await toggleAlphanumericKeyboardUsage({
			variables: { id: row.id }
		});

		if (response.data && response.data.toggleAlphanumeriKeyboardUsage) {
			setCondosList(response.data?.toggleAlphanumeriKeyboardUsage);
		}
	};

	const onCommonExpensesVisibilityToggle = async (row: CondoSchema) => {
		const response = await toggleCommonExpensesVisibility({
			variables: { id: row.id }
		});

		if (response.data) {
			setCondosList(response.data.toggleCommonExpensesVisibility);
		}
	};

	const TableCondos = () => (
		<CustomTable
			controls={{
				...tableProps,
				rowsPerPage: 50,
				onUpdate: row => {
					const condo = row as unknown as CondoSchema;
					setErrorFields(undefined);
					setUpdateState({ isUpdating: true, condo });
				},
				onDelete: ({ id }) => {
					setConfirmDeleteCondos(true);
					setIDToDelete({ id });
				},
				onSupportCommonAreaToggle: row => {
					handleToggleSupportCommonAreaTimeRange(row as CondoSchema);
				},
				onShouldAlphanumericKeyboard: row => {
					handleShouldKeyboardAlphanumeric(row as CondoSchema);
				},
				onCommonExpensesVisibilityToggle: row => {
					onCommonExpensesVisibilityToggle(row as CondoSchema);
				}
			}}
			columns={headForRole}
			rows={condosList}
			isVisibleRowCollapsible
			onClickCollapsible={row => {
				if ("id" in row) {
					setCondos(row as unknown as CondoSchema);
					startTransition(() => {
						navigate("/intranet/residents");
					});
				}
			}}
		/>
	);

	const ButtonCondos = () =>
		role === UserRole.plt ? (
			<>
				<ButtonGroup
					style={{ paddingTop: 20, paddingBottom: 20 }}
					variant="text"
					aria-label="text button group"
				>
					<Button
						onClick={() => {
							setErrorFields(undefined);
							setAdd(true);
						}}
					>
						<Add /> Agregar Condominio
					</Button>
				</ButtonGroup>
				<Divider style={{ marginTop: 15, marginBottom: 15 }} />
			</>
		) : null;

	const CondosList = () => (
		<Box>
			<ButtonCondos />
			<TableCondos />

			<ModalCondos
				errorsField={errorsField ? errorsField : []}
				onClose={() => setAdd(false)}
				CommonAreas={commonAreas}
				onAdd={condoCreationCallback}
				visible={isAdd}
			/>

			<ModalUpdateCondos
				errorsField={errorsField ? errorsField : []}
				commonAreas={commonAreas}
				onClose={() => setUpdateState({ isUpdating: false, condo: undefined })}
				onUpdate={condoUpdateCallback}
				isVisible={updateState.isUpdating}
				condo={updateState.condo!}
			/>

			<AlertDialog
				title={"Advertencia"}
				open={confirmDeleteCondos}
				setOpen={setConfirmDeleteCondos}
				warningMessage={"Estás apunto de eliminar un condominio. ¿Deseas continuar?"}
				onCancel={() => setIDToDelete(undefined)}
				onConfirm={condoDeletionCallback}
			/>
		</Box>
	);

	const DetailCondos = () => (
		<Box>
			<Outlet />
		</Box>
	);

	const HandleRenderCondos = () => (condo ? <DetailCondos /> : <CondosList />);

	useEffect(() => {
		if (location.pathname === PLTRouteLocation.condos) {
			handleLoadCondos();
		}
	}, [location.pathname]);

	return (
		<>
			<HandleRenderCondos />
			<ToastContainer position="bottom-left" autoClose={3000} />
		</>
	);
};

export default Condos;
