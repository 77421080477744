import { gql } from "@apollo/client";

export type ServicesContactSchema = {
  id: number;
  name: string;
  phone_number: string;
  logo_url: string;
  description: string;
  is_active: boolean;
  condo_id: number;
};

export type CreateServicesContactData = {
  CreateServicesContact: ServicesContactSchema[];
};

export type GetAllServicesContactsData = {
  GetAllServicesContacts: ServicesContactSchema[];
};

export type UpdateServicesContactData = {
  UpdateServicesContact: ServicesContactSchema[];
};

export type DeleteServicesContactData = {
  DeleteServicesContact: ServicesContactSchema[];
};

export type ToggleServicesContactActiveStatusData = {
  ToggleServicesContactActiveStatus: ServicesContactSchema[];
};

const graphQLSchema = `
  id
  name
  phone_number
  logo_url
  description
  is_active
  condo_id
`;

export const CREATE_SERVICES_CONTACT = gql`
  mutation CreateServicesContact($creationInput: ServicesContactCreationInput!) {
    CreateServicesContact(creationInput: $creationInput) {
      ${graphQLSchema}
    }
  }
`;

export const GET_ALL_SERVICES_CONTACTS = gql`
  query GetAllServicesContacts {
    GetAllServicesContacts {
      ${graphQLSchema}
    }
  }
`;

export const UPDATE_SERVICES_CONTACT = gql`
  mutation UpdateServicesContact($id: Int!, $updateInput: ServicesContactUpdateInput!) {
    UpdateServicesContact(id: $id, updateInput: $updateInput) {
      ${graphQLSchema}
    }
  }
`;

export const DELETE_SERVICES_CONTACT = gql`
  mutation DeleteServicesContact($id: Int!) {
    DeleteServicesContact(id: $id) {
      ${graphQLSchema}
    }
  }
`;

export const TOGGLE_SERVICES_CONTACT_ACTIVE_STATUS = gql`
  mutation ToggleServicesContactActiveStatus($id: Int!) {
    ToggleServicesContactActiveStatus(id: $id) {
      ${graphQLSchema}
    }
  }
`;
