import { type FC, useEffect } from "react";
import { Container, Divider, MenuItem, Select, Typography } from "@mui/material";
import Grid, { type GridOwnProps } from "@mui/material/Grid";
import PieParkingChart from "./Components/ParkingLotsPieChart";
import VisitsBarChart from "./Components/VisitsBarChart";
import DeliveriesChart from "./Components/DeliveriesBarChart";

import useGraphQL from "../../../Hooks/useGraphQL";
import { type GetAllCondosData, query_get_all_condos } from "../Condos/GraphQL";
import { useCore } from "../../../Context/Core";

import DeliveriesPieChart from "./Components/DeliveriesPieChart";
import BarMaintenance from "./Components/MaintenancesBarChart";
import ReservationsBarChart from "./Components/ReservationsBarChart";
import WorkerVisitsBarChart from "./Components/WorkerVisitsBarChart";
import InvitationsBarChart from "./Components/InvitationsBarChart";
import { Controller, useForm } from "react-hook-form";

const pieProps: GridOwnProps = {
	item: true,
	sm: 12,
	md: 12,
	lg: 6,
	xl: 6,
	justifyContent: "center",
	alignItems: "center",
	flexDirection: "column",
	display: "flex"
};

const barProps: GridOwnProps = {
	item: true,
	sm: 12,
	md: 12,
	lg: 12,
	xl: 12
};

const Dashboard = () => {
	const { useLazyGraphQuery } = useGraphQL();
	const { token } = useCore();
	const { control, watch, setValue } = useForm<{ condo_id: number }>({
		defaultValues: { condo_id: 0 }
	});

	const condo_id = watch("condo_id");
	const [loadCondos, { data: ListCondos }] = useLazyGraphQuery<GetAllCondosData>(query_get_all_condos);

	const listCondos = ListCondos?.GetAllCondos ?? [];

	useEffect(() => {
		if (token) loadCondos();
	}, [token]);

	useEffect(() => {
		if (listCondos.length > 0) {
			setValue("condo_id", listCondos[0].id);
		}
	}, [listCondos]);

	const Title: FC<{ text: string }> = ({ text }) => (
		<Typography fontWeight={"bold"} marginBottom={2} fontSize={25} flex={6}>
			{text}
		</Typography>
	);

	return (
		<Container maxWidth={"xl"}>
			<Grid container alignItems={"center"} justifyContent={"center"}>
				<Grid {...barProps} style={{ marginBottom: "2em" }}>
					<Title text="Condominios" />

					<Controller
						name="condo_id"
						control={control}
						render={({ field: { value, onChange } }) => (
							<Select
								fullWidth
								labelId="condo-parking"
								id="condo-parking"
								value={value}
								placeholder="Condominios"
								onChange={e => {
									onChange(e.target.value);
								}}
							>
								{listCondos.map(condo => (
									<MenuItem value={condo.id}>{condo.name}</MenuItem>
								))}
							</Select>
						)}
					/>

					<div style={{ margin: "3em" }} />

					<Divider />
				</Grid>

				<Grid {...pieProps}>
					<Title text="Estacionamientos" />
					<PieParkingChart condoID={condo_id} />
				</Grid>

				<Grid {...pieProps}>
					<Title text="Encomiendas" />
					<DeliveriesPieChart condoID={condo_id} />
				</Grid>

				<Grid {...barProps} style={{ paddingTop: "5em" }}>
					<Title text="Visitas" />
					<VisitsBarChart condoID={condo_id} />
				</Grid>

				<Grid {...barProps} style={{ paddingTop: "5em" }}>
					<Title text="Visitas Registradas por Trabajadores" />
					<WorkerVisitsBarChart condoID={condo_id} />
				</Grid>

				<Grid {...barProps} style={{ paddingTop: "5em" }}>
					<Title text="Invitaciones" />
					<InvitationsBarChart condoID={condo_id} />
				</Grid>

				<Grid {...barProps} style={{ paddingTop: "5em" }}>
					<Title text="Encomiendas" />
					<DeliveriesChart condoID={condo_id} />
				</Grid>

				<Grid {...barProps} style={{ paddingTop: "5em" }}>
					<Title text="Mantenciones" />
					<BarMaintenance condoID={condo_id} />
				</Grid>

				<Grid {...barProps} style={{ paddingTop: "5em" }}>
					<Title text="Reservas" />
					<ReservationsBarChart condoID={condo_id} />
				</Grid>
			</Grid>
		</Container>
	);
};

export default Dashboard;
