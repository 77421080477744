import { gql } from "@apollo/client";

export type MaintenanceTypeSchema = {
	id: string;
	title: string;
	active: boolean;
	condo_id: number;
};

export type RequestGetMaintenance = {
	GetAllMaintenanceTypesByCondoID?: undefined | null | Array<MaintenanceTypeSchema>;
};
export type RequestCreateMaintenance = {
	CreateMaintenanceType?: undefined | null | Array<MaintenanceTypeSchema>;
};
export type ToggleMaintenanceRequest = {
	ChangeActiveMaintenanceType: Array<MaintenanceTypeSchema>;
};
export type RequestUpdateMaintenance = {
	UpdateMaintenanceType?: undefined | null | Array<MaintenanceTypeSchema>;
};
export type RequestDeleteMaintenance = {
	DeleteMaintenanceType?: undefined | null | Array<MaintenanceTypeSchema>;
};

const schema = `
  id
  title
  condo_id
  active
`;

export const query_get_maintenances = gql`
  query GetAllMaintenanceTypesByCondoID {
    GetAllMaintenanceTypesByCondoID {
      ${schema}
    }
  }
`;

export const mutation_create_maintenance = gql`
  mutation CreateMaintenanceType($title: String!) {
    CreateMaintenanceType(title: $title) {
     ${schema}
    }
  }
`;

export const mutation_toggle_maintenance = gql`
  mutation ChangeActiveMaintenanceType($maintenance_type_id: Int!) {
    ChangeActiveMaintenanceType(maintenance_type_id: $maintenance_type_id) {
    ${schema}
    }
  }
`;

export const mutation_delete_maintenance = gql`
  mutation DeleteMaintenanceType($maintenance_type_id: Int!) {
    DeleteMaintenanceType(maintenance_type_id: $maintenance_type_id) {
    ${schema}
    }
  }
`;

export const mutation_update_maintenance = gql`
mutation UpdateMaintenanceType($id: Int!, $title: String!) {
	UpdateMaintenanceType(id: $id, title: $title) {
      ${schema}
    }
  }
`;
