import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Link,
  Typography,
} from "@mui/material";
import "./bulk.css";
import { CloudUpload as CloudUploadIcon } from "@mui/icons-material";

import { styled } from "@mui/material/styles";
import { ChangeEvent, useState } from "react";
import useIntegrationBulkCommonExpensives from "./Hooks/useIntegrationBulkloads";

const Input = styled("input")({
  display: "none",
});

const MessageRender = ({
  title,
  message,
  close,
}: {
  title: string;
  message: string;
  close: () => void;
}) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Typography variant="h5" component="div" gutterBottom>
      {title}
    </Typography>
    <Typography variant="body2">{message}</Typography>
    <ButtonGroup
      style={{
        paddingTop: 20,
        paddingBottom: 20,
        justifyContent: "flex-end",
        alignItems: "flex-end",
        display: "flex",
        flexDirection: "row",
      }}
      variant="text"
      aria-label="text button group"
    >
      <Button onClick={close}>Aceptar</Button>
    </ButtonGroup>
  </div>
);
const BulkCommonExpensives = () => {
  const { downloadFile, uploadExcelFile, loading } =
    useIntegrationBulkCommonExpensives();
  const [error, setError] = useState({ message: "", title: "", active: false });
  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      const files = event.target.files;

      if (files) {
        const response = await uploadExcelFile(files[0]);
        if (typeof response === "boolean") {
          setError({
            message: "Error al subir el archivo. Por favor, intenta nuevamente.",
            active: true,
            title: "Ups algo ha ocurrido!",
          });
        } else {
          setError({
            message: "Datos cargados correctamente.",
            active: true,
            title: "¡Importación exitosa!",
          });
        }
      }
    } catch (error) {
      setError({
        message: "Error al subir el archivo. Por favor, intenta nuevamente.",
        active: true,
        title: "Ups, ¡algo ha ocurrido!",
      });
    }
  };
  return (
    <div
      className="file-upload-container"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <Typography variant="h5" textAlign={"center"} marginTop={7.5}>
        <p>Ingresa datos de gastos comunes mediante tu archivo Excel.</p>

        <p>
          <small>
            En caso de que no tengas el archivo base, descárgalo{" "}
            <Link href="#" color="inherit" onClick={downloadFile}>
              acá
            </Link>
            .
          </small>
        </p>
      </Typography>

      <div className="file-upload-content" style={{ marginTop: "2em" }}>
        <Box
          sx={{ my: 2 }}
          justifyContent="center"
          alignItems="center"
          display={"flex"}
        >
          {loading ? (
            <CircularProgress />
          ) : error.active ? (
            <MessageRender
              close={() => setError({ message: "", title: "", active: false })}
              message={error.message}
              title={error.title}
            />
          ) : (
            <label htmlFor="contained-button-file">
              <Input
                accept="*"
                id="contained-button-file"
                multiple
                type="file"
                onChange={handleFileUpload}
              />
              <Button
                variant="contained"
                color="primary"
                component="span"
                startIcon={<CloudUploadIcon />}
              >
                Seleccionar Archivo
              </Button>
            </label>
          )}
        </Box>
      </div>
    </div>
  );
};

export default BulkCommonExpensives;
