import { gql } from "@apollo/client";

export type DeliveryCompanySchema = {
	id: number;
	name: string;
	is_active: boolean;
};

export type CreateDeliveryCompanyData = {
	CreateDeliveryCompany: DeliveryCompanySchema[];
};

export type GetAllDeliveryCompaniesData = {
	GetAllDeliveryCompanies: DeliveryCompanySchema[];
};

export type UpdateDeliveryCompanyData = {
	UpdateDeliveryCompany: DeliveryCompanySchema[];
};

export type DeleteDeliveryCompanyData = {
	DeleteDeliveryCompany: DeliveryCompanySchema[];
};

export type ToggleDeliveryCompanyActiveStatusData = {
	ToggleDeliveryCompanyActiveStatus: DeliveryCompanySchema[];
};

const graphQLSchema = `
	id
	name
	is_active
`;

export const GET_ALL_DELIVERY_COMPANIES = gql`
  query GetAllDeliveryCompanies {
    GetAllDeliveryCompanies {
      ${graphQLSchema}
    }
  }
`;

export const CREATE_DELIVERY_COMPANY = gql`
  mutation CreateDeliveryCompany($name: String!) {
	CreateDeliveryCompany(name: $name) {
		${graphQLSchema}
	}
  }
`;

export const UPDATE_DELIVERY_COMPANY = gql`
  mutation UpdateDeliveryCompany($id: Int!, $name: String) {
	UpdateDeliveryCompany(id: $id, name: $name) {
		${graphQLSchema}
	}
  }
`;

export const DELETE_DELIVERY_COMPANY = gql`
  mutation DeleteDeliveryCompany($companyID: Int!) {
	DeleteDeliveryCompany(companyID: $companyID) {
		${graphQLSchema}
	}
  }
`;

export const TOGGLE_DELIVERY_COMPANY_ACTIVE_STATUS = gql`
  mutation ToggleDeliveryCompanyActiveStatus($companyID: Int!) {
	ToggleDeliveryCompanyActiveStatus(companyID: $companyID) {
		${graphQLSchema}
	}
  }
`;
