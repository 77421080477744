import { useCallback, useEffect, useState, useMemo } from "react";
import { Box, Button, ButtonGroup, Divider } from "@mui/material";
import { Add } from "@mui/icons-material";
import CustomTable, { type ControlsTable, type RowTable } from "../../Components/CustomTable";
import ServicesContactCreationModal, {
	type ServicesContactCreationModalInput
} from "./CondoServicesContactCreationModal";
import ServicesContactUpdateModal, { type ServicesContactUpdateModalInput } from "./CondoServicesContactUpdateModal";
import useTable from "../../Components/CustomTable/useTable";
import useGraphQL from "../../../Hooks/useGraphQL";
import AlertDialog from "../../Components/CustomAlertDialog";
import useErrorGraphql from "../../../Hooks/useErrorGraphql";
import uploadLogoFile from "../utils/uploadLogoFile";
import { useCore } from "../../../Context/Core";

import {
	CREATE_SERVICES_CONTACT,
	GET_ALL_SERVICES_CONTACTS,
	UPDATE_SERVICES_CONTACT,
	DELETE_SERVICES_CONTACT,
	TOGGLE_SERVICES_CONTACT_ACTIVE_STATUS,
	type ServicesContactSchema,
	type CreateServicesContactData,
	type GetAllServicesContactsData,
	type UpdateServicesContactData,
	type DeleteServicesContactData,
	type ToggleServicesContactActiveStatusData
} from "./Query";

import { ToastContainer, toast } from "react-toastify";
import GuardCondos from "../../Shared/GuardCondos";

const headerRow = [
	{ id: "name", label: "Nombre" },
	{ id: "phone_number", label: "Número Telefónico" },
	{ id: "description", label: "Descripción" },
	{ id: "is_active", label: "Activo/Inactivo" },
	{ id: "actions", label: "Acciones" }
];

export default function ServicesContactsScreen(): JSX.Element {
	const [servicesContacts, setServicesContacts] = useState<ServicesContactSchema[]>([]);
	const [isCreatingServicesContact, setIsCreatingServicesContact] = useState(false);

	const [updateState, setUpdateState] = useState<{
		isUpdating: boolean;
		servicesContact?: ServicesContactSchema;
	}>({ isUpdating: false, servicesContact: undefined });

	const [shouldConfirmDeletion, setShouldConfirmDeletion] = useState(false);
	const [idToDelete, setIDToDelete] = useState<number | undefined>();

	const { useLazyGraphQuery, useGraphMutation } = useGraphQL();
	const { errorsField, setErrorFields } = useErrorGraphql();

	const [createServicesContact] = useGraphMutation<CreateServicesContactData>(CREATE_SERVICES_CONTACT);
	const [getAllServicesContacts] = useLazyGraphQuery<GetAllServicesContactsData>(GET_ALL_SERVICES_CONTACTS);
	const [updateServicesContact] = useGraphMutation<UpdateServicesContactData>(UPDATE_SERVICES_CONTACT);
	const [deleteServicesContact] = useGraphMutation<DeleteServicesContactData>(DELETE_SERVICES_CONTACT);

	const [toggleServicesContactActiveStatus] = useGraphMutation<ToggleServicesContactActiveStatusData>(
		TOGGLE_SERVICES_CONTACT_ACTIVE_STATUS
	);

	const { condo, token: bearerToken } = useCore();

	useEffect(() => {
		async function fetchServicesContacts(): Promise<void> {
			const response = await getAllServicesContacts();

			if (response.data) {
				setServicesContacts(response.data.GetAllServicesContacts);
			}
		}

		fetchServicesContacts();
	}, [condo, getAllServicesContacts, setServicesContacts]);

	const servicesContactCreationCallback = useCallback(
		async (creationInput: ServicesContactCreationModalInput) => {
			let logoURL = "";

			if (creationInput.logoFile) {
				const logoUploadResponse = await uploadLogoFile(creationInput.logoFile, bearerToken!);

				if (logoUploadResponse) {
					logoURL = logoUploadResponse.imageURL ?? "";
				}
			}

			const response = await createServicesContact({
				variables: {
					creationInput: {
						name: creationInput.name,
						phoneNumber: creationInput.phoneNumber,
						logoURL:
							logoURL && logoURL.length > 0
								? logoURL
								: "https://static.innovaweb.cl/nodejs/plt_api_nodejs/plat-logo-text.png",
						description: creationInput.description
					}
				}
			});

			if (response.data) {
				toast.success("Contacto de servicios agregado correctamente");

				setIsCreatingServicesContact(false);
				setServicesContacts(response.data.CreateServicesContact);
			}
		},
		[uploadLogoFile, createServicesContact, toast, setIsCreatingServicesContact, setServicesContacts]
	);

	const servicesContactUpdateCallback = useCallback(
		async (servicesContactID: number, updateInput: ServicesContactUpdateModalInput) => {
			let logoURL: string | undefined;

			if (updateInput.logoFile) {
				const logoUploadResponse = await uploadLogoFile(updateInput.logoFile, bearerToken!);

				if (logoUploadResponse) {
					logoURL = logoUploadResponse.imageURL;
				}
			}

			const response = await updateServicesContact({
				variables: {
					id: servicesContactID,
					updateInput: {
						name: updateInput.name,
						phoneNumber: updateInput.phoneNumber,
						logoURL:
							logoURL && logoURL.length > 0
								? logoURL
								: "https://static.innovaweb.cl/nodejs/plt_api_nodejs/plat-logo-text.png",
						description: updateInput.description
					}
				}
			});

			if (response.data) {
				toast.success("Contacto de servicios actualizado correctamente");

				setUpdateState({ isUpdating: false, servicesContact: undefined });
				setServicesContacts(response.data.UpdateServicesContact);
			}
		},
		[uploadLogoFile, updateServicesContact, toast, setUpdateState, setServicesContacts]
	);

	const servicesContactDeletionCallback = useCallback(async () => {
		if (idToDelete === undefined) {
			return;
		}

		const response = await deleteServicesContact({
			variables: { id: idToDelete }
		});

		if (response.data) {
			toast.success("Contacto de servicios eliminado correctamente");

			setServicesContacts(response.data.DeleteServicesContact);
			setShouldConfirmDeletion(false);
			setIDToDelete(undefined);
		}
	}, [idToDelete, deleteServicesContact, toast, setServicesContacts, setShouldConfirmDeletion, setIDToDelete]);

	const deletionCancelingCallback = useCallback(() => {
		setIDToDelete(undefined);
	}, [setIDToDelete]);

	const onUpdateCallback = useCallback(
		(row: RowTable) => {
			setErrorFields(undefined);
			setUpdateState({
				isUpdating: true,
				servicesContact: row as ServicesContactSchema
			});
		},
		[setErrorFields, setUpdateState]
	);

	const onDeleteCallback = useCallback(
		(row: RowTable) => {
			setShouldConfirmDeletion(true);
			setIDToDelete(row.id as number);
		},
		[setShouldConfirmDeletion, setIDToDelete]
	);

	const onActiveStatusToggleCallback = useCallback(
		async (row: RowTable) => {
			const response = await toggleServicesContactActiveStatus({
				variables: {
					id: row.id
				}
			});

			if (response.data) {
				setServicesContacts(response.data.ToggleServicesContactActiveStatus);
			}
		},
		[toggleServicesContactActiveStatus, setServicesContacts]
	);

	const tableProps = useTable();

	const customTableControls: ControlsTable = useMemo(
		() => ({
			...tableProps,
			onUpdate: onUpdateCallback,
			onDelete: onDeleteCallback,
			onActiveStatusToggle: onActiveStatusToggleCallback
		}),
		[tableProps, onUpdateCallback, onDeleteCallback, onActiveStatusToggleCallback]
	);

	const ServicesContactsTable = () => (
		<CustomTable controls={customTableControls} columns={headerRow} rows={servicesContacts} />
	);

	const onServicesContactCreationModalCloseCallback = useCallback(() => {
		setIsCreatingServicesContact(false);
	}, [setIsCreatingServicesContact]);

	const onServicesContactUpdateModalCloseCallback = useCallback(() => {
		setUpdateState({ isUpdating: false, servicesContact: undefined });
	}, [setUpdateState]);

	return (
		<Box>
			<GuardCondos />

			<ButtonGroup style={{ paddingTop: 20, paddingBottom: 20 }} variant="text" aria-label="text button group">
				<Button
					onClick={() => {
						setErrorFields(undefined);
						setIsCreatingServicesContact(true);
					}}
				>
					<Add /> Agregar Contacto de Servicios
				</Button>
			</ButtonGroup>

			<Divider style={{ marginTop: 15, marginBottom: 15 }} />

			<ServicesContactsTable />

			<ServicesContactCreationModal
				errorsField={errorsField ?? []}
				isVisible={isCreatingServicesContact}
				onSuccess={servicesContactCreationCallback}
				onClose={onServicesContactCreationModalCloseCallback}
			/>

			<ServicesContactUpdateModal
				errorsField={errorsField ?? []}
				isVisible={updateState.isUpdating}
				servicesContact={updateState.servicesContact!}
				onSuccess={servicesContactUpdateCallback}
				onClose={onServicesContactUpdateModalCloseCallback}
			/>

			<AlertDialog
				title="Advertencia"
				open={shouldConfirmDeletion}
				setOpen={setShouldConfirmDeletion}
				warningMessage="Estás a punto de eliminar un contacto de servicios. ¿Deseas continuar?"
				onCancel={deletionCancelingCallback}
				onConfirm={servicesContactDeletionCallback}
			/>

			<ToastContainer position="bottom-left" autoClose={3000} />
		</Box>
	);
}
