import { type FC, useEffect, forwardRef, Ref } from "react";

import clsx from "clsx";

import {
	AppBar,
	Avatar,
	Box,
	CssBaseline,
	Divider,
	Drawer as RootDrawer,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Toolbar,
	Typography,
	useTheme,
	Chip
} from "@mui/material";

import { Menu as MenuIcon, ExitToApp as ExitToAppIcon, PhoneIphone as PhoneIcon } from "@mui/icons-material";
import { Outlet, useLocation, useNavigate, NavigateFunction } from "react-router-dom";
import uuid from "react-uuid";
import LogoPNG2 from "../../../Assets/Image/plat-logo.png";
import GuardIntranet from "../GuardIntranet";
import { useCore } from "../../../Context/Core";
import { UserRole } from "../../../Types";
import PlatStatus from "../PlatStatus";
import useDrawer from "./Hooks/useDrawer";
import useStatesDrawer from "./Hooks/useStatesDrawer";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem, TreeItemContentProps, TreeItemProps, useTreeItem } from "@mui/x-tree-view/TreeItem";
import { styled, alpha } from "@mui/material/styles";
import { CondoSchema } from "../../Screens/Condos/GraphQL";

const container = window !== undefined ? () => window.document.body : undefined;
const drawerWidth = 240;

interface Props {
	window?: () => Window;
}
const CustomContentRoot = styled("div")(({ theme }) => ({
	WebkitTapHighlightColor: "transparent",
	"&&:hover, &&.Mui-disabled, &&.Mui-focused, &&.Mui-selected, &&.Mui-selected.Mui-focused, &&.Mui-selected:hover": {
		backgroundColor: "transparent"
	},
	".MuiTreeItem-contentBar": {
		position: "absolute",
		width: "100%",
		height: 24,
		left: 0
	},
	"&:hover .MuiTreeItem-contentBar": {
		backgroundColor: theme.palette.action.hover,
		"@media (hover: none)": {
			backgroundColor: "transparent"
		}
	},
	"&.Mui-disabled .MuiTreeItem-contentBar": {
		opacity: theme.palette.action.disabledOpacity,
		backgroundColor: "transparent"
	},
	"&.Mui-focused .MuiTreeItem-contentBar": {
		backgroundColor: theme.palette.action.focus
	},
	"&.Mui-selected .MuiTreeItem-contentBar": {
		backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
	},
	"&.Mui-selected:hover .MuiTreeItem-contentBar": {
		backgroundColor: alpha(
			theme.palette.primary.main,
			theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
		),
		"@media (hover: none)": {
			backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
		}
	},
	"&.Mui-selected.Mui-focused .MuiTreeItem-contentBar": {
		backgroundColor: alpha(
			theme.palette.primary.main,
			theme.palette.action.selectedOpacity + theme.palette.action.focusOpacity
		)
	}
}));

const CustomContent = forwardRef(function CustomContent(props: TreeItemContentProps, ref) {
	const { className, classes, label, nodeId, icon: iconProp, expansionIcon, displayIcon } = props;

	const { disabled, expanded, selected, focused, handleExpansion, handleSelection, preventSelection } =
		useTreeItem(nodeId);

	const icon = iconProp || expansionIcon || displayIcon;

	const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		preventSelection(event);
	};

	const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		handleExpansion(event);
		handleSelection(event);
	};

	return (
		<CustomContentRoot
			className={clsx(className, classes.root, {
				"Mui-expanded": expanded,
				"Mui-selected": selected,
				"Mui-focused": focused,
				"Mui-disabled": disabled
			})}
			onClick={handleClick}
			onMouseDown={handleMouseDown}
			ref={ref as React.Ref<HTMLDivElement>}
		>
			<div className="MuiTreeItem-contentBar" />
			<div className={classes.iconContainer}>{icon}</div>
			<Typography component="div" className={classes.label}>
				{label}
			</Typography>
		</CustomContentRoot>
	);
});

type ClickProp = {
	onClick?: () => void;
};

const CustomTreeItem = forwardRef(function CustomTreeItem(
	props: TreeItemProps & ClickProp,
	ref: React.Ref<HTMLLIElement>
) {
	return <TreeItem ContentComponent={CustomContent} {...props} ref={ref} />;
});

const BoxLogo: FC<{
	navigate: NavigateFunction;
	backgroundColor: string;
}> = props => (
	<Toolbar
		className="hover-darken"
		style={{
			justifyContent: "center",
			alignItems: "center",
			backgroundColor: props.backgroundColor
		}}
	>
		<div onClick={() => props.navigate("/intranet/home")} style={{ cursor: "pointer" }}>
			<img src={LogoPNG2} alt="logo" loading="lazy" width={90} height={45} />
		</div>
	</Toolbar>
);

const DrawerNav: FC<{
	navigate: NavigateFunction;
	backgroundColor: string;
	isActive: (location: string) => boolean;
	condo?: CondoSchema;
	role?: string | null;
}> = props => {
	const theme = useTheme();
	const DrawerOptions = useDrawer();
	const list =
		props.role === UserRole.admin
			? DrawerOptions.ThreeViewList.filter(nav => !DrawerOptions.ExcludeAdminNav.includes(nav.label))
			: DrawerOptions.ThreeViewList;

	const validateSubList = (list: { nav: string; label: string; id: number }[]) => {
		return props.role === UserRole.admin
			? list.filter(nav => !DrawerOptions.ExcludeAdminNav.includes(nav.label))
			: list;
	};

	return (
		<div>
			<BoxLogo {...props} />
			<Divider />
			<TreeView
				aria-label="file system navigator"
				defaultCollapseIcon={<ExpandMoreIcon />}
				defaultExpandIcon={<ChevronRightIcon />}
				defaultExpanded={["1", "5", "8", "10", "20"]}
				sx={{ flexGrow: 1, maxWidth: 400, overflowY: "auto" }}
			>
				{list.map(categoryNav => (
					<div
						onClick={() => {
							if (categoryNav.nav && !props.condo) props.navigate(categoryNav.nav);
						}}
					>
						<CustomTreeItem
							style={{
								backgroundColor:
									categoryNav.nav && props.isActive(categoryNav.nav)
										? theme.palette.action.focus
										: "white",
								marginTop: "1em",
								marginBottom: "1em"
							}}
							nodeId={categoryNav.id.toString()}
							label={categoryNav.label}
						>
							{categoryNav.id === 20 && !props.condo
								? null
								: validateSubList(categoryNav.ScreenList).map(subNav => (
										<div onClick={() => props.navigate(subNav.nav)}>
											<CustomTreeItem
												nodeId={subNav.id.toString()}
												label={subNav.label}
												icon={DrawerOptions.RenderIcon("")[subNav.label]}
												style={{
													marginTop: "4%",
													flexDirection: "row-reverse"
												}}
											/>
										</div>
								  ))}
						</CustomTreeItem>
					</div>
				))}
			</TreeView>
		</div>
	);
};

const Drawer: FC<Props> = () => {
	const theme = useTheme();
	const { cleanAuth, getLettersName, condo, cleanCondosID, role } = useCore();
	const DrawerOptions = useDrawer();
	const DrawerStates = useStatesDrawer();

	const uLocation = useLocation();
	const navigate = useNavigate();

	const handleDrawerToggle = () => DrawerStates.setMobileOpen(mobileOpen => !mobileOpen);

	const isActive = (nav: string): boolean => uLocation.pathname === "/intranet/" + nav;

	const DrawerFullScreen = () => (
		<RootDrawer
			variant="permanent"
			sx={{
				display: { xs: "none", sm: "block" },
				"& .MuiDrawer-paper": {
					boxSizing: "border-box",
					width: drawerWidth
				}
			}}
			open
		>
			<DrawerNav
				backgroundColor={theme.palette.secondary.main}
				navigate={navigate}
				isActive={isActive}
				condo={condo}
				role={role}
			/>
		</RootDrawer>
	);

	const DrawerMobileScreen = () => (
		<RootDrawer
			container={container}
			variant="temporary"
			open={DrawerStates.mobileOpen}
			onClose={handleDrawerToggle}
			ModalProps={{
				keepMounted: true // Better open performance on mobile.
			}}
			sx={{
				display: { xs: "block", sm: "none" },
				"& .MuiDrawer-paper": {
					boxSizing: "border-box",
					width: drawerWidth
				}
			}}
		>
			<DrawerNav
				navigate={navigate}
				backgroundColor={theme.palette.secondary.main}
				isActive={isActive}
				condo={condo}
				role={role}
			/>
		</RootDrawer>
	);

	const BoxAsideNav = () => (
		<Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
			<DrawerMobileScreen />
			<DrawerFullScreen />
		</Box>
	);

	const BoxMain = () => (
		<Box
			component="main"
			sx={{
				flexGrow: 1,
				p: 3,
				width: { sm: `calc(100% - ${drawerWidth}px)` }
			}}
		>
			<Toolbar />
			<Outlet />
		</Box>
	);

	const BoxToolBar = () => (
		<AppBar
			position="fixed"
			elevation={0}
			style={{ backgroundColor: theme.palette.primary.main }}
			sx={{
				width: { sm: `calc(100% - ${drawerWidth}px)` },
				ml: { sm: `${drawerWidth}px` }
			}}
		>
			<Toolbar
				style={{
					background:
						"linear-gradient(90deg, rgba(179,189,220,1) 0%, rgba(78,102,174,1) 35%, rgba(28,58,151,1) 75%, rgba(3,37,140,1) 100%)",
					justifyContent: "space-between"
				}}
			>
				<IconButton
					color="inherit"
					aria-label="open drawer"
					edge="start"
					onClick={handleDrawerToggle}
					sx={{ mr: 2, display: { sm: "none" } }}
				>
					<MenuIcon />
				</IconButton>
				<Typography variant="h6" noWrap component="div" color={"black"}>
					{DrawerStates.location}
					{condo ? (
						<Chip
							label={condo.name}
							onDelete={() => {
								cleanCondosID();
								navigate("/intranet/condos");
							}}
							variant="outlined"
							sx={{
								"& .MuiChip-deleteIcon": {
									color: "white"
								}
							}}
							style={{
								backgroundColor: theme.palette.primary.light,
								color: "white",
								marginLeft: 15,
								marginRight: 15
							}}
						/>
					) : null}
					{role === UserRole.plt && condo ? ( // verificar usuario tipo PLT
						<Chip
							onClick={() => DrawerStates.setShowPlatModal(true)}
							icon={<PhoneIcon color="primary" />}
							label="PLAT"
							variant="outlined"
							sx={{
								"& .MuiChip-phoneIphone": {
									color: "white"
								}
							}}
							style={{
								color: "white",
								marginLeft: 15,
								marginRight: 15
							}}
						/>
					) : null}
				</Typography>

				<Avatar
					onClick={() => DrawerStates.setIsOptionsActive(isOptionsActive => !isOptionsActive)}
					sx={{ bgcolor: "white", color: "#0E89D9", cursor: "pointer" }}
				>
					{getLettersName()}
				</Avatar>
				{DrawerStates.isOptionsActive ? (
					<div
						style={{
							top: 0,
							left: 0,
							position: "absolute",
							backgroundColor: "transparent",
							height: "100vh",
							width: "100%"
						}}
						onClick={() => DrawerStates.setIsOptionsActive(false)}
					/>
				) : null}

				<ListItem
					style={{
						display: DrawerStates.isOptionsActive ? "flex" : "none",
						position: "absolute",
						width: 200,
						right: 10,
						top: 56,
						zIndex: 1,
						backgroundColor: "white",
						border: "1px solid #0E89D9"
					}}
					onBlurCapture={() => {
						console.log("click fuera");
					}}
					key={uuid()}
					disablePadding
				>
					<ListItemButton onClick={() => cleanAuth()}>
						<ListItemIcon>
							<ExitToAppIcon />
						</ListItemIcon>
						<ListItemText primary={"Cerrar Sesión"} style={{ color: "gray" }} />
					</ListItemButton>
				</ListItem>
			</Toolbar>
		</AppBar>
	);

	const checkLocation = () => {
		const location = uLocation.pathname.split("/").reduce((acc, curr) => acc + curr);

		const loc = DrawerOptions.NavListArray.find(d => "intranet" + d.nav === location);

		if (loc) {
			DrawerStates.setLocation(loc.label);
		}
		const loc2 = DrawerOptions.NavListCondominios.find(d => "intranet" + d.nav.replace("/", "") === location);

		if (loc2) {
			DrawerStates.setLocation(loc2.label);
		}
	};

	useEffect(() => {
		checkLocation();
		return () => {
			checkLocation();
		};
	}, [uLocation]);

	return (
		<Box sx={{ display: "flex" }}>
			<GuardIntranet />
			<CssBaseline />
			<BoxToolBar />
			<BoxAsideNav />
			<BoxMain />

			<PlatStatus handleClose={() => DrawerStates.setShowPlatModal(false)} visible={DrawerStates.showPlatModal} />
		</Box>
	);
};

export default Drawer;
