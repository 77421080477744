import { useCallback, useEffect, useState, useMemo } from "react";
import { Box, Button, ButtonGroup, Divider } from "@mui/material";
import { Add } from "@mui/icons-material";
import CustomTable, { type ControlsTable, type RowTable } from "../../Components/CustomTable";
import PossessionTypeCreationModal from "./PossessionTypeCreationModal";
import PossessionTypeUpdateModal from "./PossessionTypeUpdateModal";
import useTable from "../../Components/CustomTable/useTable";
import useGraphQL from "../../../Hooks/useGraphQL";
import AlertDialog from "../../Components/CustomAlertDialog";
import useErrorGraphql from "../../../Hooks/useErrorGraphql";

import {
  CREATE_POSSESSION_TYPE,
  GET_ALL_POSSESSION_TYPES,
  UPDATE_POSSESSION_TYPE,
  DELETE_POSSESSION_TYPE,
  TOGGLE_POSSESSION_TYPE_ACTIVE_STATUS,
  type PossessionTypeSchema,
  type CreatePossessionTypeData,
  type GetAllPossessionTypesData,
  type UpdatePossessionTypeData,
  type DeletePossessionTypeData,
  type TogglePossessionTypeActiveStatusData,
} from "./Query";

import { ToastContainer, toast } from "react-toastify";

const headerRow = [
  { id: "label", label: "Etiqueta" },
  { id: "is_active", label: "Activo/Inactivo" },
  { id: "actions", label: "Acciones" }
];

export default function PossessionTypesScreen(): JSX.Element {
  const [possessionTypes, setPossessionTypes] = useState<PossessionTypeSchema[]>([]);
  const [isCreatingPossessionType, setIsCreatingPossessionType] = useState(false);

  const [updateState, setUpdateState] = useState<{
    isUpdating: boolean,
    possessionType?: PossessionTypeSchema
  }>({ isUpdating: false, possessionType: undefined });

  const [shouldConfirmDeletion, setShouldConfirmDeletion] = useState(false);
  const [idToDelete, setIDToDelete] = useState<number | undefined>();

  const { useLazyGraphQuery, useGraphMutation } = useGraphQL();
  const { errorsField, setErrorFields } = useErrorGraphql();

  const [createPossessionType] = useGraphMutation<CreatePossessionTypeData>(CREATE_POSSESSION_TYPE);
  const [getAllPossessionTypes] = useLazyGraphQuery<GetAllPossessionTypesData>(GET_ALL_POSSESSION_TYPES);
  const [updatePossessionType] = useGraphMutation<UpdatePossessionTypeData>(UPDATE_POSSESSION_TYPE);
  const [deletePossessionType] = useGraphMutation<DeletePossessionTypeData>(DELETE_POSSESSION_TYPE);

  const [togglePossessionTypeActiveStatus] = useGraphMutation<TogglePossessionTypeActiveStatusData>(
    TOGGLE_POSSESSION_TYPE_ACTIVE_STATUS
  );

  useEffect(() => {
    async function fetchPossessionTypes(): Promise<void> {
      const response = await getAllPossessionTypes();

      if (response.data) {
        setPossessionTypes(response.data.GetAllPossessionTypes);
      }
    }

    fetchPossessionTypes();
  }, [getAllPossessionTypes, setPossessionTypes]);

  const possessionTypeCreationCallback = useCallback(
    async (label: string) => {      
      const response = await createPossessionType({
        variables: { label }
      });

      if (response.data) {
        toast.success("Tipo de posesión agregado correctamente");

        setIsCreatingPossessionType(false);
        setPossessionTypes(response.data.CreatePossessionType);
      }
    },
    [createPossessionType, toast, setIsCreatingPossessionType, setPossessionTypes]
  );

  const possessionTypeUpdateCallback = useCallback(
    async (possessionTypeID: number, label?: string) => {
      const response = await updatePossessionType({
        variables: {
          id: possessionTypeID,
          label
        }
      });

      if (response.data) {
        toast.success("Tipo de posesión actualizado correctamente");

        setUpdateState({ isUpdating: false, possessionType: undefined });
        setPossessionTypes(response.data.UpdatePossessionType);
      }
    },
    [updatePossessionType, toast, setUpdateState, setPossessionTypes]
  );

  const possessionTypeDeletionCallback = useCallback(async () => {
    if (idToDelete === undefined) {
      return;
    }

    const response = await deletePossessionType({
      variables: { id: idToDelete },
    });

    if (response.data) {
      toast.success("Tipo de posesión eliminado correctamente");

      setPossessionTypes(response.data.DeletePossessionType);
      setShouldConfirmDeletion(false);
      setIDToDelete(undefined);
    }
  }, [
    idToDelete,
    deletePossessionType,
    toast,
    setPossessionTypes,
    setShouldConfirmDeletion,
    setIDToDelete,
  ]);

  const deletionCancelingCallback = useCallback(() => {
    setIDToDelete(undefined);
  }, [setIDToDelete]);

  const onUpdateCallback = useCallback(
    (row: RowTable) => {
      setErrorFields(undefined);
      setUpdateState({
        isUpdating: true,
        possessionType: row as PossessionTypeSchema,
      });
    },
    [setErrorFields, setUpdateState]
  );

  const onDeleteCallback = useCallback(
    (row: RowTable) => {
      setShouldConfirmDeletion(true);
      setIDToDelete(row.id as number);
    },
    [setShouldConfirmDeletion, setIDToDelete]
  );

  const onActiveStatusToggleCallback = useCallback(
    async (row: RowTable) => {
      const response = await togglePossessionTypeActiveStatus({
        variables: {
          id: row.id,
        }
      });

      if (response.data) {
        setPossessionTypes(response.data.TogglePossessionTypeActiveStatus);
      }
    },
    [togglePossessionTypeActiveStatus, setPossessionTypes]
  );

  const tableProps = useTable();

  const customTableControls: ControlsTable = useMemo(
    () => ({
      ...tableProps,
      onUpdate: onUpdateCallback,
      onDelete: onDeleteCallback,
      onActiveStatusToggle: onActiveStatusToggleCallback,
    }),
    [tableProps, onUpdateCallback, onDeleteCallback, onActiveStatusToggleCallback]
  );

  const PossessionTypesTable = () => (
    <CustomTable
      controls={customTableControls}
      columns={headerRow}
      rows={possessionTypes}
    />
  );

  const onPossessionTypeCreationModalCloseCallback = useCallback(() => {
    setIsCreatingPossessionType(false);
  }, [setIsCreatingPossessionType]);

  const onPossessionTypeUpdateModalCloseCallback = useCallback(() => {
    setUpdateState({ isUpdating: false, possessionType: undefined });
  }, [setUpdateState]);

  return (
    <Box>
      <ButtonGroup
        style={{ paddingTop: 20, paddingBottom: 20 }}
        variant="text"
        aria-label="text button group"
      >
        <Button
          onClick={() => {
            setErrorFields(undefined);
            setIsCreatingPossessionType(true);
          }}
        >
          <Add /> Agregar Tipo de Posesión
        </Button>
      </ButtonGroup>

      <Divider style={{ marginTop: 15, marginBottom: 15 }} />

      <PossessionTypesTable />

      <PossessionTypeCreationModal
        errorsField={errorsField ?? []}
        isVisible={isCreatingPossessionType}
        onSuccess={possessionTypeCreationCallback}
        onClose={onPossessionTypeCreationModalCloseCallback}
      />

      <PossessionTypeUpdateModal
        errorsField={errorsField ?? []}
        isVisible={updateState.isUpdating}
        possessionType={updateState.possessionType!}
        onSuccess={possessionTypeUpdateCallback}
        onClose={onPossessionTypeUpdateModalCloseCallback}
      />

      <AlertDialog
        title="Advertencia"
        open={shouldConfirmDeletion}
        setOpen={setShouldConfirmDeletion}
        warningMessage="Estás a punto de eliminar un tipo de posesión. ¿Deseas continuar?"
        onCancel={deletionCancelingCallback}
        onConfirm={possessionTypeDeletionCallback}
      />

      <ToastContainer position="bottom-left" autoClose={3000} />
    </Box>
  );
}
