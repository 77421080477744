import { Nationality } from "../../CondosResidents/GraphQL";
import { PropertySchema } from "../../CondosProperty/GraphQL";

import { gql } from "@apollo/client";

export type BannedVisitorSchema = {
	id: number;
	identification: string;
	nationality: Nationality,
	condo_id: number;
  formatted_rut?: string;
	Properties?: PropertySchema[];
};

export type FormattedBannedVisitor = BannedVisitorSchema & {
	formattedIdentification: string,
	nationalityDescription: string
};

export type GetAllBannedVisitorsData = {
  GetAllBannedVisitors: BannedVisitorSchema[];
};

export type CreateBannedVisitorData = {
  CreateBannedVisitor: BannedVisitorSchema[];
};

export type UpdateBannedVisitorData = {
  UpdateBannedVisitor: BannedVisitorSchema[];
};

export type DeleteBannedVisitorData = {
  DeleteBannedVisitor: BannedVisitorSchema[];
};

export type ToggleBannedVisitorActiveStatusData = {
  ToggleBannedVisitorActiveStatus: BannedVisitorSchema[];
};

const graphQLSchema = `
	id
	identification
	nationality
	condo_id
  formatted_rut

	Properties {
		id
		residence_identification
	}
`;

export const CREATE_BANNED_VISITOR = gql`
  mutation CreateBannedVisitor($creationInput: BannedVisitorCreationInput!) {
    CreateBannedVisitor(creationInput: $creationInput) {
      ${graphQLSchema}
    }
  }
`;

export const GET_ALL_BANNED_VISITORS = gql`
  query GetAllLogBannedVisitors {
    GetAllBannedVisitors {
      ${graphQLSchema}
    }
  }
`;

export const UPDATE_BANNED_VISITOR = gql`
  mutation UpdateBannedVisitor($id: Int!, $updateInput: BannedVisitorUpdateInput!) {
    UpdateBannedVisitor(id: $id, updateInput: $updateInput) {
      ${graphQLSchema}
    }
  }
`;

export const DELETE_BANNED_VISITOR = gql`
  mutation DeleteBannedVisitor($id: Int!) {
    DeleteBannedVisitor(id: $id) {
      ${graphQLSchema}
    }
  }
`;
