import {
	Autocomplete,
	Box,
	Chip,
	IconButton,
	Snackbar,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
	Tooltip,
	Typography,
	useTheme
} from "@mui/material";

import { Fragment, createRef, useState, type FC } from "react";
import uuid from "react-uuid";
import { Edit as EditIcon, Delete as DeleteIcon, FileCopy as FileCopyIcon } from "@mui/icons-material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

import useFilterTable from "./useFilterTable";
import { Condos } from "../../Screens/Users/GraphQL";
import { UserRole } from "../../../Types";

export interface ColumnTable {
	id: string;
	label: string;
	minWidth?: number;
	align?: "right";
	isImage?: boolean;
	isCopyClipBoard?: boolean;
	isMultipleSelect?: boolean;
	isUserTable?: boolean;
	list_options?: { id: number; name: string }[];
	format?: (value: number) => string;
	onChangeMultiSelect?: (data: Array<unknown>, row: RowTable) => void;
}

export interface RowTable {
	[key: string]: string | number | boolean | null | object;
}

export interface ControlsTable {
	onUpdate?: (row: RowTable) => void;
	onDelete?: (props: { id: number; __typename: string; row: RowTable }) => void;
	onActiveStatusToggle?: (props: RowTable) => void;
	onMainStatusToggle?: (props: RowTable) => void;
	onPlatStatusToggle?: (props: RowTable) => void;
	onSupportCommonAreaToggle?: (props: RowTable) => void;
	onShouldAlphanumericKeyboard?: (props: RowTable) => void;
	onCommonExpensesVisibilityToggle?: (props: RowTable) => void;
	setCollapsible: (state: { active: boolean; id: number }) => void;
	setPage: (state: number) => void;
	setRowsPerPage: (state: number) => void;
	setTabValue: (state: number) => void;
	tabValue: number;
	collapsible?: {
		active: boolean;
		id: number;
	};
	page: number;
	rowsPerPage: number;
}

interface CustomTableProps {
	columns: Array<ColumnTable>;
	rows: Array<RowTable>;
	controls: ControlsTable;
	CustomRow?: FC<{ row: RowTable; index: number }>;
	text_list_empty?: string;
	onClickCollapsible?: (row: RowTable) => void;
	isVisibleRowCollapsible?: boolean;
}

type propsCellRender = {
	row: RowTable;
	column: ColumnTable;
	value: string | number | boolean | null | object;
	disabled?: { update: boolean; delete: boolean };
};

const CustomTable: FC<CustomTableProps> = ({
	columns,
	rows,
	controls,
	CustomRow,
	isVisibleRowCollapsible,
	onClickCollapsible
}) => {
	const theme = useTheme();
	const [searchText, setSearchText] = useState<string>("");

	const { SearchInput, handleFilterList } = useFilterTable(columns, {
		searchText,
		setSearchText
	});

	const $Table = createRef<HTMLTableElement>();

	const [snackbarOpen, setSnackbarOpen] = useState<{
		snackbarOpen: boolean;
		snackbarMessage: string;
		snackbarSeverity: string;
	}>({
		snackbarOpen: false,
		snackbarMessage: "",
		snackbarSeverity: "success"
	});
	/** core props */
	const {
		onUpdate,
		onDelete,
		onActiveStatusToggle,
		onSupportCommonAreaToggle,
		onShouldAlphanumericKeyboard,
		onCommonExpensesVisibilityToggle,
		onMainStatusToggle,
		onPlatStatusToggle,
		page,
		rowsPerPage,
		setPage,
		setRowsPerPage
	} = controls;

	/** functions */
	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const ImageRow = ({ url }: { row: RowTable; url: string }) => (
		<TableCell key={uuid()} align={"center"}>
			<img src={`${url}`} alt={"item"} loading="lazy" width={100} height={60} />
		</TableCell>
	);

	const CellForListAdmins: FC<propsCellRender> = props => {
		const list = props.row["UsersInCondos"] as unknown as Condos;
		const condos = Array.isArray(list) ? list.map(e => e.Condo) : [];
		return (
			<TableCell align={"center"}>
				{props.column.list_options ? (
					<Autocomplete
						multiple
						id={uuid()}
						size="small"
						options={props.column.list_options}
						getOptionLabel={(option: any) => option.name}
						onChange={(e, value) => {
							if (props.column.onChangeMultiSelect) props.column.onChangeMultiSelect(value, props.row);
						}}
						defaultValue={condos}
						renderTags={(value, getTagProps) =>
							value.map((option: any, index) => {
								if (!option) {
									return;
								}

								return (
									<Chip
										variant="outlined"
										label={option.name}
										size="small"
										{...getTagProps({ index })}
									/>
								);
							})
						}
						renderInput={params => <TextField {...params} variant="filled" label="Seleccionar" />}
					/>
				) : (
					"-"
				)}
			</TableCell>
		);
	};

	const CellImage: FC<propsCellRender> = ({ row, value }) => {
		return <ImageRow row={row} url={value as string} />;
	};
	const CellCleanRow: FC<propsCellRender> = props => <TableCell align={"center"}>-</TableCell>;

	const CellClipBoard: FC<propsCellRender> = ({ value, row }) => (
		<TableCell key={uuid()} align={"center"}>
			{`${value}  `}
			<Tooltip title="Copiar a portapapeles">
				<IconButton
					onClick={async () => {
						await navigator.clipboard
							.writeText(`${row.order_id}`)
							.then(() => {
								setSnackbarOpen({
									snackbarMessage: "Copiado en portapapeles",
									snackbarOpen: true,
									snackbarSeverity: "success"
								});
							})
							.catch(err => {
								setSnackbarOpen({
									snackbarMessage: "Error en copiar a portapapeles",
									snackbarOpen: true,
									snackbarSeverity: "error"
								});
							});
					}}
					aria-label="Copiar al portapapeles"
				>
					<FileCopyIcon />
				</IconButton>
			</Tooltip>
		</TableCell>
	);

	const CellDefaultValue: FC<propsCellRender> = ({ value }) => (
		<TableCell key={uuid()} align={"center"}>
			{`${value}`}
		</TableCell>
	);

	const validateStateButton = (isShow: null | undefined | boolean) =>
		typeof isShow === "boolean" && isShow === false ? false : true; // por defecto siempre muestra el boton de actualizar o eliminar

	const DeleteButton = ({ row, isShowButton }: { row: RowTable; isShowButton?: boolean }) =>
		!onDelete ? null : validateStateButton(isShowButton) ? (
			<IconButton
				onClick={() => {
					if (onDelete)
						onDelete({
							id: Number(row.id),
							__typename: row.__typename as string,
							row
						});
				}}
			>
				<DeleteIcon color={"error"} />
			</IconButton>
		) : null;

	const UpdateButton = ({ row, isShowButton }: { row: RowTable; isShowButton?: boolean }) =>
		validateStateButton(isShowButton) ? (
			<IconButton
				onClick={() => {
					if (onUpdate) onUpdate(row);
				}}
			>
				<EditIcon color={"warning"} />
			</IconButton>
		) : null;

	const ActionRow: FC<propsCellRender> = ({ row, disabled }) => (
		<TableCell key={uuid()} align={"center"}>
			<UpdateButton row={row} isShowButton={disabled?.update} />
			<DeleteButton row={row} isShowButton={disabled?.delete} />
		</TableCell>
	);
	// colorcar visible con props
	const CollapsibleRow = ({ row }: { row: RowTable }) =>
		isVisibleRowCollapsible ? (
			<TableCell
				key={uuid()}
				style={{
					width: 40,
					paddingBottom: 0,
					paddingTop: 0
				}}
			>
				<IconButton
					onClick={event => {
						if (onClickCollapsible) onClickCollapsible(row);
					}}
					style={{
						alignItems: "center",
						justifyContent: "center",
						display: "flex"
					}}
				>
					<RadioButtonUncheckedIcon />
				</IconButton>
			</TableCell>
		) : null;

	const ActiveStatusToggleSwitch: FC<propsCellRender> = ({ value, row }) => (
		<TableCell align={"center"}>
			<Switch
				checked={Boolean(value)}
				onChange={event => {
					if (onActiveStatusToggle) {
						onActiveStatusToggle({
							...row,
							state: Boolean(event.target.checked),
							id: Number(row.id),
							type: row.type as string,
							__typename: row.__typename as string
						});
					} else if (onMainStatusToggle) {
						onMainStatusToggle({
							...row,
							state: Boolean(event.target.checked),
							id: Number(row.id),
							type: row.type as string,
							__typename: row.__typename as string
						});
					} else if (onPlatStatusToggle) {
						onPlatStatusToggle({
							...row,
							state: Boolean(event.target.checked),
							id: Number(row.id),
							type: row.type as string,
							__typename: row.__typename as string
						});
					} else if (onSupportCommonAreaToggle) {
						onSupportCommonAreaToggle({
							...row,
							state: Boolean(event.target.checked),
							id: Number(row.id),
							type: row.type as string,
							__typename: row.__typename as string
						});
					}
				}}
			/>
		</TableCell>
	);

	const MainStatusToggleSwitch: FC<propsCellRender> = ({ value, row }) => (
		<TableCell key={uuid()} align={"center"}>
			<Switch
				checked={Boolean(value)}
				onChange={event => {
					if (onMainStatusToggle) {
						onMainStatusToggle({
							...row,
							state: Boolean(event.target.checked),
							id: Number(row.id),
							type: row.type as string,
							__typename: row.__typename as string
						});
					}
				}}
			/>
		</TableCell>
	);

	const SupportsCommonAreaReservationTimeRangesSwitch: FC<propsCellRender> = ({ value, row }) => (
		<TableCell key={uuid()} align={"center"}>
			<Switch
				checked={Boolean(value)}
				onChange={event => {
					if (onSupportCommonAreaToggle) {
						onSupportCommonAreaToggle({
							...row,
							state: Boolean(event.target.checked),
							id: Number(row.id),
							type: row.type as string,
							__typename: row.__typename as string
						});
					}
				}}
			/>
		</TableCell>
	);

	const ShouldUseAlphanumericKeyboardforPropertySearch: FC<propsCellRender> = ({ value, row }) => (
		<TableCell key={uuid()} align={"center"}>
			<Switch
				checked={Boolean(value)}
				onChange={event => {
					if (onShouldAlphanumericKeyboard) {
						onShouldAlphanumericKeyboard({
							...row,
							state: Boolean(event.target.checked),
							id: Number(row.id),
							type: row.type as string,
							__typename: row.__typename as string
						});
					}
				}}
			/>
		</TableCell>
	);

	const ShouldShowCommonExpensesSwitch: FC<propsCellRender> = ({ value, row }) => (
		<TableCell key={uuid()} align={"center"}>
			<Switch
				checked={Boolean(value)}
				onChange={event => {
					if (onCommonExpensesVisibilityToggle) {
						onCommonExpensesVisibilityToggle({
							...row,
							state: Boolean(event.target.checked),
							id: Number(row.id),
							type: row.type as string,
							__typename: row.__typename as string
						});
					}
				}}
			/>
		</TableCell>
	);

	const PlatStatusToggleSwitch = ({ value, row }: propsCellRender) => (
		<TableCell key={uuid()} align={"center"}>
			<Switch
				checked={Boolean(value)}
				onChange={event => {
					if (onPlatStatusToggle) {
						onPlatStatusToggle({
							...row,
							state: Boolean(event.target.checked),
							id: Number(row.id),
							type: row.type as string,
							__typename: row.__typename as string
						});
					}
				}}
			/>
		</TableCell>
	);

	const DefaultRow = ({ row }: { row: RowTable }) => {
		return (
			<TableRow hover role="checkbox" tabIndex={-1} key={uuid()}>
				<CollapsibleRow row={row} />
				{columns.map(column => (
					<FactoryRenderRow key={uuid()} value={row[column.id]} column={column} row={row} />
				))}
			</TableRow>
		);
	};

	const FactoryRenderRow = (props: propsCellRender) => {
		const { column, row, value } = props;
		const Role = row["role"];

		const isSelectForAdmin = column.isUserTable ? Role === UserRole.admin : column.isMultipleSelect;

		if (isSelectForAdmin) {
			return <CellForListAdmins {...props} key={uuid()} />;
		}

		if (column.isImage) {
			return <CellImage {...props} key={uuid()} />;
		}

		if (column.id === "actions") {
			return <ActionRow {...props} key={uuid()} />;
		}

		if (column.id === "is_active") {
			return <ActiveStatusToggleSwitch {...props} key={uuid()} />;
		}

		if (column.id === "active") {
			return <ActiveStatusToggleSwitch {...props} key={uuid()} />;
		}

		if (column.id === "is_main") {
			return <MainStatusToggleSwitch {...props} key={uuid()} />;
		}

		if (column.id === "supports_common_area_reservation_time_ranges") {
			return <SupportsCommonAreaReservationTimeRangesSwitch {...props} key={uuid()} />;
		}

		if (column.id === "should_use_alphanumeric_keyboard_for_property_search") {
			return <ShouldUseAlphanumericKeyboardforPropertySearch {...props} key={uuid()} />;
		}

		if (column.id === "should_show_common_expenses_to_residents") {
			return <ShouldShowCommonExpensesSwitch {...props} key={uuid()} />;
		}

		if (column.id === "supports_plat") {
			return <PlatStatusToggleSwitch {...props} key={uuid()} />;
		}

		if (column.isCopyClipBoard) {
			return <CellClipBoard {...props} key={uuid()} />;
		}

		if (value != null && value !== "") {
			return <CellDefaultValue {...props} key={uuid()} />;
		}

		return <CellCleanRow {...props} key={uuid()} />;
	};

	const DeckBodyTable = ({
		list,
		Component,
		Head,
		disabled
	}: {
		list: Array<RowTable> | undefined;
		Component: FC<{
			row: RowTable;
			columns?: Array<ColumnTable>;
			disabled?: { update: boolean; delete: boolean };
		}>;
		Head?: Array<ColumnTable>;
		disabled?: {
			update: boolean;
			delete: boolean;
		};
	}) =>
		list?.length === 0 ? (
			<caption>
				<ListEmpty text="Listado Vacío" />
			</caption>
		) : (
			<TableBody>
				{list?.map(row => (
					<Fragment key={uuid()}>
						<Component row={row} columns={Head} disabled={disabled} />
					</Fragment>
				))}
			</TableBody>
		);

	const DeckHeadTable = ({ list }: { list: Array<ColumnTable | null> | undefined }) => (
		<TableHead>
			<TableRow>
				{list?.map(column =>
					column ? (
						<TableCell
							key={column.id}
							align={"center"}
							style={{
								minWidth: column.minWidth,
								backgroundColor: theme.palette.primary.light
							}}
						>
							<Typography fontWeight={"bold"} color={"white"} fontSize={13}>
								{column.label}
							</Typography>
						</TableCell>
					) : null
				)}
			</TableRow>
		</TableHead>
	);

	/** Primary table */
	const DeckCustomRow = () =>
		CustomRow
			? handleFilterList(rows, searchText)
					.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
					.map((row, index) => <CustomRow key={uuid()} row={row} index={index} />)
			: null;

	const Body = () => (
		<DeckBodyTable
			list={handleFilterList(rows, searchText).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
			Component={DefaultRow}
		/>
	);
	// is visible desde prop
	const Head = () => (
		<DeckHeadTable list={[isVisibleRowCollapsible ? { id: "detalle", label: "Seleccionar" } : null, ...columns]} />
	);

	const Pagination = () => (
		<TablePagination
			rowsPerPageOptions={[10, 25, 50, 75, 100]}
			component="div"
			count={rows.length}
			rowsPerPage={rowsPerPage}
			page={page}
			onPageChange={handleChangePage}
			onRowsPerPageChange={handleChangeRowsPerPage}
			labelRowsPerPage={"Filas por página"}
			labelDisplayedRows={({ from, to, count }) => `${from}–${to} de ${count !== -1 ? count : `Mayor que ${to}`}`}
		/>
	);

	const DefaultRender = () => (
		<Fragment>
			<Head />
			<Body />
		</Fragment>
	);

	const HandleRenderTable = () => (CustomRow ? DeckCustomRow() : <DefaultRender />);

	const ListEmpty = ({ text }: { text: string }) => (
		<Box flex={1} width={"100%"} justifyContent={"center"} alignItems={"center"} padding={5}>
			<Fragment>
				<Box border={"1px solid"} borderColor={theme.palette.secondary.main}>
					<h3
						style={{
							textAlign: "center",
							flex: 1
						}}
					>
						{text}
					</h3>
				</Box>
			</Fragment>
		</Box>
	);

	return (
		<Box sx={{ width: "100%", paddingLeft: "2.7rem", paddingRight: "2.7rem" }}>
			<SearchInput />

			<TableContainer
				sx={{
					maxHeight: 640,
					overflowX: "auto"
				}}
				ref={$Table}
			>
				<Table stickyHeader aria-label="sticky table">
					{HandleRenderTable()}
				</Table>
			</TableContainer>

			<Pagination />

			<Snackbar
				open={snackbarOpen.snackbarOpen}
				autoHideDuration={2000}
				onClose={() => {
					setSnackbarOpen({
						snackbarMessage: "",
						snackbarOpen: false,
						snackbarSeverity: "success"
					});
				}}
				message={snackbarOpen.snackbarMessage}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left"
				}}
				ContentProps={{
					style: {
						backgroundColor: snackbarOpen.snackbarSeverity === "success" ? "green" : "red"
					}
				}}
			/>
		</Box>
	);
};

export default CustomTable;
