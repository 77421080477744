import { useEffect, memo, type FC } from "react";
import useGraphQL from "../../../../../Hooks/useGraphQL";
import { GetActionLogsResponseData, GET_ACTION_LOGS } from "../../GraphQL";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import DateRangePicker from "../../../../Components/DateRangePicker/DateRangePicker";
import { CircularProgress } from "@mui/material";
import CustomBarChart, { type BarData } from "../../../../Components/CustomBarChart";
import { ActionLogType } from "../../../Reports/types";

enum VisitsDataKey {
	arrival = "Entrada de visitas",
	exit = "Salida de visitas"
}

interface VisitsChartData {
	name: string;
	[VisitsDataKey.arrival]: number;
	[VisitsDataKey.exit]: number;
}

const visitsBarsData: BarData[] = [
	{
		barFillColor: "#E68B00",
		dataKey: VisitsDataKey.arrival,
		rectangleFillColor: "rgba(255, 255, 255, 0.7)",
		rectangleStrokeColor: "#E68B00"
	},
	{
		barFillColor: "#FFE082",
		dataKey: VisitsDataKey.exit,
		rectangleFillColor: "rgba(255, 255, 255, 0.4)",
		rectangleStrokeColor: "#FFE082"
	}
];

const actionLogTypes = [ActionLogType.newVisit, ActionLogType.finishedVisit];

const VisitsBarChart: FC<{ condoID: number }> = ({ condoID }) => {
	const { useLazyGraphQuery } = useGraphQL();

	const [loadVisits, { data: visitsData, loading: isLoadingVisitsData }] =
		useLazyGraphQuery<GetActionLogsResponseData>(GET_ACTION_LOGS);

	const { setValue, getValues } = useForm<{
		startDate: Date;
		endDate: Date;
	}>({
		defaultValues: {
			startDate: dayjs().subtract(6, "day").toDate(),
			endDate: dayjs().toDate()
		}
	});

	const formatDate = (date: Date) => dayjs(date).format("YYYY-MM-DD");

	const list = visitsData?.actionLogs
		? visitsData.actionLogs.reduce((acc: VisitsChartData[], item) => {
				const existingDate = acc.find(entry => entry.name === item.local_date);

				if (existingDate) {
					if (item.register_type === ActionLogType.newVisit) {
						existingDate[VisitsDataKey.arrival] += 1;
					} else if (item.register_type === ActionLogType.finishedVisit) {
						existingDate[VisitsDataKey.exit] += 1;
					}
				} else {
					const newEntry = {
						name: item.local_date,
						[VisitsDataKey.arrival]: item.register_type === ActionLogType.newVisit ? 1 : 0,
						[VisitsDataKey.exit]: item.register_type === ActionLogType.finishedVisit ? 1 : 0
					};
					acc.push(newEntry);
				}
				return acc;
		  }, [])
		: [];

	const Loader = () => (
		<div style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
			<CircularProgress />
		</div>
	);

	useEffect(() => {
		if (condoID) {
			loadVisits({
				variables: {
					queryInput: {
						condoID,
						startDate: getValues("startDate"),
						endDate: getValues("endDate"),
						actionLogTypes: actionLogTypes
					}
				}
			});
		}
	}, [condoID]);

	if (!condoID) {
		return <Loader />;
	}

	if (isLoadingVisitsData) {
		return <Loader />;
	}

	return (
		<section style={{ width: "95%", height: 350 }}>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
					paddingBottom: "2%",
					marginTop: "2em"
				}}
			>
				<DateRangePicker
					defaultStartDate={getValues("startDate")}
					defaultEndDate={getValues("endDate")}
					shouldHideFutureDates={true}
					onChange={dates => {
						setValue("endDate", dates.endDate);
						setValue("startDate", dates.startDate);

						loadVisits({
							variables: {
								queryInput: {
									condoID: condoID,
									startDate: formatDate(getValues("startDate")),
									endDate: formatDate(getValues("endDate")),
									actionLogTypes: actionLogTypes
								}
							}
						});
					}}
				/>
			</div>

			<CustomBarChart data={list.reverse()} barsData={visitsBarsData} />
		</section>
	);
};

export default memo(VisitsBarChart);
