import {
	Autocomplete,
	Button,
	ButtonGroup,
	Chip,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
	useTheme
} from "@mui/material";

import { FC, useCallback, useEffect } from "react";
import { Nationality, getNationalitySpanishDescription, ResidentSchema } from "../GraphQL";
import { Controller, useForm } from "react-hook-form";
import useEnterKey from "../../../../Hooks/useEnterKey";
import { format as formatRUT } from "rut.js";
import uuid from "react-uuid";
import { Add, Close } from "@mui/icons-material";
import { ResidentType, getResidentTypeSpanishDescription } from "../GraphQL";

import type { ParkingSchema } from "../../CondosParking/Querys";
import type { PropertySchema } from "../../CondosProperty/GraphQL";
import type { PLTFieldErrors } from "../../../../Types";

export type ResidentForm = Omit<ResidentSchema, "active"> & {
	parkings: ParkingSchema[];
	secondary_patent: string;
};

interface Props {
	errorsField: PLTFieldErrors;
	onSubmit: (form: ResidentForm) => void;
	onCancel: () => void;
	listParking: ParkingSchema[];
	propertiesList: Array<PropertySchema>;
	resident?: ResidentSchema;
}

const FormResident: FC<Props> = ({ errorsField, listParking, resident, propertiesList, onSubmit, onCancel }) => {
	const text = resident ? "Actualizar Residente" : "Agregar Residente";

	const theme = useTheme();

	const { control, getValues, setValue } = useForm<ResidentForm>({
		defaultValues: {
			email: "",
			last_name: "",
			name: "",
			nationality: Nationality.CHILEAN,
			patent: "",
			personal_identification: "",
			phone_number: "",
			property_id: 0,
			resident_type: "" as ResidentType,
			secondary_patent: "",
			parkings: []
		}
	});

	const hasError = useCallback(
		(key: string) => {
			if (errorsField) {
				return errorsField.find(e => e.key === key) ? true : false;
			}

			return false;
		},
		[errorsField]
	);

	const getErrorMessage = useCallback(
		(key: string) => {
			if (errorsField) {
				const found = errorsField.find(e => e.key === key);

				if (found) {
					return found.message;
				}
			}

			return "";
		},
		[errorsField]
	);

	useEnterKey(() => onSubmit(getValues()));

	useEffect(() => {
		if (resident) {
			const parkingLots = resident.ParkingsResidents?.map(element => element.Parking!).filter(
				parkingLot => parkingLot !== null
			);

			setValue("personal_identification", resident.personal_identification);
			setValue("name", resident.name);
			setValue("last_name", resident.last_name);
			setValue("email", resident.email);
			setValue("phone_number", resident.phone_number);
			setValue("property_id", resident.property_id);
			setValue("resident_type", resident.resident_type);
			setValue("nationality", resident.nationality);
			setValue("patent", resident.patent ? resident.patent : "");
			setValue("secondary_patent", resident.secondary_patent ? resident.secondary_patent : "");
			setValue("id", resident.id);
			setValue("parkings", parkingLots ?? []);
		}
	}, [resident]);

	const NationalitySelector = () => (
		<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
			<Controller
				control={control}
				render={({ field: { onChange, value } }) => (
					<FormControl sx={{ width: "100%" }}>
						<InputLabel
							id="Nacionalidad"
							style={{
								color: hasError("nationality") ? theme.palette.error.main : theme.palette.primary.main
							}}
						>
							Nacionalidad
						</InputLabel>

						<Select
							labelId="Nacionalidad"
							id="Nacionalidad"
							value={value}
							label="Age"
							onChange={e => {
								onChange(e.target.value);

								if (e.target.value === "CHILEAN") {
									setValue(
										"personal_identification",
										formatRUT(getValues("personal_identification"))
									);
								}
							}}
							error={hasError("nationality")}
						>
							<MenuItem value="">
								<em>Seleccionar Nacionalidad</em>
							</MenuItem>

							{Object.values(Nationality).map(nationality => (
								<MenuItem key={`${nationality}-selres`} value={nationality}>
									{getNationalitySpanishDescription(nationality)}
								</MenuItem>
							))}
						</Select>

						{hasError("nationality") ? (
							<FormHelperText style={{ color: theme.palette.error.main }}>
								{getErrorMessage("nationality")}
							</FormHelperText>
						) : null}
					</FormControl>
				)}
				name="nationality"
			/>
		</Grid>
	);

	const IdentificationInput = () => (
		<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
			<Controller
				control={control}
				render={({ field: { onChange, value } }) => (
					<TextField
						fullWidth
						color={"primary"}
						style={{ width: "100%" }}
						id={uuid()}
						value={value}
						onChange={e => {
							const identification = e.target.value;

							if (getValues().nationality === "CHILEAN" && identification.length > 2) {
								onChange(formatRUT(identification));
								return;
							}

							onChange(identification);
						}}
						label="RUT/Pasaporte"
						variant="outlined"
						error={hasError("personal_identification")}
						helperText={getErrorMessage("personal_identification")}
					/>
				)}
				name="personal_identification"
			/>
		</Grid>
	);

	const NameInput = () => (
		<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={6}>
			<Controller
				control={control}
				render={({ field: { onChange, value } }) => (
					<TextField
						color={"primary"}
						style={{ width: "100%" }}
						id={uuid()}
						fullWidth
						value={value}
						onChange={onChange}
						label="Nombre"
						variant="outlined"
						error={hasError("name")}
						helperText={getErrorMessage("name")}
					/>
				)}
				name="name"
			/>
		</Grid>
	);

	const LastNameInput = () => (
		<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={6}>
			<Controller
				control={control}
				render={({ field: { onChange, value } }) => (
					<TextField
						color={"primary"}
						style={{ width: "100%" }}
						id={uuid()}
						fullWidth
						value={value}
						onChange={onChange}
						label="Apellido"
						variant="outlined"
						error={hasError("last_name")}
						helperText={getErrorMessage("last_name")}
					/>
				)}
				name="last_name"
			/>
		</Grid>
	);

	const EmailInput = () => (
		<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={6}>
			<Controller
				control={control}
				render={({ field: { onChange, value } }) => (
					<TextField
						color={"primary"}
						style={{ width: "100%" }}
						id={uuid()}
						fullWidth
						value={value}
						onChange={onChange}
						label="Email"
						variant="outlined"
						error={hasError("email")}
						helperText={getErrorMessage("email")}
					/>
				)}
				name="email"
			/>
		</Grid>
	);

	const PhoneNumberInput = () => (
		<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={6}>
			<Controller
				control={control}
				render={({ field: { onChange, value } }) => (
					<TextField
						color={"primary"}
						style={{ width: "100%" }}
						id={uuid()}
						fullWidth
						value={value}
						onChange={onChange}
						label="Número Telefónico"
						variant="outlined"
						error={hasError("phone_number")}
						helperText={getErrorMessage("phone_number")}
					/>
				)}
				name="phone_number"
			/>
		</Grid>
	);

	const PropertySelector = () => (
		<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
			<Controller
				control={control}
				name="property_id"
				render={({ field: { onChange, value } }) => (
					<FormControl sx={{ width: "100%" }}>
						<InputLabel
							id="propertySelectorLabel"
							style={{
								color: hasError("property_id") ? theme.palette.error.main : theme.palette.primary.main
							}}
						>
							Inmueble
						</InputLabel>

						<Select
							labelId="propertySelectorLabel"
							id="propertySelector"
							value={value}
							label="Inmueble"
							onChange={e => onChange(Number(e.target.value))}
							error={hasError("property_id")}
						>
							<MenuItem value={0}>
								<em>Seleccionar inmueble</em>
							</MenuItem>

							{propertiesList.map(item => (
								<MenuItem key={`${item.id}-selpro`} value={item.id}>
									{item.residence_identification}
								</MenuItem>
							))}
						</Select>

						{hasError("property_id") ? (
							<FormHelperText style={{ color: theme.palette.error.main }}>
								{getErrorMessage("property_id")}
							</FormHelperText>
						) : null}
					</FormControl>
				)}
			/>
		</Grid>
	);

	const CarPlateInput = () => (
		<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={6}>
			<Controller
				control={control}
				render={({ field: { onChange, value } }) => (
					<TextField
						color={"primary"}
						style={{ width: "100%" }}
						id={uuid()}
						fullWidth
						value={value}
						onChange={onChange}
						label="Patente"
						variant="outlined"
						error={hasError("patent")}
						helperText={getErrorMessage("patent")}
					/>
				)}
				name="patent"
			/>
		</Grid>
	);

	const SecondaryCarPlateInput = () => (
		<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={6}>
			<Controller
				control={control}
				render={({ field: { onChange, value } }) => (
					<TextField
						color={"primary"}
						style={{ width: "100%" }}
						id={uuid()}
						fullWidth
						value={value}
						onChange={onChange}
						label="Segunda Patente"
						variant="outlined"
						error={hasError("secondary_patent")}
						helperText={getErrorMessage("secondary_patent")}
					/>
				)}
				name="secondary_patent"
			/>
		</Grid>
	);

	const ResidentTypeSelector = () => (
		<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
			<Controller
				control={control}
				name="resident_type"
				render={({ field: { onChange, value } }) => (
					<FormControl sx={{ width: "100%" }}>
						<InputLabel
							id="residentTypeSelectorLabel"
							style={{
								color: hasError("resident_type") ? theme.palette.error.main : theme.palette.primary.main
							}}
						>
							Tipo de Residente
						</InputLabel>

						<Select
							labelId="residentTypeSelectorLabel"
							id="residentTypeSelector"
							value={value}
							label="Tipo de residente"
							onChange={e => onChange(e.target.value)}
							error={hasError("resident_type")}
						>
							<MenuItem value="">
								<em>Seleccionar tipo de residente</em>
							</MenuItem>

							{Object.values(ResidentType).map(residentType => (
								<MenuItem key={`${residentType}-selres`} value={residentType}>
									{getResidentTypeSpanishDescription(residentType)}
								</MenuItem>
							))}
						</Select>

						{hasError("resident_type") ? (
							<FormHelperText style={{ color: theme.palette.error.main }}>
								{getErrorMessage("resident_type")}
							</FormHelperText>
						) : null}
					</FormControl>
				)}
			/>
		</Grid>
	);

	const ParkingLotSelector = () => (
		<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
			<Controller
				control={control}
				name="parkings"
				render={({ field: { onChange, value } }) => (
					<Autocomplete
						multiple
						id={uuid()}
						size="small"
						options={listParking}
						getOptionLabel={(parkingLot: ParkingSchema) => parkingLot.label}
						onChange={(e, value) => {
							onChange(value);
						}}
						value={value}
						renderTags={(parkingLots: ParkingSchema[], getTagProps) =>
							parkingLots.map((parkingLot: ParkingSchema, index) => (
								<Chip
									variant="outlined"
									label={parkingLot.label}
									size="small"
									{...getTagProps({ index })}
								/>
							))
						}
						renderInput={params => (
							<TextField
								{...params}
								variant="filled"
								label="Seleccionar estacionamientos"
								placeholder="Estacionamientos"
								error={hasError("parkings_ids")}
								helperText={getErrorMessage("parkings_ids")}
							/>
						)}
					/>
				)}
			/>
		</Grid>
	);

	return (
		<>
			<Grid container flexDirection={"row"}>
				<Typography variant="h5" color={theme.palette.primary.main} textAlign={"center"}>
					{text}
				</Typography>

				<PropertySelector />
				<ResidentTypeSelector />
				<NationalitySelector />
				<IdentificationInput />
				<NameInput />
				<LastNameInput />
				<EmailInput />
				<PhoneNumberInput />
				<CarPlateInput />
				<SecondaryCarPlateInput />
				<ParkingLotSelector />
			</Grid>

			<ButtonGroup
				style={{ paddingTop: 20, paddingBottom: 20, alignSelf: "center" }}
				variant="text"
				aria-label="text button group"
			>
				<Button onClick={onCancel}>
					<Close /> Cancelar
				</Button>

				<Button onClick={() => onSubmit(getValues())}>
					<Add /> {text}
				</Button>
			</ButtonGroup>
		</>
	);
};

export default FormResident;
