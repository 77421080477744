import {
	ActionLogTypesCategory,
	ActionLogDescriptionPropertyName,
	VisitCategoryActionLogDescriptionPropertyLabel,
	ResidentCategoryActionLogDescriptionPropertyLabel,
	MaintenanceCategoryActionLogDescriptionPropertyLabel,
	ParkingCategoryActionLogDescriptionPropertyLabel,
	DeliveryCategoryActionLogDescriptionPropertyLabel,
	CommonAreaReservationCategoryActionLogDescriptionPropertyLabel,
	LogEntryCategoryActionLogDescriptionPropertyLabel,
	InvitationCategoryActionLogDescriptionPropertyLabel,
	UserCategoryActionLogDescriptionPropertyLabel,
	PossessionCategoryActionLogDescriptionPropertyLabel,
	type ActionLogDescription,
	type HeaderRow
} from "../types";

const visitCategoryHeaderRow: HeaderRow = [
	{
		id: ActionLogDescriptionPropertyName.localDatetime,
		label: VisitCategoryActionLogDescriptionPropertyLabel.localDatetime
	},
	{
		id: ActionLogDescriptionPropertyName.typeDescription,
		label: VisitCategoryActionLogDescriptionPropertyLabel.typeDescription
	},
	{
		id: ActionLogDescriptionPropertyName.visitIdentification,
		label: VisitCategoryActionLogDescriptionPropertyLabel.visitIdentification
	},
	{
		id: ActionLogDescriptionPropertyName.visitFullName,
		label: VisitCategoryActionLogDescriptionPropertyLabel.visitFullName
	},
	{
		id: ActionLogDescriptionPropertyName.visitType,
		label: VisitCategoryActionLogDescriptionPropertyLabel.visitType
	},
	{
		id: ActionLogDescriptionPropertyName.propertyLabel,
		label: VisitCategoryActionLogDescriptionPropertyLabel.propertyLabel
	},
	{
		id: ActionLogDescriptionPropertyName.carPlate,
		label: VisitCategoryActionLogDescriptionPropertyLabel.carPlate
	},
	{
		id: ActionLogDescriptionPropertyName.visitDeliveryTypeLabel,
		label: VisitCategoryActionLogDescriptionPropertyLabel.visitDeliveryTypeLabel
	},
	{
		id: ActionLogDescriptionPropertyName.userFullName,
		label: VisitCategoryActionLogDescriptionPropertyLabel.userFullName
	},
	{
		id: ActionLogDescriptionPropertyName.entryMethodDescription,
		label: VisitCategoryActionLogDescriptionPropertyLabel.entryMethodDescription
	},
	{
		id: ActionLogDescriptionPropertyName.description,
		label: VisitCategoryActionLogDescriptionPropertyLabel.description
	}
];

const residentCategoryHeaderRow: HeaderRow = [
	{
		id: ActionLogDescriptionPropertyName.localDatetime,
		label: ResidentCategoryActionLogDescriptionPropertyLabel.localDatetime
	},
	{
		id: ActionLogDescriptionPropertyName.typeDescription,
		label: ResidentCategoryActionLogDescriptionPropertyLabel.typeDescription
	},
	{
		id: ActionLogDescriptionPropertyName.propertyLabel,
		label: ResidentCategoryActionLogDescriptionPropertyLabel.propertyLabel
	},
	{
		id: ActionLogDescriptionPropertyName.residentFullName,
		label: ResidentCategoryActionLogDescriptionPropertyLabel.residentFullName
	},
	{
		id: ActionLogDescriptionPropertyName.residentIdentification,
		label: ResidentCategoryActionLogDescriptionPropertyLabel.residentIndentification
	},
	{
		id: ActionLogDescriptionPropertyName.residentType,
		label: ResidentCategoryActionLogDescriptionPropertyLabel.residentType
	},
	{
		id: ActionLogDescriptionPropertyName.residentEmail,
		label: ResidentCategoryActionLogDescriptionPropertyLabel.residentEmail
	},
	{
		id: ActionLogDescriptionPropertyName.residentPhoneNumber,
		label: ResidentCategoryActionLogDescriptionPropertyLabel.residentPhoneNumber
	},
	{
		id: ActionLogDescriptionPropertyName.residentNationality,
		label: ResidentCategoryActionLogDescriptionPropertyLabel.residentNationality
	},
	{
		id: ActionLogDescriptionPropertyName.userFullName,
		label: ResidentCategoryActionLogDescriptionPropertyLabel.userFullName
	},
	{
		id: ActionLogDescriptionPropertyName.description,
		label: ResidentCategoryActionLogDescriptionPropertyLabel.description
	}
];

const maintenanceCategoryHeaderRow: HeaderRow = [
	{
		id: ActionLogDescriptionPropertyName.localDatetime,
		label: MaintenanceCategoryActionLogDescriptionPropertyLabel.localDatetime
	},
	{
		id: ActionLogDescriptionPropertyName.typeDescription,
		label: MaintenanceCategoryActionLogDescriptionPropertyLabel.typeDescription
	},
	{
		id: ActionLogDescriptionPropertyName.maintenanceCompanyName,
		label: MaintenanceCategoryActionLogDescriptionPropertyLabel.maintenanceCompanyName
	},
	{
		id: ActionLogDescriptionPropertyName.maintenanceTypeLabel,
		label: MaintenanceCategoryActionLogDescriptionPropertyLabel.maintenanceTypeLabel
	},
	{
		id: ActionLogDescriptionPropertyName.technicianFullName,
		label: MaintenanceCategoryActionLogDescriptionPropertyLabel.technicianFullName
	},
	{
		id: ActionLogDescriptionPropertyName.technicianRUT,
		label: MaintenanceCategoryActionLogDescriptionPropertyLabel.technicianRUT
	},
	{
		id: ActionLogDescriptionPropertyName.carPlate,
		label: MaintenanceCategoryActionLogDescriptionPropertyLabel.carPlate
	},
	{
		id: ActionLogDescriptionPropertyName.userFullName,
		label: MaintenanceCategoryActionLogDescriptionPropertyLabel.userFullName
	},
	{
		id: ActionLogDescriptionPropertyName.entryMethodDescription,
		label: MaintenanceCategoryActionLogDescriptionPropertyLabel.entryMethodDescription
	},
	{
		id: ActionLogDescriptionPropertyName.description,
		label: MaintenanceCategoryActionLogDescriptionPropertyLabel.description
	}
];

const parkingCategoryHeaderRow: HeaderRow = [
	{
		id: ActionLogDescriptionPropertyName.parkingLotAssignmentLocalDatetime,
		label: ParkingCategoryActionLogDescriptionPropertyLabel.parkingLotAssignmentLocalDatetime
	},
	{
		id: ActionLogDescriptionPropertyName.parkedMinutesDescription,
		label: ParkingCategoryActionLogDescriptionPropertyLabel.parkedMinutesDescription
	},
	{
		id: ActionLogDescriptionPropertyName.parkingLotLabel,
		label: ParkingCategoryActionLogDescriptionPropertyLabel.parkingLotLabel
	},
	{
		id: ActionLogDescriptionPropertyName.propertyLabel,
		label: ParkingCategoryActionLogDescriptionPropertyLabel.propertyLabel
	},
	{
		id: ActionLogDescriptionPropertyName.carPlate,
		label: ParkingCategoryActionLogDescriptionPropertyLabel.carPlate
	},
	{
		id: ActionLogDescriptionPropertyName.typeDescription,
		label: ParkingCategoryActionLogDescriptionPropertyLabel.typeDescription
	},
	{
		id: ActionLogDescriptionPropertyName.localDatetime,
		label: ParkingCategoryActionLogDescriptionPropertyLabel.localDatetime
	},
	{
		id: ActionLogDescriptionPropertyName.userFullName,
		label: ParkingCategoryActionLogDescriptionPropertyLabel.userFullName
	},
	{
		id: ActionLogDescriptionPropertyName.description,
		label: ParkingCategoryActionLogDescriptionPropertyLabel.description
	},
	{
		id: ActionLogDescriptionPropertyName.wasParkingLotFinedDescription,
		label: ParkingCategoryActionLogDescriptionPropertyLabel.wasParkingLotFinedDescription
	}
];

const deliveryCategoryHeaderRow: HeaderRow = [
	{
		id: ActionLogDescriptionPropertyName.localDatetime,
		label: DeliveryCategoryActionLogDescriptionPropertyLabel.localDatetime
	},
	{
		id: ActionLogDescriptionPropertyName.typeDescription,
		label: DeliveryCategoryActionLogDescriptionPropertyLabel.typeDescription
	},
	{
		id: ActionLogDescriptionPropertyName.residentFullName,
		label: DeliveryCategoryActionLogDescriptionPropertyLabel.residentFullName
	},
	{
		id: ActionLogDescriptionPropertyName.residentIdentification,
		label: DeliveryCategoryActionLogDescriptionPropertyLabel.residentIdentification
	},
	{
		id: ActionLogDescriptionPropertyName.propertyLabel,
		label: DeliveryCategoryActionLogDescriptionPropertyLabel.propertyLabel
	},
	{
		id: ActionLogDescriptionPropertyName.packagesCount,
		label: DeliveryCategoryActionLogDescriptionPropertyLabel.packagesCount
	},
	{
		id: ActionLogDescriptionPropertyName.pickingUpResidentFullName,
		label: DeliveryCategoryActionLogDescriptionPropertyLabel.pickingUpResidentFullName
	},
	{
		id: ActionLogDescriptionPropertyName.userFullName,
		label: DeliveryCategoryActionLogDescriptionPropertyLabel.userFullName
	},
	{
		id: ActionLogDescriptionPropertyName.description,
		label: DeliveryCategoryActionLogDescriptionPropertyLabel.description
	}
];

const commonAreaReservationCategoryHeaderRow: HeaderRow = [
	{
		id: ActionLogDescriptionPropertyName.localDatetime,
		label: CommonAreaReservationCategoryActionLogDescriptionPropertyLabel.localDatetime
	},
	{
		id: ActionLogDescriptionPropertyName.typeDescription,
		label: CommonAreaReservationCategoryActionLogDescriptionPropertyLabel.typeDescription
	},
	{
		id: ActionLogDescriptionPropertyName.reservedCommonAreaTypeLabel,
		label: CommonAreaReservationCategoryActionLogDescriptionPropertyLabel.reservedCommonAreaTypeLabel
	},
	{
		id: ActionLogDescriptionPropertyName.reservationDate,
		label: CommonAreaReservationCategoryActionLogDescriptionPropertyLabel.reservationDate
	},
	{
		id: ActionLogDescriptionPropertyName.reservationTimeRangeDescription,
		label: CommonAreaReservationCategoryActionLogDescriptionPropertyLabel.reservationTimeRangeDescription
	},
	{
		id: ActionLogDescriptionPropertyName.propertyLabel,
		label: CommonAreaReservationCategoryActionLogDescriptionPropertyLabel.propertyLabel
	},
	{
		id: ActionLogDescriptionPropertyName.residentFullName,
		label: CommonAreaReservationCategoryActionLogDescriptionPropertyLabel.residentFullName
	},
	{
		id: ActionLogDescriptionPropertyName.userFullName,
		label: CommonAreaReservationCategoryActionLogDescriptionPropertyLabel.userFullName
	},
	{
		id: ActionLogDescriptionPropertyName.description,
		label: CommonAreaReservationCategoryActionLogDescriptionPropertyLabel.description
	}
];

const logEntryCategoryHeaderRow: HeaderRow = [
	{
		id: ActionLogDescriptionPropertyName.localDatetime,
		label: LogEntryCategoryActionLogDescriptionPropertyLabel.localDatetime
	},
	{
		id: ActionLogDescriptionPropertyName.typeDescription,
		label: LogEntryCategoryActionLogDescriptionPropertyLabel.typeDescription
	},
	{
		id: ActionLogDescriptionPropertyName.logEntryTypeCategoryDescription,
		label: LogEntryCategoryActionLogDescriptionPropertyLabel.logEntryTypeCategoryDescription
	},
	{
		id: ActionLogDescriptionPropertyName.logEntryTypeLabel,
		label: LogEntryCategoryActionLogDescriptionPropertyLabel.logEntryTypeLabel
	},
	{
		id: ActionLogDescriptionPropertyName.logEntryDescription,
		label: LogEntryCategoryActionLogDescriptionPropertyLabel.logEntryDescription
	},
	{
		id: ActionLogDescriptionPropertyName.propertyLabel,
		label: LogEntryCategoryActionLogDescriptionPropertyLabel.propertyLabel
	},
	{
		id: ActionLogDescriptionPropertyName.userFullName,
		label: LogEntryCategoryActionLogDescriptionPropertyLabel.userFullName
	},
	{
		id: ActionLogDescriptionPropertyName.description,
		label: LogEntryCategoryActionLogDescriptionPropertyLabel.description
	}
];

const invitationCategoryHeaderRow: HeaderRow = [
	{
		id: ActionLogDescriptionPropertyName.localDatetime,
		label: InvitationCategoryActionLogDescriptionPropertyLabel.localDatetime
	},
	{
		id: ActionLogDescriptionPropertyName.invitationLocalDatetime,
		label: InvitationCategoryActionLogDescriptionPropertyLabel.invitationLocalDatetime
	},
	{
		id: ActionLogDescriptionPropertyName.invitationConfirmationLocalDatetime,
		label: InvitationCategoryActionLogDescriptionPropertyLabel.invitationConfirmationLocalDatetime
	},
	{
		id: ActionLogDescriptionPropertyName.typeDescription,
		label: InvitationCategoryActionLogDescriptionPropertyLabel.typeDescription
	},
	{
		id: ActionLogDescriptionPropertyName.hostFullName,
		label: InvitationCategoryActionLogDescriptionPropertyLabel.hostFullName
	},
	{
		id: ActionLogDescriptionPropertyName.visitorRUT,
		label: InvitationCategoryActionLogDescriptionPropertyLabel.visitorRUT
	},
	{
		id: ActionLogDescriptionPropertyName.visitorName,
		label: InvitationCategoryActionLogDescriptionPropertyLabel.visitorName
	},
	{
		id: ActionLogDescriptionPropertyName.eventName,
		label: InvitationCategoryActionLogDescriptionPropertyLabel.eventName
	},
	{
		id: ActionLogDescriptionPropertyName.isGroupInvitationDescription,
		label: InvitationCategoryActionLogDescriptionPropertyLabel.isGroupInvitationDescription
	},
	{
		id: ActionLogDescriptionPropertyName.carPlate,
		label: InvitationCategoryActionLogDescriptionPropertyLabel.carPlate
	},
	{
		id: ActionLogDescriptionPropertyName.userFullName,
		label: InvitationCategoryActionLogDescriptionPropertyLabel.userFullName
	},
	{
		id: ActionLogDescriptionPropertyName.description,
		label: InvitationCategoryActionLogDescriptionPropertyLabel.description
	}
];

const userCategoryHeaderRow: HeaderRow = [
	{
		id: ActionLogDescriptionPropertyName.localDatetime,
		label: UserCategoryActionLogDescriptionPropertyLabel.localDatetime
	},
	{
		id: ActionLogDescriptionPropertyName.typeDescription,
		label: UserCategoryActionLogDescriptionPropertyLabel.typeDescription
	},
	{
		id: ActionLogDescriptionPropertyName.editedUserPasswordDescription,
		label: UserCategoryActionLogDescriptionPropertyLabel.editedUserPasswordDescription
	},
	{
		id: ActionLogDescriptionPropertyName.editedUserEnrollmentPrivilegeDescription,
		label: UserCategoryActionLogDescriptionPropertyLabel.editedUserEnrollmentPrivilegeDescription
	},
	{
		id: ActionLogDescriptionPropertyName.userFullName,
		label: UserCategoryActionLogDescriptionPropertyLabel.userFullName
	},
	{
		id: ActionLogDescriptionPropertyName.description,
		label: UserCategoryActionLogDescriptionPropertyLabel.description
	}
];

const possessionCategoryHeaderRow: HeaderRow = [
	{
		id: ActionLogDescriptionPropertyName.localDatetime,
		label: PossessionCategoryActionLogDescriptionPropertyLabel.localDatetime
	},
	{
		id: ActionLogDescriptionPropertyName.typeDescription,
		label: PossessionCategoryActionLogDescriptionPropertyLabel.typeDescription
	},
	{
		id: ActionLogDescriptionPropertyName.propertyLabel,
		label: PossessionCategoryActionLogDescriptionPropertyLabel.propertyLabel
	},
	{
		id: ActionLogDescriptionPropertyName.possessionLabel,
		label: PossessionCategoryActionLogDescriptionPropertyLabel.possessionLabel
	},
	{
		id: ActionLogDescriptionPropertyName.possessionTypeLabel,
		label: PossessionCategoryActionLogDescriptionPropertyLabel.possessionTypeLabel
	},
	{
		id: ActionLogDescriptionPropertyName.userFullName,
		label: PossessionCategoryActionLogDescriptionPropertyLabel.userFullName
	},
	{
		id: ActionLogDescriptionPropertyName.description,
		label: PossessionCategoryActionLogDescriptionPropertyLabel.description
	}
];

const useReportsTable = () => {
	function getHeaderRowFromActionLogTypesCategory(
		actionLogTypesCategory: ActionLogTypesCategory
	): HeaderRow | undefined {
		switch (actionLogTypesCategory) {
			case ActionLogTypesCategory.visit:
				return visitCategoryHeaderRow;
			case ActionLogTypesCategory.resident:
				return residentCategoryHeaderRow;
			case ActionLogTypesCategory.maintenance:
				return maintenanceCategoryHeaderRow;
			case ActionLogTypesCategory.parking:
				return parkingCategoryHeaderRow;
			case ActionLogTypesCategory.delivery:
				return deliveryCategoryHeaderRow;
			case ActionLogTypesCategory.commonAreaReservation:
				return commonAreaReservationCategoryHeaderRow;
			case ActionLogTypesCategory.logEntry:
				return logEntryCategoryHeaderRow;
			case ActionLogTypesCategory.invitation:
				return invitationCategoryHeaderRow;
			case ActionLogTypesCategory.user:
				return userCategoryHeaderRow;
			case ActionLogTypesCategory.possession:
				return possessionCategoryHeaderRow;
			default:
				return undefined;
		}
	}

	function getReportEntriesFromActionLogsWithCategory(
		actionLogs: ActionLogDescription[],
		category: ActionLogTypesCategory
	): Record<string, any>[] {
		let reportEntries: Record<string, any>[];

		switch (category) {
			case ActionLogTypesCategory.visit:
				reportEntries = getVisitReportEntriesFromActionLogs(actionLogs);
				break;
			case ActionLogTypesCategory.resident:
				reportEntries = getResidentReportEntriesFromActionLogs(actionLogs);
				break;
			case ActionLogTypesCategory.maintenance:
				reportEntries = getMaintenanceReportEntriesFromActionLogs(actionLogs);
				break;
			case ActionLogTypesCategory.parking:
				reportEntries = getParkingReportEntriesFromActionLogs(actionLogs);
				break;
			case ActionLogTypesCategory.delivery:
				reportEntries = getDeliveryReportEntriesFromActionLogs(actionLogs);
				break;
			case ActionLogTypesCategory.commonAreaReservation:
				reportEntries = getCommonAreaReservationReportEntriesFromActionLogs(actionLogs);
				break;
			case ActionLogTypesCategory.logEntry:
				reportEntries = getLogEntryReportEntriesFromActionLogs(actionLogs);
				break;
			case ActionLogTypesCategory.invitation:
				reportEntries = getInvitationReportEntriesFromActionLogs(actionLogs);
				break;
			case ActionLogTypesCategory.user:
				reportEntries = getUserReportEntriesFromActionLogs(actionLogs);
				break;
			case ActionLogTypesCategory.possession:
				reportEntries = getPossessionReportEntriesFromActionLogs(actionLogs);
				break;
		}

		return reportEntries;
	}

	function getVisitReportEntriesFromActionLogs(actionLogs: ActionLogDescription[]): Record<string, any>[] {
		return actionLogs.map(actionLog => ({
			[VisitCategoryActionLogDescriptionPropertyLabel.localDatetime]: actionLog.local_datetime,
			[VisitCategoryActionLogDescriptionPropertyLabel.typeDescription]: actionLog.type_description,
			[VisitCategoryActionLogDescriptionPropertyLabel.visitIdentification]: actionLog.visit_identification,
			[VisitCategoryActionLogDescriptionPropertyLabel.visitFullName]: actionLog.visit_full_name,
			[VisitCategoryActionLogDescriptionPropertyLabel.visitType]: actionLog.visit_type,
			[VisitCategoryActionLogDescriptionPropertyLabel.propertyLabel]: actionLog.property_label,
			[VisitCategoryActionLogDescriptionPropertyLabel.carPlate]: actionLog.car_plate,
			[VisitCategoryActionLogDescriptionPropertyLabel.visitDeliveryTypeLabel]:
				actionLog.visit_delivery_type_label,
			[VisitCategoryActionLogDescriptionPropertyLabel.userFullName]: actionLog.user_full_name,
			[VisitCategoryActionLogDescriptionPropertyLabel.entryMethodDescription]: actionLog.entry_method_description,
			[VisitCategoryActionLogDescriptionPropertyLabel.description]: actionLog.description
		}));
	}

	function getResidentReportEntriesFromActionLogs(actionLogs: ActionLogDescription[]): Record<string, any>[] {
		return actionLogs.map(actionLog => ({
			[ResidentCategoryActionLogDescriptionPropertyLabel.localDatetime]: actionLog.local_datetime,
			[ResidentCategoryActionLogDescriptionPropertyLabel.typeDescription]: actionLog.type_description,
			[ResidentCategoryActionLogDescriptionPropertyLabel.propertyLabel]: actionLog.property_label,
			[ResidentCategoryActionLogDescriptionPropertyLabel.residentFullName]: actionLog.resident_full_name,
			[ResidentCategoryActionLogDescriptionPropertyLabel.residentIndentification]:
				actionLog.resident_identification,
			[ResidentCategoryActionLogDescriptionPropertyLabel.residentType]: actionLog.resident_type,
			[ResidentCategoryActionLogDescriptionPropertyLabel.residentEmail]: actionLog.resident_email,
			[ResidentCategoryActionLogDescriptionPropertyLabel.residentPhoneNumber]: actionLog.resident_phone_number,
			[ResidentCategoryActionLogDescriptionPropertyLabel.residentNationality]: actionLog.resident_nationality,
			[ResidentCategoryActionLogDescriptionPropertyLabel.carPlate]: actionLog.car_plate,
			[ResidentCategoryActionLogDescriptionPropertyLabel.residentSecondCarPlate]:
				actionLog.resident_second_car_plate,
			[ResidentCategoryActionLogDescriptionPropertyLabel.residentParkingLotLabel]:
				actionLog.resident_parking_lot_label,
			[ResidentCategoryActionLogDescriptionPropertyLabel.residentSecondParkingLotLabel]:
				actionLog.resident_second_parking_lot_label,
			[ResidentCategoryActionLogDescriptionPropertyLabel.userFullName]: actionLog.user_full_name,
			[ResidentCategoryActionLogDescriptionPropertyLabel.description]: actionLog.description
		}));
	}

	function getMaintenanceReportEntriesFromActionLogs(actionLogs: ActionLogDescription[]): Record<string, any>[] {
		return actionLogs.map(actionLog => ({
			[MaintenanceCategoryActionLogDescriptionPropertyLabel.localDatetime]: actionLog.local_datetime,
			[MaintenanceCategoryActionLogDescriptionPropertyLabel.typeDescription]: actionLog.type_description,
			[MaintenanceCategoryActionLogDescriptionPropertyLabel.maintenanceCompanyName]:
				actionLog.maintenance_company_name,
			[MaintenanceCategoryActionLogDescriptionPropertyLabel.maintenanceTypeLabel]:
				actionLog.maintenance_type_label,
			[MaintenanceCategoryActionLogDescriptionPropertyLabel.technicianFullName]: actionLog.technician_full_name,
			[MaintenanceCategoryActionLogDescriptionPropertyLabel.technicianRUT]: actionLog.technician_rut,
			[MaintenanceCategoryActionLogDescriptionPropertyLabel.carPlate]: actionLog.car_plate,
			[MaintenanceCategoryActionLogDescriptionPropertyLabel.userFullName]: actionLog.user_full_name,
			[MaintenanceCategoryActionLogDescriptionPropertyLabel.entryMethodDescription]:
				actionLog.entry_method_description,
			[MaintenanceCategoryActionLogDescriptionPropertyLabel.description]: actionLog.description
		}));
	}

	function getParkingReportEntriesFromActionLogs(actionLogs: ActionLogDescription[]): Record<string, any>[] {
		return actionLogs.map(actionLog => ({
			[ParkingCategoryActionLogDescriptionPropertyLabel.parkingLotAssignmentLocalDatetime]:
				actionLog.parking_lot_assignment_local_datetime,
			[ParkingCategoryActionLogDescriptionPropertyLabel.parkedMinutesDescription]:
				actionLog.parked_minutes_description,
			[ParkingCategoryActionLogDescriptionPropertyLabel.parkingLotLabel]: actionLog.parking,
			[ParkingCategoryActionLogDescriptionPropertyLabel.propertyLabel]: actionLog.property_label,
			[ParkingCategoryActionLogDescriptionPropertyLabel.carPlate]: actionLog.car_plate,
			[ParkingCategoryActionLogDescriptionPropertyLabel.typeDescription]: actionLog.type_description,
			[ParkingCategoryActionLogDescriptionPropertyLabel.localDatetime]: actionLog.local_datetime,
			[ParkingCategoryActionLogDescriptionPropertyLabel.userFullName]: actionLog.user_full_name,
			[ParkingCategoryActionLogDescriptionPropertyLabel.description]: actionLog.description,
			[ParkingCategoryActionLogDescriptionPropertyLabel.wasParkingLotFinedDescription]:
				actionLog.was_parking_lot_fined_description
		}));
	}

	function getDeliveryReportEntriesFromActionLogs(actionLogs: ActionLogDescription[]): Record<string, any>[] {
		return actionLogs.map(actionLog => ({
			[DeliveryCategoryActionLogDescriptionPropertyLabel.localDatetime]: actionLog.local_datetime,
			[DeliveryCategoryActionLogDescriptionPropertyLabel.typeDescription]: actionLog.type_description,
			[DeliveryCategoryActionLogDescriptionPropertyLabel.residentFullName]: actionLog.resident_full_name,
			[DeliveryCategoryActionLogDescriptionPropertyLabel.residentIdentification]:
				actionLog.resident_identification,
			[DeliveryCategoryActionLogDescriptionPropertyLabel.propertyLabel]: actionLog.property_label,
			[DeliveryCategoryActionLogDescriptionPropertyLabel.packagesCount]: actionLog.packages_count,
			[DeliveryCategoryActionLogDescriptionPropertyLabel.pickingUpResidentFullName]:
				actionLog.picking_up_resident_full_name,
			[DeliveryCategoryActionLogDescriptionPropertyLabel.userFullName]: actionLog.user_full_name,
			[DeliveryCategoryActionLogDescriptionPropertyLabel.description]: actionLog.description
		}));
	}

	function getCommonAreaReservationReportEntriesFromActionLogs(
		actionLogs: ActionLogDescription[]
	): Record<string, any>[] {
		return actionLogs.map(actionLog => ({
			[CommonAreaReservationCategoryActionLogDescriptionPropertyLabel.localDatetime]: actionLog.local_datetime,
			[CommonAreaReservationCategoryActionLogDescriptionPropertyLabel.typeDescription]:
				actionLog.type_description,
			[CommonAreaReservationCategoryActionLogDescriptionPropertyLabel.reservedCommonAreaTypeLabel]:
				actionLog.reserved_common_area_type_label,
			[CommonAreaReservationCategoryActionLogDescriptionPropertyLabel.reservationDate]:
				actionLog.reservation_date,
			[CommonAreaReservationCategoryActionLogDescriptionPropertyLabel.reservationTimeRangeDescription]:
				actionLog.reservation_time_range_description,
			[CommonAreaReservationCategoryActionLogDescriptionPropertyLabel.propertyLabel]: actionLog.property_label,
			[CommonAreaReservationCategoryActionLogDescriptionPropertyLabel.residentFullName]:
				actionLog.resident_full_name,
			[CommonAreaReservationCategoryActionLogDescriptionPropertyLabel.userFullName]: actionLog.user_full_name,
			[CommonAreaReservationCategoryActionLogDescriptionPropertyLabel.description]: actionLog.description
		}));
	}

	function getLogEntryReportEntriesFromActionLogs(actionLogs: ActionLogDescription[]): Record<string, any>[] {
		return actionLogs.map(actionLog => ({
			[LogEntryCategoryActionLogDescriptionPropertyLabel.localDatetime]: actionLog.local_datetime,
			[LogEntryCategoryActionLogDescriptionPropertyLabel.typeDescription]: actionLog.type_description,
			[LogEntryCategoryActionLogDescriptionPropertyLabel.logEntryTypeCategoryDescription]:
				actionLog.log_entry_type_category_description,
			[LogEntryCategoryActionLogDescriptionPropertyLabel.logEntryTypeLabel]: actionLog.log_entry_type_label,
			[LogEntryCategoryActionLogDescriptionPropertyLabel.logEntryDescription]: actionLog.log_entry_description,
			[LogEntryCategoryActionLogDescriptionPropertyLabel.propertyLabel]: actionLog.property_label,
			[LogEntryCategoryActionLogDescriptionPropertyLabel.userFullName]: actionLog.user_full_name,
			[LogEntryCategoryActionLogDescriptionPropertyLabel.description]: actionLog.description
		}));
	}

	function getInvitationReportEntriesFromActionLogs(actionLogs: ActionLogDescription[]): Record<string, any>[] {
		return actionLogs.map(actionLog => ({
			[InvitationCategoryActionLogDescriptionPropertyLabel.localDatetime]: actionLog.local_datetime,
			[InvitationCategoryActionLogDescriptionPropertyLabel.invitationLocalDatetime]:
				actionLog.invitation_local_datetime,
			[InvitationCategoryActionLogDescriptionPropertyLabel.invitationConfirmationLocalDatetime]:
				actionLog.invitation_confirmation_local_datetime,
			[InvitationCategoryActionLogDescriptionPropertyLabel.typeDescription]: actionLog.type_description,
			[InvitationCategoryActionLogDescriptionPropertyLabel.hostFullName]: actionLog.host_full_name,
			[InvitationCategoryActionLogDescriptionPropertyLabel.visitorRUT]: actionLog.visitor_rut,
			[InvitationCategoryActionLogDescriptionPropertyLabel.visitorName]: actionLog.visitorName,
			[InvitationCategoryActionLogDescriptionPropertyLabel.eventName]: actionLog.event_name,
			[InvitationCategoryActionLogDescriptionPropertyLabel.isGroupInvitationDescription]:
				actionLog.is_group_invitation_description,
			[InvitationCategoryActionLogDescriptionPropertyLabel.carPlate]: actionLog.car_plate,
			[InvitationCategoryActionLogDescriptionPropertyLabel.userFullName]: actionLog.user_full_name,
			[InvitationCategoryActionLogDescriptionPropertyLabel.description]: actionLog.description
		}));
	}

	function getUserReportEntriesFromActionLogs(actionLogs: ActionLogDescription[]): Record<string, any>[] {
		return actionLogs.map(actionLog => ({
			[UserCategoryActionLogDescriptionPropertyLabel.localDatetime]: actionLog.local_datetime,
			[UserCategoryActionLogDescriptionPropertyLabel.typeDescription]: actionLog.type_description,
			[UserCategoryActionLogDescriptionPropertyLabel.editedUserPasswordDescription]:
				actionLog.edited_user_password_description,
			[UserCategoryActionLogDescriptionPropertyLabel.editedUserEnrollmentPrivilegeDescription]:
				actionLog.edited_user_enrollment_privilege_description,
			[UserCategoryActionLogDescriptionPropertyLabel.userFullName]: actionLog.user_full_name,
			[UserCategoryActionLogDescriptionPropertyLabel.description]: actionLog.description
		}));
	}

	function getPossessionReportEntriesFromActionLogs(actionLogs: ActionLogDescription[]): Record<string, any>[] {
		return actionLogs.map(actionLog => ({
			[PossessionCategoryActionLogDescriptionPropertyLabel.localDatetime]: actionLog.local_datetime,
			[PossessionCategoryActionLogDescriptionPropertyLabel.typeDescription]: actionLog.type_description,
			[PossessionCategoryActionLogDescriptionPropertyLabel.propertyLabel]: actionLog.property_label,
			[PossessionCategoryActionLogDescriptionPropertyLabel.possessionLabel]: actionLog.possession_label,
			[PossessionCategoryActionLogDescriptionPropertyLabel.possessionTypeLabel]: actionLog.possession_type_label,
			[PossessionCategoryActionLogDescriptionPropertyLabel.userFullName]: actionLog.user_full_name,
			[PossessionCategoryActionLogDescriptionPropertyLabel.description]: actionLog.description
		}));
	}

	return {
		getHeaderRowFromActionLogTypesCategory,
		getReportEntriesFromActionLogsWithCategory
	};
};

export default useReportsTable;
