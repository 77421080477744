import { gql } from "@apollo/client";

export type SupportContactSchema = {
  id: number;
  name: string;
  phone_number: string;
  description: string;
  is_active: boolean;
  is_main: boolean;
};

export type CreateSupportContactData = {
  CreateSupportContact: SupportContactSchema[];
};

export type GetAllSupportContactsData = {
  GetAllSupportContacts: SupportContactSchema[];
};

export type UpdateSupportContactData = {
  UpdateSupportContact: SupportContactSchema[];
};

export type DeleteSupportContactData = {
  DeleteSupportContact: boolean;
};

export type ToggleSupportContactActiveStatusData = {
  ToggleSupportContactActiveStatus: boolean;
};

export type ToggleSupportContactMainStatusData = {
  ToggleSupportContactMainStatus: boolean;
};

const graphQLSchema = `
  id
  name
  phone_number
  description
  is_active
  is_main
`;

export const CREATE_SUPPORT_CONTACT = gql`
  mutation CreateSupportContact($creationInput: SupportContactCreationInput!) {
    CreateSupportContact(creationInput: $creationInput) {
      ${graphQLSchema}
    }
  }
`;

export const GET_ALL_SUPPORT_CONTACTS = gql`
  query GetAllSupportContacts {
    GetAllSupportContacts {
      ${graphQLSchema}
    }
  }
`;

export const UPDATE_SUPPORT_CONTACT = gql`
  mutation UpdateSupportContact($id: Int!, $updateInput: SupportContactUpdateInput!
  ) {
    UpdateSupportContact(id: $id, updateInput: $updateInput) {
      ${graphQLSchema}
    }
  }
`;

export const DELETE_SUPPORT_CONTACT = gql`
  mutation DeleteSupportContact($id: Int!) {
    DeleteSupportContact(id: $id)
  }
`;

export const TOGGLE_SUPPORT_CONTACT_ACTIVE_STATUS = gql`
  mutation ToggleSupportContactActiveStatus($id: Int!) {
    ToggleSupportContactActiveStatus(id: $id)
  }
`;

export const TOGGLE_SUPPORT_CONTACT_MAIN_STATUS = gql`
  mutation ToggleSupportContactMainStatus($id: Int!) {
    ToggleSupportContactMainStatus(id: $id)
  }
`;
