import React, { FC } from "react";
import { Box, Modal } from "@mui/material";

import type { PLTFieldErrors } from "../../../../Types";
import type { PropertySchema } from "../../CondosProperty/GraphQL";
import type { ParkingSchema } from "../../CondosParking/Querys";

import FormResident, { type ResidentForm } from "../FormResident";

type props = {
	visible: boolean;
	errorsField: PLTFieldErrors;
	handleClose: () => void;
	onCancel: () => void;
	onAdd: (form: ResidentForm) => void;
	propertiesList: Array<PropertySchema>;
	listParking: ParkingSchema[];
};

const ModalResident: FC<props> = ({
	visible,
	errorsField,
	handleClose,
	onAdd,
	onCancel,
	propertiesList,
	listParking
}) => (
	<Modal open={visible} onClose={handleClose}>
		<Box
			sx={{
				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				width: "50%",
				bgcolor: "background.paper",
				boxShadow: 24,
				p: 4,
				maxHeight: "80%",
				overflow: "auto"
			}}
		>
			<FormResident
				errorsField={errorsField}
				listParking={listParking}
				onCancel={onCancel}
				onSubmit={onAdd}
				propertiesList={propertiesList}
			/>
		</Box>
	</Modal>
);

export default ModalResident;
