export type PLTFieldErrors = {
	message: string;
	code_error: number;
	key: string;
}[];

export enum CodeError {
	Unauthorized = 401,
	Forbidden = 403,
	InternalError = 500
}

export enum UserRole {
	plt = "PLT",
	admin = "ADMIN",
	worker = "WORKER"
}
