import { useCallback, useEffect, type FC } from "react";
import { Add, Close } from "@mui/icons-material";

import { useTheme, Box, Button, ButtonGroup, Grid, Modal, TextField, Typography } from "@mui/material";

import { Controller, useForm } from "react-hook-form";
import uuid from "react-uuid";

import type { PossessionTypeSchema } from "../Query";
import type { PLTFieldErrors } from "../../../../Types";

type PossessionTypeUpdateModalProps = {
	isVisible: boolean;
	errorsField: PLTFieldErrors;
	possessionType: PossessionTypeSchema;
	onSuccess: (possessionTypeID: number, label?: string) => void;
	onClose: () => void;
};

export const PossessionTypeCreationModal: FC<PossessionTypeUpdateModalProps> = ({
	isVisible,
	errorsField,
	possessionType,
	onSuccess,
	onClose
}) => {
	const theme = useTheme();

	const { control, getValues, setValue, reset } = useForm<{ label: string }>({
		defaultValues: {
			label: ""
		}
	});

	const isError = useCallback((key: string) => errorsField.some(error => error.key === key), [errorsField]);

	const getErrorText = useCallback(
		(key: string) => errorsField?.find(error => error.key === key)?.message ?? "",
		[errorsField]
	);

	useEffect(() => {
		if (isVisible) {
			setValue("label", possessionType.label);
		} else {
			reset();
		}
	}, [isVisible, setValue, possessionType]);

	return (
		<Modal open={isVisible} onClose={onClose}>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: 400,
					bgcolor: "background.paper",
					boxShadow: 24,
					p: 4
				}}
			>
				<Grid container flexDirection={"row"}>
					<Typography variant="h5" color={theme.palette.primary.main} textAlign={"center"}>
						Actualizar Tipo de Posesión
					</Typography>

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							name="label"
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={value}
									onChange={onChange}
									label="Etiqueta"
									variant="outlined"
									error={isError("label")}
									helperText={getErrorText("label")}
								/>
							)}
						/>
					</Grid>
				</Grid>

				<ButtonGroup
					style={{ paddingTop: 20, paddingBottom: 20, alignSelf: "center" }}
					variant="text"
					aria-label="text button group"
				>
					<Button onClick={onClose}>
						<Close /> Cancelar
					</Button>

					<Button onClick={() => onSuccess(possessionType.id, getValues().label)}>
						<Add /> Actualizar
					</Button>
				</ButtonGroup>
			</Box>
		</Modal>
	);
};

export default PossessionTypeCreationModal;
