import { gql } from "@apollo/client";
import { CommonAreasSchema } from "../../CommonAreas/Querys";

export interface CondoSchema {
	[key: string]: any;

	id: number;
	name: string;
	pairing_code: number;
	maximum_parking_time: number;
	inactivity_time: number;
	address: string;
	primary_phone_number: string;
	secondary_phone_number?: string;
	devices_number: number;
	supports_plat: boolean;
	supports_common_area_reservation_time_ranges: boolean;
	should_use_alphanumeric_keyboard_for_property_search: boolean;
	should_show_common_expenses_to_residents: boolean;

	CommonAreas: CommonAreasSchema[];
}

export type RequestCreateCondos = {
	RegisterCondo: null | undefined | Array<CondoSchema>;
};

export type RequestUpdateCondos = {
	UpdateCondo: null | undefined | Array<CondoSchema>;
};

export type RequestDeleteCondos = {
	DeleteCondo: null | undefined | Array<CondoSchema>;
};

export type GetAllCondosData = {
	GetAllCondos: CondoSchema[];
};

export type ToggleCommonAreaReservationTimeRangeSupport = {
	ToggleCommonAreaReservationTimeRangeSupport: CondoSchema[];
};

export type toggleAlphanumeriKeyboardUsageResponse = {
	toggleAlphanumeriKeyboardUsage: CondoSchema[];
};

export type ToggleCommonExpensesVisibilityResponseData = {
	toggleCommonExpensesVisibility: CondoSchema[];
};

const graphQLSchema = `
    id
    name
    pairing_code
    maximum_parking_time
    inactivity_time
    address
    primary_phone_number
    secondary_phone_number
    devices_number
    supports_plat
    supports_common_area_reservation_time_ranges
    should_use_alphanumeric_keyboard_for_property_search
    should_show_common_expenses_to_residents

    CommonAreas{
        id
        label
    }
`;

export const mutation_register_condos = gql`
    mutation RegisterCondo($FormCreateCondo:FormCreateCondo!){
        RegisterCondo(FormCreateCondo:$FormCreateCondo){
            ${graphQLSchema}
        }
    }
`;

export const mutation_update_condos = gql`
    mutation UpdateCondo($FormUpdateCondo:FormUpdateCondo!){
        UpdateCondo(FormUpdateCondo:$FormUpdateCondo){
            ${graphQLSchema}
        }
    }
`;

export const mutation_delete_condos = gql`
    mutation DeleteCondo($condo_id: Int!){
        DeleteCondo(condo_id:$condo_id){
            ${graphQLSchema}
        }
    }
`;

export const query_get_all_condos = gql`
    query GetAllCondos{
        GetAllCondos{
            ${graphQLSchema}
        }
    }
`;

export const mutation_toggle_common_area_reservation_time_range_support = gql`
    mutation ToggleCommonAreaReservationTimeRangeSupport($id: Int!) {
        ToggleCommonAreaReservationTimeRangeSupport(id: $id) {
            ${graphQLSchema}
        }
    }
`;
export const mutation_toggle_alphanumeri_keyboard_usage = gql`
    mutation ToggleAlphanumeriKeyboardUsage($id: Int!) {
        toggleAlphanumeriKeyboardUsage(id: $id) {
            ${graphQLSchema}
        }
    }
`;

export const TOGGLE_COMMON_EXPENSES_VISIBILITY = gql`
    mutation ToggleCommonExpensesVisibility($id: Int!) {
        toggleCommonExpensesVisibility(id: $id) {
            ${graphQLSchema}
        }
    }
`;
