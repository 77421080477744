import { useState, useCallback, useEffect, memo, type FC } from "react";
import useGraphQL from "../../../../../Hooks/useGraphQL";
import { GET_ACTION_LOGS, type GetActionLogsResponseData, type ActionLog } from "../../GraphQL";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import DateRangePicker from "../../../../Components/DateRangePicker/DateRangePicker";
import { CircularProgress } from "@mui/material";
import CustomBarChart, { type BarData, type BaseBarChartData } from "../../../../Components/CustomBarChart";
import { ActionLogType } from "../../../Reports/types";
import { GET_ACTIVE_WORKERS_BY_CONDO, type GetActiveWorkersByCondoResponseData } from "../../../CondosWorkers/GraphQL";
import chroma from "chroma-js";
import groupBy from "object.groupby";

import type { DateRange } from "../../../../Components/DateRangePicker/DateRangePicker";

type WorkerVisitsChartData = BaseBarChartData & { [key: string]: number };
type WorkersColors = Record<number, string>;

const colorPaletteName = "Dark2";

const WorkerVisitsBarChart: FC<{ condoID: number }> = ({ condoID }) => {
	const [workerVisitsData, setWorkerVisitsData] = useState<WorkerVisitsChartData[]>([]);
	const [workersColors, setWorkersColors] = useState<WorkersColors>({});
	const [workerVisitsBarsData, setWorkerVisitsBarsData] = useState<BarData[]>([]);

	const { useLazyGraphQuery } = useGraphQL();

	const [getActiveWorkersByCondo, { loading: isLoadingWorkers }] =
		useLazyGraphQuery<GetActiveWorkersByCondoResponseData>(GET_ACTIVE_WORKERS_BY_CONDO);

	const [getVisitsLogs, { data: visitsLogs, loading: isLoadingVisitsData }] =
		useLazyGraphQuery<GetActionLogsResponseData>(GET_ACTION_LOGS);

	const { setValue, getValues } = useForm<{
		dateRange: DateRange;
	}>({
		defaultValues: {
			dateRange: {
				startDate: dayjs().subtract(6, "day").toDate(),
				endDate: dayjs().toDate()
			}
		}
	});

	const fetchActiveWorkers = useCallback(
		async (condoID: number) => {
			const response = await getActiveWorkersByCondo({
				variables: { condoID }
			});

			if (response.data) {
				const workers = response.data.activeWorkers;
				const colorPalette = chroma.scale(colorPaletteName).mode("lch").colors(workers.length);
				const newWorkersColors: WorkersColors = {};

				workers.forEach((worker, index) => (newWorkersColors[worker.id] = colorPalette[index]));
				setWorkersColors(newWorkersColors);

				console.log(newWorkersColors);
			}
		},
		[getActiveWorkersByCondo]
	);

	const fetchVisitsLogs = useCallback(
		async (condoID: number, dateRange: DateRange) => {
			await getVisitsLogs({
				variables: {
					queryInput: {
						condoID,
						startDate: dateRange.startDate,
						endDate: dateRange.endDate,
						actionLogTypes: [ActionLogType.newVisit]
					}
				}
			});
		},
		[getVisitsLogs]
	);

	useEffect(() => {
		if (condoID) {
			fetchActiveWorkers(condoID);
			fetchVisitsLogs(condoID, getValues("dateRange"));
		}
	}, [condoID, fetchActiveWorkers, fetchVisitsLogs, getValues]);

	useEffect(() => {
		const actionLogs = visitsLogs?.actionLogs;

		if (!actionLogs) {
			return;
		}

		const actionLogsByDate = groupBy(actionLogs, ({ local_date }) => local_date);
		const allWorkers: Record<number, string> = {};

		setWorkerVisitsData(
			Object.values(actionLogsByDate).map(logsOnDate => {
				const actionLogsByWorker: Partial<Record<string, ActionLog[]>> = groupBy(
					logsOnDate!,
					({ user_full_name }) => user_full_name!
				);

				const workerVisitsCounts = Object.values(actionLogsByWorker).map(logsOfWorker => {
					const workerID = logsOfWorker![0].user_id!;
					const workerName = logsOfWorker![0].user_full_name!;

					allWorkers[workerID] = workerName;

					return { [workerName]: logsOfWorker!.length };
				});

				return Object.assign({ name: logsOnDate![0].local_date }, ...workerVisitsCounts);
			})
		);

		setWorkerVisitsBarsData(
			Object.keys(allWorkers).map(workerID => ({
				barFillColor: workersColors[Number(workerID)],
				dataKey: allWorkers[Number(workerID)],
				rectangleFillColor: "rgba(255, 255, 255, 0.7)",
				rectangleStrokeColor: workersColors[Number(workerID)],
				stackID: "workerStack"
			}))
		);
	}, [visitsLogs, workersColors]);

	const Loader = () => (
		<div style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
			<CircularProgress />
		</div>
	);

	if (!condoID) {
		return <Loader />;
	}

	if (isLoadingWorkers || isLoadingVisitsData) {
		return <Loader />;
	}

	return (
		<section
			style={{
				width: "95%",
				height: 350
			}}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
					paddingBottom: "2%",
					marginTop: "2em"
				}}
			>
				<DateRangePicker
					defaultStartDate={getValues("dateRange").startDate}
					defaultEndDate={getValues("dateRange").endDate}
					shouldHideFutureDates={true}
					onChange={dates => {
						const dateRange = { startDate: dates.startDate, endDate: dates.endDate };

						setValue("dateRange", dateRange);
						fetchVisitsLogs(condoID, dateRange);
					}}
				/>
			</div>

			<CustomBarChart data={workerVisitsData.reverse()} barsData={workerVisitsBarsData} />
		</section>
	);
};

export default memo(WorkerVisitsBarChart);
