import type { UserSchema } from "../Users/GraphQL";
import type { ResidentSchema } from "../CondosResidents/GraphQL";
import type { VisitDeliveryTypeSchema } from "../CondosVisitDeliveryTypes/Querys";
import type { ParkingSchema } from "../CondosParking/Querys";
import type { PropertySchema } from "../CondosProperty/GraphQL";
import type { DeliveryCompanySchema } from "../DeliveryCompanies/Query";
import type { CommonAreaTypeSchema } from "../CondoCommonAreaTypes/Querys";
import type { LogEntryTypeSchema, LogEntryTypeCategory } from "../CondoLogEntryTypes/Query";

export enum ActionLogType {
	newVisit = "newVisit",
	finishedVisit = "finishedVisit",
	visitEmail = "visitEmail",
	visitPhoneCall = "visitPhoneCall",
	newResident = "newResident",
	assignedParkingLot = "assignedParkingLot",
	releasedParkingLot = "releasedParkingLot",
	expiredParkingLot = "expiredParkingLot",
	parkingLotAlert = "parkingLotAlert",
	parkingLotPhoneCall = "parkingLotPhoneCall",
	parkingLotFine = "parkingLotFine",
	newMaintenance = "newMaintenance",
	finishedMaintenance = "finishedMaintenance",
	newDelivery = "newDelivery",
	finishedDelivery = "finishedDelivery",
	newCommonAreaReservation = "newCommonAreaReservation",
	canceledCommonAreaReservation = "canceledCommonAreaReservation",
	newLogEntry = "newLogEntry",
	newInvitation = "newInvitation",
	receivedVisitor = "receivedVisitor",
	editedUser = "editedUser",
	newPossession = "newPossession",
	editedPossession = "editedPossession"
}

export enum ActionLogTypesCategory {
	visit = "visit",
	resident = "resident",
	parking = "parking",
	maintenance = "maintenance",
	delivery = "delivery",
	commonAreaReservation = "commonAreaReservation",
	logEntry = "logEntry",
	invitation = "invitation",
	user = "user",
	possession = "possession"
}

export enum EntryMethod {
	manual = "Manual",
	scan = "Scanner"
}

export enum VisitType {
	private = "private",
	delivery = "delivery",
	services = "services",
	apartmentHotel = "apartmentHotel",
	arrival = "arrival"
}

export enum CommonAreaReservationTimeRange {
	morning = "morning",
	afternoon = "afternoon",
	night = "night",
	allDay = "allDay"
}

export interface VisitSchema {
	id: number;
	personal_identification?: string;
	name: string;
	last_name: string;
	type: VisitType;
	is_in_progress: boolean;
	car_plate?: string;
	resident_id?: number;
	delivery_type_id?: number;
	full_name: string;

	Resident?: ResidentSchema;
	VisitDeliveryType?: VisitDeliveryTypeSchema;
	Parking?: ParkingSchema;
	Properties?: PropertySchema[];
}

export interface MaintenanceSchema {
	id: number;
	technical_rut: string;
	technical_name: string;
	technical_last_name: string;
	company: string;
	is_in_progress: boolean;
	car_plate?: string;
	maintenance_type: string;
	condo_id: number;

	Parking?: ParkingSchema;
}

export interface DeliverySchema {
	id: number;
	packages_count: number;
	is_in_progress: boolean;
	resident_name: string;
	resident_last_name: string;
	condo_id: number | null;
	property_id: number | null;
	resident_id?: number | null;
	company_id: number | null;

	Property?: PropertySchema;
	Resident?: ResidentSchema;
	DeliveryCompany?: DeliveryCompanySchema;
}

export interface CommonAreaReservationSchema {
	id: number;
	date: string;
	time_range: CommonAreaReservationTimeRange;
	was_canceled: boolean;
	common_area_type_id: number | null;
	resident_id: number | null;

	CommonAreaType?: CommonAreaTypeSchema;
	Resident?: ResidentSchema;
}

export interface LogEntrySchema {
	id: number;
	description: string;
	log_entry_type_id: number | null;
	condo_id: number | null;
	resident_id?: number | null;

	LogEntryType?: LogEntryTypeSchema;
	Resident?: ResidentSchema;
}

export interface InvitationSchema {
	id: number;
	full_name_hosts: string;
	visitor_alias?: string;
	event_name?: string;
	visitor_name?: string;
	visitor_last_name?: string;
	visitor_rut?: string;
	visitor_email?: string;
	visitor_phone_number?: string;
	is_group_invitation?: boolean;
	is_visitor_trusted: boolean;
	was_id_card_scanned?: boolean;
	condo_name: string;
	property_label: string;
	property_id: number | null;
	resident_id: number | null;
	parent_invitation_id?: number | null;
	code: number | null;
	utc_timestamp_invitation: number;
	is_confirm?: boolean;
	invitation_used: boolean;
	is_expired: boolean;
	car_plate_visitor?: string;

	visitor_full_name?: string;

	Property?: PropertySchema;
	Resident?: ResidentSchema;
	SubInvitations?: InvitationSchema[];
	ParentInvitation?: InvitationSchema;
}

export interface PossessionTypeSchema {
	id: number;
	label: string;
	is_active: boolean;

	Possessions?: PossessionSchema[];
}

export interface PossessionSchema {
	id: number;
	label: string;
	possession_type_id: number | null;
	property_id: number | null;

	PossessionType?: PossessionTypeSchema;
	Property?: PropertySchema;
}

export interface ActionLogSchema {
	id: number;
	description: string;
	register_type: ActionLogType;
	utc_timestamp: number;
	user_full_name: string;
	property_label?: string;
	resident_full_name?: string;
	company_name?: string;
	car_plate?: string;
	parking?: string;
	entry_method?: EntryMethod;
	condo_id: number | null;
	user_id: number | null;
	resident_id?: number | null;
	parked_minutes?: number;
	was_parking_lot_fined?: boolean;
	visit_full_name?: string;
	visit_type?: VisitType;
	visit_delivery_type_label?: string;
	visit_id?: number | null;
	maintenance_id?: number | null;
	packages_count?: number;
	picking_up_resident_full_name?: string;
	delivery_id?: number | null;
	reservation_date?: string;
	reservation_time_range?: CommonAreaReservationTimeRange;
	canceling_resident_full_name?: string;
	reserved_common_area_type_label?: string;
	reserved_common_area_label?: string;
	common_area_reservation_id?: number | null;
	log_entry_description?: string;
	log_entry_type_category?: LogEntryTypeCategory;
	log_entry_type_label?: string;
	log_entry_id?: number | null;
	host_full_name?: string;
	visitor_alias?: string;
	event_name?: string;
	visitor_full_name?: string;
	invitation_utc_timestamp?: number;
	is_group_invitation?: boolean;
	invitation_id?: number | null;
	edited_user_password?: boolean;
	edited_user_enrollment_privilege?: boolean;
	possession_label?: string;
	possession_type_label?: string;
	possession_id?: number | null;
	local_datetime: string;
	local_date: string;
	local_time: string;
	parking_lot_assignment_local_datetime?: string;
	invitation_local_datetime?: string;
	invitation_confirmation_local_datetime?: string;

	User?: UserSchema;
	Resident?: ResidentSchema;
	Visit?: VisitSchema;
	Maintenance?: MaintenanceSchema;
	Delivery?: DeliverySchema;
	CommonAreaReservation?: CommonAreaReservationSchema;
	LogEntry?: LogEntrySchema;
	Invitation?: InvitationSchema;
	Possession?: PossessionSchema;
}

export interface ActionLogDescription extends ActionLogSchema {
	[key: string]: any;

	type_description: string;
	visit_identification?: string;
	resident_identification?: string;
	resident_type?: string;
	resident_email?: string;
	resident_phone_number?: string;
	resident_nationality?: string;
	resident_second_car_plate?: string;
	resident_parking_lot_label?: string;
	resident_second_parking_lot_label?: string;
	technician_full_name?: string;
	technician_rut?: string;
	maintenance_company_name?: string;
	maintenance_type_label?: string;
	reservation_time_range_description?: string;
	log_entry_type_category_description?: string;
	parked_minutes_description?: string;
	was_parking_lot_fined_description?: string;
	parking_lot_assignment_local_datetime?: string;
	visitor_name?: string;
	visitor_rut?: string;
	is_group_invitation_description?: string;
	edited_user_password_description?: string;
	edited_user_enrollment_privilege_description?: string;
	entry_method_description?: string;
}

export enum ActionLogDescriptionPropertyName {
	localDatetime = "local_datetime",
	visitIdentification = "visit_identification",
	visitFullName = "visit_full_name",
	visitType = "visit_type",
	visitDeliveryTypeLabel = "visit_delivery_type_label",
	propertyLabel = "property_label",
	carPlate = "car_plate",
	userFullName = "user_full_name",
	entryMethodDescription = "entry_method_description",
	typeDescription = "type_description",
	description = "description",
	residentFullName = "resident_full_name",
	residentIdentification = "resident_identification",
	residentType = "resident_type",
	residentEmail = "resident_email",
	residentPhoneNumber = "resident_phone_number",
	residentNationality = "resident_nationality",
	residentSecondCarPlate = "resident_second_car_plate",
	residentParkingLotLabel = "resident_parking_lot_label",
	residentSecondParkingLotLabel = "resident_second_parking_lot_label",
	maintenanceCompanyName = "maintenance_company_name",
	maintenanceTypeLabel = "maintenance_type_label",
	technicianFullName = "technician_full_name",
	technicianRUT = "technician_rut",
	parkedMinutesDescription = "parked_minutes_description",
	parkingLotLabel = "parking",
	parkingLotAssignmentLocalDatetime = "parking_lot_assignment_local_datetime",
	invitationLocalDatetime = "invitation_local_datetime",
	invitationConfirmationLocalDatetime = "invitation_confirmation_local_datetime",
	wasParkingLotFinedDescription = "was_parking_lot_fined_description",
	packagesCount = "packages_count",
	pickingUpResidentFullName = "picking_up_resident_full_name",
	reservedCommonAreaTypeLabel = "reserved_common_area_type_label",
	reservationDate = "reservation_date",
	reservationTimeRangeDescription = "reservation_time_range_description",
	logEntryTypeCategoryDescription = "log_entry_type_category_description",
	logEntryTypeLabel = "log_entry_type_label",
	logEntryDescription = "log_entry_description",
	hostFullName = "host_full_name",
	visitorName = "visitor_name",
	visitorRUT = "visitor_rut",
	eventName = "event_name",
	isGroupInvitationDescription = "is_group_invitation_description",
	editedUserPasswordDescription = "edited_user_password_description",
	editedUserEnrollmentPrivilegeDescription = "edited_user_enrollment_privilege_description",
	possessionLabel = "possession_label",
	possessionTypeLabel = "possession_type_label"
}

export enum VisitCategoryActionLogDescriptionPropertyLabel {
	localDatetime = "Fecha de Acción",
	typeDescription = "Tipo de Registro",
	visitIdentification = "Identificación",
	visitFullName = "Nombre",
	visitType = "Tipo",
	propertyLabel = "Inmueble",
	carPlate = "Patente",
	visitDeliveryTypeLabel = "Tipo de Entrega",
	userFullName = "Trabajador",
	entryMethodDescription = "Método de Entrada",
	description = "Descripción"
}

export enum ResidentCategoryActionLogDescriptionPropertyLabel {
	localDatetime = "Fecha de Acción",
	typeDescription = "Tipo de Registro",
	propertyLabel = "Inmueble",
	residentFullName = "Nombre",
	residentIndentification = "Identificación",
	residentType = "Tipo",
	residentEmail = "Email",
	residentPhoneNumber = "Número Telefónico",
	carPlate = "Patente",
	residentNationality = "Nacionalidad",
	residentSecondCarPlate = "Segunda Patente",
	residentParkingLotLabel = "Estacionamiento",
	residentSecondParkingLotLabel = "Segundo Estacionamiento",
	userFullName = "Trabajador",
	description = "Descripción"
}

export enum MaintenanceCategoryActionLogDescriptionPropertyLabel {
	localDatetime = "Fecha de Acción",
	typeDescription = "Tipo de Registro",
	maintenanceCompanyName = "Empresa",
	maintenanceTypeLabel = "Tipo de Mantención",
	technicianFullName = "Técnico",
	technicianRUT = "RUT del Técnico",
	carPlate = "Patente",
	userFullName = "Trabajador",
	entryMethodDescription = "Método de Entrada",
	description = "Descripción"
}

export enum ParkingCategoryActionLogDescriptionPropertyLabel {
	localDatetime = "Fecha de Acción",
	typeDescription = "Tipo de Registro",
	parkedMinutesDescription = "Minutos Estacionados",
	carPlate = "Patente",
	parkingLotLabel = "Estacionamiento",
	propertyLabel = "Inmueble",
	parkingLotAssignmentLocalDatetime = "Entrada a Estacionamiento",
	wasParkingLotFinedDescription = "Fue Multado",
	userFullName = "Trabajador",
	description = "Descripción"
}

export enum DeliveryCategoryActionLogDescriptionPropertyLabel {
	localDatetime = "Fecha de Acción",
	typeDescription = "Tipo de Registro",
	residentFullName = "Residente",
	residentIdentification = "RUT",
	propertyLabel = "Inmueble",
	packagesCount = "Cantidad de Paquetes",
	pickingUpResidentFullName = "Residente que Retira",
	userFullName = "Trabajador",
	description = "Descripción"
}

export enum CommonAreaReservationCategoryActionLogDescriptionPropertyLabel {
	localDatetime = "Fecha de Acción",
	typeDescription = "Tipo de Registro",
	reservedCommonAreaTypeLabel = "Espacio Común Reservado",
	reservationDate = "Fecha Reservada",
	reservationTimeRangeDescription = "Franja Horaria",
	propertyLabel = "Inmueble",
	residentFullName = "Residente",
	userFullName = "Trabajador",
	description = "Descripción"
}

export enum LogEntryCategoryActionLogDescriptionPropertyLabel {
	localDatetime = "Fecha de Acción",
	typeDescription = "Tipo de Registro",
	logEntryTypeCategoryDescription = "Categoría",
	logEntryTypeLabel = "Tipo",
	logEntryDescription = "Detalle",
	propertyLabel = "Inmueble",
	userFullName = "Trabajador",
	description = "Descripción"
}

export enum InvitationCategoryActionLogDescriptionPropertyLabel {
	localDatetime = "Fecha de Acción",
	invitationLocalDatetime = "Fecha de Invitación",
	invitationConfirmationLocalDatetime = "Fecha de Confirmación",
	typeDescription = "Tipo de Registro",
	hostFullName = "Anfitrión",
	visitorRUT = "RUT del Invitado",
	visitorName = "Invitado",
	eventName = "Evento",
	isGroupInvitationDescription = "Invitación Grupal",
	carPlate = "Patente",
	userFullName = "Trabajador",
	description = "Descripción"
}

export enum UserCategoryActionLogDescriptionPropertyLabel {
	localDatetime = "Fecha de Acción",
	typeDescription = "Tipo de Registro",
	editedUserPasswordDescription = "Contraseña Modificada",
	editedUserEnrollmentPrivilegeDescription = "Permiso de Enrolamiento Modificado",
	userFullName = "Trabajador",
	description = "Descripción"
}

export enum PossessionCategoryActionLogDescriptionPropertyLabel {
	localDatetime = "Fecha de Acción",
	typeDescription = "Tipo de Registro",
	propertyLabel = "Inmueble",
	possessionLabel = "Posesión",
	possessionTypeLabel = "Tipo de Posesión",
	userFullName = "Trabajador",
	description = "Descripción"
}

export interface HeaderRowColumn {
	readonly id: string;
	readonly label: string;
}

export type HeaderRow = HeaderRowColumn[];
