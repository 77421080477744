import { useCallback, useEffect, type FC } from "react";
import { Add, Close } from "@mui/icons-material";

import { useTheme, Box, Button, ButtonGroup, Grid, Modal, TextField, Typography } from "@mui/material";

import { Controller, useForm } from "react-hook-form";
import uuid from "react-uuid";

import type { SupportContactSchema } from "../Query";
import type { PLTFieldErrors } from "../../../../Types";

export interface SupportContactUpdateInput {
	name?: string;
	phoneNumber?: string;
	description?: string;
}

type SupportContactUpdateModalProps = {
	isVisible: boolean;
	errorsField: PLTFieldErrors;
	supportContact: SupportContactSchema;
	onSuccess: (supportContactID: number, creationInput: SupportContactUpdateInput) => void;
	onClose: () => void;
};

export const SupportContactCreationModal: FC<SupportContactUpdateModalProps> = ({
	isVisible,
	errorsField,
	supportContact,
	onSuccess,
	onClose
}) => {
	const theme = useTheme();

	const { control, getValues, setValue, reset } = useForm<SupportContactUpdateInput>({
		defaultValues: {
			name: "",
			phoneNumber: "",
			description: ""
		}
	});

	const isError = useCallback((key: string) => errorsField.some(error => error.key === key), [errorsField]);

	const getErrorText = useCallback(
		(key: string) => errorsField?.find(error => error.key === key)?.message ?? "",
		[errorsField]
	);

	useEffect(() => {
		if (isVisible) {
			setValue("name", supportContact.name);
			setValue("phoneNumber", supportContact.phone_number);
			setValue("description", supportContact.description);
		} else {
			reset();
		}
	}, [isVisible, setValue, supportContact]);

	return (
		<Modal open={isVisible} onClose={onClose}>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: 400,
					bgcolor: "background.paper",
					boxShadow: 24,
					p: 4
				}}
			>
				<Grid container flexDirection={"row"}>
					<Typography variant="h5" color={theme.palette.primary.main} textAlign={"center"}>
						Actualizar Contacto de Soporte
					</Typography>

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							name="name"
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={value}
									onChange={onChange}
									label="Nombre"
									variant="outlined"
									error={isError("name")}
									helperText={getErrorText("name")}
								/>
							)}
						/>
					</Grid>

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							name="phoneNumber"
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={value}
									onChange={onChange}
									label="Número telefónico"
									variant="outlined"
									error={isError("phoneNumber")}
									helperText={getErrorText("phoneNumber")}
								/>
							)}
						/>
					</Grid>

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							name="description"
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={value}
									onChange={onChange}
									label="Descripción"
									variant="outlined"
									error={isError("description")}
									helperText={getErrorText("description")}
								/>
							)}
						/>
					</Grid>
				</Grid>

				<ButtonGroup
					style={{ paddingTop: 20, paddingBottom: 20, alignSelf: "center" }}
					variant="text"
					aria-label="text button group"
				>
					<Button onClick={onClose}>
						<Close /> Cancelar
					</Button>

					<Button onClick={() => onSuccess(supportContact.id, getValues())}>
						<Add /> Actualizar
					</Button>
				</ButtonGroup>
			</Box>
		</Modal>
	);
};

export default SupportContactCreationModal;
