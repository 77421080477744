import { gql } from "@apollo/client";

export interface GlobalSettingsSchema {
	id: number;
	invitations_grace_period_minutes: number;
	invitations_validity_window_minutes: number;
	plat_contact_number: string;
}

export interface GlobalSettingsUpdateInput {
	invitationsGracePeriodMinutes?: number;
	invitationsValidityWindowMinutes?: number;
	platContactNumber?: string;
}

export type GetGlobalSettingsResponseData = {
	globalSettings: GlobalSettingsSchema;
};

export type UpdateGlobalSettingsResponseData = {
	updateGlobalSettings: GlobalSettingsSchema;
};

const graphQLSchema = `
	id
	invitations_grace_period_minutes
	invitations_validity_window_minutes
	plat_contact_number
`;

export const GET_GLOBAL_SETTINGS = gql`
	query GetGlobalSettings {
		globalSettings {
			${graphQLSchema}
		}
	}
`;

export const UPDATE_GLOBAL_SETTINGS = gql`
	mutation UpdateGlobalSettings($updateInput: GlobalSettingsUpdateInput!) {
		updateGlobalSettings(updateInput: $updateInput) {
			${graphQLSchema}
		}
	}
`;
