import { gql } from "@apollo/client";

export enum LogEntryTypeCategory {
	notice = "notice",
	request = "request",
	wall = "wall"
}

const logEntryTypeCategorySpanishDescriptions: Record<LogEntryTypeCategory, string> = {
  [LogEntryTypeCategory.notice]: "Novedad",
  [LogEntryTypeCategory.request]: "Requerimiento",
  [LogEntryTypeCategory.wall]: "Muro",
};

export function getLogEntryTypeCategorySpanishDescription(logEntryTypeCategory: LogEntryTypeCategory): string {
  return logEntryTypeCategorySpanishDescriptions[logEntryTypeCategory];
}

export function getLogEntryTypeCategoryFromSpanishDescription(
  spanishDescription: string
): LogEntryTypeCategory | undefined {
  const spanishDescriptionEntries = Object.entries(
    logEntryTypeCategorySpanishDescriptions
  ) as [key: LogEntryTypeCategory, value: string][]

  const matchingEntry = spanishDescriptionEntries.find(([_, value]: [key: LogEntryTypeCategory, value: string]) =>
    value === spanishDescription
  );

  return matchingEntry?.[0];
}

export type LogEntryTypeSchema = {
  id: number;
  category: LogEntryTypeCategory;
  label: string;
  is_active: boolean;
  condo_id: number;

  LogEntries?: {
    id: number;
    description: string;
    resident_id: number;
  };
};

export type FormattedLogEntryType = LogEntryTypeSchema & { categoryDescription: string };

export type GetAllLogEntryTypesData = {
  GetAllLogEntryTypes: LogEntryTypeSchema[];
};

export type CreateLogEntryTypeData = {
  CreateLogEntryType: LogEntryTypeSchema[];
};

export type UpdateLogEntryTypeData = {
  UpdateLogEntryType: LogEntryTypeSchema[];
};

export type DeleteLogEntryTypeData = {
  DeleteLogEntryType: LogEntryTypeSchema[];
};

export type ToggleLogEntryTypeActiveStatusData = {
  ToggleLogEntryTypeActiveStatus: LogEntryTypeSchema[];
};

const graphQLSchema = `
    id
    category
    label
    is_active
    condo_id

    LogEntries {
        id
        description
        resident_id
    }
`;

export const CREATE_LOG_ENTRY_TYPE = gql`
  mutation CreateLogEntryType($category: LogEntryTypeCategory!, $label: String!) {
    CreateLogEntryType(category: $category, label: $label) {
      ${graphQLSchema}
    }
  }
`;

export const GET_ALL_LOG_ENTRY_TYPES = gql`
  query GetAllLogEntryTypes {
    GetAllLogEntryTypes {
      ${graphQLSchema}
    }
  }
`;

export const UPDATE_LOG_ENTRY_TYPE = gql`
  mutation UpdateLogEntryType($id: Int!, $updateInput: LogEntryTypeUpdateInput!) {
    UpdateLogEntryType(id: $id, updateInput: $updateInput) {
      ${graphQLSchema}
    }
  }
`;

export const DELETE_LOG_ENTRY_TYPE = gql`
  mutation DeleteLogEntryType($id: Int!) {
    DeleteLogEntryType(id: $id) {
      ${graphQLSchema}
    }
  }
`;

export const TOGGLE_LOG_ENTRY_TYPE_ACTIVE_STATUS = gql`
  mutation ToggleLogEntryTypeActiveStatus($id: Int!) {
    ToggleLogEntryTypeActiveStatus(id: $id) {
      ${graphQLSchema}
    }
  }
`;

