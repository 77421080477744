import {
	GET_IN_PROGRESS_DELIVERIES_COUNTS,
	GET_FINISHED_DELIVERIES_COUNTS,
	type DeliveriesCount,
	type InProgressDeliveriesCountsData,
	type FinishedDeliveriesCountsData
} from "../../GraphQL";

import DateRangePicker from "../../../../Components/DateRangePicker/DateRangePicker";
import useGraphQL from "../../../../../Hooks/useGraphQL";
import CustomBarChart, { type BarData } from "../../../../Components/CustomBarChart";
import { useState, useEffect, useCallback, memo, type FC } from "react";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { CircularProgress } from "@mui/material";

dayjs.extend(isSameOrBefore);

enum DeliveryCountType {
	pending = "Encomiendas Pendientes",
	pickedUp = "Encomiendas Entregadas"
}

interface DeliveriesChartData {
	name: string;
	[DeliveryCountType.pending]: number;
	[DeliveryCountType.pickedUp]: number;
}

interface DeliveriesChartProps {
	condoID: number;
}

const deliveriesBarsData: BarData[] = [
	{
		barFillColor: "#81C784",
		dataKey: DeliveryCountType.pending,
		rectangleFillColor: "rgba(255, 255, 255, 0.7)",
		rectangleStrokeColor: "#81C784"
	},
	{
		barFillColor: "#D4E157",
		dataKey: DeliveryCountType.pickedUp,
		rectangleFillColor: "rgba(255, 255, 255, 0.4)",
		rectangleStrokeColor: "#D4E157"
	}
];

const DeliveriesChart: FC<DeliveriesChartProps> = ({ condoID }) => {
	const [deliveriesData, setDeliveriesData] = useState<DeliveriesChartData[]>([]);
	const { useLazyGraphQuery } = useGraphQL();

	const [getInProgressDeliveriesCounts, { loading: isLoadingInProgressDeliveriesCounts }] =
		useLazyGraphQuery<InProgressDeliveriesCountsData>(GET_IN_PROGRESS_DELIVERIES_COUNTS);

	const [getFinishedDeliveriesCounts, { loading: isLoadingFinishedDeliveriesCounts }] =
		useLazyGraphQuery<FinishedDeliveriesCountsData>(GET_FINISHED_DELIVERIES_COUNTS);

	const { watch, setValue, getValues } = useForm<{ startDate: Date; endDate: Date }>({
		defaultValues: {
			startDate: dayjs().subtract(6, "day").toDate(),
			endDate: dayjs().toDate()
		}
	});

	const startDate = watch("startDate");
	const endDate = watch("endDate");

	const getInProgressDeliveriesCountsInDateRange = useCallback(async (): Promise<DeliveriesCount[]> => {
		let inProgressDeliveriesCounts: DeliveriesCount[] | undefined;

		const inProgressDeliveriesCountsResponse = await getInProgressDeliveriesCounts({
			variables: {
				countsInput: {
					condoID,
					startDate,
					endDate
				}
			}
		});

		if (inProgressDeliveriesCountsResponse.data) {
			inProgressDeliveriesCounts = inProgressDeliveriesCountsResponse.data.inProgressDeliveriesCounts;
		}

		return inProgressDeliveriesCounts ?? [];
	}, [getInProgressDeliveriesCounts, condoID, endDate, startDate]);

	const getFinishedDeliveriesCountsInDateRange = useCallback(async (): Promise<DeliveriesCount[]> => {
		let finishedDeliveriesCounts: DeliveriesCount[] | undefined;

		const finishedDeliveriesCountsResponse = await getFinishedDeliveriesCounts({
			variables: {
				countsInput: {
					condoID,
					startDate,
					endDate
				}
			}
		});

		if (finishedDeliveriesCountsResponse.data) {
			finishedDeliveriesCounts = finishedDeliveriesCountsResponse.data.finishedDeliveriesCounts;
		}

		return finishedDeliveriesCounts ?? [];
	}, [getFinishedDeliveriesCounts, condoID, endDate, startDate]);

	const updateDeliveriesData = useCallback(async (): Promise<void> => {
		const updatedDeliveriesData: DeliveriesChartData[] = [];

		const inProgressDeliveriesCounts: DeliveriesCount[] = [];
		const finishedDeliveriesCounts: DeliveriesCount[] = [];

		await Promise.all([
			inProgressDeliveriesCounts.push(...(await getInProgressDeliveriesCountsInDateRange())),
			finishedDeliveriesCounts.push(...(await getFinishedDeliveriesCountsInDateRange()))
		]);

		if (
			inProgressDeliveriesCounts.length === 0 ||
			inProgressDeliveriesCounts.length !== finishedDeliveriesCounts.length
		) {
			return;
		}

		for (let i = 0; i < inProgressDeliveriesCounts.length; i++) {
			if (inProgressDeliveriesCounts[i].count === 0 && finishedDeliveriesCounts[i].count === 0) {
				continue;
			}

			updatedDeliveriesData.push({
				name: dayjs(inProgressDeliveriesCounts[i].date).format("DD/MM/YYYY"),
				[DeliveryCountType.pending]: inProgressDeliveriesCounts[i].count,
				[DeliveryCountType.pickedUp]: finishedDeliveriesCounts[i].count
			});
		}

		setDeliveriesData(updatedDeliveriesData);
	}, [getInProgressDeliveriesCountsInDateRange, getFinishedDeliveriesCountsInDateRange]);

	useEffect(() => {
		if (!condoID) {
			return;
		}

		updateDeliveriesData();
	}, [condoID, startDate, endDate, updateDeliveriesData]);

	const LoadingSpinner = () => (
		<div style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
			<CircularProgress />
		</div>
	);

	if (!condoID || isLoadingInProgressDeliveriesCounts || isLoadingFinishedDeliveriesCounts) {
		return <LoadingSpinner />;
	}

	return (
		<section style={{ width: "95%", height: 350, marginTop: "2em" }}>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
					paddingBottom: "2%"
				}}
			>
				<DateRangePicker
					defaultStartDate={getValues("startDate")}
					defaultEndDate={getValues("endDate")}
					shouldHideFutureDates={true}
					onChange={dates => {
						setValue("endDate", dates.endDate);
						setValue("startDate", dates.startDate);
					}}
				/>
			</div>

			<CustomBarChart data={deliveriesData} barsData={deliveriesBarsData} />
		</section>
	);
};

export default memo(DeliveriesChart);
