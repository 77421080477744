import React, { useCallback, useEffect, useState } from "react";
import { useCore } from "../../../Context/Core";
import { Box, Button, ButtonGroup, Divider, Snackbar } from "@mui/material";
import { Add } from "@mui/icons-material";
import CustomTable from "../../Components/CustomTable";
import useTable from "../../Components/CustomTable/useTable";
import useGraphQL from "../../../Hooks/useGraphQL";
import {
  DisablePLATSupport,
  EnablePLATSupport,
  PropertySchema,
  RequestCreateProperty,
  RequestDeleteProperty,
  RequestGetProperty,
  RequestUpdateProperty,
  mutation_delete_property,
  mutation_disable_plat_support,
  mutation_enable_plat_support,
  mutation_register_property,
  mutation_update_property,
  query_get_properties,
} from "./GraphQL";
import ModalProperty from "./ModalProperty";
import GuardCondos from "../../Shared/GuardCondos";
import ModalUpdateProperty from "./ModalUpdateProperty";
import AlertDialog from "../../Components/CustomAlertDialog";
import useErrorGraphql from "../../../Hooks/useErrorGraphql";

import { ToastContainer, toast } from "react-toastify";

const head = [
  { id: "residence_identification", label: "Inmueble" },
  { id: "actions", label: "Acciones" },
  { id: "supports_plat", label: "Habilitar PLAT" },
];
const CondosProperty = () => {
  const [isAdd, setAdd] = useState<boolean>(false);

  const [isUpdate, setUpdate] = useState<{
    active: boolean;
    property: PropertySchema | undefined;
  }>({ active: false, property: undefined });

  const [currentList, setCurrentList] = useState<Array<PropertySchema>>([]);

  const [confirmDeleteDelivery, setConfirmDeleteDelivery] =
    useState<boolean>(false);
  const [idDelete, setIdDelete] = useState<{ id: number } | undefined>();

  const { condo } = useCore();

  const { useLazyGraphQuery, useGraphMutation } = useGraphQL();

  const { errorsField, setErrorFields } = useErrorGraphql();

  const tableProps = useTable();

  const [loadProperties] =
    useLazyGraphQuery<RequestGetProperty>(query_get_properties);

  const [createProperty] = useGraphMutation<RequestCreateProperty>(
    mutation_register_property
  );
  const [updateProperty] = useGraphMutation<RequestUpdateProperty>(
    mutation_update_property
  );
  const [deleteProperty] = useGraphMutation<RequestDeleteProperty>(
    mutation_delete_property
  );

  const [enablePlatSupport] = useGraphMutation<EnablePLATSupport>(
    mutation_enable_plat_support
  );

  const [disablePlatSupport] = useGraphMutation<DisablePLATSupport>(
    mutation_disable_plat_support
  );

  const handleCreate = useCallback(
    async ({
      residence_identification,
    }: {
      residence_identification: string;
    }) => {
      if (condo) {
        const reponse = await createProperty({
          variables: {
            FormCreateProperty: {
              residence_identification,
            },
          },
        });
        if (reponse.data) {
          const list = reponse.data.RegisterProperty;
          if (list) {
            toast.success("Inmueble agregado correctamente");
            setAdd(false);
            setCurrentList(list);
          }
        }
      }
    },
    []
  );
  const handleUpdate = useCallback(
    async ({
      residence_identification,
      id,
    }: {
      residence_identification: string;
      id: number;
    }) => {
      if (condo) {
        const response = await updateProperty({
          variables: {
            FormUpdateProperty: {
              residence_identification,
              id: Number(id),
            },
          },
        });
        if (response.data) {
          const list = response.data.UpdateProperty;
          if (list) {
            toast.success("Inmueble actualizado correctamente");
            setUpdate({ active: false, property: undefined });
            setCurrentList(list);
          }
        }
      }
    },
    []
  );

  const handleDelete = useCallback(async (id: number) => {
    const response = await deleteProperty({
      variables: { property_id: Number(id) },
    });
    if (response.data) {
      const list = response.data.DeleteProperty;
      if (list) {
        toast.success("Inmueble Eliminado correctamente");
        setCurrentList(list);
        setConfirmDeleteDelivery(false);
        setIdDelete(undefined);
      }
    }
  }, []);

  const handleLoadDeliveries = useCallback(() => {
    if (condo)
      loadProperties().then((response) => {
        if (response.data) {
          const list = response.data.GetAllPropertiesByCondoId;
          const formatedList = list.map((e) => {
            return {
              id: e.id,
              condo_id: e.condo_id,
              residence_identification: e.residence_identification,
              supports_plat: e.supports_plat,
            };
          });
          if (list) setCurrentList(formatedList);
        }
      });
  }, []);

  const handlePlatStatusToggle = async (row: PropertySchema) => {
    if (row.supports_plat) {
      await disablePlatSupport({ variables: { id: row.id } });
    } else {
      await enablePlatSupport({ variables: { id: row.id } });
    }
    handleLoadDeliveries();
  };

  const PropertyTable = () => (
    <CustomTable
      controls={{
        ...tableProps,
        onUpdate: (row) => {
          setErrorFields(undefined);
          setUpdate({
            active: true,
            property: row as unknown as PropertySchema,
          });
        },
        onActiveStatusToggle: () => {},
        onDelete: ({ id }) => {
          setConfirmDeleteDelivery(true);
          setIdDelete({ id: Number(id) });
        },
        onPlatStatusToggle: (row) => {
          handlePlatStatusToggle(row as PropertySchema);
        },
      }}
      columns={head}
      rows={currentList}
    />
  );

  useEffect(() => {
    handleLoadDeliveries();
  }, [condo]);

  return (
    <Box>
      <GuardCondos />

      <ButtonGroup
        style={{ paddingTop: 20, paddingBottom: 20 }}
        variant="text"
        aria-label="text button group"
      >
        <Button
          onClick={() => {
            setErrorFields(undefined);
            setAdd(true);
          }}
        >
          <Add /> Agregar Inmueble
        </Button>
      </ButtonGroup>
      <Divider style={{ marginTop: 15, marginBottom: 15 }} />
      <PropertyTable />
      <ModalProperty
        errorsField={errorsField ? errorsField : []}
        handleClose={() => setAdd(false)}
        onAdd={handleCreate}
        onCancel={() => setAdd(false)}
        visible={isAdd}
      />
      <ModalUpdateProperty
        errorsField={errorsField ? errorsField : []}
        handleClose={() => setUpdate({ active: false, property: undefined })}
        onUpdate={handleUpdate}
        onCancel={() => setUpdate({ active: false, property: undefined })}
        visible={isUpdate.active}
        property={isUpdate.property}
      />
      <AlertDialog
        title={"Advertencia"}
        open={confirmDeleteDelivery}
        setOpen={setConfirmDeleteDelivery}
        warningMessage={
          "Estás apunto de eliminar un Inmueble. ¿Deseas continuar?"
        }
        onCancel={() => setIdDelete(undefined)}
        onConfirm={() => {
          if (idDelete) handleDelete(idDelete.id);
        }}
      />

      <ToastContainer position="bottom-left" autoClose={3000} />
    </Box>
  );
};
export default CondosProperty;
