import { gql } from "@apollo/client";

const schema = `
  id
  description
  register_type
  active
  date
  user_id
  resident_id
  visit_id
`;

export type residentLogsSchema = {
  id: number;
  description: string;
  register_type: string;
  active: boolean;
  date: string;
  user_id: number;
  resident_id: number;
  visit_id: number;
};

export type RequestGetResidentLogs = {
  GetActionLogsResidentByCondoID?: undefined | null | Array<residentLogsSchema>;
};

export const query_get_visit = gql`
query GetActionLogsResidentByCondoID($condo_id: Int!) {
	GetActionLogsResidentByCondoID(condo_id: $condo_id) {
		${schema}
	}
}
`;
