import { useCallback, useEffect, type FC } from "react";
import { Add, Close } from "@mui/icons-material";
import { Nationality, getNationalitySpanishDescription } from "../../CondosResidents/GraphQL";

import {
	useTheme,
	Box,
	Button,
	ButtonGroup,
	Grid,
	Modal,
	TextField,
	Typography,
	Autocomplete,
	Chip,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	FormHelperText
} from "@mui/material";

import { Controller, useForm } from "react-hook-form";
import uuid from "react-uuid";
import { format as formatRUT } from "rut.js";

import type { PropertySchema } from "../../CondosProperty/GraphQL";
import type { PLTFieldErrors } from "../../../../Types";

export interface BannedVisitorCreationModalInput {
	identification: string;
	nationality: Nationality;
	propertiesLabels?: string[];
}

interface BannedVisitorCreationModalProps {
	isVisible: boolean;
	errorsField: PLTFieldErrors;
	properties: PropertySchema[];
	onSuccess: (creationInput: BannedVisitorCreationModalInput) => void;
	onClose: () => void;
}

export const BannedVisitorCreationModal: FC<BannedVisitorCreationModalProps> = ({
	isVisible,
	errorsField,
	properties,
	onSuccess,
	onClose
}) => {
	const theme = useTheme();

	const { control, getValues, setValue, reset } = useForm<BannedVisitorCreationModalInput>({
		defaultValues: {
			identification: "",
			nationality: Nationality.CHILEAN,
			propertiesLabels: undefined
		}
	});

	const isError = useCallback((key: string) => errorsField.some(error => error.key === key), [errorsField]);

	const getErrorText = useCallback(
		(key: string) => errorsField?.find(error => error.key === key)?.message ?? "",
		[errorsField]
	);

	useEffect(() => {
		if (!isVisible) {
			reset();
		}
	}, [isVisible, reset]);

	return (
		<Modal open={isVisible} onClose={onClose}>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: 400,
					bgcolor: "background.paper",
					boxShadow: 24,
					p: 4
				}}
			>
				<Grid container flexDirection={"row"}>
					<Typography variant="h5" color={theme.palette.primary.main} textAlign={"center"}>
						Agregar Visita Bloqueada
					</Typography>

					<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
						<Controller
							control={control}
							name="identification"
							render={({ field: { onChange, value } }) => (
								<TextField
									color={"primary"}
									style={{ width: "100%" }}
									id={uuid()}
									fullWidth
									value={value}
									onChange={e => {
										const rut = e.target.value;

										if (getValues().nationality === "CHILEAN" && rut.length > 2) {
											onChange(formatRUT(rut));
											return;
										}

										onChange(rut);
									}}
									label="Identificación"
									variant="outlined"
									error={isError("identification")}
									helperText={getErrorText("identification")}
								/>
							)}
						/>
					</Grid>
				</Grid>

				<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
					<Controller
						control={control}
						name="nationality"
						render={({ field: { onChange, value } }) => (
							<FormControl sx={{ width: "100%" }}>
								<InputLabel
									id="nationality"
									style={{
										color: isError("nationality")
											? theme.palette.error.main
											: theme.palette.primary.main
									}}
								>
									Nacionalidad
								</InputLabel>

								<Select
									labelId="nationality"
									id="nationality"
									value={value}
									label="Nacionalidad"
									onChange={e => {
										onChange(e.target.value);

										if (e.target.value === "CHILEAN") {
											setValue("identification", formatRUT(getValues("identification")));
										}
									}}
									error={isError("nationality")}
								>
									{Object.values(Nationality).map(nationality => (
										<MenuItem key={`${nationality}-selres`} value={nationality}>
											{getNationalitySpanishDescription(nationality)}
										</MenuItem>
									))}
								</Select>

								{isError("nationality") ? (
									<FormHelperText style={{ color: theme.palette.error.main }}>
										{getErrorText("nationality")}
									</FormHelperText>
								) : null}
							</FormControl>
						)}
					/>
				</Grid>

				<Grid style={{ padding: 15 }} item xs={12} sm={12} md={12} lg={12}>
					<Controller
						control={control}
						name="propertiesLabels"
						render={({ field: { onChange, value } }) => (
							<Autocomplete
								multiple
								id={uuid()}
								size="small"
								options={properties.map(property => property.residence_identification)}
								onChange={(_, value) => {
									onChange(value);
								}}
								defaultValue={value}
								renderTags={(propertiesLabels, getTagProps) =>
									propertiesLabels.map((propertyLabel, index) => (
										<Chip
											variant="outlined"
											label={propertyLabel}
											size="small"
											{...getTagProps({ index })}
										/>
									))
								}
								renderInput={params => (
									<TextField
										{...params}
										variant="filled"
										label="Seleccionar inmuebles"
										placeholder="Inmuebles"
									/>
								)}
							/>
						)}
					/>
				</Grid>

				<ButtonGroup
					style={{ paddingTop: 20, paddingBottom: 20, alignSelf: "center" }}
					variant="text"
					aria-label="text button group"
				>
					<Button onClick={onClose}>
						<Close /> Cancelar
					</Button>

					<Button onClick={() => onSuccess(getValues())}>
						<Add /> Agregar
					</Button>
				</ButtonGroup>
			</Box>
		</Modal>
	);
};

export default BannedVisitorCreationModal;
