import { Bar, BarChart, CartesianGrid, Legend, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

export interface BarData {
	dataKey: string;
	barFillColor: string;
	rectangleFillColor: string;
	rectangleStrokeColor: string;
	stackID?: string;
}

export interface BaseBarChartData {
	name: string;
}

interface CustomBarChartProps<T> {
	data: (BaseBarChartData & T)[];
	barsData: BarData[];
}

const CustomBarChart = <T,>({ data, barsData }: CustomBarChartProps<T>) => {
	return (
		<ResponsiveContainer width="100%" height="100%">
			<BarChart
				data={data}
				margin={{
					top: 5,
					right: 30,
					left: 20,
					bottom: 5
				}}
			>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="name" />
				<YAxis />
				<Tooltip />
				<Legend />

				{barsData.map((barData, index) => (
					<Bar
						key={`i-${barData.dataKey}-${index}`}
						dataKey={barData.dataKey}
						stackId={barData.stackID}
						fill={barData.barFillColor}
						activeBar={
							<Rectangle fill={barData.rectangleFillColor} stroke={barData.rectangleStrokeColor} />
						}
					/>
				))}
			</BarChart>
		</ResponsiveContainer>
	);
};
export default CustomBarChart;
