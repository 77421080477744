import { gql } from "@apollo/client";

export type EmergencyContactSchema = {
  id: number;
  name: string;
  phone_number: string;
  logo_url: string;
  description: string;
  is_active: boolean;
};

export type CreateEmergencyContactData = {
  CreateEmergencyContact: EmergencyContactSchema[];
};

export type GetAllEmergencyContactsData = {
  GetAllEmergencyContacts: EmergencyContactSchema[];
};

export type UpdateEmergencyContactData = {
  UpdateEmergencyContact: EmergencyContactSchema[];
};

export type DeleteEmergencyContactData = {
  DeleteEmergencyContact: EmergencyContactSchema[];
};

export type ToggleEmergencyContactActiveStatusData = {
  ToggleEmergencyContactActiveStatus: EmergencyContactSchema[];
};

const graphQLSchema = `
  id
  name
  phone_number
  logo_url
  description
  is_active
`;

export const CREATE_EMERGENCY_CONTACT = gql`
  mutation CreateEmergencyContact($creationInput: EmergencyContactCreationInput!) {
    CreateEmergencyContact(creationInput: $creationInput) {
      ${graphQLSchema}
    }
  }
`;

export const GET_ALL_EMERGENCY_CONTACTS = gql`
  query GetAllEmergencyContacts {
    GetAllEmergencyContacts {
      ${graphQLSchema}
    }
  }
`;

export const UPDATE_EMERGENCY_CONTACT = gql`
  mutation UpdateEmergencyContact($id: Int!, $updateInput: EmergencyContactUpdateInput!) {
    UpdateEmergencyContact(id: $id, updateInput: $updateInput) {
      ${graphQLSchema}
    }
  }
`;

export const DELETE_EMERGENCY_CONTACT = gql`
  mutation DeleteEmergencyContact($id: Int!) {
    DeleteEmergencyContact(id: $id) {
      ${graphQLSchema}
    }
  }
`;

export const TOGGLE_EMERGENCY_CONTACT_ACTIVE_STATUS = gql`
  mutation ToggleEmergencyContactActiveStatus($id: Int!) {
    ToggleEmergencyContactActiveStatus(id: $id) {
      ${graphQLSchema}
    }
  }
`;
