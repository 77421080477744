import { gql } from "@apollo/client";

const schema = `
  id
  label
  is_reserved_for_visitors
  active
`;

export interface ParkingSchema {
	id: number;
	label: string;
	is_reserved_for_visitors: boolean;
	active: boolean;
}

export interface FormattedParkingLot extends ParkingSchema {
	[key: string]: any; // Why the fuck do I need this?
	is_reserved_for_visitors_spanish_description: string;
}

export type GetAllParkingLotsResponseData = {
	GetAllParkings: ParkingSchema[];
};

export type CreateParkingResponseData = {
	CreateParking: ParkingSchema[];
};

export type UpdateParkingLotResponseDate = {
	UpdateParking: ParkingSchema[];
};

export type DeleteParkingLotResponseData = {
	DeleteParking: ParkingSchema[];
};

export type ToggleParkingLotActiveStatusResponseData = {
	ActiveParking: ParkingSchema[];
};

export function getIsReservedForVisitorsSpanishDescription(isReservedForVisitors: boolean): string {
	return isReservedForVisitors ? "Sí" : "No";
}

export const query_get_all_parking = gql`
query GetAllParkings{
  GetAllParkings{
    ${schema}
  }
}
`;
export const mutation_create_parking = gql`
  mutation CreateParking($label: String!, $isReservedForVisitors: Boolean) {
    CreateParking(label: $label, isReservedForVisitors: $isReservedForVisitors) {
      ${schema}
    }
  }
`;
export const mutation_update_parking = gql`
mutation UpdateParking($id: Int!,$label: String!){
  UpdateParking(id:$id,label:$label){
    ${schema}
  }
}
`;
export const mutation_delete_parking = gql`
mutation DeleteParking($id: Int!){
  DeleteParking(id:$id){
    ${schema}
  }
}
`;
export const mutation_active_parking = gql`
mutation ActiveParking($id: Int!){
  ActiveParking(id:$id){
    ${schema}
  }
}
`;
