import { useEffect, useCallback, memo, type FC } from "react";
import useGraphQL from "../../../../../Hooks/useGraphQL";
import { GetActionLogsResponseData, GET_ACTION_LOGS } from "../../GraphQL";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import DateRangePicker from "../../../../Components/DateRangePicker/DateRangePicker";
import { CircularProgress } from "@mui/material";
import CustomBarChart, { type BarData } from "../../../../Components/CustomBarChart";
import { ActionLogType } from "../../../Reports/types";

import type { DateRange } from "../../../../Components/DateRangePicker/DateRangePicker";

enum InvitationsDataKey {
	new = "Invitaciones creadas",
	redeemed = "Invitaciones canjeadas"
}

interface InvitationsChartData {
	name: string;
	[InvitationsDataKey.new]: number;
	[InvitationsDataKey.redeemed]: number;
}

const invitationsBarsData: BarData[] = [
	{
		barFillColor: "#022F35",
		dataKey: InvitationsDataKey.new,
		rectangleFillColor: "rgba(255, 255, 255, 0.7)",
		rectangleStrokeColor: "#022F35"
	},
	{
		barFillColor: "#91A0A3",
		dataKey: InvitationsDataKey.redeemed,
		rectangleFillColor: "rgba(255, 255, 255, 0.4)",
		rectangleStrokeColor: "#91A0A3"
	}
];

const InvitationsBarChart: FC<{ condoID: number }> = ({ condoID }) => {
	const { useLazyGraphQuery } = useGraphQL();

	const [getInvitationsLogs, { data: invitationsData, loading: isLoadingInvitationsData }] =
		useLazyGraphQuery<GetActionLogsResponseData>(GET_ACTION_LOGS);

	const { setValue, getValues } = useForm<{
		dateRange: DateRange;
	}>({
		defaultValues: {
			dateRange: {
				startDate: dayjs().subtract(6, "day").toDate(),
				endDate: dayjs().toDate()
			}
		}
	});

	const invitationsCountsData = invitationsData?.actionLogs
		? invitationsData.actionLogs.reduce((acc: InvitationsChartData[], item) => {
				const existingDate = acc.find(entry => entry.name === item.local_date);

				if (existingDate) {
					if (item.register_type === ActionLogType.newInvitation) {
						existingDate[InvitationsDataKey.new] += item.is_group_invitation ? 0 : 1;
					} else if (item.register_type === ActionLogType.receivedVisitor) {
						existingDate[InvitationsDataKey.redeemed] += 1;
					}
				} else {
					const newEntry = {
						name: item.local_date,
						[InvitationsDataKey.new]:
							item.register_type === ActionLogType.newInvitation && !item.is_group_invitation ? 1 : 0,
						[InvitationsDataKey.redeemed]: item.register_type === ActionLogType.receivedVisitor ? 1 : 0
					};
					acc.push(newEntry);
				}
				return acc;
		  }, [])
		: [];

	const fetchInvitationsLogs = useCallback(
		async (condoID: number, dateRange: DateRange) => {
			await getInvitationsLogs({
				variables: {
					queryInput: {
						condoID,
						startDate: dateRange.startDate,
						endDate: dateRange.endDate,
						actionLogTypes: [ActionLogType.newInvitation, ActionLogType.receivedVisitor]
					}
				}
			});
		},
		[getInvitationsLogs]
	);

	useEffect(() => {
		if (condoID) {
			fetchInvitationsLogs(condoID, getValues("dateRange"));
		}
	}, [condoID, fetchInvitationsLogs, getValues]);

	const Loader = () => (
		<div style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
			<CircularProgress />
		</div>
	);

	if (!condoID) {
		return <Loader />;
	}

	if (isLoadingInvitationsData) {
		return <Loader />;
	}

	return (
		<section style={{ width: "95%", height: 350 }}>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
					paddingBottom: "2%",
					marginTop: "2em"
				}}
			>
				<DateRangePicker
					defaultStartDate={getValues("dateRange").startDate}
					defaultEndDate={getValues("dateRange").endDate}
					shouldHideFutureDates={true}
					onChange={dates => {
						const dateRange = { startDate: dates.startDate, endDate: dates.endDate };

						setValue("dateRange", dateRange);
						fetchInvitationsLogs(condoID, dateRange);
					}}
				/>
			</div>

			<CustomBarChart data={invitationsCountsData.reverse()} barsData={invitationsBarsData} />
		</section>
	);
};

export default memo(InvitationsBarChart);
