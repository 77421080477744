import { gql } from "@apollo/client";

const schema = `
  id
  description
  register_type
  active
  date
  user_id
  resident_id
  visit_id
`;

export type visitLogSchema = {
  id: number;
  personal_identification: string;
  name: string;
  last_name: string;
  reason: string;
  date: string;
  time: string;
  property_id: number;
  delivery_id: number;
  active: boolean;
};

export type RequestGetVisitLog = {
  GetActionLogsVisitByCondoID?: undefined | null | Array<visitLogSchema>;
};

export const query_get_visit = gql`
query GetActionLogsVisitByCondoID($condo_id: Int!) {
	GetActionLogsVisitByCondoID(condo_id: $condo_id) {
		${schema}
	}
}
`;