import React, { useCallback, useEffect, useState } from "react";
import { useCore } from "../../../Context/Core";
import { Box, Button, ButtonGroup, Divider } from "@mui/material";
import { Add } from "@mui/icons-material";
import CustomTable, { RowTable } from "../../Components/CustomTable";
import useTable from "../../Components/CustomTable/useTable";
import useGraphQL from "../../../Hooks/useGraphQL";

import ModalParking from "./ModalParking";
import GuardCondos from "../../Shared/GuardCondos";
import ModalUpdateParking from "./ModalUpdateParking";
import AlertDialog from "../../Components/CustomAlertDialog";
import useErrorGraphql from "../../../Hooks/useErrorGraphql";
import {
	mutation_active_parking,
	mutation_create_parking,
	mutation_delete_parking,
	mutation_update_parking,
	query_get_all_parking,
	type FormattedParkingLot,
	type ToggleParkingLotActiveStatusResponseData,
	type CreateParkingResponseData,
	type DeleteParkingLotResponseData,
	type GetAllParkingLotsResponseData,
	type UpdateParkingLotResponseDate,
	getIsReservedForVisitorsSpanishDescription
} from "./Querys";

import { ToastContainer, toast } from "react-toastify";

const head = [
	{ id: "label", label: "Etiqueta del Estacionamiento" },
	{ id: "is_reserved_for_visitors_spanish_description", label: "Reservado para Visitas" },
	{ id: "active", label: "Activo/Inactivo" },
	{ id: "actions", label: "Acciones" }
];

const CondosParking = () => {
	const [isAdd, setAdd] = useState<boolean>(false);

	const [isUpdate, setUpdate] = useState<{
		active: boolean;
		label: string | undefined;
		id: undefined | number;
	}>({ active: false, label: undefined, id: undefined });

	const [currentList, setCurrentList] = useState<FormattedParkingLot[]>([]);

	const [confirmDeleteParking, setConfirmDeleteParking] = useState<boolean>(false);
	const [idDelete, setIdDelete] = useState<{ id: number } | undefined>();

	const { condo } = useCore();

	const { useLazyGraphQuery, useGraphMutation } = useGraphQL();

	const [loadParkings] = useLazyGraphQuery<GetAllParkingLotsResponseData>(query_get_all_parking);
	const [createParking] = useGraphMutation<CreateParkingResponseData>(mutation_create_parking);
	const [updateParking] = useGraphMutation<UpdateParkingLotResponseDate>(mutation_update_parking);
	const [deleteParking] = useGraphMutation<DeleteParkingLotResponseData>(mutation_delete_parking);
	const [activeParking] = useGraphMutation<ToggleParkingLotActiveStatusResponseData>(mutation_active_parking);

	const { errorsField, setErrorFields } = useErrorGraphql();

	const tableProps = useTable();

	const handleCreate = useCallback(async (label: string, isReservedForVisitors?: boolean) => {
		if (condo) {
			const response = await createParking({
				variables: { label, isReservedForVisitors }
			});

			if (response.data) {
				const list = response.data.CreateParking;

				if (list) {
					toast.success("Estacionamiento agregado correctamente");
					setAdd(false);

					setCurrentList(
						list.map(parkingLot => ({
							...parkingLot,
							is_reserved_for_visitors_spanish_description: parkingLot.is_reserved_for_visitors
								? "Sí"
								: "No"
						}))
					);
				}
			}
		}
	}, []);

	const handleUpdate = useCallback(async (label: string, id: number) => {
		if (condo) {
			// update parking
			const response = await updateParking({
				variables: { id, label }
			}); // mock para eliminar error
			if (response.data) {
				const list = response.data.UpdateParking;
				if (list) {
					toast.success("Estacionamiento actualizado correctamente");
					setUpdate({ active: false, label: undefined, id: undefined });

					setCurrentList(
						list.map(parkingLot => ({
							...parkingLot,
							is_reserved_for_visitors_spanish_description: getIsReservedForVisitorsSpanishDescription(
								parkingLot.is_reserved_for_visitors
							)
						}))
					);
				}
			}
		}
	}, []);

	const onActive = async (row: RowTable) => {
		if (condo) {
			const response = await activeParking({
				variables: {
					id: Number(row.id)
				}
			});
			if (response.data) {
				const list = response.data.ActiveParking;
				if (list) {
					toast.success("Estacionamiento activado correctamente");

					setCurrentList(
						list.map(parkingLot => ({
							...parkingLot,
							is_reserved_for_visitors_spanish_description: getIsReservedForVisitorsSpanishDescription(
								parkingLot.is_reserved_for_visitors
							)
						}))
					);
				}
			}
		}
	};

	const handleDelete = useCallback(async (id: number) => {
		if (condo) {
			const response = await deleteParking({
				variables: {
					id
				}
			});
			if (response.data) {
				const list = response.data.DeleteParking;
				if (list) {
					toast.success("Estacionamiento eliminado correctamente");
					setConfirmDeleteParking(false);
					setIdDelete(undefined);

					setCurrentList(
						list.map(parkingLot => ({
							...parkingLot,
							is_reserved_for_visitors_spanish_description: getIsReservedForVisitorsSpanishDescription(
								parkingLot.is_reserved_for_visitors
							)
						}))
					);
				}
			}
		}
	}, []);

	const handleLoadParkings = useCallback(() => {
		if (condo) {
			// load list parking
			loadParkings().then(response => {
				if (response && response.data) {
					const list = response.data.GetAllParkings;

					setCurrentList(
						list.map(parkingLot => ({
							...parkingLot,
							is_reserved_for_visitors_spanish_description: getIsReservedForVisitorsSpanishDescription(
								parkingLot.is_reserved_for_visitors
							)
						}))
					);
				}
			});
		}
	}, []);

	const ParkingTable = () => (
		<CustomTable
			controls={{
				...tableProps,
				onUpdate: row => {
					setErrorFields(undefined);
					setUpdate({
						active: true,
						label: row.label as string,
						id: Number(row.id)
					});
				},
				onActiveStatusToggle: onActive,
				onDelete: ({ id }) => {
					setConfirmDeleteParking(true);
					setIdDelete({ id: Number(id) });
				}
			}}
			columns={head}
			rows={currentList}
		/>
	);

	useEffect(() => {
		handleLoadParkings();
	}, [condo]);

	return (
		<Box>
			<GuardCondos />

			<ButtonGroup style={{ paddingTop: 20, paddingBottom: 20 }} variant="text" aria-label="text button group">
				<Button
					onClick={() => {
						setErrorFields(undefined);
						setAdd(true);
					}}
				>
					<Add /> Agregar Estacionamiento
				</Button>
			</ButtonGroup>
			<Divider style={{ marginTop: 15, marginBottom: 15 }} />
			<ParkingTable />
			<ModalParking
				errorsField={errorsField ? errorsField : []}
				handleClose={() => setAdd(false)}
				onAdd={handleCreate}
				onCancel={() => setAdd(false)}
				visible={isAdd}
			/>
			<ModalUpdateParking
				errorsField={errorsField ? errorsField : []}
				handleClose={() => setUpdate({ active: false, label: undefined, id: undefined })}
				onUpdate={handleUpdate}
				onCancel={() => setUpdate({ active: false, label: undefined, id: undefined })}
				visible={isUpdate.active}
				label={isUpdate.label}
				id={isUpdate.id}
			/>
			<AlertDialog
				title={"Advertencia"}
				open={confirmDeleteParking}
				setOpen={setConfirmDeleteParking}
				warningMessage={"Estás apunto de eliminar un estacionamiento. ¿Deseas continuar?"}
				onCancel={() => setIdDelete(undefined)}
				onConfirm={() => {
					if (idDelete) handleDelete(idDelete.id);
				}}
			/>

			<ToastContainer position="bottom-left" autoClose={3000} />
		</Box>
	);
};

export default CondosParking;
