import React, { useCallback, useEffect, useState } from "react";
import { useCore } from "../../../Context/Core";
import { Box, Button, ButtonGroup, Divider } from "@mui/material";
import { Add } from "@mui/icons-material";
import CustomTable, { RowTable } from "../../Components/CustomTable";
import useTable from "../../Components/CustomTable/useTable";
import useGraphQL from "../../../Hooks/useGraphQL";
import MaintenanceCompanyModal from "./MaintenanceCompanyModal";
import GuardCondos from "../../Shared/GuardCondos";
import MaintenanceCompanyUpdateModal from "./MaintenanceCompanyUpdateModal";
import AlertDialog from "../../Components/CustomAlertDialog";
import useErrorGraphql from "../../../Hooks/useErrorGraphql";
import {
	type MaintenanceCompanySchema,
	type RequestCreateMaintenanceCompany,
	type RequestDeleteMaintenanceCompany,
	type RequestGetMaintenanceCompany,
	type RequestUpdateMaintenanceCompany,
	type ToggleMaintenanceCompanyRequest,
	type RequestGetTypeMaintenance,
	type TypesMaintenanceSchema,
	mutation_delete_maintenance_company,
	mutation_create_maintenance_company,
	mutation_toggle_maintenance_company,
	mutation_update_maintenance_company,
	query_get_maintenance_company,
	query_get_type_maintenance
} from "./Query";

import { ToastContainer, toast } from "react-toastify";

const head = [
	{ id: "title", label: "Nombre" },
	{ id: "active", label: "Activo/Inactivo" },
	{ id: "actions", label: "Acciones" }
];

const CondosCompany = () => {
	const [isAdd, setAdd] = useState<boolean>(false);

	const [isUpdate, setUpdate] = useState<{
		active: boolean;
		maintenanceCompany: MaintenanceCompanySchema | undefined;
	}>({ active: false, maintenanceCompany: undefined });

	const [currentList, setCurrentList] = useState<Array<MaintenanceCompanySchema>>([]);
	const [typeMaintenanceList, setTypeMaintenanceList] = useState<Array<TypesMaintenanceSchema>>([]);

	const [confirmDeleteMaintenanceCompany, setConfirmDeleteMaintenanceCompany] = useState<boolean>(false);
	const [idDelete, setIdDelete] = useState<{ id: number } | undefined>();

	const { condo } = useCore();

	const { useLazyGraphQuery, useGraphMutation } = useGraphQL();

	const { errorsField, setErrorFields } = useErrorGraphql();

	const tableProps = useTable();

	const [loadMaintenanceCompany] = useLazyGraphQuery<RequestGetMaintenanceCompany>(query_get_maintenance_company);

	const [loadTypesMaintenance] = useLazyGraphQuery<RequestGetTypeMaintenance>(query_get_type_maintenance);

	const [createMaintenanceCompany] = useGraphMutation<RequestCreateMaintenanceCompany>(
		mutation_create_maintenance_company
	);
	const [deleteMaintenanceCompanies] = useGraphMutation<RequestDeleteMaintenanceCompany>(
		mutation_delete_maintenance_company
	);
	const [toogleMaintenanceCompanies] = useGraphMutation<ToggleMaintenanceCompanyRequest>(
		mutation_toggle_maintenance_company
	);
	const [updateMaintenanceCompany] = useGraphMutation<RequestUpdateMaintenanceCompany>(
		mutation_update_maintenance_company
	);

	const handleCreate = async ({
		title,
		maintenance_type_ids
	}: {
		title: string;
		maintenance_type_ids: Array<number>;
	}) => {
		if (condo) {
			const reponse = await createMaintenanceCompany({
				variables: {
					creationForm: {
						title,
						maintenance_type_ids
					}
				}
			});

			const list = reponse.data?.CreateMaintenanceCompany;

			if (list) {
				toast.success("Empresa agregada correctamente");
				setAdd(false);
				setCurrentList(list);
			}
		}
	};

	const handleUpdate = async ({
		id,
		title,
		maintenance_type_ids
	}: {
		id: number;
		title: string;
		maintenance_type_ids: Array<number>;
	}) => {
		if (condo) {
			const reponse = await updateMaintenanceCompany({
				variables: {
					updateForm: {
						title,
						id: Number(id),
						maintenance_type_ids
					}
				}
			});

			const list = reponse.data?.UpdateMaintenanceCompany;
			if (list) {
				toast.success("Empresa actualizada correctamente");
				setUpdate({ active: false, maintenanceCompany: undefined });
				setCurrentList(list);
			}
		}
	};

	const onActive = async (row: RowTable) => {
		if (condo) {
			const response = await toogleMaintenanceCompanies({
				variables: {
					company_id: Number(row.id)
				}
			});
			const list = response.data?.ToggleMaintenanceCompanyActiveStatus;
			if (list) setCurrentList(list);
		}
	};

	const handleDelete = async (id: number) => {
		const response = await deleteMaintenanceCompanies({
			variables: { company_id: Number(id) }
		});
		const list = response.data?.DeleteMaintenanceCompany;
		if (list) {
			toast.success("Empresa Eliminada correctamente");
			setCurrentList(list);
			setConfirmDeleteMaintenanceCompany(false);
			setIdDelete(undefined);
		}
	};

	const handleLoadMaintenanceCompanies = useCallback(() => {
		if (condo)
			loadMaintenanceCompany().then(response => {
				const list = response.data?.GetAllMaintenanceCompaniesByCondoID;
				if (list) setCurrentList(list);
			});
	}, [condo, loadMaintenanceCompany]);
	const handleLoadTypeMaintenance = useCallback(() => {
		if (condo)
			loadTypesMaintenance().then(response => {
				const list = response.data?.GetAllMaintenanceTypesByCondoID;
				if (list) {
					setTypeMaintenanceList(list);
				}
			});
	}, [condo, loadTypesMaintenance]);

	const MaintenanceCompanyTable = () => (
		<CustomTable
			controls={{
				...tableProps,
				onUpdate: row => {
					setErrorFields(undefined);
					setUpdate({
						active: true,
						maintenanceCompany: row as unknown as MaintenanceCompanySchema
					});
				},
				onActiveStatusToggle: onActive,
				onDelete: ({ id }) => {
					setConfirmDeleteMaintenanceCompany(true);
					setIdDelete({ id: Number(id) });
				}
			}}
			columns={head}
			rows={currentList}
		/>
	);

	useEffect(() => {
		handleLoadTypeMaintenance();
		handleLoadMaintenanceCompanies();
	}, [condo, handleLoadTypeMaintenance, handleLoadMaintenanceCompanies]);

	return (
		<Box>
			<GuardCondos />

			<ButtonGroup style={{ paddingTop: 20, paddingBottom: 20 }} variant="text" aria-label="text button group">
				<Button
					onClick={() => {
						setErrorFields(undefined);
						setAdd(true);
					}}
				>
					<Add /> Agregar Empresa
				</Button>
			</ButtonGroup>
			<Divider style={{ marginTop: 15, marginBottom: 15 }} />
			<MaintenanceCompanyTable />
			<MaintenanceCompanyModal
				errorsField={errorsField ? errorsField : []}
				handleClose={() => setAdd(false)}
				onAdd={handleCreate}
				onCancel={() => setAdd(false)}
				visible={isAdd}
				listTypeMaintenance={typeMaintenanceList}
			/>
			<MaintenanceCompanyUpdateModal
				errorsField={errorsField ? errorsField : []}
				handleClose={() => setUpdate({ active: false, maintenanceCompany: undefined })}
				onUpdate={handleUpdate}
				onCancel={() => setUpdate({ active: false, maintenanceCompany: undefined })}
				visible={isUpdate.active}
				maintenanceCompany={isUpdate.maintenanceCompany}
				listTypeMaintenance={typeMaintenanceList}
			/>
			<AlertDialog
				title="Advertencia"
				open={confirmDeleteMaintenanceCompany}
				setOpen={setConfirmDeleteMaintenanceCompany}
				warningMessage="Estás apunto de eliminar una empresa. ¿Deseas continuar?"
				onCancel={() => setIdDelete(undefined)}
				onConfirm={() => {
					if (idDelete) handleDelete(idDelete.id);
				}}
			/>
			<ToastContainer position="bottom-left" autoClose={3000} />
		</Box>
	);
};

export default CondosCompany;
