import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { useCore } from "../../Context/Core";
import { PLTFieldErrors } from "../../Types";

const useErrorGraphql = () => {
	const { ErrorSubject } = useCore();
	const [errorsField, setErrorFields] = useState<PLTFieldErrors | undefined>();

	useEffect(() => {
		let Sub: null | Subscription = null;
		if (ErrorSubject) {
			Sub = ErrorSubject.subscribe(FieldsError => {
				if (Array.isArray(FieldsError)) setErrorFields(FieldsError);
			});
		}
		return () => {
			console.log("clean state");
			setErrorFields(undefined);
			if (Sub) Sub.unsubscribe();
		};
	}, [ErrorSubject]);

	return {
		errorsField,
		setErrorFields
	};
};
export default useErrorGraphql;
