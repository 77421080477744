import { Box, Button, ButtonGroup, CircularProgress, Link, Typography } from "@mui/material";
import "./bulk.css";
import useIntegrationBulkloads from "./Hooks/useIntegrationBulkloads";
import { CloudUpload as CloudUploadIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { ChangeEvent, useState, useMemo } from "react";
import CustomTable, { type ControlsTable } from "../../Components/CustomTable";
import useTable from "../../Components/CustomTable/useTable";
import { AxiosError } from "axios";

interface CondosDataFieldValidationError {
	readonly [key: string]: any;
	readonly message: string;
	readonly fieldKey: number;
	readonly fieldIndex: number;
	readonly sheetName: string;
}

interface CondosDataCreatedCounts {
	readonly createdCondosCount: number;
	readonly createdPropertiesCount: number;
	readonly createdParkingLotsCount: number;
	readonly createdResidentsCount: number;
}

interface CondosDataFieldsValidationErrors {
	readonly condosFieldValidationErrors: CondosDataFieldValidationError[];
	readonly propertiesFieldValidationErrors: CondosDataFieldValidationError[];
	readonly parkingLotsFieldValidationErrors: CondosDataFieldValidationError[];
	readonly residentsFieldValidationErrors: CondosDataFieldValidationError[];
}

interface CondosDataCreationResult {
	readonly createdCounts: CondosDataCreatedCounts;
	readonly fieldsValidationErrors: CondosDataFieldsValidationErrors;
}

const Input = styled("input")({
	display: "none"
});

const headerRow = [
	{ id: "message", label: "Error" },
	{ id: "sheetName", label: "Hoja" },
	{ id: "fieldIndex", label: "Fila" }
];

const MessageRender = ({ title, message, close }: { title: string; message: string; close: () => void }) => (
	<Box
		style={{
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center"
		}}
	>
		<Typography variant="h5" component="div" gutterBottom>
			{title}
		</Typography>

		<Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
			{message}
		</Typography>

		<ButtonGroup
			style={{
				paddingTop: 10,
				justifyContent: "flex-end",
				alignItems: "flex-end",
				display: "flex",
				flexDirection: "row"
			}}
			variant="text"
			aria-label="text button group"
		>
			<Button onClick={close}>Aceptar</Button>
		</ButtonGroup>
	</Box>
);

const BulkLoads = () => {
	const { downloadFile, uploadExcelFile, isLoading } = useIntegrationBulkloads();

	const [result, setResult] = useState({ message: "", title: "", active: false });
	const [fieldErrors, setFieldErrors] = useState<CondosDataFieldValidationError[]>([]);

	const tableProps = useTable();
	const customTableControls: ControlsTable = useMemo(() => tableProps, [tableProps]);

	const FieldErrorsTable = () => (
		<CustomTable controls={customTableControls} columns={headerRow} rows={fieldErrors} />
	);

	const handleCondosDataCreationResultErrors = (errors: CondosDataFieldsValidationErrors) => {
		type FieldsValidationErrorsKeys = keyof typeof errors;
		type FieldsValidationErrors = (typeof errors)[FieldsValidationErrorsKeys];

		let allFieldsValidationErrors = Object.values(errors) as FieldsValidationErrors;

		allFieldsValidationErrors = allFieldsValidationErrors
			.filter(fieldsValidationErrors => fieldsValidationErrors.length > 0)
			.flat()
			.map(fieldValidationError => ({
				...fieldValidationError,
				fieldIndex: fieldValidationError.fieldIndex + 1
			}));

		if (allFieldsValidationErrors.length > 0) {
			setFieldErrors(allFieldsValidationErrors);
		}
	};

	const handleCondosDataCreationResult = (creationResult: CondosDataCreationResult) => {
		const createdObjectsMessages = [
			`Condominios creados: ${creationResult.createdCounts.createdCondosCount}`,
			`Inmuebles creados: ${creationResult.createdCounts.createdPropertiesCount}`,
			`Estacionamientos creados: ${creationResult.createdCounts.createdParkingLotsCount}`,
			`Residentes creados: ${creationResult.createdCounts.createdResidentsCount}`
		];

		setResult({
			message: createdObjectsMessages.join("\n"),
			active: true,
			title: "¡Importación exitosa!"
		});

		handleCondosDataCreationResultErrors(creationResult.fieldsValidationErrors);
	};

	const uploadCondosDataFile = async (event: ChangeEvent<HTMLInputElement>) => {
		const defaultErrorMessage = "Error al subir el archivo. Por favor, intenta nuevamente.";

		try {
			const files = event.target.files;

			if (files) {
				const response = await uploadExcelFile(files[0]);

				if (!response.data) {
					setResult({
						message: defaultErrorMessage,
						active: true,
						title: "Ups, ¡algo ha ocurrido!"
					});

					return;
				}

				handleCondosDataCreationResult(response.data);
			}
		} catch (error) {
			setResult({
				message: error instanceof AxiosError ? `${error.response?.data?.error?.message}.` : defaultErrorMessage,
				active: true,
				title: "Ups, ¡algo ha ocurrido!"
			});
		}
	};

	return (
		<Box>
			<Box className="file-upload-container">
				<Typography variant="h5" textAlign="center">
					<p>Ingresa datos a tu sistema mediante tu archivo Excel.</p>

					<p>
						<small>
							En caso de que no tengas el archivo base, descárgalo{" "}
							<Link href="#" color="inherit" onClick={downloadFile}>
								acá
							</Link>
							.
						</small>
					</p>
				</Typography>

				<Box className="file-upload-content" sx={{ p: 0 }}>
					{isLoading ? (
						<CircularProgress />
					) : result.active ? (
						<MessageRender
							close={() => {
								setResult({ message: "", title: "", active: false });
								setFieldErrors([]);
							}}
							message={result.message}
							title={result.title}
						/>
					) : (
						<label htmlFor="contained-button-file">
							<Input
								accept="*"
								id="contained-button-file"
								multiple
								type="file"
								onChange={uploadCondosDataFile}
							/>

							<Button
								variant="contained"
								color="primary"
								component="span"
								startIcon={<CloudUploadIcon />}
							>
								Seleccionar Archivo
							</Button>
						</label>
					)}
				</Box>
			</Box>

			{fieldErrors.length > 0 && <FieldErrorsTable />}
		</Box>
	);
};

export default BulkLoads;
