import { useCallback, useEffect, useState, useMemo } from "react";
import { Box, Button, ButtonGroup, Divider } from "@mui/material";
import { Add } from "@mui/icons-material";
import CustomTable, { type ControlsTable, type RowTable } from "../../Components/CustomTable";
import SupportContactCreationModal, { type SupportContactCreationInput } from "./SupportContactCreationModal";
import SupportContactUpdateModal, { type SupportContactUpdateInput } from "./SupportContactUpdateModal";
import useTable from "../../Components/CustomTable/useTable";
import useGraphQL from "../../../Hooks/useGraphQL";
import AlertDialog from "../../Components/CustomAlertDialog";
import useErrorGraphql from "../../../Hooks/useErrorGraphql";

import {
	CREATE_SUPPORT_CONTACT,
	GET_ALL_SUPPORT_CONTACTS,
	UPDATE_SUPPORT_CONTACT,
	DELETE_SUPPORT_CONTACT,
	TOGGLE_SUPPORT_CONTACT_ACTIVE_STATUS,
	TOGGLE_SUPPORT_CONTACT_MAIN_STATUS,
	type SupportContactSchema,
	type CreateSupportContactData,
	type GetAllSupportContactsData,
	type UpdateSupportContactData,
	type DeleteSupportContactData,
	type ToggleSupportContactActiveStatusData,
	type ToggleSupportContactMainStatusData
} from "./Query";

import { ToastContainer, toast } from "react-toastify";

const headerRow = [
	{ id: "name", label: "Nombre" },
	{ id: "phone_number", label: "Número Telefónico" },
	{ id: "description", label: "Descripción" },
	{ id: "is_active", label: "Activo/Inactivo" },
	{ id: "is_main", label: "Principal" },
	{ id: "actions", label: "Acciones" }
];

export default function SupportContactsScreen(): JSX.Element {
	const [supportContacts, setSupportContacts] = useState<SupportContactSchema[]>([]);
	const [isCreatingSupportContact, setIsCreatingSupportContact] = useState(false);

	const [updateState, setUpdateState] = useState<{
		isUpdating: boolean;
		supportContact?: SupportContactSchema;
	}>({ isUpdating: false, supportContact: undefined });

	const [shouldConfirmDeletion, setShouldConfirmDeletion] = useState(false);
	const [idToDelete, setIDToDelete] = useState<number | undefined>();

	const { useLazyGraphQuery, useGraphMutation } = useGraphQL();
	const { errorsField, setErrorFields } = useErrorGraphql();

	const [createSupportContact] = useGraphMutation<CreateSupportContactData>(CREATE_SUPPORT_CONTACT);
	const [getAllSupportContacts] = useLazyGraphQuery<GetAllSupportContactsData>(GET_ALL_SUPPORT_CONTACTS);
	const [updateSupportContact] = useGraphMutation<UpdateSupportContactData>(UPDATE_SUPPORT_CONTACT);
	const [deleteSupportContact] = useGraphMutation<DeleteSupportContactData>(DELETE_SUPPORT_CONTACT);

	const [toggleSupportContactActiveStatus] = useGraphMutation<ToggleSupportContactActiveStatusData>(
		TOGGLE_SUPPORT_CONTACT_ACTIVE_STATUS
	);

	const [toggleSupportContactMainStatus] = useGraphMutation<ToggleSupportContactMainStatusData>(
		TOGGLE_SUPPORT_CONTACT_MAIN_STATUS
	);

	useEffect(() => {
		async function fetchSupportContacts(): Promise<void> {
			const response = await getAllSupportContacts();

			if (response.data) {
				setSupportContacts(response.data.GetAllSupportContacts);
			}
		}

		fetchSupportContacts();
	}, [getAllSupportContacts, setSupportContacts]);

	const supportContactCreationCallback = useCallback(
		async (creationInput: SupportContactCreationInput) => {
			const response = await createSupportContact({
				variables: { creationInput }
			});

			if (response.data) {
				toast.success("Contacto de soporte agregado correctamente");

				setIsCreatingSupportContact(false);
				setSupportContacts(response.data.CreateSupportContact);
			}
		},
		[createSupportContact, toast, setIsCreatingSupportContact, setSupportContacts]
	);

	const supportContactUpdateCallback = useCallback(
		async (supportContactID: number, updateInput: SupportContactUpdateInput) => {
			const response = await updateSupportContact({
				variables: {
					id: supportContactID,
					updateInput
				}
			});

			if (response.data) {
				toast.success("Contacto de soporte actualizado correctamente");

				setUpdateState({ isUpdating: false, supportContact: undefined });
				setSupportContacts(response.data.UpdateSupportContact);
			}
		},
		[updateSupportContact, toast, setUpdateState, setSupportContacts]
	);

	const supportContactDeletionCallback = useCallback(async () => {
		if (idToDelete === undefined) {
			return;
		}

		const response = await deleteSupportContact({
			variables: { id: idToDelete }
		});

		if (response.data?.DeleteSupportContact) {
			toast.success("Contacto de soporte eliminado correctamente");

			const supportContactsCopy = [...supportContacts];
			delete supportContactsCopy[
				supportContactsCopy.findIndex(supportContact => supportContact.id === idToDelete)
			];

			setSupportContacts(supportContactsCopy);

			setShouldConfirmDeletion(false);
			setIDToDelete(undefined);
		}
	}, [
		idToDelete,
		deleteSupportContact,
		toast,
		supportContacts,
		setSupportContacts,
		setShouldConfirmDeletion,
		setIDToDelete
	]);

	const deletionCancelingCallback = useCallback(() => {
		setIDToDelete(undefined);
	}, [setIDToDelete]);

	const onUpdateCallback = useCallback(
		(row: RowTable) => {
			setErrorFields(undefined);
			setUpdateState({
				isUpdating: true,
				supportContact: row as SupportContactSchema
			});
		},
		[setErrorFields, setUpdateState]
	);

	const onDeleteCallback = useCallback(
		(row: RowTable) => {
			if (supportContacts.find(supportContact => supportContact.id === row.id)?.is_main) {
				return;
			}

			setShouldConfirmDeletion(true);
			setIDToDelete(row.id as number);
		},
		[supportContacts, setShouldConfirmDeletion, setIDToDelete]
	);

	const onActiveStatusToggleCallback = useCallback(
		async (row: RowTable) => {
			const response = await toggleSupportContactActiveStatus({
				variables: {
					id: row.id
				}
			});

			if (response.data?.ToggleSupportContactActiveStatus) {
				const supportContactsCopy = [...supportContacts];
				const supportContact = supportContactsCopy.find(supportContact => supportContact.id === row.id)!;

				supportContact.is_active = !supportContact.is_active;

				setSupportContacts(supportContactsCopy);
			}
		},
		[toggleSupportContactActiveStatus, supportContacts, setSupportContacts]
	);

	// Temporal
	const onMainStatusToggleCallback = useCallback(
		async (row: RowTable) => {
			const response = await toggleSupportContactMainStatus({
				variables: {
					id: row.id
				}
			});

			if (response.data?.ToggleSupportContactMainStatus) {
				setSupportContacts(
					supportContacts.map(supportContact => ({
						...supportContact,
						is_main: supportContact.id === row.id
					}))
				);
			}
		},
		[toggleSupportContactMainStatus, setSupportContacts, supportContacts]
	);

	const tableProps = useTable();

	const customTableControls: ControlsTable = useMemo(
		() => ({
			...tableProps,
			onUpdate: onUpdateCallback,
			onDelete: onDeleteCallback,
			onActiveStatusToggle: onActiveStatusToggleCallback,
			onMainStatusToggle: onMainStatusToggleCallback
		}),
		[tableProps, onUpdateCallback, onDeleteCallback, onActiveStatusToggleCallback, onMainStatusToggleCallback]
	);

	const SupportContactsTable = () => (
		<CustomTable controls={customTableControls} columns={headerRow} rows={supportContacts} />
	);

	const onContactCreationModalCloseCallback = useCallback(() => {
		setIsCreatingSupportContact(false);
	}, [setIsCreatingSupportContact]);

	const onContactUpdateModalCloseCallback = useCallback(() => {
		setUpdateState({ isUpdating: false, supportContact: undefined });
	}, [setUpdateState]);

	return (
		<Box>
			<ButtonGroup style={{ paddingTop: 20, paddingBottom: 20 }} variant="text" aria-label="text button group">
				<Button
					onClick={() => {
						setErrorFields(undefined);
						setIsCreatingSupportContact(true);
					}}
				>
					<Add /> Agregar Contacto de Soporte
				</Button>
			</ButtonGroup>

			<Divider style={{ marginTop: 15, marginBottom: 15 }} />

			<SupportContactsTable />

			<SupportContactCreationModal
				errorsField={errorsField ?? []}
				isVisible={isCreatingSupportContact}
				onSuccess={supportContactCreationCallback}
				onClose={onContactCreationModalCloseCallback}
			/>

			<SupportContactUpdateModal
				errorsField={errorsField ?? []}
				isVisible={updateState.isUpdating}
				supportContact={updateState.supportContact!}
				onSuccess={supportContactUpdateCallback}
				onClose={onContactUpdateModalCloseCallback}
			/>

			<AlertDialog
				title="Advertencia"
				open={shouldConfirmDeletion}
				setOpen={setShouldConfirmDeletion}
				warningMessage="Estás a punto de eliminar un contacto de soporte. ¿Deseas continuar?"
				onCancel={deletionCancelingCallback}
				onConfirm={supportContactDeletionCallback}
			/>

			<ToastContainer position="bottom-left" autoClose={3000} />
		</Box>
	);
}
