import { gql } from "@apollo/client";

export interface DeliveryActionLogSchema {
	id: number;
	utc_timestamp: number;
	property_label: string;
	resident_full_name: string;
	user_full_name: string;
	packages_count: number;
	delivery_id: number | null;
	local_datetime: string;
	is_delivery_expired?: boolean;
}

export type GetInProgressDeliveryLogsResponseData = {
	GetInProgressDeliveryLogs: DeliveryActionLogSchema[];
};

export type FinishDeliveriesWithoutResidentResponseData = {
	finishDeliveriesWithoutResident: number;
};

const graphQLSchema = `
	id
	property_label
	resident_full_name
	user_full_name
	packages_count
	delivery_id
	local_datetime
	is_delivery_expired
`;

export const GET_IN_PROGRESS_DELIVERY_LOGS = gql`
	query GetInProgressDeliveryLogs($propertyLabel: String, $date: DateTime) {
		GetInProgressDeliveryLogs(propertyLabel: $propertyLabel, date: $date) {
     	 ${graphQLSchema}
    	}
  	}
`;

export const FINISH_DELIVERIES_WITHOUT_RESIDENT = gql`
	mutation FinishDeliveriesWithoutResident($deliveriesIDs: [Int!]!) {
		finishDeliveriesWithoutResident(deliveriesIDs: $deliveriesIDs)
	}
`;
