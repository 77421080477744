import { apiURL } from "../../../Hooks/useClientGraphql";

export interface LogoUploadResponse {
	imageURL?: string;
}

export default async function uploadLogoFile(logoFile: File, bearerToken: string): Promise<LogoUploadResponse> {
	const formData = new FormData();
	formData.append("logo", logoFile);

	try {
		const response = await fetch(`${apiURL}/api/logo`, {
			headers: {
				Authorization: `Bearer ${bearerToken}`
			},
			method: "POST",
			body: formData
		});

		if (!response.ok) {
			throw new Error(`Error HTTP ${response.status}`);
		}

		return await response.json();
	} catch (error) {
		console.error("Error al intentar subir el logo: ", error);
		return { imageURL: undefined };
	}
}
