import { Box, Modal } from "@mui/material";
import { PLTFieldErrors } from "../../../../Types";
import { ResidentSchema } from "../GraphQL";
import { PropertySchema } from "../../CondosProperty/GraphQL";
import FormResident, { type ResidentForm } from "../FormResident";

import { type ParkingSchema } from "../../CondosParking/Querys";
import type { FC } from "react";

type Props = {
	visible: boolean;
	errorsField: PLTFieldErrors;
	resident: ResidentSchema | undefined;
	handleClose: () => void;
	onCancel: () => void;
	onUpdate: (form: ResidentForm) => void;
	propertiesList: Array<PropertySchema>;
	listParking: ParkingSchema[];
};

const ModalUpdateResident: FC<Props> = ({
	visible,
	errorsField,
	resident,
	handleClose,
	onUpdate,
	onCancel,
	propertiesList,
	listParking
}) => (
	<Modal open={visible} onClose={handleClose}>
		<Box
			sx={{
				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				width: "50%",
				bgcolor: "background.paper",
				boxShadow: 24,
				p: 4,
				maxHeight: "80%",
				overflow: "auto"
			}}
		>
			<FormResident
				errorsField={errorsField}
				listParking={listParking}
				onCancel={onCancel}
				onSubmit={onUpdate}
				propertiesList={propertiesList}
				resident={resident}
			/>
		</Box>
	</Modal>
);

export default ModalUpdateResident;
